import React from 'react'
import './Stepsbar.scss'
import { useNavigate } from 'react-router-dom'

function Stepsbar({ step }) {
  const navigate = useNavigate()
  return (
    <div className="stepsbar">
      <div>
        <button
          onClick={() => navigate('/steps', { state: '0' })}
          className={step === '0' ? 'active' : ''}
          data-testid="step0actionbtn"
        >
          STEP 0
        </button>
        <button
          onClick={() => navigate('/steps', { state: '0.5' })}
          className={step === '0.5' ? 'active' : ''}
          data-testid="step05actionbtn"
        >
          STEP 0.5
        </button>
        <button
          onClick={() => navigate('/steps', { state: '1' })}
          className={step === '1' ? 'active' : ''}
          data-testid="step1actionbtn"
        >
          STEP 1
        </button>
      </div>
      <div>
        <button
          onClick={() => navigate('/grid')}
          className="active"
          data-testid="gridactionbtn"
        >
          GRID
        </button>
      </div>
    </div>
  )
}

export default Stepsbar
