import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import '../../../App.css'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import LoadingStatus from '../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { AuthContext } from '../../context/authProvider'
import { useNavigate } from 'react-router-dom'
import { createBadgeRequest } from '../../../redux/actions/badgeAction'
import { useLocation } from 'react-router-dom'

const SubmitRequest = ({ setShowModal, channel }) => {
  let tokenValue = jwt_decode(localStorage.getItem('token'))

  const [authCreds] = React.useContext(AuthContext)
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching Metadata details...',
  })
  //   const [userMail, setUserMail] = useState(tokenValue.preferred_username)
  const [roleValue, setRolevalue] = useState(false)
  const [requirement, setRequirement] = useState(null)
  const [badgeNames, setBadgenames] = useState()
  const [badgeName, setBadgeName] = useState('')
  const [badgeLevel, setBadgeLevel] = useState('')
  const [badgeLevels, setBadgeLevels] = useState()
  const [userEmail, setUserEmail] = useState(tokenValue.preferred_username)
  const [badgeId, setBadgeId] = useState('')
  const [evidence, setEvidence] = useState('')
  let history = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const route = location.pathname.split('/')[2]
  const endpoint = authCreds.restEndpoint

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Adding Details...' })

    if (
      badgeId == '' ||
      evidence == '' ||
      badgeName == '' ||
      badgeLevel == ''
    ) {
      toast('Please fill all fields', toastTopRightError)
      setLoadingStatus({ status: false, message: 'Adding Details...' })
    } else {
      const payload = {
        user_email: userEmail,
        badge_id: badgeId,
        evidence: evidence,
        badge_name: badgeName,
        badge_level_name: badgeLevel,
        role_type: channel,
      }
      dispatch(createBadgeRequest(endpoint, payload)).then(() => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        {
          route === 'my-badges'
            ? window.location.reload()
            : history('/badge/my-badges')
        }

        handleCloseModal()
      })
    }
  }

  // Logic to check Role
  useEffect(() => {
    if (
      tokenValue?.roles?.includes('khdapi.Admin') ||
      tokenValue?.roles?.includes('khdapi.collabadmin')
    ) {
      setRolevalue(true)
    } else {
      setRolevalue(false)
    }
  }, [])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(`${endpoint}/api/badges/get_static_badges?role_type=${channel}`, {
        headers: post_headers,
      })
      .then((res) => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        setRequirement('')
        setBadgenames(res?.data?.unique_badge_name)
      })
  }, [])
  const handleRequirement = (value) => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(
        `${endpoint}/api/badges/get_static_badges?filters=[{"op":"eq","field":"badge_name","value":"${badgeName}"},{"op":"eq","field":"badge_level_name","value":"${value}"},{"op":"eq","field":"role_type","value":"${channel}"}]`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        setBadgeLevel(value)
        setBadgeId(res?.data?.data[0]?.id)
        setRequirement(res?.data?.data[0]?.requirements)
      })
  }

  const handleRequirementBadge = (value) => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(
        `${endpoint}/api/badges/get_static_badges?filters=[{"op":"eq","field":"badge_name","value":"${value}"},{"op":"eq","field":"role_type","value":"${channel}"}]`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        setBadgeName(value)
        setBadgeLevels(res?.data?.data)
        setBadgeLevel('')
        setRequirement('')
      })
  }
  //Return Modal Design here
  return (
    <>
      {' '}
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Form>
            <Container className="m-0">
              <Row className="mb-3 mr-3 rowFlex">
                {roleValue == true ? (
                  <Form.Group
                    className=""
                    as={Col}
                    md="4"
                    controlId="validationFormikUsername"
                  >
                    <Form.Label>KHC Email</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        aria-describedby="inputGroupPrepend"
                        name="mail"
                        defaultValue={tokenValue.preferred_username}
                        onChange={(e) => setUserEmail(e.target.value)}
                        // isInvalid={!!errors.de_mail}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.de_mail}
                  </Form.Control.Feedback> */}
                    </InputGroup>
                  </Form.Group>
                ) : (
                  <Form.Group
                    className="flexItem"
                    as={Col}
                    md="4"
                    controlId="validationFormikUsername"
                  >
                    <Form.Label>KHC Email</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="Username"
                        aria-describedby="inputGroupPrepend"
                        name="mail"
                        defaultValue={tokenValue.preferred_username}
                        // onChange={(e) => setField('de_mail', e.target.value, e)}
                        // isInvalid={!!errors.de_mail}
                      />
                      {/* <Form.Control.Feedback type="invalid">
                    {errors.de_mail}
                  </Form.Control.Feedback> */}
                    </InputGroup>
                  </Form.Group>
                )}
                <Form.Group
                  className=""
                  as={Col}
                  md="4"
                  controlId="validationFormik01"
                >
                  <Form className="mt-2">
                    <Form.Label> </Form.Label>
                    <Form.Select
                      value={badgeName}
                      onChange={(e) => handleRequirementBadge(e.target.value)}
                      //   isInvalid={!!errors.vendor}
                    >
                      <option>Select badge</option>
                      {badgeNames?.sort().map((badge) => {
                        return <option key={badge}>{badge}</option>
                      })}
                    </Form.Select>
                  </Form>
                </Form.Group>

                <Form.Group
                  className=""
                  as={Col}
                  md="4"
                  controlId="validationFormik01"
                >
                  <Form className="mt-2">
                    <Form.Label> </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        handleRequirement(e.target.value)
                      }}
                      value={badgeLevel}
                      //   onChange={(e) => setField('vendor', e.target.value, e)}
                      //   isInvalid={!!errors.vendor}
                    >
                      <option>Select Level</option>
                      {badgeLevels?.map((bl) => {
                        return (
                          <option
                            key={bl.badge_level_name}
                            value={bl.badge_level_name}
                          >
                            {bl.badge_level_name}
                          </option>
                        )
                      })}
                    </Form.Select>
                  </Form>
                </Form.Group>
              </Row>
              <Row className="mb-3 mr-3 rowFlex">
                <Form.Group
                  className=""
                  as={Col}
                  // md="5"
                  controlId="validationFormikUsername"
                >
                  {requirement != '' ? (
                    <>
                      <Form.Label>Requirements:</Form.Label>
                      <p style={{ whiteSpace: 'break-spaces' }}>
                        <b>{requirement}</b>
                      </p>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}

                  <Form.Label>Evidence</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="evidence"
                      aria-describedby="inputGroupPrepend"
                      name="evidence"
                      as="textarea"
                      rows={4}
                      // defaultValue={tokenValue.preferred_username}
                      onChange={(e) => setEvidence(e.target.value)}
                      // isInvalid={!!errors.de_mail}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                    {errors.de_mail}
                  </Form.Control.Feedback> */}
                  </InputGroup>
                </Form.Group>
              </Row>
            </Container>
          </Form>
          <Modal.Footer>
            <Button
              variant="primary"
              className="addModalBtn"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="addModalBtn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  )
}
export default SubmitRequest
