import React, { useContext, useState, useEffect } from 'react'
import { getDbtReport } from '../../../redux/actions/dbtReportAction'
import { AuthContext } from '../../context/authProvider'

import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'

export default function dbtReport() {
  const [reportUrl, setReportUrl] = useState()
  const [authCreds] = useContext(AuthContext)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const dbtReportData = useSelector((state) => state?.dbtReportData)
  useEffect(() => {
    setLoadingStatus({
      status: true,
      message: 'Fetching Picklists for the columns...',
    })
    dispatch(getDbtReport(endpoint, localStorage.getItem('token'))).then(() => {
      setLoadingStatus({
        status: false,
        message: 'Fetching Picklists for the columns...',
      })
    })
  }, [])
  useEffect(() => {
    if (dbtReportData?.loading == false) {
      setReportUrl(dbtReportData?.dbtReportData?.blob_sas_url)
    }
  }, [dbtReportData])
  return (
    <>
      {loadingStatus.status == true ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div style={{ background: '#fff' }}>
          <iframe src={reportUrl} height={'1000px'} width={'100%'}></iframe>
        </div>
      )}
    </>
  )
}
