import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Navbar from '../../Navbar'
import NpsMenu from './npsMenu'
import HelpContent from './helpContent'

export default function index() {
  return (
    <>
      <Navbar title="NPS" HelpContent={HelpContent} />
      <NpsMenu />
      <div className="home-nps">
        <Link to="/create-survey">
          <Button variant="primary" style={{ width: '300px', margin: '8px' }}>
            Create Form
          </Button>{' '}
        </Link>
        <Link to="/view-surveys">
          <Button variant="secondary" style={{ width: '300px', margin: '8px' }}>
            View Forms
          </Button>
        </Link>
      </div>
    </>
  )
}
