import {
  READ_SNOWFLAKE_TABLE_DATA_BEGIN,
  READ_SNOWFLAKE_TABLE_DATA_FAIL,
  READ_SNOWFLAKE_TABLE_DATA_SUCCESS,
} from '../actions/snowFlakeCloneAction'
const initialState = {
  readSfTableData: [],
  handleSfTableData: [],
  handleEditSfTableData: [],
  loading: true,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case READ_SNOWFLAKE_TABLE_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case READ_SNOWFLAKE_TABLE_DATA_SUCCESS:
      return {
        ...state,
        readSfTableData: action.payload,
        loading: false,
      }
    case READ_SNOWFLAKE_TABLE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    //     case HANDLE_SNOWFLAKE_TABLE_DATA_BEGIN:
    //   return {
    //     ...state,
    //     loading: true
    //   }
    // case HANDLE_SNOWFLAKE_TABLE_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     handleSfTableData: action.payload,
    //     loading: false,
    //   };
    //   case HANDLE_SNOWFLAKE_TABLE_DATA_FAIL:
    //     return {
    //       ...state,
    //       loading:false,
    //       error: action.payload.error
    //     }
    //     case HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_BEGIN:
    //       return {
    //         ...state,
    //         loading: true
    //       }
    //     case HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_SUCCESS:
    //       return {
    //         ...state,
    //         handleEditSfTableData: action.payload,
    //         loading: false,
    //       };
    //       case HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_FAIL:
    //         return {
    //           ...state,
    //           loading:false,
    //           error: action.payload.error
    //         }
    default:
      return state
  }
}
