import {
  POD_FUNCTIONAL_AREA,
  POD_FUNCTIONAL_AREA_LOADING,
  POD_FUNCTIONAL_AREA_ERROR,
} from '../../constants/types'

const initialState = {
  podFunctionalAreas: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case POD_FUNCTIONAL_AREA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case POD_FUNCTIONAL_AREA:
      return {
        ...state,
        loading: false,
        podFunctionalAreas: action.payload,
      }
    case POD_FUNCTIONAL_AREA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
