import React, { useState } from 'react'
import LoadingStatus from '../../Helper/LoadingStatus'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import '../../../App.css'
import { AuthContext } from '../../context/authProvider'

import { useDispatch } from 'react-redux'
import { anzUploadFile } from '../../../redux/actions/anzRecommendationAction'
export default function AnzRecommendation() {
  const [authCreds] = React.useContext(AuthContext)

  const [anzfile, setAnzFile] = React.useState('')
  const [anzErrors, setAnzErrors] = useState({})
  const [anzForm, setAnzForm] = useState({})
  // const [showAnz, setShowAnz] = useState(false)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Downloading file...',
  })
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint

  //handle anz modal
  const handleCloseAnz = () => {
    // setShowAnz(false)
    setAnzErrors({})
    setAnzForm(new Object())
    // setAnzOption('')
  }
  const getRecommendation = () => {
    let formData = new FormData()
    formData.append('input_data', anzfile)

    setLoadingStatus({ status: true, message: 'Downloading file...' })
    dispatch(anzUploadFile(endpoint, formData, anzfile))
      .then(() => {
        setLoadingStatus({ status: false, message: 'Downloading file...' })
        handleCloseAnz()
      })
      .catch((error) => {
        return error
      })
  }

  //handle anz form submit
  const handleAnzSubmit = (e) => {
    e.preventDefault()
    // get our new errors
    const newAnzErrors = findAnzFormErrors()
    // Conditional logic:
    if (Object.keys(newAnzErrors).length > 0) {
      // We got errors!
      setAnzErrors(newAnzErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      getRecommendation()
    }
  }

  //Find AZN form errors
  const findAnzFormErrors = () => {
    const { anz_excel } = anzForm
    const newAnzErrors = {}
    // ANZ excel errors
    if (!anz_excel || anz_excel === '')
      newAnzErrors.anz_excel = 'No file selected'
    else if (!anz_excel.includes('.xlsm'))
      newAnzErrors.anz_excel = 'Please select a .xlsm file'

    return newAnzErrors
  }

  //update error and form states and handle on change of anz
  const setAnzField = (field, value, e) => {
    setAnzForm({
      ...anzForm,
      [field]: value,
    })
    // Check and see if errors exist, and remove them from the error object:
    if (anzErrors[field])
      setAnzErrors({
        ...anzErrors,
        [field]: null,
      })

    if (field === 'anz_excel') {
      let anzfile = e.target.files[0]
      setAnzFile(anzfile)
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          Welcome to the ANZ Deployment Optimization & Recommendation Tool!
        </Modal.Title>
      </Modal.Header>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Text
                id="passwordHelpBlock"
                style={{ fontSize: '14px', lineHeight: '1' }}
                muted
              >
                <p>
                  Step 1: Please ensure to update the data in the ShipSchedule
                  tab for today’s date
                </p>
                <p>
                  Step 2: Please update the Input Parameter in the Control Tab
                  (# of Containers needed, Beef or No Beef, Priority SKUs, Beef
                  SKUs, Limited SKUs)
                </p>
                <p>Step 3: Upload the file and click Download Recommendation</p>
                <p>Step 4: Review recommendations and run Create PO lines</p>
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label className="frm-label" style={{ fontSize: '14px' }}>
                Select Excel File(.xlsm file)
              </Form.Label>
              <Form.Control
                type="file"
                size="sm"
                onChange={(e) => setAnzField('anz_excel', e.target.value, e)}
                isInvalid={!!anzErrors.anz_excel}
              />
              <Form.Control.Feedback type="invalid">
                {anzErrors.anz_excel}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleAnzSubmit}>
              Download Recommendation
            </Button>
          </Form>
        </Modal.Body>
      )}
    </>
  )
}
