import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightSuccessANZ,
  toastTopRightErrorANZ,
} from '../../components/Helper/ToastObjects'
export const anzUploadFile = (endpoint, payload, file1) => async (dispatch) => {
  try {
    dispatch(anzFileUploadBegin())
    let post_headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .post(
        `${endpoint}/api/anz_recommendation/anz_recommend?isDownloadable=true`,
        payload,
        {
          headers: post_headers,
          responseType: 'blob',
        }
      )
      .then((res) => {
        if (res.status != 200) {
          toast(
            'File processing failed due to an error!!',
            toastTopRightErrorANZ
          )
        } else {
          dispatch(anzFileUploadSuccess(res.data))
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${file1.name.slice(0, -5)}_recommendation.xlsx`
          )
          document.body.appendChild(link)
          link.click()
          toast('Transfer to sharepoint done', toastTopRightSuccessANZ)
        }
      })
  } catch (e) {
    dispatch(anzFileUploadFailure(e))
  }
}

export const ANZ_FILE_UPLOAD_BEGIN = 'ANZ_FILE_UPLOAD_BEGIN'
export const ANZ_FILE_UPLOAD_SUCCESS = 'ANZ_FILE_UPLOAD_SUCCESS'
export const ANZ_FILE_UPLOAD_FAIL = 'ANZ_FILE_UPLOAD_FAIL'

const anzFileUploadBegin = () => ({
  type: ANZ_FILE_UPLOAD_BEGIN,
})

const anzFileUploadSuccess = (getData) => ({
  type: ANZ_FILE_UPLOAD_SUCCESS,
  payload: getData,
})

const anzFileUploadFailure = (error) => ({
  type: ANZ_FILE_UPLOAD_FAIL,
  payload: { error },
})
