import React from 'react'
import { Table, Tabs, Tab } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

const ImportPreview = ({ importData }) => {
  return Object.keys(importData).length < 1 ? (
    <span>Loading....</span>
  ) : (
    <Tabs id="uncontrolled-tab-example" className="mb-3">
      <Tab
        eventKey="Insert"
        title={'Inserts (' + importData.insert.length + ')'}
      >
        {importData.insert.length < 1 ? (
          <p>No Inserts</p>
        ) : (
          <Table responsive key={Object.keys(importData).length}>
            <thead>
              <tr>
                {Object.keys(importData.insert[0]).map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {importData.insert.slice(0, 100).map((obj, i) => (
                <tr key={i}>
                  {Object.values(obj).map((row, index) => (
                    <td key={index}>{row}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Tab>
      <Tab
        eventKey="Update"
        title={'Updates (' + importData.update.length + ')'}
      >
        {importData.update.length < 1 ? (
          <p>No Updates</p>
        ) : (
          <Table responsive key={Object.keys(importData).length}>
            <thead>
              <tr>
                {Object.keys(importData.update[0]).map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {importData.update.slice(0, 100).map((obj, i) => (
                <tr key={i}>
                  {Object.values(obj).map((row, index) => (
                    <td key={index}>{row}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Tab>
    </Tabs>
  )
}

export default ImportPreview
