import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { AuthContext } from '../components/context/authProvider'

export const useAuthAndDispatch = () => {
  const dispatch = useDispatch()
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint

  return { dispatch, endpoint }
}
