import React, { useState, useContext } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './index.css'
import { AuthContext } from '../../../../context/authProvider'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../../../Helper/ToastObjects'
import 'quill-mention'
import { subscribePost } from '../../../../../redux/actions/collabAction'
import { useDispatch } from 'react-redux'
import { ForumHandler } from '../../../../Utils'
import MentionHandler from '../../../Utilities/quillSearch'

export default function Answer({ answer, questionData, user_email, func }) {
  const dispatch = useDispatch()

  const [authCreds] = useContext(AuthContext)

  const endpoint = authCreds.restEndpoint

  let userFetchTimeOut

  //react quill source callback
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('q')
  //   const [UpdateComment, setUpdateComment] = useState('')
  const [showUpdate, setUpdateShow] = useState(false)
  const [editorContent, setEditorContent] = useState('')

  const handleQuill = (value) => {
    setEditorContent(value)
  }
  const handleSubcriptionMulti = async (values) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      user_email: values,
      post_id: id,
    }

    dispatch(subscribePost(endpoint, body, false)).then(() => {
      func()
      setEditorContent('')
      setUpdateShow(false)
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    })
  }
  const handleUpdateAnswer = async (ans_id) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    if (editorContent.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      let tagName = questionData?.tags
      var finalArray = tagName.map(function (obj) {
        return obj.tag_name
      })
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = editorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      const body = {
        title: null,
        description: editorContent,
        user_email: answer?.user_email.toLowerCase(),
        channel: localStorage.getItem('channel'),
        parent_id: id,
        root_id: id,
        tags: finalArray,
        created_on: answer?.created_on,
        created_by: answer?.created_by.toLowerCase(),
        modified_by: user_email.toLowerCase(),
        mentioned_emails: mentionVal,
      }

      await axios
        .put(`${endpoint}/api/collab/edit_answer?answer_id=${ans_id}`, body, {
          headers: post_headers,
        })
        .then(() => {
          if (mentionVal.length > 0) {
            dispatch(handleSubcriptionMulti(mentionVal))
          } else {
            func()
            setEditorContent('')
            setUpdateShow(false)
            setLoadingStatus({ status: false, message: 'Fetching data...' })
          }
        })
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Answer can not be empty', toastTopRightError)
    }
  }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <EditIcon
            onClick={() => setUpdateShow(!showUpdate)}
            style={{ marginLeft: 'auto' }}
          />
          <div>
            {showUpdate ? (
              <>
                <div className="main-answer">
                  <h3
                    style={{
                      fontSize: '22px',
                      margin: '10px 0',
                      fontWeight: '400',
                    }}
                  >
                    Your Answer
                  </h3>

                  <ReactQuill
                    style={{ background: '#fff' }}
                    theme="snow"
                    className="react-quill"
                    defaultValue={answer.description}
                    onChange={handleQuill}
                    modules={module}
                  />
                </div>
                <button
                  onClick={() => handleUpdateAnswer(answer?.id)}
                  style={{
                    maxWidth: 'fit-content',
                  }}
                  className="post-ans-btn"
                >
                  Update answer
                </button>
              </>
            ) : null}
          </div>
        </>
      )}
    </>
  )
}
