import {
  GET_ADMIN_BADGES_LOAD,
  GET_BADGES_ADMIN,
  GET_BADGES_ADMIN_ERROR,
  ADD_BADGE_LOAD,
  ADD_BADGE_ERROR,
  ADD_BADGE_POST,
  EDIT_BADGE_ERROR,
  EDIT_BADGE_POST,
  EDIT_BADGE_LOAD,
} from '../constants/types'

const initialState = {
  adminBadges: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_BADGES_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_BADGES_ADMIN:
      return {
        ...state,
        adminBadges: action.payload,
        loading: false,
        error: null,
      }
    case GET_BADGES_ADMIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ADD_BADGE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_BADGE_POST:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case ADD_BADGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case EDIT_BADGE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case EDIT_BADGE_POST:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case EDIT_BADGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
