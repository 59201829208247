import { useMemo } from 'react'
import { ForumHandler } from '../../Utils'

class MentionHandler {
  static mentionconfig = (quillSearch) => {
    const module = useMemo(
      () => ({
        toolbar: false,
        clipboard: {
          matchVisual: false,
        },
        mention: {
          allowedChars: ForumHandler.allowedCharactersQuill,
          mentionDenotationChars: ['@'],
          onSelect: function (item, insertItem) {
            item.value = item.id.split('@')[0]
            insertItem(item)
          },
          source: quillSearch,
        },
      }),
      []
    )
    return module
  }
}

export default MentionHandler
