import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightSuccessANZ,
  toastTopRightErrorANZ,
} from '../../../components/Helper/ToastObjects'
import {
  GET_DE_FILE_UPLOAD_PATH,
  GET_DE_FILE_UPLOAD_PATH_LOADING,
  GET_DE_FILE_UPLOAD_PATH_ERROR,
  GET_DE_FILE_UPLOAD_REGEX,
  GET_DE_FILE_UPLOAD_REGEX_LOADING,
  GET_DE_FILE_UPLOAD_REGEX_ERROR,
  GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES,
  GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_LOADING,
  GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_ERROR,
} from '../../constants/types'

export const uploadFileToBlobAction =
  (endpoint, payload, file1, file_path, file_name, overwrite) =>
  async (dispatch) => {
    try {
      dispatch(fileUploadToBlobBegin())
      let post_headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      await axios
        .post(
          `${endpoint}/api/de_file_upload/upload_data_file?filename=${file_name}&filepath=${file_path}&overwrite=${overwrite}`,
          payload,
          {
            headers: post_headers,
          }
        )
        .then((response) => {
          dispatch(fileUploadToBlobSuccess(response.data))
          toast(response.data.message, toastTopRightSuccessANZ)
        })
        .catch((error) => {
          dispatch(fileUploadToBlobFailure(error.response.data))
          toast(error.response.data.message, toastTopRightErrorANZ)
        })
    } catch (e) {
      dispatch(fileUploadToBlobFailure(e))
    }
  }

export const FILE_UPLOAD_TO_BLOB_BEGIN = 'FILE_UPLOAD_TO_BLOB_BEGIN'
export const FILE_UPLOAD_TO_BLOB_SUCCESS = 'FILE_UPLOAD_TO_BLOB_SUCCESS'
export const FILE_UPLOAD_TO_BLOB_FAIL = 'FILE_UPLOAD_TO_BLOB_FAIL'

const fileUploadToBlobBegin = () => ({
  type: FILE_UPLOAD_TO_BLOB_BEGIN,
})

const fileUploadToBlobSuccess = (getData) => ({
  type: FILE_UPLOAD_TO_BLOB_SUCCESS,
  payload: getData,
})

const fileUploadToBlobFailure = (error) => ({
  type: FILE_UPLOAD_TO_BLOB_FAIL,
  payload: error,
})

export const getDeFileUploadPaths = (endpoint) => async (dispatch) => {
  dispatch({
    type: GET_DE_FILE_UPLOAD_PATH_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/de_file_upload/get_all_file_paths_for_webapp`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          khuser: `${localStorage.getItem('user')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_DE_FILE_UPLOAD_PATH,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_DE_FILE_UPLOAD_PATH_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getDeFileUploadRegex = (endpoint) => async (dispatch) => {
  dispatch({
    type: GET_DE_FILE_UPLOAD_REGEX_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/de_file_upload/get_allowed_file_regex`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          khuser: `${localStorage.getItem('user')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_DE_FILE_UPLOAD_REGEX,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_DE_FILE_UPLOAD_REGEX_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getDeFilePresentNames =
  (endpoint, filepath) => async (dispatch) => {
    dispatch({
      type: GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_LOADING,
    })
    try {
      await axios
        .get(
          `${endpoint}/api/de_file_upload/get_all_file_present_at_path?filepath=${filepath}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              khuser: `${localStorage.getItem('user')}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES,
            payload: res.data,
          })
          return res.data
        })
    } catch (e) {
      dispatch({
        type: GET_DE_FILE_UPLOAD_PRESENT_FILE_NAMES_ERROR,
        payload: e.response.data.message,
      })
    }
  }
