import {
  GET_PENDING_BADGES,
  GET_PENDING_BADGES_LOAD,
  GET_PENDING_BADGE_ERROR,
} from '../constants/types'

const initialState = {
  pendingBadges: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_BADGES_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_PENDING_BADGES:
      return {
        ...state,
        pendingBadges: action.payload,
        loading: false,
        error: null,
      }
    case GET_PENDING_BADGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
