import React, { useEffect, useContext, useState } from 'react'
import LoadingStatus from '../../Helper/LoadingStatus'
import jwt_decode from 'jwt-decode'
import Sidebar from '../AppBar/Appbar'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'
import './idea.css'
import { getAllIdeas, approveOrRejectIdea, getAllFilters } from '../../../redux/actions/ideasActions/ideasAction'
import { AuthContext } from '../../context/authProvider'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import CardsView from '../Utilities/cardView'
import { useNavigate } from 'react-router-dom'
import { status_label, sortByMapping, orderByMapping, categoryArr, IdeasPagination } from './IdeaFunction'
import { toastTopRightError } from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RoleHandler } from '../Utilities/roleCheck'

export default function ManageIdeas() {
  let history = useNavigate()

  let decodedToken
  let tokenValues = localStorage?.getItem('token')
  if (tokenValues) {
    decodedToken = jwt_decode(tokenValues)
  }
  const roleValue = RoleHandler.checkCollabAPIAdminRole(tokenValues)
  useEffect(() => {
    if (!roleValue) {
      history('/ideas')
    }
  }, [roleValue])
  const loadingMsg = 'Fetching Idea records...'
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: loadingMsg,
  })
  const handleCard = (id) => {
    history(`/ideas/detail?q=${id}`)
  }
  const [authCreds] = useContext(AuthContext)
  const filterValues = useSelector((state)=> state.ideasFilterReducer?.getIdeasFilter)
  const [channel, setChannel] = useState(localStorage.getItem('channel'))
  const [pageSize, setPageSize] = useState(filterValues[0]?.filterManageIdeas?.pageSize)
  const [currentPage, setCurrentPage] = useState(filterValues[0]?.filterManageIdeas?.currentPage)
  const [totalPages, setTotalPages] = useState(0)
  const [searchValue, setSearchValue] = useState(filterValues[0]?.filterManageIdeas?.searchValue)
  const [sortBy, setSortBy] = useState(filterValues[0]?.filterManageIdeas?.sortBy)
  const [orderBy, setOrderBy] = useState(filterValues[0]?.filterManageIdeas?.orderBy)
  const [categoryFilter, setCategoryFilter] = useState(filterValues[0]?.filterManageIdeas?.category)
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected)
  }
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '12px',
      whiteSpace: 'break-spaces',
    },
  }))
  const dispatch = useDispatch()
  const handleSearch= () => fetchData();
  const handleSearchEnter = (e) =>{
    if (e.key === 'Enter') {
      fetchData();
    }
  }
  const [searchFlag, setSearchFlag] = useState()

  const clearSearch = async () => {
    await setSearchValue('')
    setSearchFlag(true)
  }
  const getAllIdeasManageData = useSelector((state) => state.getAllIdeasReducer.getAllIdeas)
  const [manageIdeasData, setManageIdeasData] = useState([])
  const endpoint = authCreds.restEndpoint
  /* eslint-disable no-unused-vars */

  const fetchData = async () => {
    setLoadingStatus({
      status: true,
      message: loadingMsg,
    })
    const params = {
      page: currentPage,
      per_page: pageSize,
      sort_order: orderBy,
      sort_by_view: false,
      sort_by_vote: false,
    }
    if (sortBy && sortBy == 'Like') {
      params.sort_by_vote = true
    } else if (sortBy && sortBy == 'View') {
      params.sort_by_view = true
    }
    let filtersArr = []
    filtersArr.push(`{"op":"eq","field":"status","value":"New"}`)
    if (categoryFilter && categoryFilter != 'All') {
      filtersArr.push(
        `{"op":"eq","field":"idea_category","value":"${categoryFilter}"}`
      )
    }
    if (channel && channel !== '') {
      filtersArr.push(`{"op":"eq","field":"chapter","value":"${channel}"}`)
    }
    params.filters = '[' + filtersArr.join(',') + ']'
    let url = `${endpoint}/api/ideas/get_all_ideas`
    if (searchValue && searchValue.length > 0) {
      params.query = searchValue
      url = `${endpoint}/api/ideas/search_idea`
    } else {
      params.query = ''
      url = `${endpoint}/api/ideas/get_all_ideas`
    }

    dispatch(getAllIdeas(url, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: loadingMsg,
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: loadingMsg,
        })
      })
  }
  const approveOrReject = (status, idea_id, e) => {
    e.stopPropagation()
    if (typeof status == 'boolean') {
      let statusText = 'Reject'
      if (status) {
        statusText = 'Approve'
      }
      if (
        window.confirm(
          'Are you sure you want to ' + statusText + ' this idea ?'
        )
      ) {
        setLoadingStatus({
          status: true,
          message: loadingMsg,
        })
        if (decodedToken && decodedToken.preferred_username) {
          let params = {
            id: idea_id,
            is_approved: status,
            user_email: decodedToken.preferred_username.toLowerCase(),
          }
          // approve or reject Idea
          dispatch(approveOrRejectIdea(endpoint, params)).then(() => {
            setLoadingStatus({ status: false, message: loadingMsg })
            fetchData()
          })
        } else {
          setLoadingStatus({ status: false, message: loadingMsg })
          toast('Please login to continue.', toastTopRightError)
        }
      }
    } else {
      toast('Please click the button again!', toastTopRightError)
    }
  }
  useEffect(() => {
    fetchData()
  }, [sortBy, orderBy, categoryFilter, currentPage, searchFlag, channel, pageSize])
  useEffect(()=>{
    let filters = filterValues;
    if(filters[0]?.filterManageIdeas?.category){
      filters[0].filterManageIdeas.category = categoryFilter;
    }
    if(filters[0]?.filterManageIdeas?.orderBy){
      filters[0].filterManageIdeas.orderBy = orderBy;
    }
    if(filters[0]?.filterManageIdeas?.sortBy){
      filters[0].filterManageIdeas.sortBy = sortBy;
    }
    if(filters[0]?.filterManageIdeas?.searchValue || filters[0]?.filterManageIdeas?.searchValue === ''){
      filters[0].filterManageIdeas.searchValue = searchValue;
    }
    if(filters[0]?.filterManageIdeas?.pageSize){
      filters[0].filterManageIdeas.pageSize = pageSize;
    }
    if(filters[0]?.filterManageIdeas?.currentPage){
      filters[0].filterManageIdeas.currentPage = currentPage;
    }

    dispatch(getAllFilters(filters))

  },[categoryFilter, sortBy, orderBy, pageSize, currentPage, searchValue])

  useEffect(() => {
    let ideasDataArr = getAllIdeasManageData?.data
    if (ideasDataArr?.length > 0) {
      setManageIdeasData(ideasDataArr)
    } else {
      setManageIdeasData([])
    }

    setTotalPages(Math.ceil(getAllIdeasManageData?.total_results / pageSize))
  }, [getAllIdeasManageData])
  useEffect(() => {
    if (!localStorage.getItem('channel_metadata')) {
      history('/ideas')
    }
  }, [])

  return (
    <Sidebar
      channel={channel}
      setChannel={setChannel}
      Page="Review/Approve Ideas"
      Index={
        <div
          style={{
            backgroundColor: '#f5f7f8',
            padding: '15px',
            minHeight: '100vh',
          }}
        >
          <div className="nav-menu" style={{ display: 'flex' }}>
            <div>
              <Box sx={{ minWidth: 120, margin: '10px' }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    sx={{
                      width: '100%',
                      height: '37px',
                      marginTop: '6px',
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-standard"
                    value={categoryFilter}
                    label="badge-name"
                    onChange={(e) => setCategoryFilter(e.target.value)}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {Object.entries(categoryArr).map(([key, val]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {val}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div>
              <DropdownButton
                id="dropdown-order-by"
                title={`Sort By: ${sortBy}`}
                className="glossary-sort-buttons glossary-sort-buttons-idea"
                onSelect={(eventKey) => setSortBy(eventKey)}
              >
                {sortByMapping.map((val) => (
                  <Dropdown.Item eventKey={val} key={val}>
                    {val}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div>
              <DropdownButton
                id="dropdown-order-by"
                title={orderBy}
                className="glossary-sort-buttons glossary-sort-buttons-idea"
                onSelect={(eventKey) => setOrderBy(eventKey)}
              >
                {orderByMapping.map((val) => (
                  <Dropdown.Item eventKey={val} key={val}>
                    {val}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div
              className="search-bar-collab"
              style={{ height: 'fit-content', margin: '13px', width: '30%' }}
            >
              <div
                className="collab-search-container"
                style={{ border: '1px solid #adb5bd', borderRadius: '0' }}
              >
                <input
                  placeholder="search..."
                  type="text"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                  }}
                  onKeyDown={(e)=>handleSearchEnter(e)}
                  className="collab-search"
                />
                <>
                  {searchValue.length > 0 ? (
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip">Clear Search</Tooltip>}
                    >
                      <button onClick={clearSearch} className="clearbtn">
                        <ClearIcon />
                      </button>
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </>
              </div>
              <button onClick={handleSearch} className="search-button">
                <SearchIcon />
              </button>
            </div>
          </div>
          {loadingStatus.status ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : (
            <>
              {manageIdeasData && manageIdeasData?.length == 0 ? (
                <div>More ideas to Come.</div>
              ) : (
                <>
                  <div className="cards">
                    {manageIdeasData?.map((data) => {
                      let retArr = status_label(data.status)

                      return (
                        <CardsView
                          key={data.id}
                          handleClick={() => handleCard(data.id)}
                          title_content={
                            <>
                              {data.title && data.title.length > 35 ? (
                                <BootstrapTooltip title={data.title}>
                                  <span className="idea_title">
                                    {data.title}
                                  </span>
                                </BootstrapTooltip>
                              ) : (
                                <span className="idea_title">{data.title}</span>
                              )}
                              <span className="idea_label">Category</span>{' '}
                              <span className="idea_category_badge">
                                {categoryArr[data.idea_category]}
                              </span>
                              &nbsp;
                              <span className="idea_label">Status</span>{' '}
                              <span
                                className={`idea_category_badge ${retArr.badge_status_class}`}
                              >
                                {data.status}
                              </span>
                              <br />
                              <span className="idea_label">Requester</span>{' '}
                              <span
                                className={`idea_category_badge`}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {data?.created_by
                                  ? data.created_by.split('@')[0]
                                  : 'N/A'}
                              </span>
                            </>
                          }
                          border_status_class={retArr.border_status_class}
                          action_content={
                            <>
                              <BootstrapTooltip title="Click to Approve idea">
                                <Button
                                  size="medium"
                                  variant="outlined"
                                  className="outlined-success"
                                  onClick={(e) =>
                                    approveOrReject(true, data.id, e)
                                  }
                                >
                                  <CheckCircleOutlineIcon /> Approve
                                </Button>
                              </BootstrapTooltip>
                              <BootstrapTooltip title="Click to Reject idea">
                                <Button
                                  size="medium"
                                  variant="outlined"
                                  className="outlined-danger"
                                  onClick={(e) =>
                                    approveOrReject(false, data.id, e)
                                  }
                                >
                                  <CancelOutlinedIcon /> Reject
                                </Button>
                              </BootstrapTooltip>
                            </>
                          }
                        />
                      )
                    })}
                  </div>
                  <IdeasPagination
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    totalResults={getAllIdeasManageData?.total_results}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                  />
                </>
              )}
            </>
          )}
        </div>
      }
    />
  )
}
