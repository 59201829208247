import React, { useState, useEffect } from 'react'

const DoNotShowAgainCheckbox = ({ labelText, storageKey }) => {
  const [checked, setChecked] = useState(false)

  const handleChange = (event) => {
    setChecked(event.target.checked)
    if (event.target.checked) {
      localStorage.setItem(storageKey, 'true')
    } else {
      localStorage.removeItem(storageKey)
    }
  }

  useEffect(() => {
    const doNotShowAgain = localStorage.getItem(storageKey)
    setChecked(doNotShowAgain === 'true')
  }, [storageKey])

  return (
    <div>
      <label>
        <input type="checkbox" checked={checked} onChange={handleChange} />
        {labelText}
      </label>
    </div>
  )
}

export default DoNotShowAgainCheckbox
