import React, { useState, useContext } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import { AuthContext } from '../../../../context/authProvider'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../../../Helper/ToastObjects'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useDispatch } from 'react-redux'
import 'quill-mention'
import { subscribePost } from '../../../../../redux/actions/collabAction'
import { ForumHandler } from '../../../../Utils'
import MentionHandler from '../../../Utilities/quillSearch'
export default function Comment({ comment, questionData, user_email, func }) {
  const [authCreds] = useContext(AuthContext)

  const endpoint = authCreds.restEndpoint
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('q')

  const [showUpdate, setUpdateShow] = useState(false)
  const dispatch = useDispatch()

  let userFetchTimeOut

  const handleSubcriptionMulti = async (values) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      user_email: values,
      post_id: id,
    }

    dispatch(subscribePost(endpoint, body, false)).then(() => {
      func()
      setEditorContent('')
      setUpdateShow(false)
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    })
  }
  //react quill source callback
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  const [editorContent, setEditorContent] = useState('')
  const handleQuill = (value) => {
    setEditorContent(value)
  }

  const handleUpdateComment = async (comment_id) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    if (editorContent.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = editorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      let tagName = questionData?.tags
      var finalArray = tagName.map(function (obj) {
        return obj.tag_name
      })

      const body = {
        title: null,
        description: editorContent,
        user_email: comment?.user_email.toLowerCase(),
        parent_id: id,
        root_id: id,
        tags: finalArray,
        channel: localStorage.getItem('channel'),
        created_on: comment?.created_on,
        created_by: comment?.created_by.toLowerCase(),
        modified_by: user_email.toLowerCase(),
        mentioned_emails: mentionVal,
      }
      await axios
        .put(
          `${endpoint}/api/collab/edit_comment?comment_id=${comment_id}`,
          body,
          {
            headers: post_headers,
          }
        )
        .then(() => {
          setUpdateShow(false)
          func()
          if (mentionVal.length > 0) {
            dispatch(handleSubcriptionMulti(mentionVal))
          } else {
            setLoadingStatus({ status: false, message: 'Fetching data...' })
            setEditorContent('')
          }
          setLoadingStatus({ status: false, message: 'Fetching data...' })
        })
        .catch((err) => setLoadingStatus({ status: false, message: err }))
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Comment can not be empty', toastTopRightError)
    }
  }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <EditIcon onClick={() => setUpdateShow(!showUpdate)} />
          <div>
            {showUpdate ? (
              <div className="title">
                <ReactQuill
                  style={{ background: '#fff' }}
                  theme="snow"
                  className="react-quill"
                  defaultValue={comment.description}
                  onChange={handleQuill}
                  modules={module}
                />
                <button
                  onClick={() => handleUpdateComment(comment?.id)}
                  style={{
                    maxWidth: 'fit-content',
                  }}
                  className="comment-btn"
                >
                  Update comment
                </button>
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  )
}
