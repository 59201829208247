import {
  ADD_REF_TABLE_DATA_LOADING,
  ADD_REF_TABLE_DATA,
  GET_ADD_ROW_ERROR,
  ADD_RESET_STATE,
} from '../constants/types'

const initialState = {
  addRowRefData: [],
  loading: true,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_REF_TABLE_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ADD_REF_TABLE_DATA:
      return {
        ...state,
        addRowRefData: action.payload,
        loading: false,
      }
    case GET_ADD_ROW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ADD_RESET_STATE:
      return {
        addRowRefData: [],
        loading: true,
        error: null,
      }
    default:
      return state
  }
}
