import React, { useEffect, useState, useContext } from 'react'
import { Form, Dropdown } from 'react-bootstrap'
import LoadingStatus from './Helper/LoadingStatus'
import { getTables, getMetaDataByTable } from '../redux/actions/refDataAction'
import {
  MdOutlineTableView,
  MdDangerous,
  MdArrowDropDown,
} from 'react-icons/md'
import SampleTable from './pages/RefData/DisplayTable'

import * as authProvider from './context/authProvider'
import { RefDataContext } from './context/refDataProvider'
import { TableHandler } from './Utils'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { addRefTableDataReset } from '../redux/actions/addRowRefDataAction'
import { editRefTableDataReset } from '../redux/actions/editRefTableDataAction'
import { useNavigate } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { MdHelpOutline, MdInfoOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from 'react-bootstrap'
import Typography from '@mui/material/Typography'
import { useParams } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import DoNotShowAgainCheckbox from './Helper/DoNotShowAgainCheckbox.js'

const Dashboard = () => {
  // Aquires authentication token and grabs initial data (metadata, table list) to spin up the app.
  /* eslint-disable no-unused-vars */

  const [selectedTable, setSelectedTable] = useState('')

  const [ifListExist, setIfListExist] = useState(false)
  const [authCreds] = useContext(authProvider.AuthContext)
  const [refData, setRefData] = useContext(RefDataContext)
  const [ifTableChange, setIfTableChange] = useState(false)
  const [picklist_value, setPicklist_value] = React.useState({})

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching Metadata details...',
  })
  let history = useNavigate()
  let dndIntro = localStorage.getItem('is_RefData_Intro_Muted')
  const [open, setOpen] =
    dndIntro == 'true' ? React.useState(false) : React.useState(true)
  const [openTableDescription, setOpenTableDescription] = React.useState(false)
  const [tableDescription, setTableDescription] = useState('')
  const [ifTableAccess, setIfTableAccess] = useState(true)
  const [tableFilter, setTableFilter] = useState('')

  const { tablename } = useParams()

  const descriptionElementRef = React.useRef(null)

  function handleTableSelect(value) {
    history(`/ref_data/${value}`)
    setSelectedTable(value)
    localStorage.setItem('selected_table', JSON.stringify(value))
    dispatch(getMetaDataByTable(endpoint, value.toUpperCase()))

    setIfTableChange(true)
    setPicklist_value(metaDataByTable?.metaDataByTable?.data?.fields)
    dispatch(addRefTableDataReset())
    dispatch(editRefTableDataReset())
    setTableFilter('')
  }

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])
  useEffect(() => {
    if (tablename) {
      dispatch(getMetaDataByTable(endpoint, tablename.toUpperCase())).then(
        () => {
          setSelectedTable(tablename)
        }
      )
    }
  }, [])

  const tables = useSelector((state) => state.tables)
  const metaDataByTable = useSelector((state) => state?.metaDataByTable)

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  useEffect(() => {
    if (token != null) {
      dispatch(getTables(endpoint, token))
    }
  }, [token, dispatch])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenTableDescription = () => {
    setOpenTableDescription(true)
  }

  // const handleCloseTableDescription = () => {
  //   setOpenTableDescription(false)
  // }

  useEffect(() => {
    // if authCreds gets updated, then hit table_list and metadata request
    if (token == null) return
    setIfListExist(true)
    if (metaDataByTable.loading === false) {
      Promise.all([tables, metaDataByTable])
        .then(([table_list_response, metadata_response]) => {
          setRefData({
            tableList: table_list_response?.tables.data,
            columnMetadata: metadata_response?.metaDataByTable?.data,
            hiddenColumns: TableHandler.createHiddenList(
              metadata_response?.metaDataByTable?.data
            ),
          })
          setPicklist_value(
            metadata_response?.metaDataByTable?.data[selectedTable]?.fields
          )
          setTableDescription(
            metadata_response?.metaDataByTable?.data[selectedTable][
              'description'
            ]
          )
          setIfListExist(false)
        })
        .catch((error) => {
          setIfListExist(false)
        })
    }
  }, [authCreds, tables, setRefData, metaDataByTable])

  if (ifListExist) {
    return <LoadingStatus />
  }

  return (
    <>
      {tables.error.length > 1 ? (
        <>
          <Navbar
            variant="dark"
            expand="lg"
            className="mt-2 mb-1 dark-blue bg-gradient"
          >
            <Container fluid>
              <Navbar.Brand>Reference Data</Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-dark-example" />
            </Container>
          </Navbar>
          <div
            class="bg-white text-dark text-center"
            style={{ height: '69vh', paddingTop: '150px' }}
          >
            <div>
              <MdDangerous style={{ fontSize: '60px' }} />
            </div>
            <div class="mt-3 fs-5">{tables.error}</div>
            <div class="mt-3 fs-5">
              <Typography gutterBottom>
                To request for access for ref data go to -
                <a
                  href="https://khcprod.service-now.com/khc_sp?id=khc_sc_cat_item&sys_id=8d8dcf5bdbb1b70020013c9b7c96193a"
                  className="email-link"
                >
                  https://khcprod.service-now.com/khc_sp?id=khc_sc_cat_item&sys_id=8d8dcf5bdbb1b70020013c9b7c96193a
                </a>
              </Typography>

              <Typography gutterBottom>
                1) In *Add/Remove members select add
              </Typography>
              <Typography gutterBottom>
                2) In Security Group to modify, Search for KHDE_API_WRITER.
              </Typography>
              <Typography gutterBottom>
                3) In Add members, select user names which needs to access de
                webapp
              </Typography>
            </div>
          </div>
        </>
      ) : metaDataByTable.loading || tables.loading ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : selectedTable.length < 1 ? (
        <>
          <Navbar
            variant="dark"
            expand="lg"
            className="mt-2 mb-1 dark-blue bg-gradient"
          >
            <Container fluid>
              <Navbar.Brand>Reference Data</Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-dark-example" />
              <Navbar.Collapse id="navbar-dark-example">
                <Nav>
                  <Form className="snowflake-param-container">
                    <Dropdown
                      size="sm"
                      className="ref-data-table-select d-inline-block"
                    >
                      <Dropdown.Toggle className="d-flex justify-content-between align-items-center">
                        <span>{selectedTable || 'Select Table'}</span>
                        <MdArrowDropDown size={20} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100 shadow">
                        <Form.Control
                          size="sm"
                          style={{ margin: '10px auto', width: '95%' }}
                          placeholder="Search Table"
                          value={tableFilter}
                          onChange={(e) => setTableFilter(e.target.value)}
                          autoFocus
                        />
                        <div className="table-select-dropdown-menu">
                          {tables?.tables?.data
                            ?.filter((tableItem) =>
                              tableItem
                                .toLowerCase()
                                .includes(tableFilter.toLowerCase())
                            )
                            .map((table, index) => (
                              <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleTableSelect(table)}
                                >
                                  {table}
                                </Dropdown.Item>
                              </>
                            ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form>
                </Nav>
              </Navbar.Collapse>
            </Container>
            <div style={{ marginRight: '10px' }}>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip">About Ref Data</Tooltip>}
              >
                <Button
                  aria-label="how to use Ref Data ?"
                  variant="outlined"
                  label="fd"
                  onClick={handleClickOpen}
                >
                  <MdHelpOutline
                    style={{ fontSize: '28px', color: '#F9D949' }}
                  />
                </Button>
              </OverlayTrigger>
              {(!dndIntro || open) && (
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">
                    About Ref Data
                  </DialogTitle>
                  <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      <Typography gutterBottom>
                        Ref data is a tool for managing data. It allows you to
                        add, delete, update, import and export table data.
                      </Typography>

                      <Typography gutterBottom>
                        <Alert key="info" variant="info">
                          For any query related to DE Web App Post it in Q&A
                          Forum tool available in menu or
                          <Alert.Link href="https://deweb.apps.kraftheinz.com/kholab">
                            <u> Click here </u>
                          </Alert.Link>
                        </Alert>
                      </Typography>

                      <Typography variant="h6" gutterBottom>
                        <b>1. Filter</b>
                      </Typography>
                      <Typography gutterBottom>
                        In ref data you can search single as well as multiple
                        values in a column. For applying multiple filters on a
                        column, write filter values separated by comma without
                        space in-between. For example: filter1,filter2,filter3
                      </Typography>
                      <Typography gutterBottom>
                        i. For searching null values just write double qoutes{' '}
                        {'""'} without any spaces.
                      </Typography>
                      <Typography gutterBottom>
                        ii. For hard search write values inside {'**'} . This
                        will return the exact matches.
                      </Typography>
                      <Typography gutterBottom>
                        iii. For case sensitive search write values inside{' '}
                        {'""'}.
                      </Typography>

                      <Typography variant="h6" gutterBottom>
                        <b> 2. Add</b>
                      </Typography>
                      <Typography gutterBottom>
                        In ref data you add a single row by clicking the add
                        button. A modal will pop-up in which you will able to
                        pass values in the mentioned columns.
                      </Typography>

                      <Typography variant="h6" gutterBottom>
                        <b> 3. Edit</b>
                      </Typography>
                      <Typography gutterBottom>
                        Ref data allows you to edit single column as well as
                        multiple columns.
                      </Typography>
                      <Typography gutterBottom>
                        For editing multiple rows: select rows by clicking on
                        checkboxes and then click on edit button. A modal will
                        open, there users can update the values of the records.
                      </Typography>
                      <Typography gutterBottom>
                        Inline Editing: You can update single row by just
                        multiple double clicking any row, which will then allow
                        you to update the row values.
                      </Typography>

                      <Typography variant="h6" gutterBottom>
                        <b> 4. Delete</b>
                      </Typography>
                      <Typography gutterBottom>
                        For Deleting rows: select rows by clicking on checkboxes
                        and then click on Delete button.
                      </Typography>
                      <Typography gutterBottom>
                        <b>Note:</b> The deletion in ref data refers to soft
                        delete only. Deleted entries will be there in database
                        you can restore it anytime.
                      </Typography>

                      <Typography variant="h6" gutterBottom>
                        <b> 5. Restore Deleted Record</b>
                      </Typography>
                      <Typography gutterBottom>
                        If you delete a record and want to restore it then click
                        on the check box named *show deleted records*.
                      </Typography>
                      <Typography gutterBottom>
                        After clicking *show deleted records*, you will find the
                        list of deleted records. For restoring records just
                        click on checkbox to select a record and then click on
                        *Restore Deleted*
                      </Typography>

                      <Typography variant="h6" gutterBottom>
                        <b> 6. Seed/Import</b>
                      </Typography>
                      <Typography gutterBottom>
                        <b>Seed:</b> In Ref data on initial load click on the
                        seed button, then select a .xlsx file (excel). It will
                        bulk upload your data in the table.
                      </Typography>
                      <Typography gutterBottom>
                        <b>Import:</b> In Ref data click on the import button,
                        then select a .xlsx file (excel). A pop-up will come
                        which will show you the list of records to be
                        inserted/updated.
                      </Typography>
                      <Typography gutterBottom>
                        <b>Note: </b>seeding and importing should be done with
                        the exported file only. First download the template by
                        using export and then reupload with your data.
                      </Typography>

                      <Typography variant="h6" gutterBottom>
                        <b> 7.Export</b>
                      </Typography>
                      <Typography gutterBottom>
                        Export allows you the download the table data or
                        template in an excel file.
                      </Typography>
                      <Typography gutterBottom>
                        <b>Note: </b>If you apply filters on the table and then
                        click on export. It will download the filtered data.
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <DoNotShowAgainCheckbox
                      labelText=" Don't show this again"
                      storageKey="is_RefData_Intro_Muted"
                    />
                    <div style={{ flex: '0.95 0 0' }} />
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
          </Navbar>
          <div
            className="bg-white text-dark text-center"
            style={{ height: '69vh', paddingTop: '150px' }}
          >
            <div>
              <MdOutlineTableView style={{ fontSize: '60px' }} />
            </div>
            <div className="mt-3 fs-5">
              No table selected. Please select a table from the dropdown.
            </div>
          </div>
        </>
      ) : (
        <>
          <Navbar
            variant="dark"
            expand="lg"
            className="mt-2 mb-1 dark-blue bg-gradient"
          >
            <Container fluid>
              <Navbar.Brand>Reference Data</Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-dark-example" />
              <Navbar.Collapse id="navbar-dark-example">
                <Nav>
                  <Form className="snowflake-param-container">
                    <Dropdown
                      size="sm"
                      className="ref-data-table-select d-inline-block"
                    >
                      <Dropdown.Toggle className="d-flex justify-content-between align-items-center">
                        <span>{selectedTable || 'Select Table'}</span>
                        <MdArrowDropDown size={20} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-100 shadow">
                        <Form.Control
                          size="sm"
                          style={{ margin: '10px auto', width: '95%' }}
                          placeholder="Search Table"
                          value={tableFilter}
                          onChange={(e) => setTableFilter(e.target.value)}
                          autoFocus
                        />
                        <div className="table-select-dropdown-menu">
                          {tables?.tables?.data
                            ?.filter((tableItem) =>
                              tableItem
                                .toLowerCase()
                                .includes(tableFilter.toLowerCase())
                            )
                            .map((table, index) => (
                              <>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleTableSelect(table)}
                                >
                                  {table}
                                </Dropdown.Item>
                              </>
                            ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form>
                </Nav>
                {ifTableAccess ? (
                  <>
                    {tableDescription.length !== 0 ? (
                      <div>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">{tableDescription}</Tooltip>
                          }
                        >
                          <Button
                            aria-label="Table description ?"
                            variant="outlined"
                            label="fd"
                            onClick={handleOpenTableDescription}
                          >
                            <MdInfoOutline
                              style={{ fontSize: '28px', color: '#F9D949' }}
                            />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      <div>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip">
                              Please update the Table description in table
                              config
                            </Tooltip>
                          }
                        >
                          <Button
                            aria-label="Table description ?"
                            variant="outlined"
                            label="fd"
                            onClick={handleOpenTableDescription}
                          >
                            <MdInfoOutline
                              style={{ fontSize: '28px', color: '#F9D949' }}
                            />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Navbar.Collapse>
            </Container>
            <div style={{ marginRight: '10px' }}>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip">About Ref Data</Tooltip>}
              >
                <Button
                  aria-label="how to use Ref Data ?"
                  variant="outlined"
                  label="fd"
                  onClick={handleClickOpen}
                >
                  <MdHelpOutline
                    style={{ fontSize: '28px', color: '#F9D949' }}
                  />
                </Button>
              </OverlayTrigger>

              <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">
                  About Ref Data
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    <Typography gutterBottom>
                      Ref data is a tool for managing data. It allows you to
                      add, delete, update, import and export table data.
                    </Typography>
                    <Typography gutterBottom>
                      <Alert key="info" variant="info">
                        For any query related to DE Web App Post it in Q&A Forum
                        tool available in menu or
                        <Alert.Link href="https://deweb.apps.kraftheinz.com/kholab">
                          <u> Click here </u>
                        </Alert.Link>
                      </Alert>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      <b> 1. Filter</b>
                    </Typography>
                    <Typography gutterBottom>
                      In ref data you can search single as well as multiple
                      values in a column. For applying multiple filters on a
                      column, write filter values separated by comma without
                      space in-between. For example: filter1,filter2,filter3
                    </Typography>
                    <Typography gutterBottom>
                      i. For searching null values just write double qoutes{' '}
                      {'""'} without any spaces.
                    </Typography>
                    <Typography gutterBottom>
                      ii. For hard search write values inside {'**'} . This will
                      return the exact matches.
                    </Typography>
                    <Typography gutterBottom>
                      iii. For case sensitive search write values inside {'""'}.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      <b> 2. Add</b>
                    </Typography>
                    <Typography gutterBottom>
                      In ref data you add a single row by clicking the add
                      button. A modal will pop-up in which you will able to pass
                      values in the mentioned columns.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      <b> 3. Edit</b>
                    </Typography>
                    <Typography gutterBottom>
                      Ref data allows you to edit single column as well as
                      multiple columns.
                    </Typography>
                    <Typography gutterBottom>
                      For editing multiple rows: select rows by clicking on
                      checkboxes and then click on edit button. A modal will
                      open, there users can update the values of the records.
                    </Typography>
                    <Typography gutterBottom>
                      Inline Editing: You can update single row by just multiple
                      double clicking any row, which will then allow you to
                      update the row values.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      <b> 4. Delete</b>
                    </Typography>
                    <Typography gutterBottom>
                      For Deleting rows: select rows by clicking on checkboxes
                      and then click on Delete button.
                    </Typography>
                    <Typography gutterBottom>
                      <b>Note:</b> The deletion in ref data refers to soft
                      delete only. Deleted entries will be there in database you
                      can restore it anytime.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      <b> 5. Restore Deleted Record</b>
                    </Typography>
                    <Typography gutterBottom>
                      If you delete a record and want to restore it then click
                      on the check box named *show deleted records*.
                    </Typography>
                    <Typography gutterBottom>
                      After clicking *show deleted records*, you will find the
                      list of deleted records. For restoring records just click
                      on checkbox to select a record and then click on *Restore
                      Deleted*
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      <b> 6. Seed/Import</b>
                    </Typography>
                    <Typography gutterBottom>
                      <b>Seed:</b> In Ref data on initial load click on the seed
                      button, then select a .xlsx file (excel). It will bulk
                      upload your data in the table.
                    </Typography>
                    <Typography gutterBottom>
                      <b>Import:</b> In Ref data click on the import button,
                      then select a .xlsx file (excel). A pop-up will come which
                      will show you the list of records to be inserted/updated.
                    </Typography>
                    <Typography gutterBottom>
                      <b>Note: </b>seeding and importing should be done with the
                      exported file only. First download the template by using
                      export and then reupload with your data.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                      <b> 7.Export</b>
                    </Typography>
                    <Typography gutterBottom>
                      Export allows you the download the table data in an excel
                      file.
                    </Typography>
                    <Typography gutterBottom>
                      <b>Note: </b>If you apply filters on the table and then
                      click on export. It will download the filtered data.
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <DoNotShowAgainCheckbox
                    labelText=" Don't show this again"
                    storageKey="is_RefData_Intro_Muted"
                  />
                  <div style={{ flex: '0.95 0 0' }} />
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>
            </div>
          </Navbar>
          <SampleTable
            key={selectedTable}
            table_name={selectedTable}
            ifTableChange={ifTableChange}
            setIfTableChange={setIfTableChange}
            access_token={localStorage.getItem('token')}
            setIfTableAccess={setIfTableAccess}
            picklist_value={picklist_value}
          />
        </>
      )}
    </>
  )
}
export default Dashboard
