import React, { useContext, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge'

import { AuthContext } from '../../context/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import { getUserKudos } from '../../../redux/actions/badgeAction'
import { getPodDetails } from '../../../redux/actions/podsPageActions/podsAction'
import { ForumHandler } from '../../Utils'
import MembersTable from './memberDetailsTable'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import UserProfile from '../Users/userProfileModal'
import { useNavigate } from 'react-router-dom'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const PodDetails = ({ selectedPod, setShowPodDetails }) => {
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()
  const podDetails = useSelector((state) => state.podDetails)
  const userKudos = useSelector((state) => state.userKudos)

  const [badgeData, setbadgeData] = React.useState()
  const [showModal, setShowModal] = React.useState(false)
  const [isLocalUser, setIsLocalUser] = React.useState(false)
  let history = useNavigate()

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  function openUserprofile(row) {
    setbadgeData(row)
    if (
      localStorage.getItem('user').toUpperCase() == row?.user_name.toUpperCase()
    ) {
      setIsLocalUser(true)
    } else {
      setIsLocalUser(false)
    }
    dispatch(getUserKudos(endpoint, row?.user_email)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      setShowModal(true)
    })
  }

  const columns = [
    'EMAIL',
    'CHAPTER',
    'TITLE',
    'GOLD',
    'SILVER',
    'BRONZE',
    'KUDOS',
    'COMPANY',
    'GROUP',
    'COUNTRY',
    'CITY',
    'TIME IN POSITION',
  ]

  const endpoint = authCreds.restEndpoint

  const stakeholderMatches = selectedPod?.stakeholders_html?.match(
    ForumHandler.userHTMLCharacters
  )
  const productOwnerMatches = selectedPod?.product_owners_html?.match(
    ForumHandler.userHTMLCharacters
  )
  const builderMatches = selectedPod?.builders_html?.match(
    ForumHandler.userHTMLCharacters
  )

  let stakeholderValues = stakeholderMatches
    ? stakeholderMatches.map((mention) => mention.slice(0))
    : []

  let productOwnerValues = productOwnerMatches
    ? productOwnerMatches.map((mention) => mention.slice(0))
    : []

  let builderValues = builderMatches
    ? builderMatches.map((mention) => mention.slice(0))
    : []

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const podData = {
      pod_id: selectedPod?.id,
      builders: builderValues,
      stakeholders: stakeholderValues,
      product_owners: productOwnerValues,
    }

    dispatch(getPodDetails(endpoint, podData))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }, [])

  const handleClosePodDetails = () => {
    setShowPodDetails(false)
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Container className="m-0">
            <Accordion defaultExpanded>
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Tooltip title="Name">
                  <Typography>{selectedPod?.pod_name}</Typography>
                </Tooltip>
                <Tooltip title="Funtional Area">
                  <Badge bg="primary" className="pod-badge">
                    {selectedPod?.functional_area}
                  </Badge>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip title="Description">
                  <Typography>{selectedPod?.description}</Typography>
                </Tooltip>
              </AccordionDetails>
            </Accordion>
            <div>
              <Accordion defaultExpanded>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>Builders</Typography>
                  <Typography>
                    <Badge bg="secondary" className="pod-badge">
                      {podDetails?.podDetails?.data?.members?.builders?.length}
                    </Badge>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MembersTable
                    columns={columns}
                    members={podDetails?.podDetails?.data?.members?.builders}
                    openUserprofile={openUserprofile}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>Product Owners </Typography>
                  <Typography>
                    <Badge bg="secondary" className="pod-badge">
                      {
                        podDetails?.podDetails?.data?.members?.product_owners
                          ?.length
                      }
                    </Badge>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MembersTable
                    columns={columns}
                    members={
                      podDetails?.podDetails?.data?.members?.product_owners
                    }
                    openUserprofile={openUserprofile}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded>
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>Stakeholders</Typography>
                  <Typography>
                    <Badge bg="secondary" className="pod-badge">
                      {
                        podDetails?.podDetails?.data?.members?.stakeholders
                          ?.length
                      }
                    </Badge>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MembersTable
                    columns={columns}
                    members={
                      podDetails?.podDetails?.data?.members?.stakeholders
                    }
                    openUserprofile={openUserprofile}
                  />
                </AccordionDetails>
              </Accordion>
            </div>

            <Modal.Footer>
              <Button
                variant="danger"
                type="submit"
                className="addModalBtn"
                onClick={handleClosePodDetails}
              >
                Close
              </Button>
            </Modal.Footer>
          </Container>

          {showModal ? (
            <>
              <UserProfile
                selectedUser={badgeData?.user_name}
                badgeData={badgeData}
                setBadgeData={setbadgeData}
                showModal={showModal}
                setShowModal={setShowModal}
                isLocalUser={isLocalUser}
                history={history}
                pageSize={1}
                pageIndex={1}
                userKudos={userKudos}
              />
            </>
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}

export default PodDetails
