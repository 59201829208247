import {
  GET_DBT_REPORT_ERROR,
  GET_DBT_REPORT,
  GET_DBT_REPORT_LOADING,
} from '../constants/types'
import axios from 'axios'

export const getDbtReport = (endpoint, token) => async (dispatch) => {
  dispatch({
    type: GET_DBT_REPORT_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/dbt_observability_report/get_dbt_observability`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_DBT_REPORT,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_DBT_REPORT_ERROR,
      payload: e.response.data.message,
    })
  }
}
