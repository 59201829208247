import React, { useContext, useState, useEffect } from 'react'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
import { AuthContext } from '../../context/authProvider'
import { RefDataContext } from '../../context/refDataProvider'
import { TableHandler } from '../../Utils'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { useDispatch, useSelector } from 'react-redux'
import { addRefTableData } from '../../../redux/actions/addRowRefDataAction'

const AddRow = ({
  table_name,
  setLoadingStatus,
  setShowAddRow,
  picklist_value,
  setIfTableChange,
}) => {
  const [rowCount, setRowCount] = useState([0])
  /* eslint-disable no-unused-vars */

  const [authCreds, setAuthCreds] = useContext(AuthContext)
  const [refData, setRefData] = useContext(RefDataContext)
  const [colname, setColname] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [open, setOpen] = useState(null)

  const [enabledCols, setEnabledCols] = useState([])
  const checkboxRef = React.useRef()
  const addRowRefData = useSelector((state) => state.addRowRefData)
  const [rows, setRows] = useState([])

  function addNewEmptyRow() {
    let newRow = {}
    enabledCols?.forEach((col) => {
      newRow[col] = null
    })
    
    setRows([...rows, newRow])
  }

  useEffect(() => {
    addNewEmptyRow()
  }, [])

  let getSuggestions = (columnName) => {
    if (picklist_value && picklist_value.length > 0) {
      let data = picklist_value.find((col) => col.name == columnName)

      if (data && data.picklists) {
        return data.picklists.map((picklist, i) => (
          <option key={i} value={picklist.value}>
            {picklist.label}
          </option>
        ))
      }
      return null
    }
  }

  useEffect(() => {
    var mainArray = []
    picklist_value.map((res) => {
      if (!refData.hiddenColumns[table_name].includes(res.name)) {
        var tempArray = res
        mainArray.push(tempArray)

        setColname(mainArray)
      }
    })
  }, [])

  const onInputChange = (e) => {
    let key = e.target.getAttribute('data-key')
    let row = Number(e.target.getAttribute('data-row'))
    let value = e.target.value

    // let data = picklist_value.find((col) => col.name == key)
    if (value === '') {
      value = null
    } else if (!isNaN(+value)) {
      value = +e.target.value
    } else {
      value = e.target.value
    }

    rows[row][key] = value
  }

  const updateRowCount = () => {
    let c = Number(rowCount.slice(-1)[0]) + 1
    const updated_arr = [...rowCount, c]
    setRowCount(updated_arr)

    addNewEmptyRow()
  }

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  function hasAllNullValues(obj) {
    return Object.values(obj).every((value) => value === null)
  }

  const uploadObjectData = () => {
    setIfTableChange(false)
    if (table_name.length < 1) {
      toast('No tables selected', toastTopRightError)
      return
    }
    const payload = []

    //removes empty entries from array
    rows.forEach((obj) => {
      if (!hasAllNullValues(obj)) {
        let local_payload = {}
        Object.entries(obj).forEach(([key, value]) => {
          if (obj[key] !== '') {
            local_payload[key] = value
          }
        })
        payload.push(local_payload)
      }
    })
    if (payload.length === 0) {
      toast('Please enter some data', toastTopRightError)
      return
    }
    for (let i = 0; i < payload.length; i++) {
      if (Object.keys(payload[i]).length < 1) {
        toast('Empty fields in columns', toastTopRightError)
        return
      }
    }
    setShowAddRow(false)
    dispatch(addRefTableData(endpoint, table_name.toUpperCase(), payload))
    setLoadingStatus({ status: false, message: 'Adding table record(s)...' })
  }

  const setShowAddRowModal = () => {
    setShowAddRow(false)
  }
  useState(() => {
    setEnabledCols(
      TableHandler.getAddColumnList(
        refData.columnMetadata,
        table_name,
        refData.hiddenColumns[table_name]
      )
    )
  }, [])

  return (
    <React.Fragment>
      <ToastContainer containerId="qwerty2" draggable={false} />
      <Row></Row>
      <Table responsive>
        <thead>
          <tr key={uuidv4()}>
            {enabledCols.map((ele) => {
              return <th key={uuidv4()}> {ele} </th>
            })}
          </tr>
        </thead>
        <tbody>
          {rowCount.map((count) => (
            <tr key={uuidv4()}>
              {colname.map((key) => {
                return (
                  <td key={key.name}>
                    {key.datatype == 'date' ? (
                      <input
                        type="date"
                        // class="form-control"
                        list={key.name}
                        style={{ width: '100%' }}
                        data-key={key.name}
                        placeholder="yyyy-mm-dd"
                        data-row={count}
                        onChange={onInputChange}
                        autocomplete="off"
                        value={rows[count][key.name]}
                      />
                    ) : key.datatype == 'datetime' ? (
                      <input
                        type="datetime-local"
                        // class="form-control"
                        list={key.name}
                        style={{ width: '100%' }}
                        data-key={key.name}
                        placeholder="yyyy-mm-dd"
                        data-row={count}
                        onChange={onInputChange}
                        autocomplete="off"
                        value={rows[count][key.name]}
                      />
                    ) : (
                      <input
                        list={key.name}
                        data-key={key.name}
                        data-row={count}
                        onChange={onInputChange}
                        autocomplete="off"
                        value={rows[count][key.name]}
                      />
                    )}

                    <datalist id={key.name}>
                      {getSuggestions(key.name)}
                    </datalist>
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Col sm={8}>
          <button title="Add a new empty row" onClick={updateRowCount}>
            <span role="img" aria-label="add row icon">
              ➕
            </span>
          </button>
        </Col>
        <hr style={{ marginTop: '5px' }} />
        <Row>
          <Col
            sm={4}
            style={{
              marginLeft: 'auto',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              variant="outline-danger"
              onClick={setShowAddRowModal}
              style={{ marginRight: '5px' }}
            >
              Close
            </Button>
            <Button variant="outline-primary" onClick={uploadObjectData}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Row>
    </React.Fragment>
  )
}

export default AddRow
