import {
  GET_FORUM_METADATA,
  GET_FORUM_METADATA_LOADING,
  GET_FORUM_METADATA_ERROR,
} from '../constants/types'
import axios from 'axios'
import { toastTopRightError } from '../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getForumMetadata = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FORUM_METADATA_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_FORUM_METADATA,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_FORUM_METADATA_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
