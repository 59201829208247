import {
  GET_ALL_IDEAS_ERROR,
  GET_ALL_IDEAS,
  GET_ALL_IDEAS_LOAD,
} from '../../constants/types'

const initialState = {
  getAllIdeas: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_IDEAS_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_ALL_IDEAS:
      return {
        ...state,
        getAllIdeas: action.payload,
        loading: false,
        error: null,
      }
    case GET_ALL_IDEAS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
