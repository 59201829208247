import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../../../AppBar/Appbar'
import './index.css'
import MainQuestion from './MainQuestion'
// import axios from 'axios'
import { AuthContext } from '../../../../context/authProvider'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import {
  getpostbyid,
  // getusers,
} from '../../../../../redux/actions/collabAction'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: theme.palette.text.secondary,
  height: '100%',
}))
export function Index() {
  const [authCreds] = useContext(AuthContext)
  const [roles, setRoles] = useState([])
  //const [userData, setUserData] = useState()
  const endpoint = authCreds.restEndpoint
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [viewData, setViewData] = useState()

  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('q')
  const getPostbyidcollabData = useSelector(
    (state) => state.getPostbyidcollabData
  )

  const dispatch = useDispatch()

  useEffect(() => {
    async function handlepostbyid() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      dispatch(getpostbyid(endpoint, id)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    handlepostbyid()
  }, [id])
  // useEffect(() => {
  //   dispatch(getusers(endpoint))
  // }, [])

  useEffect(() => {
    if (getPostbyidcollabData?.loading == false) {
      let quesData = getPostbyidcollabData?.getPostbyidcollabData?.data
      let roleData = getPostbyidcollabData?.getPostbyidcollabData

      setViewData(quesData?.question)
      setRoles(roleData?.user_roles)
    }
  }, [getPostbyidcollabData?.getPostbyidcollabData?.data])
  // useEffect(() => {
  //   if (getCollabUser?.loading == false) {
  //     let quesData = getCollabUser?.getCollabUser?.data
  //     if (quesData?.length > 0) {
  //       setUserData(quesData)
  //     }
  //   }
  // }, [getCollabUser?.getCollabUser?.data])
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="View Question"
            Index={
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Item>
                        <MainQuestion viewData={viewData} roles={roles} />
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default Index
