import { marked } from 'marked'
class Manifest {
  _parentMap: Map<string, string[]>
  _childMap: Map<string, string[]>

  _nodes: Map<string, ManifestNode>
  _sources: Map<string, SourceDefinition>
  _macros: Map<string, Dbt_Manifest.Macro>
  _exposures: Map<string, Dbt_Manifest.Exposure>

  _tags: Set<string> = new Set()
  _packages: Set<string> = new Set()

  _docs: Map<string, Dbt_Manifest.Documentation>
  _defaultOverview: string

  _tree: FlatTree
  _dbTree: FlatTree

  constructor(raw_manifest: Dbt_Manifest.ManifestSchema) {
    this._nodes = new Map(Object.entries(raw_manifest.nodes))
    this._macros = new Map(Object.entries(raw_manifest.macros))
    this._sources = new Map(Object.entries(raw_manifest.sources))
    this._exposures = new Map(Object.entries(raw_manifest.exposures))

    if (raw_manifest.parent_map)
      this._parentMap = new Map(Object.entries(raw_manifest.parent_map))
    else this._parentMap = new Map()

    if (raw_manifest.child_map)
      this._childMap = new Map(Object.entries(raw_manifest.child_map))
    else this._childMap = new Map()

    this._docs = new Map(
      Object.entries(raw_manifest.docs).map(([k, v]) => [
        k,
        { ...v, block_contents: marked(v.block_contents) },
      ])
    )

    const docKeys = [...this._docs.keys()]
    const defaultOverviews = docKeys.filter((k) => /__overview__$/.test(k))
    if (defaultOverviews.length > 1) {
      this._defaultOverview = defaultOverviews
        .filter((k) => k !== 'doc.dbt.__overview__')
        .pop() as string
    } else {
      this._defaultOverview = defaultOverviews[0]
    }

    const masterTree = new Map([['projects', new Map()]])
    const projectsTree = masterTree.get('projects') as Map<any, any>
    ;[...this._nodes.values(), ...this._macros.values()].forEach((item) => {
      if (
        item.docs?.show &&
        (item.resource_type !== 'test' || !('test_metadata' in item)) &&
        (item.resource_type !== 'macro' ||
          (!item.package_name.includes('dbt') &&
            !item.package_name.includes('audit_helper') &&
            !item.package_name.includes('codegen')))
      ) {
        if ('tags' in item) {
          if (item.tags === undefined || item.tags.length === 0)
            item.tags = ['untagged']
          else {
            item.tags = [...new Set(item.tags)]
            item.tags.forEach((tag) => this._tags.add(tag))
          }
        }

        const path = item.original_file_path.split('/')
        const dirs = path.slice(0, -1)
        const pkg = item.package_name

        this._packages.add(pkg)

        !projectsTree.has(pkg) && projectsTree.set(pkg, new Map())
        let currentTree = projectsTree.get(pkg)

        dirs.forEach((dir) => {
          !currentTree.has(dir) && currentTree.set(dir, new Map())
          currentTree = currentTree.get(dir)
        })

        currentTree.set(item.unique_id, item.name)
      }
    })
    masterTree.set('sources', new Map())
    const sources_tree = masterTree.get('sources') as Map<any, any>
    this._sources.forEach((src) => {
      if (src.tags === undefined || src.tags.length === 0) {
        src.tags = ['untagged']
      } else {
        src.tags = [...new Set(src.tags)]
        src.tags.forEach((tag) => this._tags.add(tag))
      }

      this._packages.add(src.package_name)
      const src_name = src.source_name

      !sources_tree.has(src_name) && sources_tree.set(src_name, new Map())
      const currentTree = sources_tree.get(src_name)
      currentTree.set(src.unique_id, src.name)
    })

    masterTree.set('exposures', new Map())
    const exposure_tree = masterTree.get('exposures') as Map<any, any>
    this._exposures.forEach((exposure) => {
      if (exposure.tags === undefined || exposure.tags.length === 0) {
        exposure.tags = ['untagged']
      } else {
        exposure.tags = [...new Set(exposure.tags)]
        exposure.tags.forEach((tag) => this._tags.add(tag))
      }
      !exposure_tree.has(exposure.type) &&
        exposure_tree.set(exposure.type, new Map())
      exposure_tree.get(exposure.type).set(exposure.unique_id, exposure)
    })

    const dbMap = new Map()
    ;[...this._nodes.values(), ...this._sources.values()].forEach((item) => {
      if (
        (item.resource_type === 'model' && item.docs?.show) ||
        item.resource_type === 'source'
      ) {
        const db_name = item.database
        if (db_name) {
          !dbMap.has(db_name) && dbMap.set(db_name, new Map())
          const db = dbMap.get(db_name)
          if (db) {
            const schema_name = item.schema
            !db.has(schema_name) && db.set(schema_name, new Map())
            const schema = db.get(schema_name)
            schema && schema.set(item.unique_id, item.name)
          }
        }
      }
    })

    const flatten = (map: Tree): FlatTree =>
      [...map]
        .sort()
        .map(([k, v]) =>
          v instanceof Map
            ? [
                (this._docs.has(`doc.${k}.${k}_overview`)
                  ? '!!'
                  : this._docs.has(`doc.${k}.__${k}__`)
                  ? '#!'
                  : '!') + k,
                flatten(v),
              ]
            : k
        ) as FlatTree

    this._tree = flatten(masterTree)
    this._dbTree = flatten(dbMap)
    this._tags.add('untagged')
  }
}
export default Manifest
