import {
  GET_CURRENT_SPRINT,
  GET_CURRENT_SPRINT_LOADING,
  GET_CURRENT_SPRINT_ERROR,
} from '../constants/types'

const initialState = {
  currentSprint: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_SPRINT_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_CURRENT_SPRINT:
      return {
        ...state,
        currentSprint: action.payload,
        loading: false,
      }
    case GET_CURRENT_SPRINT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
