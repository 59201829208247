import {
  GET_JOB_POSTINGS_ERROR,
  GET_JOB_POSTINGS,
  GET_JOB_POSTINGS_LOAD,
} from '../../constants/types'
import axios from 'axios'

export const getJobPostings = (URL, params) => async (dispatch) => {
  dispatch({
    type: GET_JOB_POSTINGS_LOAD,
  })
  try {
    await axios
      .get(URL, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_JOB_POSTINGS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_JOB_POSTINGS_ERROR,
      payload: e.response.data.message,
    })
  }
}
