import {
  GET_DE_FILE_UPLOAD_REGEX,
  GET_DE_FILE_UPLOAD_REGEX_LOADING,
  GET_DE_FILE_UPLOAD_REGEX_ERROR,
} from '../../constants/types'

const initialState = {
  deFileUploadRegex: '',
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DE_FILE_UPLOAD_REGEX_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_DE_FILE_UPLOAD_REGEX:
      return {
        ...state,
        deFileUploadRegex: action.payload,
        loading: false,
      }
    case GET_DE_FILE_UPLOAD_REGEX_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
