import {
  GET_DBT_GRADING_PROJECTS_ERROR,
  GET_DBT_GRADING_PROJECTS,
  GET_DBT_GRADING_PROJECTS_LOAD,
  GET_DBT_GRADING_MODELS_ERROR,
  GET_DBT_GRADING_MODELS,
  GET_DBT_GRADING_MODELS_LOAD,
  GET_DBT_GRADING_SCORES_ERROR,
  GET_DBT_GRADING_SCORES,
  GET_DBT_GRADING_SCORES_LOAD,
  GET_AVG_ALGORITHMS_SCORE_ERROR,
  GET_AVG_ALGORITHMS_SCORE,
  GET_AVG_ALGORITHMS_SCORE_LOAD,
} from '../../constants/types'
import axios from 'axios'

export const getDbtGradingProjects = (endpoint, params) => async (dispatch) => {
  dispatch({
    type: GET_DBT_GRADING_PROJECTS_LOAD,
  })
  try {
    await axios
      .get(`${endpoint}`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_DBT_GRADING_PROJECTS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_DBT_GRADING_PROJECTS_ERROR,
      payload: e.response.data.message,
    })
  }
}
export const getDbtGradingModels = (endpoint, params) => async (dispatch) => {
  dispatch({
    type: GET_DBT_GRADING_MODELS_LOAD,
  })
  try {
    await axios
      .get(`${endpoint}/api/dbt_grading/get_dbt_grading_models`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_DBT_GRADING_MODELS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_DBT_GRADING_MODELS_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getAvgAlgorithmsScore = (endpoint, params) => async (dispatch) => {
  dispatch({
    type: GET_AVG_ALGORITHMS_SCORE_LOAD,
  })
  try {
    await axios
      .get(`${endpoint}/api/dbt_grading/get_avg_algorithms_score`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_AVG_ALGORITHMS_SCORE,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_AVG_ALGORITHMS_SCORE_ERROR,
      payload: e.response.data.message,
    })
  }
}
export const getDbtGradingScores = (endpoint, params) => async (dispatch) => {
  dispatch({
    type: GET_DBT_GRADING_SCORES_LOAD,
  })

  try {
    await axios
      .get(`${endpoint}/api/dbt_grading/get_dbt_grading_scores`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_DBT_GRADING_SCORES,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_DBT_GRADING_SCORES_ERROR,
      payload: e.response.data.message,
    })
  }
}
