import help from '../components/pages/Utilities/fnf_helpers'
import GridColumnMapping from './GridColumnMapping'

let gridSchema = {}

Object.values(GridColumnMapping).map((value) => {
  gridSchema[value.key] = value.default_value
})

// const gridSchema = {
//     FINE_TYPE: '',
//     FINE_CATEGORY: '',
//     CUSTOMER: '',
//     WAREHOUSE: '',
//     OWING_ORG: '',
//     FINE: '',
//     DESCRIPTION: '',
//     GRID_FINE_ID: 0,
//     VALUE: '',
//     FINE_PERCENTAGE: 0,
//     MAX_AMOUNT: 0,
//     FINE_CALCULATED_ON: '',
//     CFR_PERCENTAGE: 0,
//     NOTES: '',
//     ON_TIME_THRESHOLD: '0',
//     OT: 0,
//     IN_FULL_THRESHOLD: '0',
//     IN_FULL: 0,
//     CARRIER: 0,
//     LOGISTICS: 0,
//     PRODUCTION: 0,
//     INVENTORY: 0,
//     COUNTRY: '',
//     ALLOCATION_ISSUE: 0,
//     ASN: 0,
//     ASN_DOWNLOAD_FAILURE: 0,
//     ASN_MISMATCH: 0,
//     ASSORTMENT_DISCREPANCY: 0,
//     BARCODE_COMPLIANCE: 0,
//     BROKEN_PALLET: 0,
//     CANCELLED: 0,
//     CARRIER_CANCEL_APPT: 0,
//     CARRIER_DELAY: 0,
//     CARRIER_DETENTION: 0,
//     CARRIER_FAILURE: 0,
//     CARRIER_LATE_APPT: 0,
//     CARRIER_NO_APPT: 0,
//     CARRIER_NO_CALL_NO_SHOW: 0,
//     CARRIER_NO_SHOW: 0,
//     CARRIER_POST_DATE_APPT: 0,
//     CARRIER_SAME_DAY_APPT: 0,
//     CARRIER_RESCHEDULE_APPT: 0,
//     CARRIER_RESCHEDULE_APPT_WITHOUT_NOTICE: 0,
//     CARRIER_WITHOUT_PAPERWORK: 0,
//     DAMAGED_ITEMS: 0,
//     DAMAGED_PALLET: 0,
//     DATE_ISSUE: 0,
//     DEFECT_PALLET: 0,
//     INADEQUATE_PALLET: 0,
//     INCORRECT_DC: 0,
//     INCORRECT_INFORMATION: 0,
//     INCORRECT_ORDER: 0,
//     INCORRECT_PALLET: 0,
//     INCORRECT_SHIPMENT: 0,
//     INCORRECT_TRAILER: 0,
//     INCORRECT_UPC: 0,
//     LOAD_COMPLIANCE: 0,
//     LOAD_SHIFTING: 0,
//     MISSING_BOL: 0,
//     MISSING_PACKING_LIST: 0,
//     MISSING_DOCUMENT: 0,
//     MISSING_LABEL_PALLET: 0,
//     MISSING_PO: 0,
//     NON_COMPLIANCE: 0,
//     PALLET_COMPLIANCE: 0,
//     PALLET_RESTACK: 0,
//     PO_DISCREPANCY: 0,
//     POD_DISCREPANCY: 0,
//     POOR_QUALITY_PALLET: 0,
//     PRICE_DISCREPANCY: 0,
//     PRODUCT_ISSUE: 0,
//     QUALITY_ISSUE: 0,
//     REWORK: 0,
//     UNACCEPTABLE_PALLET: 0,
//     STEPF: 0,
//     DISPUTE_WEATHER: 0,
//     DROP_1_DAY_EARLY: 0,
//     LIVE_1_DAY_EARLY: 0,
//     OT_DEF_DAY_OF: 0,
//     CPU_FINABLE: 0,
//     UNREASONABLE_PROPOSAL: 0,
//     ID: 0,
//     PO_DELIVERY_THRESHOLD_PERCENTAGE: '0',
//     ORAD_QUARTERLY_THRESHOLD_PERCENTAGE: '0',
//     CFR_QUARTERLY_THRESHOLD_PERCENTAGE: '0',
//     ORAD_MONTHLY_THRESHOLD_PERCENTAGE: '0',
//     CFR_MONTHLY_THRESHOLD_PERCENTAGE: '0',
//     RULE_1_DESCRIPTION: '',
//     ADDITIONAL_RULE_1_DESCRIPTION: '',
//     RULE_2_DESCRIPTION: '',
//     ADDITIONAL_RULE_2_DESCRIPTION: '',
//     EMAIL_TO: '',
//     FINE: 0,
//     FEES: 0,
//     RESERVED_COL_1: '',
//     RESERVED_COL_2: '',
//     RESERVED_COL_3: '',
//     RESERVED_COL_4: '',
//     RESERVED_COL_5: '',
//     RESERVED_COL_6: '',
//     RESERVED_COL_7: '',
//     RESERVED_COL_8: '',
// };

const gridColumns = [{ id: 'action', label: '', yesno: false }]

Object.values(GridColumnMapping).map((value) => {
  if (value.visibleInGridTable === true) {
    gridColumns.push({
      id: value.key,
      label: value.label,
      yesno: value.input_type === 'Radio',
      minWidth: value.minColumnWidth,
      format: (inpval) =>
        inpval === null || inpval === '-'
          ? '-'
          : value.input_type === 'Radio'
          ? inpval === true
            ? 'Yes'
            : 'No'
          : value.input_type === 'Number'
          ? `${value.prefix}${help.formatNumbers(inpval, 0)}${value.suffix}`
          : `${value.prefix}${inpval}${value.suffix}`,
    })
  }
})

// const gridColumns2 = [
//     { id: 'action', label: '', yesno: false },
//     // { id: 'ID', label: 'ID', yesno: false },
//     { id: 'GRID_FINE_ID', label: 'Grid Fine ID', yesno: false },
//     { id: 'FINE_TYPE', label: 'Fine Type', minWidth: 150, yesno: false },
//     {
//         id: 'FINE_CATEGORY',
//         label: 'Fine Category',
//         minWidth: 150,
//         yesno: false,
//     },
//     // { id: 'FINE', label: 'Fine', minWidth: 200, yesno: false },
//     { id: 'DESCRIPTION', label: 'Desc', minWidth: 400, yesno: false },
//     { id: 'CUSTOMER', label: 'Customer', yesno: false },
//     { id: 'VALUE', label: 'Value', minWidth: 200, yesno: false },

//     {
//         id: 'FINE_PERCENTAGE',
//         label: 'Fine Percent',
//         minWidth: 150,
//         yesno: false,
//         format: (value) => `${value} %`,
//     },
//     { id: 'MAX_AMOUNT', label: 'Max Amount', minWidth: 150, yesno: false },
//     {
//         id: 'PO_DELIVERY_THRESHOLD_PERCENTAGE',
//         label: 'PO Delivery Threshold %',
//         minWidth: 150,
//         yesno: false,
//         format: (value) => `${value || 0} %`,
//     },
//     {
//         id: 'ORAD_QUARTERLY_THRESHOLD_PERCENTAGE',
//         label: 'Orad Quarterly Threshold %',
//         minWidth: 150,
//         yesno: false,
//         format: (value) => `${value || 0} %`,
//     },
//     {
//         id: 'CFR_QUARTERLY_THRESHOLD_PERCENTAGE',
//         label: 'CFR Quarterly Threshold %',
//         minWidth: 150,
//         yesno: false,
//         format: (value) => `${value || 0} %`,
//     },
//     {
//         id: 'ORAD_MONTHLY_THRESHOLD_PERCENTAGE',
//         label: 'ORAD Monthly Threshold %',
//         minWidth: 150,
//         yesno: false,
//         format: (value) => `${value || 0} %`,
//     },
//     {
//         id: 'CFR_MONTHLY_THRESHOLD_PERCENTAGE',
//         label: 'CFR Monthly Threshold %',
//         minWidth: 150,
//         yesno: false,
//         format: (value) => `${value || 0} %`,
//     },

//     // {
//     //   id: 'CFR_PERCENTAGE',
//     //   label: 'CFR Percentage',
//     //   minWidth: 150,
//     //   yesno: false,
//     // },
//     {
//         id: 'RULE_1_DESCRIPTION',
//         label: 'Rule 1',
//         yesno: false,
//         minWidth: 150,
//     },
//     {
//         id: 'ADDITIONAL_RULE_1_DESCRIPTION',
//         label: 'ADDITIONAL RULE 1',
//         yesno: false,
//         minWidth: 150,
//     },
//     {
//         id: 'RULE_2_DESCRIPTION',
//         label: 'RULE 2',
//         yesno: false,
//         minWidth: 150,
//     },
//     {
//         id: 'ADDITIONAL_RULE_2_DESCRIPTION',
//         label: 'ADDITIONAL RULE 2',
//         yesno: false,
//         minWidth: 150,
//     },
//     {
//         id: 'FINE_CALCULATED_ON',
//         label: 'Fine Calculated On',
//         minWidth: 150,
//         yesno: false,
//     },

//     { id: 'OWING_ORG', label: 'Owning Org', yesno: false },
//     { id: 'NOTES', label: 'Notes', yesno: false },
//     {
//         id: 'WAREHOUSE',
//         label: 'Warehouse',
//         yesno: false,
//     },
//     {
//         id: 'FINE',
//         label: 'Fine',
//         yesno: false,
//     },
//     {
//         id: 'FEES',
//         label: 'Fees',
//         yesno: false,
//     },
//     {
//         id: 'OT',
//         label: 'OT',
//         format: (value) => (value === 0 ? 'No' : 'Yes'),
//         yesno: true,
//     },
//     {
//         id: 'In_Full',
//         label: 'In Full',
//         format: (value) => (value === 0 ? 'No' : 'Yes'),
//         yesno: true,
//     },
// ];

export default gridColumns
export { gridSchema }
