export const columnsMap = {
  KH_UID: 'KH_UID',
  ACTION: 'ACTION',
  ACTUAL_ARRIVAL_DATE: 'ACTUAL_ARRIVAL_DATE',
  ACCOUNTING_PERIOD: 'ACCOUNTING_PERIOD',
  CFR: 'CFR',
  CALCULATED_FINE_AMOUNT: 'calculated_fine_amount',
  CUSTOMER_PO_NO: 'KH_CUSTOMER_PO_NUMBER',
  CFR_MISSED: 'CFR_MISSED',
  CFR_FINE_AMOUNT: 'CFR_FINE_AMOUNT',
  CPU: 'CPU_FLAG',
  CASES_ORDERED: 'CASES_ORDERED',
  DC_NO_KH: 'DC_NUMBER_KH_SIDE',
  DC_NO_RETAILER: 'DC_NUMBER_RETAILER_SIDE',
  ES3_OR_NON_ES3: 'ES3_OR_NON_ES3',
  ESTIMATED_ARRIVAL_DATE: 'ESTIMATED_ARRIVAL_DATE',
  EVIDENCE_REQUESTED: 'EVIDENCE_REQUESTED',
  EVIDENCE_TYPE: 'EVIDENCE_TYPE',
  FINE_TYPE: 'FINAL_FINE_TYPE',
  FINE_AMOUNT_DERIVED: 'FINE_AMOUNT_DERIVED',
  INVOICE_VALUE: 'INVOICE_VALUE',
  INVOICE_NUMBER: 'INVOICE_NUMBER',
  IF_DISCREPENCIES: 'IF_DISCREPANCIES',
  LIABLE_FINE_AMOUNT: 'LIABLE_FINE_AMOUNT',
  NO_OF_UNITS_SHIPPED: 'NO_OF_UNITS_SHIPPED',
  NO_OF_UNITS_ORDERED: 'NO_OF_UNITS_ORDERED',
  ORAD_MISSED: 'ORAD_MISSED',
  ORAD: 'ORAD',
  ORAD_FINE_AMOUNT: 'ORAD_FINE_AMOUNT',
  PRIORITY: 'PRIORITY',
  PO_DATE: 'PURCHASE_ORDER_DATE',
  PO_TYPE: 'PURCHASE_ORDER_TYPE',
  POSSIBLE_DISPUTE: 'POSSIBLE_DISPUTE',
  RETAILER_PO: 'RETAILER_PO',
  REPORTED_ARRIVAL_DATE: 'REPORTED_ARRIVAL_DATE',
  REPORTED_CFR: 'REPORTED_CFR',
  RETAILER_STATE: 'RETAILER_STATE',
  REPORTED_FINE_TYPE: 'REPORTED_FINE_TYPE',
  REASON_FOR_REJECTION: 'reason_for_rejection',
  RETAILER_NAME: 'RETAILER_NAME',
  RETAILER_COUNTRY: 'RETAILER_COUNTRY',
  REPORTED_FINE_AMOUNT: 'FINE_AMOUNT_DERIVED',
  SO_TYPE: 'SALES_ORDER_TYPE',
  SO_NO: 'STANDARD_ORDER_NUMBER',
  SHIPPING_DATE: 'DATE_OF_SHIPMENT',
  SHIPPING_STATUS: 'SHIPPING_STATUS',
  SKU_CUTS: 'sku_cuts',
  STATUS: 'STATUS',
  SHIP_MODE_DETAILS: 'ship_mode_details',
  TRANSPORT_REFERENCE: 'TRANS_REF_BOL',
  TRANSPORT_METHOD: 'TRANSPORT_METHOD',
  TOTAL_VOLUME: 'TOTAL_VOLUME',
  TOTAL_WEIGHT: 'TOTAL_WEIGHT',
  UNITS_SHIPPED_OUTSIDE_SHIPPING_WINDOW:
    'UNITS_SHIPPED_OUTSIDE_SHIPPING_WINDOW',
  VALUE_OF_SHIPPED_ITEMS: 'VALUE_OF_SHIPPED_ITEMS',
  VALID_INVALID: 'VALID_INVALID',
  VALUE_OF_ORDER_PLACED: 'VALUE_OF_ORDER_PLACED',

  NO_FINES: 'No. fines',
  TOTAL_FINES_VALUE: 'Total Fines Value',
  CUSTOMER_REPORTED_FINES_VALUE: 'Customer Reported Fine',
  FINES_PROCESSED: '#Fines Processed',
  PENDING_VALIDATION: '#Pending validation',
  VALID_FINES: 'Valid Fines',
  INVALID_FINES: 'Invalid Fines',
  PENDING_FINE_VALUE: 'Pending Fines Value',
  VALID_FINE_VALUE: 'Valid Fines Value',
  INVALID_FINE_VALUE: 'Invalid Fines Value',
  ORDERS: '#orders',
  TOTAL_ORDER_VALUE: 'Total Order Value',
  SHIPPED_ORDERS: 'Shipped orders',
  PENDING_ORDERS: 'Pending orders',
  POSSIBLE_COMPLIANCE: '#Possible Compliance',
  POSSIBLE_NON_COMPLIANCE: '#Possible Non Compliance',
  POSSIBLE_FINE_VALUE: 'Possible Fine Value',
  STEP0_FINES: '#Step0 fines',
  CUSTOMER_REPORTED_FINES: '#Customer Reported Fines',
  MAPPED_FINES: '#mapped Fines',
  UNMAPPED_FINES: '#Unmapped Fines',
  MAPPED_FINE_VALUE: 'Mapped fine value',

  ITEM_LEVEL_DESCRIPTION: 'ITEM_LEVEL_DESCRIPTION',
  ITEM_ID: 'kh_unique_id',
}

export default columnsMap
