import { lazy, Suspense, useEffect, useState } from 'react'
import GraphManager from './Classes/GraphManager'
import Manifest from './Classes/Manifest'
import SideMenu from './side_menu/SideMenu'
import LoadingStatus from './loading_status/LoadingStatus'
import Content from './content/Content'
import styled from 'styled-components'
import { inflate } from 'pako'
import GraphLauncher from './graph editor/components/GraphLauncher'
import ManifestZip from './data/manifest_de2.json.gz'
const GraphEditor = lazy(() => import('./graph editor/GraphEditor'))
const MiniGraph = lazy(() => import('./graph editor/MiniGraph'))

export enum TREE_MODE {
  PROJECT,
  DATABASE,
}

enum GRAPH_MODE {
  CLOSED,
  SMALL,
  FULL,
}

const StyledDataDictioary = styled.div`
  width: 105%;
  height: 100%;
  display: flex;
  align-items: stretch;
  margin-left: -2.5%;
  color: rgb(145, 149, 153);
  background: #f8f9fa;
  overflow: hidden;
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`

const DataDictionary = () => {
  const [manifest, setManifest] = useState<Manifest>({} as Manifest)
  const [graphManager, setGraphManager] = useState<GraphManager>(
    {} as GraphManager
  )
  const [loadingStatus, setLoadingStatus] = useState(true)
  const [treeMode, setTreeMode] = useState(TREE_MODE.PROJECT)
  const [graphMode, setGraphMode] = useState(GRAPH_MODE.CLOSED)
  const [graphFilter, setGraphFilter] = useState<GraphFilter>({
    select: '',
    exclude: '',
    tags: manifest._tags,
    packages: manifest._packages,
    resources: new Set([
      'model',
      'seed',
      'snapshot',
      'source',
      'test',
      'analysis',
      'exposure',
    ]),
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setSearching] = useState(false)
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({
    column: false,
    description: false,
    name: true,
    sql: false,
    tags: false,
  })

  const loadManifest = async () => {
    setLoadingStatus(true)

    fetch(ManifestZip)
      .then((res: Response) => res.arrayBuffer())
      .then((buffer: ArrayBuffer) => {
        const manifestObj = new Manifest(
          JSON.parse(inflate(buffer, { to: 'string' }))
        )
        setManifest(manifestObj)
        setGraphManager(new GraphManager(manifestObj))
        setGraphFilter({
          select: '',
          exclude: '',
          tags: manifestObj._tags,
          packages: manifestObj._packages,
          resources: new Set([
            'model',
            'seed',
            'snapshot',
            'source',
            'test',
            'analysis',
            'exposure',
          ]),
        })
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoadingStatus(false))
    // setLoadingStatus(false);
  }

  useEffect(() => {
    loadManifest()
  }, [])

  const updateCacheList = (id: string) => {
    setCacheList(new Set([...cacheList, id]))
  }

  const updateSearchOptions = (options: Partial<SearchOptions>) => {
    setSearchOptions({ ...searchOptions, ...options })
  }
  const updateGraphFilter = (arg: Partial<GraphFilter>) => {
    const newFilter = { ...graphFilter, ...arg }
    setGraphFilter(newFilter)
    if (graphManager) {
      graphManager.updateFilter(newFilter)
    }
  }
  const applyGraphFilter = () => {
    if (graphManager) {
      graphManager.applyFilter()
    }
  }
  const clearGraphFilter = () => {
    if (graphManager) {
      const f: GraphFilter = {
        select: '',
        exclude: '',
        tags: manifest._tags,
        packages: manifest._packages,
        resources: new Set([
          'model',
          'seed',
          'snapshot',
          'source',
          'test',
          'analysis',
          'exposure',
        ]),
      }
      setGraphFilter(f)
      updateGraphFilter(f)
      applyGraphFilter()
    }
  }

  const [cacheList, setCacheList] = useState<Set<string>>(new Set())

  // let manifest = require("../../../data/manifest.json");
  return loadingStatus ? (
    <LoadingStatus status_message={'Loading manifest details...'} />
  ) : (
    <StyledDataDictioary>
      <SideMenu
        treeMode={treeMode}
        setTreeMode={setTreeMode}
        manifest={manifest}
      />
      <Content
        manifest={manifest}
        isSearching={isSearching}
        setSearching={setSearching}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        searchOptions={searchOptions}
        setSearchOptions={updateSearchOptions}
        updateCacheList={updateCacheList}
        cacheList={cacheList}
      />
      <Suspense fallback={<div>...loading</div>}>
        {graphMode === GRAPH_MODE.CLOSED ? (
          <GraphLauncher
            click_handler={() => {
              window.location.pathname.slice(17) &&
              !window.location.pathname.includes('overview')
                ? setGraphMode(GRAPH_MODE.SMALL)
                : setGraphMode(GRAPH_MODE.FULL)
            }}
          />
        ) : graphMode === GRAPH_MODE.FULL ? (
          <GraphEditor
            manager={graphManager}
            filter={graphFilter}
            updateFilter={updateGraphFilter}
            applyFilter={applyGraphFilter}
            clearFilter={clearGraphFilter}
            tag_list={[...manifest._tags]}
            package_list={[...manifest._packages]}
            minimize={() => {
              setGraphMode(GRAPH_MODE.SMALL)
            }}
            closeGraph={() => {
              setGraphMode(GRAPH_MODE.CLOSED)
            }}
          />
        ) : (
          <MiniGraph
            manager={graphManager}
            applyFilter={applyGraphFilter}
            updateFilter={updateGraphFilter}
            maxamize={() => {
              setGraphMode(GRAPH_MODE.FULL)
            }}
            closeFunc={() => {
              setGraphMode(GRAPH_MODE.CLOSED)
            }}
          />
        )}
      </Suspense>
    </StyledDataDictioary>
  )
}

export default DataDictionary
