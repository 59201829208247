import React from 'react'

export default function Alphabet({
  clickedLetter,
  filterByLetter,
  letterData,
}) {
  return (
    <>
      <br />
      <div className="list-group list-group-horizontal glossary-abc-list-group">
        {letterData?.map((item) => (
          <li
            key={item.letter}
            className={`list-group-item ${
              clickedLetter === item.letter
                ? 'selected'
                : !item.exists
                ? 'disabled'
                : ''
            }`}
          >
            <button
              onClick={() => filterByLetter(item.letter)}
              className={`btn btn-sm glossary-abc-button ${
                clickedLetter === item.letter ? 'selected' : ''
              }`}
              title={
                clickedLetter === item.letter ? 'Click to reset filter' : null
              }
              disabled={!item.exists}
            >
              {item.letter}
            </button>
          </li>
        ))}
      </div>
      <br />
      <h3 style={{ textAlign: 'center' }}>
        {clickedLetter.length === 0
          ? 'Select a letter to filter'
          : `${clickedLetter}`}
      </h3>
      <br />
    </>
  )
}
