import { Point } from './Verts'
export default class Edge {
  public get start(): Point {
    return this._start
  }
  public get end(): Point {
    return this._end
  }
  public get length(): number {
    return Math.abs(this.start.layer - this.end.layer)
  }

  public isOnScreen(
    left: number,
    top: number,
    right: number,
    bottom: number
  ): boolean {
    if (this.start.isVert() && this.end.isVert()) {
      return (
        this.start.isOnScreen(left, top, right, bottom) ||
        this.end.isOnScreen(left, top, right, bottom)
      )
    }
    return false
  }
  constructor(private _start: Point, private _end: Point) {}
}
