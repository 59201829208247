import { Table } from 'react-bootstrap'

const SelectedTables = ({ selectedRows, headers }) => {
  return (
    <Table>
      <thead>
        <tr>
          {headers.map((heading, index) => (
            <th key={index}>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {selectedRows.map((row, index) => (
          <tr key={index}>
            <td>{row.DATABASE_NAME}</td>
            <td>{row.SCHEMA_NAME}</td>
            <td>{row.TABLE_NAME}</td>
            {row.result && (
              <td
                style={{
                  borderLeft:
                    row.result.status == 200
                      ? '1px solid green'
                      : '1px solid red',
                  borderRight:
                    row.result.status == 200
                      ? '1px solid green'
                      : '1px solid red',
                }}
              >
                {row.result.message}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SelectedTables
