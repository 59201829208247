import {
  GET_FNF_STEP0_SUMMARY_DATA,
  GET_FNF_STEP05_SUMMARY_DATA,
  GET_FNF_STEP1_SUMMARY_DATA,
  GET_FNF_STEP0_LIKELIHOOD_DATA,
  GET_FNF_STEP05_LIKELIHOOD_DATA,
  GET_FNF_STEP1_LIKELIHOOD_DATA,
  GET_FNF_STEP0_UNIQUEVALUES_DATA,
  GET_FNF_STEP05_UNIQUEVALUES_DATA,
  GET_FNF_STEP1_UNIQUEVALUES_DATA,
  START_FNF_LOADING,
  STOP_FNF_LOADING,
  GET_FNF_ERROR,
  GET_FNF_ORDER_ITEMS_DATA,
  CLEAR_FNF_ITEMS_DATA,
  GET_FNF_GRID_RULES,
  GET_FNF_GRID_UNIQUEVALUES_DATA,
} from '../constants/types'

const initialState = {
  step0SummaryData: [],
  step05SummaryData: [],
  step1SummaryData: [],
  step0LikelihoodData: [],
  step05LikelihoodData: [],
  step1LikelihoodData: [],
  step0UniqueValuesData: [],
  step05UniqueValuesData: [],
  step1UniqueValuesData: [],
  orderItemsData: [],
  gridRulesData: [],
  gridUniqueValuesData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case START_FNF_LOADING:
      return {
        ...state,
        loading: true,
      }
    case STOP_FNF_LOADING:
      return {
        ...state,
        loading: false,
      }
    case GET_FNF_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_FNF_STEP0_SUMMARY_DATA:
      return {
        ...state,
        loading: false,
        step0SummaryData: action.payload,
      }
    case GET_FNF_STEP05_SUMMARY_DATA:
      return {
        ...state,
        loading: false,
        step05SummaryData: action.payload,
      }
    case GET_FNF_STEP1_SUMMARY_DATA:
      return {
        ...state,
        loading: false,
        step1SummaryData: action.payload,
      }
    case GET_FNF_STEP0_LIKELIHOOD_DATA:
      return {
        ...state,
        loading: false,
        step0LikelihoodData: action.payload,
      }
    case GET_FNF_STEP05_LIKELIHOOD_DATA:
      return {
        ...state,
        loading: false,
        step05LikelihoodData: action.payload,
      }
    case GET_FNF_STEP1_LIKELIHOOD_DATA:
      return {
        ...state,
        loading: false,
        step1LikelihoodData: action.payload,
      }
    case GET_FNF_STEP0_UNIQUEVALUES_DATA:
      return {
        ...state,
        loading: false,
        step0UniqueValuesData: action.payload,
      }
    case GET_FNF_STEP05_UNIQUEVALUES_DATA:
      return {
        ...state,
        loading: false,
        step05UniqueValuesData: action.payload,
      }
    case GET_FNF_STEP1_UNIQUEVALUES_DATA:
      return {
        ...state,
        loading: false,
        step1UniqueValuesData: action.payload,
      }
    case GET_FNF_ORDER_ITEMS_DATA:
      return {
        ...state,
        loading: false,
        orderItemsData: action.payload,
      }
    case CLEAR_FNF_ITEMS_DATA:
      return {
        ...state,
        loading: false,
        orderItemsData: [],
      }
    case GET_FNF_GRID_RULES:
      return {
        ...state,
        loading: false,
        gridRulesData: action.payload,
      }
    case GET_FNF_GRID_UNIQUEVALUES_DATA:
      return {
        ...state,
        loading: false,
        gridUniqueValuesData: action.payload,
      }

    default:
      return state
  }
}
