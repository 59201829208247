import { useLocation, useNavigate, useParams } from 'react-router-dom'
import './Rule.scss'
import { useEffect, useState } from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gridSchema } from '../../../../data/GridData.js'
import LoadingStatus from '../../../Helper/LoadingStatus.js'
import GridColumnMapping from '../../../../data/GridColumnMapping.js'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { toastTopRightError } from '../../../Helper/ToastObjects.js'
import jwt_decode from 'jwt-decode'
import { useAuthAndDispatch } from '../../../../hooks/useAuthAndDispatch.js'
import fAndF from '../../../../services/feesFinesServices.js'

function Rule() {
  const { ruleId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [showingMore, setShowingMore] = useState(false) // Has to be first use State hook as it is used in the tests
  const [isAllowedToUpdate, setIsAllowedToUpdate] = useState(false)
  const { dispatch, endpoint } = useAuthAndDispatch()
  const storeData = useSelector((state) => state)
  const [existingrule, setExistingRule] = useState(
    ruleId === 'new' ? gridSchema : location.state || {}
  )

  const tokenValue = jwt_decode(localStorage.getItem('token'))
  const dropDownData = useSelector((state) => state?.fnf?.gridUniqueValuesData)
  const requiredWriteRole = 'khdapi.KHC_FeesFines_Group_Writer'
  const requiredReadRole = 'khdapi.KHC_FeesFines_Group_Read'
  // const requiredReadGroup = 'KHC_FeesFines_ReadGroup'
  const requiredAdminRole = 'khdapi.Admin'

  useEffect(() => {
    if (
      tokenValue?.['roles']?.indexOf(requiredWriteRole) >= 0 ||
      tokenValue?.['roles']?.indexOf(requiredAdminRole) >= 0
    ) {
      setIsAllowedToUpdate(true)
    }
    if (dropDownData) {
      if (ruleId === 'new') {
        let newRule = gridSchema
        Object.entries(GridColumnMapping).map(([, value]) => {
          if (value.input_type === 'Dropdown') {
            newRule[value.key] = dropDownData?.[value.key]?.filter(
              (val) => val !== null
            )?.[0]
          }
        })
        setExistingRule(newRule)
      }
    }
  }, [dropDownData])

  useEffect(() => {
    if (tokenValue?.['roles']?.indexOf(requiredReadRole) >= 0) {
      fetchUniqueValues()
    }
  }, [])

  const handlechange = (val, identifier, objKey) => {
    let clone = { ...existingrule }
    if (val === 'true') {
      clone[identifier.key] = true
    } else if (val === 'false') {
      clone[identifier.key] = false
    } else if (GridColumnMapping[identifier.key].input_type === 'Number') {
      clone[identifier.key] = val
      if (GridColumnMapping[objKey].max_value !== null) {
        if (val > GridColumnMapping[objKey].max_value) {
          toast(
            `Max value allowed for ${GridColumnMapping[objKey].label} is ${GridColumnMapping[objKey].max_value}`,
            toastTopRightError
          )
          clone[identifier.key] = identifier.default_value || null
        }
      }
      if (GridColumnMapping[objKey].min_value !== null) {
        if (val < GridColumnMapping[objKey].min_value) {
          toast(
            `Min value allowed for ${GridColumnMapping[objKey].label} is ${GridColumnMapping[objKey].min_value}`,
            toastTopRightError
          )
          clone[identifier.key] = identifier.default_value || null
        }
      }
    } else {
      let inputValue = val
      if (
        GridColumnMapping[identifier.key].default_value === null &&
        inputValue === ''
      ) {
        inputValue = null
      }
      clone[identifier.key] = inputValue
    }
    setExistingRule(clone)
  }

  const goBackToRules = () => {
    navigate('/grid')
  }

  const fetchUniqueValues = async () => {
    if (!dropDownData) {
      fAndF.getGridUniqueValues(dispatch, endpoint)
    }
  }

  const updateRule = () => {
    if (
      existingrule[GridColumnMapping?.FINE_TYPE?.key] == '' ||
      existingrule[GridColumnMapping?.VALUE?.key] == '' ||
      existingrule[GridColumnMapping?.GRID_FINE_ID?.key] == 0
    ) {
      alert('You must enter Fine Type, Grid Fine ID and Value')
      return
    }

    if (ruleId !== 'new') {
      if (window.confirm('Are you sure you want to edit this rule ?')) {
        let payload = {}
        Object.values(GridColumnMapping).map((columnDetail) => {
          if (columnDetail?.includedInUpdatePayload) {
            payload[columnDetail?.key] = existingrule[columnDetail?.key]
          }
        })
        let wrapper = { data: [payload] }
        window.scrollTo(0, 0)
        fAndF.editGridRule(dispatch, endpoint, wrapper)
      }
    } else {
      if (window.confirm('Are you sure you want to add new rule ?')) {
        let payload = {}
        Object.values(GridColumnMapping).map((columnDetail) => {
          if (columnDetail?.includedInAddPayload) {
            payload[columnDetail?.key] = existingrule[columnDetail?.key]
          }
        })
        let wrapper = { data: [payload] }
        window.scrollTo(0, 0)
        fAndF.addGridRule(dispatch, endpoint, wrapper)
      }
    }
  }

  const deleteRule = () => {
    if (window.confirm('Are you sure you want to delete this rule ?')) {
      let wrapper = { data: [existingrule?.[GridColumnMapping.KH_UID.key]] }
      window.scrollTo(0, 0)
      fAndF.deleteGridRule(dispatch, endpoint, wrapper).then(() => {
        navigate('/grid')
      })
    }
  }

  const toggleseemore = () => {
    setShowingMore(!showingMore)
  }

  const isActiveInput = (value) => {
    if (!isAllowedToUpdate) {
      return false
    }
    if (ruleId === 'new' && value.editableWhileAddingRule) {
      return true
    } else if (value.editableWhileUpdatingRule) {
      return true
    }
    return false
  }

  if (
    tokenValue?.['roles']?.indexOf(requiredReadRole) < 0 &&
    tokenValue?.['roles']?.indexOf(requiredAdminRole) < 0
  ) {
    return (
      <div className="fnf noaccess">
        {/* <h4>
          You are not authorized to access Fees & Fines,<br></br> You need to be
          a member of <span>{requiredReadGroup}</span> AD Group to access Fees &
          Fines
        </h4> */}
      </div>
    )
  }

  return (
    <div className="fnf rulepage">
      {storeData?.fnf?.loading == true && (
        <LoadingStatus
          id="loader"
          data-testid="test_loader"
          status_message="Loading Data ..."
        />
      )}
      <div className="bar1">
        <h4>GRID RULE</h4>
      </div>
      <div className="ruledetails">
        <div className="actionitems">
          <button id="backbtn" onClick={goBackToRules}>
            Back to Rules
          </button>
          {isAllowedToUpdate && (
            <FontAwesomeIcon
              data-testid="Delete_Rule"
              onClick={deleteRule}
              title="Delete Rule"
              icon={faTrash}
            />
          )}
        </div>
        <h4>
          GRID RULE ID:{' '}
          {ruleId === 'new'
            ? 'New'
            : existingrule?.[GridColumnMapping.GRID_FINE_ID.key]}
        </h4>
        <div className="ruleinfo">
          {Object.entries(GridColumnMapping).map(([key, value]) => {
            if (
              !(
                (!showingMore && !value.primary) ||
                value.visibleInDetailScreen === false
              )
            ) {
              if (value.input_type === 'Dropdown') {
                return (
                  <div className="eachruleinfo" key={`${value.key}`}>
                    <h4>{value.label}</h4>
                    <select
                      data-testid={`test_select_${value.key}`}
                      disabled={!isActiveInput(value)}
                      onChange={(e) => handlechange(e.target.value, value, key)}
                      key={`${value.key}_select`}
                      value={existingrule[value.key]}
                    >
                      {dropDownData?.[value.dropdown_values]
                        ?.filter((val) => val !== null)
                        ?.map((data, index) => {
                          return (
                            <option key={`${value.key}_${index}`}>
                              {data}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                )
              } else if (value.input_type === 'String') {
                return (
                  <div className="eachruleinfo" key={key}>
                    <h4>{value.label}</h4>
                    <input
                      data-testid={`test_input_${value.key}`}
                      type="text"
                      value={existingrule?.[value.key] || ''}
                      onChange={(e) => handlechange(e.target.value, value, key)}
                      disabled={!isActiveInput(value)}
                    />
                  </div>
                )
              } else if (value.input_type === 'Number') {
                return (
                  <div className="eachruleinfo" key={key}>
                    <h4>{value.label}</h4>
                    <input
                      data-testid={`test_input_${value.key}`}
                      type="number"
                      defaultValue={existingrule?.[value.key]}
                      onChange={(e) => handlechange(e.target.value, value, key)}
                      disabled={!isActiveInput(value)}
                    />
                  </div>
                )
              } else if (value.input_type === 'Radio') {
                return (
                  <div className="eachruleinfo" key={key}>
                    <h4>{value.label}</h4>
                    <div className="radiowrapper">
                      <input
                        data-testid={`test_input_${value.key}_true`}
                        type="radio"
                        name={value.key}
                        onChange={(e) =>
                          handlechange(e.target.value, value, key)
                        }
                        checked={existingrule[value.key] === true}
                        disabled={!isActiveInput(value)}
                        value={true}
                      />{' '}
                      <span>Yes</span>
                      <input
                        data-testid={`test_input_${value.key}_false`}
                        type="radio"
                        name={value.key}
                        onChange={(e) =>
                          handlechange(e.target.value, value, key)
                        }
                        checked={existingrule[value.key] === false}
                        disabled={!isActiveInput(value)}
                        value={false}
                      />{' '}
                      <span>No</span>
                    </div>
                  </div>
                )
              }
            }
          })}
        </div>

        <button
          id="seemorebtn"
          data-testid="see_more_btn"
          onClick={toggleseemore}
        >
          {showingMore === false ? 'See More' : 'See Less'}
        </button>
        <div className="actionitems">
          {isAllowedToUpdate && (
            <button data-testid="Update_Add_Rule" onClick={updateRule}>
              {ruleId === 'new' ? 'Add Rule' : 'Update Rule'}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Rule
