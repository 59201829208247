import {
  GET_ADMIN_SURVEY_ERROR,
  GET_ADMIN_SURVEY_LOAD,
  GET_ADMIN_SURVEY_POST,
} from '../../constants/types'

const initialState = {
  getAdminSurveyData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_SURVEY_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_ADMIN_SURVEY_POST:
      return {
        ...state,
        loading: false,
        getAdminSurveyData: action.payload,
      }
    case GET_ADMIN_SURVEY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
