import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import Navbar from '../../Navbar'
import NpsMenu from './chapterNpsMenu'
// import HelpContent from './helpContent'
import Sidebar from '../AppBar/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import { AuthContext } from '../../context/authProvider'

export default function index() {
  const [authCreds] = useContext(AuthContext)

  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = React.useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  // const [loadingStatus, setLoadingStatus] = useState({
  //   status: true,
  //   message: 'Fetching table records...',
  // })
  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=COLLAB`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  return (
    <>
    <div>
          <Sidebar
            Page="NPS"
            setChannel={setChannel}
            channel={channel}
            collabMetadata={collabMetadata}
            Index={
              <>
              <NpsMenu />
              <div className="home-nps">
                <Link to="/create-survey">
                  <Button variant="primary" style={{ width: '300px', margin: '8px' }}>
                    Create Form
                  </Button>{' '}
                </Link>
                <Link to="/view-surveys">
                  <Button variant="secondary" style={{ width: '300px', margin: '8px' }}>
                    View Forms
                  </Button>
                </Link>
              </div>
              </>
            }
          />
        </div>
      {/* <Navbar title="NPS" HelpContent={HelpContent} /> */}
    
    </>
  )
}
