//import { useEffect, useMemo, useContext } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import React, { useContext } from 'react'
import jwt_decode from 'jwt-decode'
import { AuthContext } from '../../context/authProvider'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'
import { toastTopRightError } from '../../Helper/ToastObjects'
import 'react-toastify/dist/ReactToastify.css'
import {
  createIntakeRequest,
  getIngestionJobTypes,
  getIngestionDataClassifications,
} from '../../../redux/actions/IngestionIntakeAction'
import { useNavigate } from 'react-router-dom'
import NavbarRow from '../../Navbar'
import IngestionHelpContent from './IngestionHelpContent'
let tokenValue
if (localStorage.getItem('token')) {
  tokenValue = jwt_decode(localStorage.getItem('token'))
}
const IngestionIntake = () => {
  const [projectName, setProjectName] = React.useState()
  const [sourceDetail, setSourceDetail] = React.useState()
  const [status, setStatus] = React.useState()
  const statusOptions = [
    'Not Started',
    'Review In Progress',
    'Approved',
    'On Hold',
  ]
  const [deName, setDeName] = React.useState()
  const [deMail, setDeMail] = React.useState()
  const [reqBy, setReqBy] = React.useState()
  const [reqDate, setReqDate] = React.useState()
  const [dueDate, setDueDate] = React.useState()
  const [dataSource, setDataSource] = React.useState()
  const [dataSourceOptions, setDataSourceOptions] = React.useState()
  const hasFetched = React.useRef(false)
  const dispatch = useDispatch()
  const [dataClassif, setDataClassif] = React.useState()
  const [dataClassifOptions, setDataClassifOptions] = React.useState()
  const [dataOwner, setDataOwner] = React.useState()
  const [dataOwnerApp, setDataOwnerApp] = React.useState()
  const [reqComment, setReqComment] = React.useState()
  const sourceOptions = useSelector(
    (state) => state?.ingestionIntake?.sourceOptions || []
  )
  const dataClassifications = useSelector(
    (state) => state?.ingestionIntake?.dataClasifications || []
  )
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint
  const [reqTables, setReqTables] = React.useState()
  const navigate = useNavigate()

  React.useEffect(() => {
    setDeName(tokenValue?.name)
    setDeMail(tokenValue?.preferred_username)
  }, [])

  React.useEffect(() => {
    if (!hasFetched.current) {
      dispatch(getIngestionJobTypes(endpoint))
      dispatch(getIngestionDataClassifications(endpoint))
      hasFetched.current = true
    }
  }, [hasFetched])
  React.useEffect(() => {
    setDataSourceOptions(sourceOptions)
  }, [sourceOptions])
  React.useEffect(() => {
    setDataClassifOptions(dataClassifications)
  }, [dataClassifications])

  const handleSubmit = () => {
    const payload = {
      PROJECT_NAME: projectName,
      STATUS: status,
      SOURCE_DETAIL: sourceDetail,
      reqTables: reqTables,
      reqComment: reqComment,
      dataOwner: dataOwner,
      dataClassif: dataClassif,
      deName: deName,
      deMail: deMail,
      reqBy: reqBy,
      reqDate: reqDate !== null ? reqDate?.toLocaleDateString('en-US') : null,
      dueDate: dueDate !== null ? dueDate?.toLocaleDateString('en-US') : null,
      dataSource: dataSource,
    }
    const mandatoryFields = [
      projectName,
      status,
      sourceDetail,
      dataSource,
      reqTables,
      reqComment,
      dataOwner,
      dataClassif,
      deName,
      deMail,
      reqBy,
      reqDate,
      dueDate,
    ]

    if (
      mandatoryFields.some(
        (field) =>
          typeof field === 'undefined' || field === '' || field === null
      )
    ) {
      toast('All fields are mandatory', toastTopRightError)
    } else if (status.toLowerCase() === 'approved' && dataOwnerApp === null)
      toast('All fields are mandatory', toastTopRightError)
    else {
      payload.dataOwnerApp =
        status?.toLowerCase() === 'approved'
          ? dataOwnerApp?.toLocaleDateString('en-US')
          : null
      dispatch(createIntakeRequest(endpoint, payload))
    }
  }

  const newIngestionRequest = useSelector(
    (state) => state?.ingestionIntake?.newIngestionRequest
  )
  React.useEffect(() => {
    if (
      newIngestionRequest.message ===
      'Ingestion request submitted successfully.'
    ) {
      navigate('/ingestion-intake')
      dispatch(createIntakeRequest(endpoint, [], true))
    }
  }, [newIngestionRequest])
  const handleClick = () => {
    navigate(0)
  }
  const handleClickHome = () => {
    navigate('/ingestion-intake')
  }
  return (
    <React.Fragment>
      <div className="nps">
        <NavbarRow
          title={'Ingestion Request Form'}
          HelpContent={IngestionHelpContent}
        />
        <Button
          className={'m_r-5 m_t-10 nav-btn  btn-lg badge-nav-btn'}
          onClick={handleClickHome}
        >
          Home
        </Button>
        <Button
          className={'m_r-5 m_t-10 nav-btn  btn-lg badge-nav-btn'}
          onClick={handleClick}
        >
          Submit Form
        </Button>
        <div
          class="container-fluid bg-white text-dark"
          style={{ height: 'fit-content', paddingTop: '20px', width: '70%' }}
        >
          <div style={{ float: 'right', color: 'red', fontSize: 'larger' }}>
            * All fields are mandatory
          </div>
          <Form noValidate onSubmit={handleSubmit}>
            <h4
              class="text-left"
              style={{ paddingTop: '40px', paddingDown: '20px' }}
            >
              <Form.Label>Project Name</Form.Label>
            </h4>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="Project name"
              required={true}
              defaultValue={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter the project name.
            </Form.Control.Feedback>
            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Status</Form.Label>
            </h4>
            <Form.Select
              defaultValue={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option>Select Status</option>
              {statusOptions?.map((pod) => {
                return (
                  <option key={pod} value={pod}>
                    {pod}
                  </option>
                )
              })}
            </Form.Select>
            <div class="row g-3">
              <div class="col-md-6">
                <h4
                  class="text-left"
                  style={{ paddingTop: '20px', paddingDown: '20px' }}
                >
                  <Form.Label>Requested By</Form.Label>
                </h4>
                <Form.Control
                  disabled
                  type="text"
                  placeholder={deName}
                  aria-describedby="inputGroupPrepend"
                  name="Requested By"
                  defaultValue={deName}
                  onChange={(e) => setDeName('de_name', e.target.value, e)}
                />
              </div>
              <div class="col-md-6">
                <h4
                  class="text-left"
                  style={{ paddingTop: '20px', paddingDown: '20px' }}
                >
                  <Form.Label>Email ID</Form.Label>
                </h4>
                <Form.Control
                  disabled
                  type="text"
                  placeholder={deMail}
                  aria-describedby="inputGroupPrepend"
                  name="DE Email"
                  defaultValue={deMail}
                  onChange={(e) => setDeMail('de_email', e.target.value, e)}
                />
              </div>
            </div>
            <div class="row g-4">
              <div class="col-md-6">
                <h4
                  class="text-left"
                  style={{ paddingTop: '20px', paddingDown: '20px' }}
                >
                  <Form.Label>Request Date</Form.Label>
                </h4>
                {/* <Form.Control
                              type="date"
                              placeholder="Enter Date in mm/dd/yyyy"
                              name="Request Date"
                              defaultValue={reqDate}
                              onChange={(e) => setReqDate(e.target.value)}
                      /> */}
                <DatePicker
                  selected={reqDate}
                  placeholderText="Enter Date in mm/dd/yyyy"
                  onChange={(date) => setReqDate(date)}
                  className="form-control"
                  style={{ width: '621px' }}
                />
              </div>
              <div class="col-md-6">
                <h4
                  class="text-left"
                  style={{ paddingTop: '20px', paddingDown: '20px' }}
                >
                  <Form.Label>Due Date</Form.Label>
                </h4>
                {/* <Form.Control
                type="date"
                placeholder="Enter Date in mm/dd/yyyy"
                name="Due Date"
                defaultValue={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              /> */}
                <DatePicker
                  selected={dueDate}
                  placeholderText="Enter Date in mm/dd/yyyy"
                  onChange={(date) => setDueDate(date)}
                  className="form-control"
                />
              </div>
            </div>
            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Requested For</Form.Label>
            </h4>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              name="Requested For"
              defaultValue={reqBy}
              onChange={(e) => setReqBy(e.target.value)}
            />
            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Data Source</Form.Label>
            </h4>
            <Form.Select
              defaultValue={dataSource}
              onChange={(e) => setDataSource(e.target.value)}
            >
              <option>Select DataSource</option>
              {dataSourceOptions?.map((pod) => {
                return (
                  <option key={pod} value={pod}>
                    {pod}
                  </option>
                )
              })}
            </Form.Select>

            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Source Detail</Form.Label>
            </h4>
            <Form.Control
              type="text"
              placeholder="Provide Source details"
              name="name"
              defaultValue={sourceDetail}
              onChange={(e) => setSourceDetail(e.target.value)}
            />

            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Data Classification</Form.Label>
            </h4>
            <Form.Select
              defaultValue={dataClassif}
              onChange={(e) => setDataClassif(e.target.value)}
            >
              <option>Data Classification</option>
              {dataClassifOptions?.map((pod) => {
                return (
                  <option key={pod} value={pod}>
                    {pod}
                  </option>
                )
              })}
            </Form.Select>

            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Data Owner</Form.Label>
            </h4>
            <Form.Control
              type="text"
              placeholder="Data Owner"
              name="Data Owner"
              defaultValue={dataOwner}
              onChange={(e) => setDataOwner(e.target.value)}
            />

            {status?.toLowerCase() === 'approved' && (
              <>
                <h4
                  class="text-left"
                  style={{ paddingTop: '20px', paddingDown: '20px' }}
                >
                  <Form.Label>Approved on</Form.Label>
                </h4>
                <DatePicker
                  selected={dataOwnerApp}
                  placeholderText="Enter Date in mm/dd/yyyy"
                  onChange={(date) => setDataOwnerApp(date)}
                  className="form-control"
                  style={{ width: '621px' }}
                />
              </>
            )}
            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Tables</Form.Label>
            </h4>
            <Form.Control
              rows={3}
              as="textarea"
              placeholder="Tables"
              name="Tables"
              defaultValue={reqTables}
              onChange={(e) => setReqTables(e.target.value)}
            />

            <h4
              class="text-left"
              style={{ paddingTop: '20px', paddingDown: '20px' }}
            >
              <Form.Label>Comments</Form.Label>
            </h4>
            <Form.Control
              rows={3}
              as="textarea"
              placeholder="Comments"
              name="Comments"
              defaultValue={reqComment}
              onChange={(e) => setReqComment(e.target.value)}
            />

            <Button
              style={{ marginTop: '12px', marginBottom: '12px' }}
              variant="primary"
              className="addModalBtn"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IngestionIntake
