import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import columnsMap from '../../../../../../data/ColumnsMapping'
import { useEffect, useState } from 'react'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import help from '../../../../Utilities/fnf_helpers'
import { useSelector } from 'react-redux'
import fAndF from '../../../../../../services/feesFinesServices'
import { useAuthAndDispatch } from '../../../../../../hooks/useAuthAndDispatch'
import './Step1DetailedView.scss'
import EmailModal from '../../../EmailModal/EmailModal'

function Step1DetailedView({
  showModal,
  selectedModalData,
  viewgridrulesclicked,
  closeView,
}) {
  const stepsData = useSelector((state) => state)
  const [selectedTab, setSelectedTab] = useState('order_details')
  const items = stepsData?.fnf?.orderItemsData?.data || []
  const page = 0
  const rowsPerPage = 100
  const { dispatch, endpoint } = useAuthAndDispatch()

  useEffect(() => {
    if (selectedModalData && selectedTab === 'items') {
      getItems()
    }
  }, [selectedTab])

  const getItems = async () => {
    let sono = selectedModalData?.[columnsMap.SO_NO]
    const formattedFilters = help.prepareLikeFilter([
      { value: sono, field: columnsMap.SO_NO },
    ])
    let modifiedFiltersPayload = {
      filters: formattedFilters,
      limit: `${rowsPerPage}`,
      page: `${page}`,
    }
    fAndF.getItems(dispatch, endpoint, modifiedFiltersPayload)
  }

  return (
    <div className="sidebar screen1sidebar" data-testid="screen1sidebar">
      <div
        className="sidebarcontent"
        style={{ right: showModal ? 0 : '-100vw' }}
      >
        <div className="titlerow">
          <div>
            <FontAwesomeIcon
              onClick={() => {
                setSelectedTab('order_details')
                closeView()
              }}
              className="closeicon"
              icon={faTimes}
            />
            <h3>Details</h3>
          </div>
          <div>
            {selectedModalData?.[columnsMap.EVIDENCE_REQUESTED] === 'YES' && (
              <button className="podrequestedbtn">POD Requested</button>
            )}
          </div>
        </div>
        <div className="actionbtns">
          <button
            className={selectedTab === 'order_details' ? 'active' : ''}
            onClick={() => setSelectedTab('order_details')}
          >
            Claim Item Details
          </button>
          <button
            className={selectedTab === 'items' ? 'active' : ''}
            onClick={() => setSelectedTab('items')}
            data-testid="items_action"
          >
            Items
          </button>
        </div>
        {selectedTab === 'order_details' && (
          <div>
            <div className="orderdetails">
              <div className="orderdetailsheading">
                <h4>CLAIM DETAILS</h4>
                <EmailModal orderDetails={selectedModalData} />
              </div>
              <hr></hr>
              <div className="detailsinfo">
                <div className="left content">
                  <div>
                    <span className="title">Invoice No</span>
                    <span data-testid="test_invoice_number">
                      {selectedModalData?.[columnsMap.INVOICE_NUMBER]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Retailer PO#</span>
                    <span data-testid="test_purchase_order_retailer">
                      {selectedModalData?.[columnsMap.RETAILER_PO]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Liable Fine Amount</span>
                    <span data-testid="test_liable_fine_amount">
                      {selectedModalData?.[columnsMap.LIABLE_FINE_AMOUNT]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Reason For Fine</span>
                    <span data-testid="test_reason_fine">
                      {selectedModalData?.[columnsMap.FINE_TYPE]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Invoice Value</span>
                    <span data-testid="test_invoice_value">
                      {selectedModalData?.[columnsMap.INVOICE_VALUE]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Customer Name</span>
                    <span data-testid="test_customer_name">
                      {selectedModalData?.[columnsMap.RETAILER_NAME]}
                    </span>
                  </div>
                </div>
                <div className="right content">
                  <div className="rightheader">
                    <span className="title">EVIDENCE</span>
                    <button>View</button>
                  </div>
                  <div>
                    <span className="title">Evidence Requested</span>
                    <span data-testid="test_evidence_requested">
                      {selectedModalData?.[columnsMap.EVIDENCE_REQUESTED]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Evidence Type</span>
                    <span data-testid="test_evidence_type">
                      {selectedModalData?.[columnsMap.EVIDENCE_TYPE]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Priority</span>
                    <span data-testid="test_priority">
                      {selectedModalData?.[columnsMap.PRIORITY]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Status</span>
                    <span data-testid="test_status">
                      {selectedModalData?.[columnsMap.STATUS]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="orderdetails">
              <h4>COMPLIANCE CHECKS</h4>
              <hr></hr>
              <div className="detailsinfo">
                <div className="onlyleft content">
                  <div>
                    <span className="title"></span>
                    <span className="heading">AGREED VALUE</span>
                    <span className="heading">ACTUAL VALUE</span>
                    <span className="heading">RETAILER REPORTED VALUE</span>
                  </div>
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('KROGER') && (
                    <div>
                      <span className="title">ORAD</span>
                      <span data-testid="test_orad">
                        {help.formatDate(selectedModalData?.[columnsMap.ORAD])}
                      </span>
                      <span data-testid="test_actual_arrival_date">
                        {help.formatDate(
                          selectedModalData?.[columnsMap.ACTUAL_ARRIVAL_DATE]
                        )}
                      </span>
                      <span className="withicon">
                        <span data-testid="test_reported_arrival_date">
                          {help.formatDate(
                            selectedModalData?.[
                              columnsMap.REPORTED_ARRIVAL_DATE
                            ]
                          )}
                        </span>
                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'ORAD'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('TARGET') && (
                    <div>
                      <span className="title">OTS</span>
                      <span data-testid="test_orad">
                        {help.formatDate(selectedModalData?.[columnsMap.ORAD])}
                      </span>
                      <span data-testid="test_actual_arrival_date">
                        {help.formatDate(
                          selectedModalData?.[columnsMap.ACTUAL_ARRIVAL_DATE]
                        )}
                      </span>
                      <span className="withicon">
                        <span data-testid="test_reported_arrival_date">
                          {help.formatDate(
                            selectedModalData?.[
                              columnsMap.REPORTED_ARRIVAL_DATE
                            ]
                          )}
                        </span>
                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'ORAD'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('KROGER') && (
                    <div>
                      <span className="title">CFR</span>
                      <span>100%</span>
                      <span data-testid="test_cfr">
                        {selectedModalData?.CFR}%
                      </span>
                      <span className="withicon">
                        <span data-testid="test_cfr_reported">
                          {selectedModalData?.REPORTED_CFR}%
                        </span>
                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'CFR'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('TARGET') && (
                    <div>
                      <span className="title">FFR</span>
                      <span>97%</span>
                      <span data-testid="test_cfr">
                        {selectedModalData?.CFR}%
                      </span>
                      <span className="withicon">
                        <span data-testid="test_cfr_reported">
                          {selectedModalData?.REPORTED_CFR}%
                        </span>
                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'CFR'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="actionbtns bottomactions">
              <button className="active" onClick={viewgridrulesclicked}>
                View Grid Rules
              </button>
            </div>
          </div>
        )}
        {selectedTab === 'comments' && <h4>All Comments</h4>}
        {selectedTab === 'documents' && <h4>All Documents</h4>}
        {selectedTab === 'items' && (
          <div className="itemscontainer" data-testid="itemscontainer">
            {!stepsData?.fnf?.loading && items.length == 0 && (
              <h4>No Items Found</h4>
            )}
            {items.map((eachItem, index) => {
              return (
                <div className="eachcomment" key={index}>
                  <h4>{eachItem?.[columnsMap.ITEM_ID]?.split('-')?.[1]}</h4>
                  <h4>{eachItem?.[columnsMap.ITEM_LEVEL_DESCRIPTION]}</h4>
                  <p>
                    Original Requested Units:{' '}
                    {eachItem?.[columnsMap.NO_OF_UNITS_ORDERED]}
                  </p>
                  <p>
                    Actual received units:{' '}
                    <span
                      className={
                        Number(eachItem?.[columnsMap.NO_OF_UNITS_SHIPPED]) <
                        eachItem?.[columnsMap.NO_OF_UNITS_ORDERED]
                          ? 'reditems'
                          : 'greenitems'
                      }
                    >
                      {eachItem?.[columnsMap.NO_OF_UNITS_SHIPPED]}
                    </span>
                  </p>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default Step1DetailedView
