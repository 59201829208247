import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import columnsMap from '../../../../../../data/ColumnsMapping'
import { useEffect, useState } from 'react'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import help from '../../../../Utilities/fnf_helpers'
import { useSelector } from 'react-redux'
import fAndF from '../../../../../../services/feesFinesServices'
import { useAuthAndDispatch } from '../../../../../../hooks/useAuthAndDispatch'
import './Step0DetailedView.scss'

function Step0DetailedView({
  showModal,
  selectedModalData,
  viewgridrulesclicked,
  closeView,
}) {
  const stepsData = useSelector((state) => state)
  const [selectedTab, setSelectedTab] = useState('order_details')
  const items = stepsData?.fnf?.orderItemsData?.data || []
  const page = 0
  const rowsPerPage = 100
  const { dispatch, endpoint } = useAuthAndDispatch()

  useEffect(() => {
    if (selectedModalData && selectedTab === 'items') {
      getItems()
    }
  }, [selectedTab])

  const getItems = async () => {
    let sono = selectedModalData?.[columnsMap.SO_NO]
    const formattedFilters = help.prepareLikeFilter([
      { value: sono, field: columnsMap.SO_NO },
    ])
    let modifiedFiltersPayload = {
      filters: formattedFilters,
      limit: `${rowsPerPage}`,
      page: `${page}`,
    }
    fAndF.getItems(dispatch, endpoint, modifiedFiltersPayload)
  }

  return (
    <div className="sidebar screen0sidebar" data-testid="screen0sidebar">
      <div
        className="sidebarcontent"
        style={{ right: showModal ? 0 : '-100vw' }}
      >
        <div className="titlerow">
          <div>
            <FontAwesomeIcon
              onClick={() => {
                setSelectedTab('order_details')
                closeView()
              }}
              className="closeicon"
              icon={faTimes}
            />
            <h3>Details</h3>
          </div>
          <div></div>
        </div>
        <div className="actionbtns">
          <button
            className={selectedTab === 'order_details' ? 'active' : ''}
            onClick={() => setSelectedTab('order_details')}
          >
            Order/PO Details
          </button>
          <button
            className={selectedTab === 'items' ? 'active' : ''}
            onClick={() => setSelectedTab('items')}
            data-testid="items_action"
          >
            Items
          </button>
        </div>
        {selectedTab === 'order_details' && (
          <div>
            <div className="orderdetails">
              <h4>ORDER DETAILS</h4>
              <hr></hr>
              <div className="detailsinfo">
                <div className="left content">
                  <div>
                    <span className="title">Purchase Order#</span>
                    <span data-testid="test_purchase_order">
                      {selectedModalData?.[columnsMap.CUSTOMER_PO_NO]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Standard Order#</span>
                    <span data-testid="test_standard_order">
                      {selectedModalData?.[columnsMap.SO_NO]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Shipped To</span>
                    <span data-testid="test_shipped_to">
                      {selectedModalData?.[columnsMap.DC_NO_RETAILER]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Net Value</span>
                    <span data-testid="test_value_of_order_placed">
                      {help.formatNumbers(
                        selectedModalData?.[columnsMap.VALUE_OF_ORDER_PLACED]
                      )}
                    </span>
                  </div>
                  <div>
                    <span className="title">PO Date</span>
                    <span data-testid="test_po_date">
                      {selectedModalData?.[columnsMap.PO_DATE]}
                    </span>
                  </div>
                </div>
                <div className="right content">
                  <div>
                    <span className="title">PO Type</span>
                    <span data-testid="test_po_type">
                      {selectedModalData?.[columnsMap.PO_TYPE]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Transport Method</span>
                    <span data-testid="test_transport_method">
                      {selectedModalData?.[columnsMap.TRANSPORT_METHOD]}
                    </span>
                  </div>
                  <div>
                    <span className="title">Total Weight</span>
                    <span data-testid="test_total_weight">
                      {selectedModalData?.[columnsMap.TOTAL_WEIGHT]} lbs
                    </span>
                  </div>
                  <div>
                    <span className="title">Total Volume</span>
                    <span data-testid="test_total_volume">
                      {selectedModalData?.[columnsMap.TOTAL_VOLUME]} ft3
                    </span>
                  </div>
                  <div>
                    <span className="title">Status</span>
                    <span data-testid="test_status">
                      {selectedModalData?.[columnsMap.SHIPPING_STATUS]}{' '}
                      {selectedModalData?.[columnsMap.SHIPPING_STATUS] ===
                        'Shipped' && (
                        <FontAwesomeIcon className="tickicon" icon={faCheck} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="orderdetails">
              <h4>COMPLIANCE CHECKS</h4>
              <hr></hr>
              <div className="detailsinfo">
                <div className="onlyleft content">
                  <div>
                    <span className="title"></span>
                    <span className="heading">AGREED VALUE</span>
                    <span className="heading">ACTUAL VALUE</span>
                  </div>
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('KROGER') && (
                    <div>
                      <span className="title">ORAD</span>
                      <span data-testid="test_orad">
                        {help.formatDate(selectedModalData?.[columnsMap.ORAD])}
                      </span>
                      <span className="withicon">
                        <span data-testid="test_actual_arrival_date">
                          {help.formatDate(
                            selectedModalData?.[columnsMap.ACTUAL_ARRIVAL_DATE]
                          )}
                        </span>

                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'ORAD'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('TARGET') && (
                    <div>
                      <span className="title">OTS</span>
                      <span data-testid="test_orad">
                        {help.formatDate(selectedModalData?.[columnsMap.ORAD])}
                      </span>
                      <span className="withicon">
                        <span data-testid="test_actual_arrival_date">
                          {help.formatDate(
                            selectedModalData?.[columnsMap.ACTUAL_ARRIVAL_DATE]
                          )}
                        </span>
                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'ORAD'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('KROGER') && (
                    <div>
                      <span className="title">CFR</span>
                      <span>100%</span>
                      <span className="withicon">
                        <span data-testid="test_cfr">
                          {selectedModalData?.[columnsMap.CFR]}%{' '}
                        </span>
                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'CFR'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                  {selectedModalData?.[columnsMap.RETAILER_NAME]
                    ?.toUpperCase()
                    .includes('TARGET') && (
                    <div>
                      <span className="title">FFR</span>
                      <span>100%</span>
                      <span className="withicon">
                        <span data-testid="test_cfr">
                          {selectedModalData?.[columnsMap.CFR]}%
                        </span>
                        {help.checkOradOtsValidation(
                          selectedModalData,
                          'CFR'
                        ) === 'cross' ? (
                          <FontAwesomeIcon
                            className="crossicon"
                            icon={faTimes}
                          />
                        ) : help.checkOradOtsValidation(selectedModalData) ===
                          'tick' ? (
                          <FontAwesomeIcon
                            className="tickicon"
                            icon={faCheck}
                          />
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="actionbtns bottomactions">
              <button className="active" onClick={viewgridrulesclicked}>
                View Grid Rules
              </button>
            </div>
          </div>
        )}
        {selectedTab === 'items' && (
          <div className="itemscontainer" data-testid="itemscontainer">
            {!stepsData?.fnf?.loading && items.length == 0 && (
              <h4>No Items Found</h4>
            )}
            {items.map((eachItem, index) => {
              return (
                <div className="eachcomment" key={index}>
                  <h4>{eachItem?.[columnsMap.ITEM_ID]?.split('-')?.[1]}</h4>
                  <h4>{eachItem?.[columnsMap.ITEM_LEVEL_DESCRIPTION]}</h4>
                  <p>
                    Original Requested Units:{' '}
                    {eachItem?.[columnsMap.NO_OF_UNITS_ORDERED]}
                  </p>
                  <p>
                    Actual received units:
                    <span
                      className={
                        Number(eachItem?.[columnsMap.NO_OF_UNITS_SHIPPED]) <
                        eachItem?.[columnsMap.NO_OF_UNITS_ORDERED]
                          ? 'reditems'
                          : 'greenitems'
                      }
                    >
                      {eachItem?.[columnsMap.NO_OF_UNITS_SHIPPED]}
                    </span>
                  </p>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default Step0DetailedView
