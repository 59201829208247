import {
  DELETE_BADGE,
  DELETE_BADGE_ERROR,
  DELETE_BADGE_LOAD,
} from '../constants/types'

const initialState = {
  deleteBadgeData: [],
  loading: true,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_BADGE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case DELETE_BADGE:
      return {
        ...state,
        deleteBadgeData: action.payload,
        loading: false,
        error: null,
      }
    case DELETE_BADGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
