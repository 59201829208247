import {
  GET_META_DATA_BY_PICKLIST_BEGIN,
  GET_META_DATA_BY_PICKLIST_FAIL,
  GET_META_DATA_BY_PICKLIST_SUCCESS,
} from '../actions/refDataAction'

const initialState = {
  metaDataByPickList: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_META_DATA_BY_PICKLIST_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case GET_META_DATA_BY_PICKLIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_META_DATA_BY_PICKLIST_SUCCESS:
      return {
        ...state,
        metaDataByPickList: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
