import {
  GET_GLOSSARY_TERM_ID,
  GET_GLOSSARY_TERM_ID_ERROR,
  GET_GLOSSARY_TERM_ID_LOAD,
} from '../constants/types'

const initialState = {
  term: [],
  error: null,
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GLOSSARY_TERM_ID_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_GLOSSARY_TERM_ID:
      return {
        ...state,
        loading: false,
        term: action.payload,
        error: null,
      }
    case GET_GLOSSARY_TERM_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
