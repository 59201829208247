import {
  GET_META_DATA_BY_TABLE_BEGIN,
  GET_META_DATA_BY_TABLE_SUCCESS,
  GET_META_DATA_BY_TABLE_FAIL,
} from '../actions/refDataAction'

const initialState = {
  metaDataByTable: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_META_DATA_BY_TABLE_BEGIN:
      return {
        ...state,
        loading: true,
      }
    case GET_META_DATA_BY_TABLE_SUCCESS:
      return {
        ...state,
        metaDataByTable: action.payload,
        loading: false,
      }
    case GET_META_DATA_BY_TABLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
