import {
  BADGE_RESET,
  EDIT_BADGE_APPROVAL_ERROR,
  EDIT_BADGE_APPROVAL_LOAD,
  EDIT_BADGE_APPROVAL_POST,
} from '../constants/types'

const initialState = {
  badgeApprove: [],
  loading: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_BADGE_APPROVAL_LOAD:
      return {
        ...state,
        loading: true,
      }

    case EDIT_BADGE_APPROVAL_POST:
      return {
        ...state,
        badgeApprove: action.payload,
        loading: false,
      }
    case EDIT_BADGE_APPROVAL_ERROR:
      return {
        ...state,
        loading: false,
      }
    case BADGE_RESET:
      return {
        badgeApprove: [],
        loading: true,
      }
    default:
      return state
  }
}
