import React from 'react'
import './FnFHeader.scss'
import { Container, Navbar } from 'react-bootstrap'
import jwt_decode from 'jwt-decode'
import { MdDangerous } from 'react-icons/md'
import { Typography } from '@mui/material'

function FnFHeader({ component }) {
  const tokenValue = jwt_decode(localStorage.getItem('token'))
  const requiredRole = 'khdapi.KHC_FeesFines_Group_Read'
  const requiredGroup = 'KHC_FeesFines_ReadGroup'
  const requiredRole2 = 'khdapi.Admin'
  const isAllowedToAccess =
    tokenValue?.['roles']?.indexOf(requiredRole) >= 0 ||
    tokenValue?.['roles']?.indexOf(requiredRole2) >= 0

  return (
    <div className="feesnfines">
      {!isAllowedToAccess ? (
        <>
          <Navbar
            variant="dark"
            expand="lg"
            className="mt-2 mb-1 dark-blue bg-gradient"
            data-testid="feesnfines_header"
          >
            <Container fluid={'true'}>
              <Navbar.Brand className="pagetooltip">Fees & Fines</Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-dark-example" />
            </Container>
          </Navbar>
          <div
            class="bg-white text-dark text-center"
            style={{ height: '69vh', paddingTop: '150px' }}
          >
            <div>
              <MdDangerous style={{ fontSize: '60px' }} />
            </div>
            <div>
              <Typography>
                Unauthorized User. Please raise request for any of the following
                group(s): {requiredGroup}
              </Typography>
            </div>
            <div class="mt-3 fs-5">
              <Typography gutterBottom>
                To request for access for FnF go to -
                <a
                  href="https://khcprod.service-now.com/khc_sp?id=khc_sc_cat_item&sys_id=8d8dcf5bdbb1b70020013c9b7c96193a"
                  className="email-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://khcprod.service-now.com/khc_sp?id=khc_sc_cat_item&sys_id=8d8dcf5bdbb1b70020013c9b7c96193a
                </a>
              </Typography>

              <Typography gutterBottom>
                1) In *Add/Remove members select add
              </Typography>
              <Typography gutterBottom>
                2) In Security Group to modify, Search for {requiredGroup}.
              </Typography>
              <Typography gutterBottom>
                3) In Add members, select user names which needs to access de
                webapp
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <Navbar
          variant="dark"
          expand="lg"
          className="mt-2 mb-1 dark-blue bg-gradient header"
          data-testid="feesnfines_header"
        >
          <Container fluid={'true'}>
            <Navbar.Brand className="pagetooltip">Fees & Fines</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-dark-example" />
          </Container>
        </Navbar>
      )}
      {component}
    </div>
  )
}

export default FnFHeader
