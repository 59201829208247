import {
    GET_TEMPLATE_ERROR,
    GET_TEMPLATE_LOAD,
    GET_TEMPLATE_POST,
  } from '../../../constants/types'
  
  const initialState = {
    getTemplate: [],
    loading: true,
    error: '',
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_TEMPLATE_LOAD:
        return {
          ...state,
          loading: true,
        }
      case GET_TEMPLATE_POST:
        return {
          ...state,
          loading: false,
          getTemplate: action.payload,
        }
      case GET_TEMPLATE_ERROR:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
      default:
        return state
    }
  }
  
