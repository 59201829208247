const GridColumnMapping = {
  KH_ETL_IS_DELETED: {
    key: 'KH_ETL_IS_DELETED',
    label: '',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: '',
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  ACTIVE: {
    key: 'ACTIVE',
    label: 'ACTIVE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  KH_UID: {
    key: 'KH_UID',
    label: 'KH_UID',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: true,
  },
  RULE_DURATION_START: {
    key: 'RULE_DURATION_START',
    label: 'RULE DURATION START',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: true,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RULE_DURATION_END: {
    key: 'RULE_DURATION_END',
    label: 'RULE DURATION END',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: true,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  GRID_FINE_ID: {
    key: 'GRID_FINE_ID',
    label: 'GRID RULE ID',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: false,
    primary: true,
    visibleInGridTable: false,
    input_type: 'Number',
    dropdown_values: '',
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: 0,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  FINE_TYPE: {
    key: 'FINE_TYPE',
    label: 'FINE TYPE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  FINE_CATEGORY: {
    key: 'FINE_CATEGORY',
    label: 'FINE CATEGORY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Dropdown',
    dropdown_values: 'FINE_CATEGORY',
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  DESCRIPTION: {
    key: 'DESCRIPTION',
    label: 'DESCRIPTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 400,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CUSTOMER: {
    key: 'CUSTOMER',
    label: 'CUSTOMER',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Dropdown',
    dropdown_values: 'CUSTOMER',
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  VALUE: {
    key: 'VALUE',
    label: 'VALUE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 200,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  FINE_PERCENTAGE: {
    key: 'FINE_PERCENTAGE',
    label: 'FINE PERCENTAGE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Number',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: null,
    prefix: '',
    suffix: '%',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
    max_value: 100,
    min_value: 0,
  },
  MAX_AMOUNT: {
    key: 'MAX_AMOUNT',
    label: 'MAX AMOUNT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Number',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: null,
    prefix: '$',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
    min_value: 0,
  },
  RULE_STATUS: {
    key: 'RULE_STATUS',
    label: 'RULE STATUS',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Dropdown',
    dropdown_values: 'RULE_STATUS',
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  PO_DELIVERY_THRESHOLD_PERCENTAGE: {
    key: 'PO_DELIVERY_THRESHOLD_PERCENTAGE',
    label: 'PO DELIVERY ORAD/CFR THRESHOLD PERCENTAGE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Number',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: null,
    prefix: '',
    suffix: '%',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
    max_value: 100,
    min_value: 0,
  },
  ORAD_QUARTERLY_THRESHOLD_PERCENTAGE: {
    key: 'ORAD_QUARTERLY_THRESHOLD_PERCENTAGE',
    label: 'ORAD QUARTERLY THRESHOLD PERCENTAGE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Number',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: null,
    prefix: '',
    suffix: '%',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
    max_value: 100,
    min_value: 0,
  },
  CFR_QUARTERLY_THRESHOLD_PERCENTAGE: {
    key: 'CFR_QUARTERLY_THRESHOLD_PERCENTAGE',
    label: 'CFR QUARTERLY THRESHOLD PERCENTAGE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Number',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: null,
    prefix: '',
    suffix: '%',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
    max_value: 100,
    min_value: 0,
  },
  ORAD_MONTHLY_THRESHOLD_PERCENTAGE: {
    key: 'ORAD_MONTHLY_THRESHOLD_PERCENTAGE',
    label: 'ORAD MONTHLY THRESHOLD PERCENTAGE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Number',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: null,
    prefix: '',
    suffix: '%',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
    max_value: 100,
    min_value: 0,
  },
  CFR_MONTHLY_THRESHOLD_PERCENTAGE: {
    key: 'CFR_MONTHLY_THRESHOLD_PERCENTAGE',
    label: 'CFR MONTHLY THRESHOLD PERCENTAGE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Number',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: null,
    prefix: '',
    suffix: '%',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
    max_value: 100,
    min_value: 0,
  },
  RULE_1_DESCRIPTION: {
    key: 'RULE_1_DESCRIPTION',
    label: 'RULE 1 DESCRIPTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ADDITIONAL_RULE_1_DESCRIPTION: {
    key: 'ADDITIONAL_RULE_1_DESCRIPTION',
    label: 'ADDITIONAL RULE 1 DESCRIPTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  RULE_2_DESCRIPTION: {
    key: 'RULE_2_DESCRIPTION',
    label: 'RULE 2 DESCRIPTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ADDITIONAL_RULE_2_DESCRIPTION: {
    key: 'ADDITIONAL_RULE_2_DESCRIPTION',
    label: 'ADDITIONAL RULE 2 DESCRIPTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  EVIDENCE_COLLECTION: {
    key: 'EVIDENCE_COLLECTION',
    label: 'EVIDENCE COLLECTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 300,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  FINE_CALCULATED_ON: {
    key: 'FINE_CALCULATED_ON',
    label: 'FINE CALCULATED ON',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 150,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  COUNTRY: {
    key: 'COUNTRY',
    label: 'COUNTRY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  EMAIL_TO: {
    key: 'EMAIL_TO',
    label: 'EMAIL TO',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  OWING_ORG: {
    key: 'OWING_ORG',
    label: 'OWING ORG',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Dropdown',
    dropdown_values: 'OWING_ORG',
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  NOTES: {
    key: 'NOTES',
    label: 'NOTES',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  WAREHOUSE: {
    key: 'WAREHOUSE',
    label: 'WAREHOUSE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: false,
    input_type: 'Dropdown',
    dropdown_values: 'WAREHOUSE',
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  FINE: {
    key: 'FINE',
    label: 'FINE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  FEES: {
    key: 'FEES',
    label: 'FEES',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  OT: {
    key: 'OT',
    label: 'OT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  IN_FULL: {
    key: 'IN_FULL',
    label: 'IN FULL',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: true,
    visibleInGridTable: true,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER: {
    key: 'CARRIER',
    label: 'CARRIER',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  LOGISTICS: {
    key: 'LOGISTICS',
    label: 'LOGISTICS',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  PRODUCTION: {
    key: 'PRODUCTION',
    label: 'PRODUCTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INVENTORY: {
    key: 'INVENTORY',
    label: 'INVENTORY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ALLOCATION_ISSUE: {
    key: 'ALLOCATION_ISSUE',
    label: 'ALLOCATION ISSUE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ASN: {
    key: 'ASN',
    label: 'ASN',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ASN_DOWNLOAD_FAILURE: {
    key: 'ASN_DOWNLOAD_FAILURE',
    label: 'ASN DOWNLOAD FAILURE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ASN_MISMATCH: {
    key: 'ASN_MISMATCH',
    label: 'ASN MISMATCH',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ASSORTMENT_DISCREPANCY: {
    key: 'ASSORTMENT_DISCREPANCY',
    label: 'ASSORTMENT DISCREPANCY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  BARCODE_COMPLIANCE: {
    key: 'BARCODE_COMPLIANCE',
    label: 'BARCODE COMPLIANCE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  BROKEN_PALLET: {
    key: 'BROKEN_PALLET',
    label: 'BROKEN PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CANCELLED: {
    key: 'CANCELLED',
    label: 'CANCELLED',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_CANCEL_APPT: {
    key: 'CARRIER_CANCEL_APPT',
    label: 'CARRIER CANCEL APPT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_DELAY: {
    key: 'CARRIER_DELAY',
    label: 'CARRIER DELAY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_DETENTION: {
    key: 'CARRIER_DETENTION',
    label: 'CARRIER DETENTION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_FAILURE: {
    key: 'CARRIER_FAILURE',
    label: 'CARRIER FAILURE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_LATE_APPT: {
    key: 'CARRIER_LATE_APPT',
    label: 'CARRIER LATE APPT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_NO_APPT: {
    key: 'CARRIER_NO_APPT',
    label: 'CARRIER NO APPT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_NO_CALL_NO_SHOW: {
    key: 'CARRIER_NO_CALL_NO_SHOW',
    label: 'CARRIER NO CALL NO SHOW',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_NO_SHOW: {
    key: 'CARRIER_NO_SHOW',
    label: 'CARRIER NO SHOW',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_POST_DATE_APPT: {
    key: 'CARRIER_POST_DATE_APPT',
    label: 'CARRIER POST DATE APPT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_SAME_DAY_APPT: {
    key: 'CARRIER_SAME_DAY_APPT',
    label: 'CARRIER SAME DAY APPT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_RESCHEDULE_APPT: {
    key: 'CARRIER_RESCHEDULE_APPT',
    label: 'CARRIER RESCHEDULE APPT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_RESCHEDULE_APPT_WITHOUT_NOTICE: {
    key: 'CARRIER_RESCHEDULE_APPT_WITHOUT_NOTICE',
    label: 'CARRIER RESCHEDULE APPT WITHOUT NOTICE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CARRIER_WITHOUT_PAPERWORK: {
    key: 'CARRIER_WITHOUT_PAPERWORK',
    label: 'CARRIER WITHOUT PAPERWORK',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  DAMAGED_ITEMS: {
    key: 'DAMAGED_ITEMS',
    label: 'DAMAGED ITEMS',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  DAMAGED_PALLET: {
    key: 'DAMAGED_PALLET',
    label: 'DAMAGED PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  DATE_ISSUE: {
    key: 'DATE_ISSUE',
    label: 'DATE ISSUE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  DEFECT_PALLET: {
    key: 'DEFECT_PALLET',
    label: 'DEFECT PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INADEQUATE_PALLET: {
    key: 'INADEQUATE_PALLET',
    label: 'INADEQUATE PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INCORRECT_DC: {
    key: 'INCORRECT_DC',
    label: 'INCORRECT DC',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INCORRECT_INFORMATION: {
    key: 'INCORRECT_INFORMATION',
    label: 'INCORRECT INFORMATION',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INCORRECT_ORDER: {
    key: 'INCORRECT_ORDER',
    label: 'INCORRECT ORDER',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INCORRECT_PALLET: {
    key: 'INCORRECT_PALLET',
    label: 'INCORRECT PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INCORRECT_SHIPMENT: {
    key: 'INCORRECT_SHIPMENT',
    label: 'INCORRECT SHIPMENT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INCORRECT_TRAILER: {
    key: 'INCORRECT_TRAILER',
    label: 'INCORRECT TRAILER',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  INCORRECT_UPC: {
    key: 'INCORRECT_UPC',
    label: 'INCORRECT UPC',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  ITEM_QUANTITY_ISSUES: {
    key: 'ITEM_QUANTITY_ISSUES',
    label: 'ITEM QUANTITY ISSUES',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  LOAD_COMPLIANCE: {
    key: 'LOAD_COMPLIANCE',
    label: 'LOAD COMPLIANCE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  LOAD_SHIFTING: {
    key: 'LOAD_SHIFTING',
    label: 'LOAD SHIFTING',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  MISSING_BOL: {
    key: 'MISSING_BOL',
    label: 'MISSING BOL',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  MISSING_PACKING_LIST: {
    key: 'MISSING_PACKING_LIST',
    label: 'MISSING PACKING LIST',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  MISSING_DOCUMENT: {
    key: 'MISSING_DOCUMENT',
    label: 'MISSING DOCUMENT',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  MISSING_LABEL_PALLET: {
    key: 'MISSING_LABEL_PALLET',
    label: 'MISSING LABEL PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  MISSING_PO: {
    key: 'MISSING_PO',
    label: 'MISSING_PO',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  NON_COMPLIANCE: {
    key: 'NON_COMPLIANCE',
    label: 'NON COMPLIANCE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  NO_SHRINK_WRAP: {
    key: 'NO_SHRINK_WRAP',
    label: 'NO SHRINK WRAP',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  PALLET_COMPLIANCE: {
    key: 'PALLET_COMPLIANCE',
    label: 'PALLET COMPLIANCE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  PALLET_RESTACK: {
    key: 'PALLET_RESTACK',
    label: 'PALLET RESTACK',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  PO_DISCREPANCY: {
    key: 'PO_DISCREPANCY',
    label: 'PO DISCREPANCY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  POD_DISCREPANCY: {
    key: 'POD_DISCREPANCY',
    label: 'POD DISCREPANCY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  POOR_QUALITY_PALLET: {
    key: 'POOR_QUALITY_PALLET',
    label: 'POOR QUALITY_PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  PRICE_DISCREPANCY: {
    key: 'PRICE_DISCREPANCY',
    label: 'PRICE DISCREPANCY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  PRODUCT_ISSUE: {
    key: 'PRODUCT_ISSUE',
    label: 'PRODUCT ISSUE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  QUALITY_ISSUE: {
    key: 'QUALITY_ISSUE',
    label: 'QUALITY ISSUE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  REWORK: {
    key: 'REWORK',
    label: 'REWORK',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  UNACCEPTABLE_PALLET: {
    key: 'UNACCEPTABLE_PALLET',
    label: 'UNACCEPTABLE PALLET',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  STEPF: {
    key: 'STEPF',
    label: 'STEPF',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  DISPUTE_WEATHER: {
    key: 'DISPUTE_WEATHER',
    label: 'DISPUTE WEATHER',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  DROP_1_DAY_EARLY: {
    key: 'DROP_1_DAY_EARLY',
    label: 'DROP 1 DAY EARLY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  LIVE_1_DAY_EARLY: {
    key: 'LIVE_1_DAY_EARLY',
    label: 'LIVE 1 DAY EARLY',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  OT_DEF_DAY_OF: {
    key: 'OT_DEF_DAY_OF',
    label: 'OT DEF DAY OF',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  CPU_FINABLE: {
    key: 'CPU_FINABLE',
    label: 'CPU FINABLE',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  UNREASONABLE_PROPOSAL: {
    key: 'UNREASONABLE_PROPOSAL',
    label: 'UNREASONABLE PROPOSAL',
    editableWhileAddingRule: true,
    editableWhileUpdatingRule: true,
    primary: false,
    visibleInGridTable: false,
    input_type: 'Radio',
    dropdown_values: [],
    visibleInDetailScreen: true,
    minColumnWidth: 0,
    default_value: false,
    prefix: '',
    suffix: '',
    includedInAddPayload: true,
    includedInUpdatePayload: true,
  },
  RESERVED_COL_1: {
    key: 'RESERVED_COL_1',
    label: 'RESERVED COL 1',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_2: {
    key: 'RESERVED_COL_2',
    label: 'RESERVED COL 2',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_3: {
    key: 'RESERVED_COL_3',
    label: 'RESERVED COL 3',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_4: {
    key: 'RESERVED_COL_4',
    label: 'RESERVED COL 4',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_5: {
    key: 'RESERVED_COL_5',
    label: 'RESERVED COL 5',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_6: {
    key: 'RESERVED_COL_6',
    label: 'RESERVED COL 6',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_7: {
    key: 'RESERVED_COL_7',
    label: 'RESERVED COL 7',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_8: {
    key: 'RESERVED_COL_8',
    label: 'RESERVED COL 8',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_9: {
    key: 'RESERVED_COL_9',
    label: 'RESERVED COL 9',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_10: {
    key: 'RESERVED_COL_10',
    label: 'RESERVED COL 10',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_11: {
    key: 'RESERVED_COL_11',
    label: 'RESERVED COL 11',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_12: {
    key: 'RESERVED_COL_12',
    label: 'RESERVED COL 12',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_13: {
    key: 'RESERVED_COL_13',
    label: 'RESERVED COL 13',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_14: {
    key: 'RESERVED_COL_14',
    label: 'RESERVED COL 14',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_15: {
    key: 'RESERVED_COL_15',
    label: 'RESERVED COL 15',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_16: {
    key: 'RESERVED_COL_16',
    label: 'RESERVED COL 16',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_17: {
    key: 'RESERVED_COL_17',
    label: 'RESERVED COL 17',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_18: {
    key: 'RESERVED_COL_18',
    label: 'RESERVED COL 18',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_19: {
    key: 'RESERVED_COL_19',
    label: 'RESERVED COL 19',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
  RESERVED_COL_20: {
    key: 'RESERVED_COL_20',
    label: 'RESERVED COL 20',
    editableWhileAddingRule: false,
    editableWhileUpdatingRule: false,
    primary: false,
    visibleInGridTable: false,
    input_type: 'String',
    dropdown_values: [],
    visibleInDetailScreen: false,
    minColumnWidth: 0,
    default_value: '',
    prefix: '',
    suffix: '',
    includedInAddPayload: false,
    includedInUpdatePayload: false,
  },
}

export default GridColumnMapping
