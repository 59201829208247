import * as React from 'react'
import { Table, Button } from 'react-bootstrap'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import './RefTable.css'
const ExcelJS = require('exceljs')

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ErrorDialog = ({
  modalData,
  setOpenErrorLog,
  openErrorLog,
  setModalData,
}) => {
  const handleClose = () => {
    setOpenErrorLog(false)
    setModalData([])
  }

  const handleExport = () => {
    const data = modalData
    const workbook = new ExcelJS.Workbook()

    const sheet = workbook.addWorksheet('Sheet')

    const font = { name: 'Helvetica', size: 11 }
    sheet.getCell('A1').font = font
    sheet.getCell('B1').font = font
    sheet.getCell('C1').font = font

    sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
    sheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C7EEFF' },
    }

    sheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' }
    sheet.getCell('B1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C7EEFF' },
    }

    sheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' }
    sheet.getCell('C1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'C7EEFF' },
    }

    sheet.eachRow(function (row, rowNumber) {
      console.log('Row ' + rowNumber + ' = ' + JSON.stringify(row.values))
    })

    const sheetRow1 = sheet.getRow(1)

    for (let i = 1; i < 4; i++) {
      sheetRow1.getCell(i).border = {
        top: { style: 'thin', color: { argb: '040D12' } },
        left: { style: 'thin', color: { argb: '040D12' } },
        bottom: { style: 'thin', color: { argb: '040D12' } },
        right: { style: 'thin', color: { argb: '040D12' } },
      }
    }

    sheet.columns = [
      {
        header: 'RULE NAME',
        key: 'rule_name',
        width: 40,
      },
      {
        header: 'RECORD',
        key: 'record',
        width: 40,
      },
      {
        header: 'MESSAGE',
        key: 'message',
        width: 40,
      },
    ]

    data.map((data) => {
      sheet.addRow({
        rule_name: data?.rule_name,
        record: data?.record,
        message: data?.message,
      })
    })

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = 'error_log.xlsx'
      anchor.click()
      window.URL.revokeObjectURL(url)
    })
  }

  return (
    <div>
      <Dialog
        open={openErrorLog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
      >
        <DialogTitle>{'List of Failed Rules'}</DialogTitle>
        <DialogContent>
          <Table className="table-bg" responsive>
            <thead>
              <tr>
                <th className="cell-border">#</th>
                <th className="cell-border">Rule Name</th>
                <th className="cell-border">Record</th>
                <th className="cell-border">Error Message</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((failed_rule, index) => {
                return (
                  <tr
                    className="cell-border"
                    key={`${failed_rule} ${Math.random()}`}
                  >
                    <td
                      className="cell-border"
                      key={`${failed_rule} ${Math.random()}`}
                    >
                      {index + 1}
                    </td>
                    <td
                      className="cell-border"
                      key={`${failed_rule} ${Math.random()}`}
                    >
                      {failed_rule?.rule_name}
                    </td>
                    <td
                      className="cell-border"
                      key={`${failed_rule} ${Math.random()}`}
                    >
                      {JSON.stringify(failed_rule?.record)}
                    </td>
                    <td
                      className="cell-border"
                      key={`${failed_rule} ${Math.random()}`}
                    >
                      {failed_rule?.message}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="success" onClick={handleExport}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ErrorDialog
