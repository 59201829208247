import React, { useContext, useRef } from 'react'
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from 'react-table'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Table, Button, Row, Col } from 'react-bootstrap'
import '../../../App.css'
import '../../../index.css'
import '../RefData/RefTable.css'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import LoadingStatus from '../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../Helper/ToastObjects'
import axios from 'axios'
import { AuthContext } from '../../context/authProvider'
import { useDispatch, useSelector } from 'react-redux'
import { getIngestionRequests } from '../../../redux/actions/IngestionIntakeAction'
import DatePicker from 'react-datepicker'

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))

function IngestionTable({ columns }) {
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [totalPages, setTotalPages] = React.useState()
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [editRows, setEditRows] = React.useState([])
  const [dataOwnerApp, setDataOwnerApp] = React.useState(null)
  const token = localStorage.getItem('token')
  const rowIndex = null
  const dispatch = useDispatch()
  const StatusOptions = [
    'Not Started',
    'Review In Progress',
    'Approved',
    'On Hold',
  ]
  const removeFilterColumns = [
    'SOURCE_DETAIL',
    'REQUESTED_BY',
    'DATA_CLASSIFICATION',
    'COMMENTS',
    'REQUEST_DATE',
    'REVIEW_COMMENTS',
  ]
  const available_page_size = ['5', '10', '20', '50', '100']
  const [status, setStatus] = React.useState('')
  const [showModal, setshowModal] = React.useState(false)
  const [comments, setComments] = React.useState([])
  const [reviewComments, setReviewComments] = React.useState([])
  const [updateRecord, setUpdateRecord] = React.useState([])
  const [editModeState, setEditModeState] = React.useState(false)
  const [statusSelection, setStatusSelection] = React.useState('')
  const inlineValue = []
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint
  const statusSelectionRef = useRef(statusSelection)

  React.useEffect(() => {
    statusSelectionRef.current = statusSelection
  }, [statusSelection])
  const ingestionRequests = useSelector((state) => state.ingestionIntake)
  const [data, setData] = React.useState(
    ingestionRequests?.ingestionRequests?.data || []
  )

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records....',
  })

  let timeoutIds = []
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  const handleDeleteEditMode = () => {
    setEditModeState(false)
  }
  const handleUpdateEditMode = () => {
    editRows.push(inlineValue)

    // updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }
  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    React.useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload =
          statusSelectionRef.current !== ''
            ? [
                {
                  op: 'eq',
                  field: 'STATUS',
                  value: `${statusSelectionRef.current}`,
                },
              ]
            : []
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  React.useEffect(() => {
    if (statusSelection.length > 0 && statusSelection !== 'Select Status') {
      let filter = columnFilters
      let filter_payload = [
        { op: 'eq', field: 'STATUS', value: `${statusSelection}` },
      ]
      let isPresent = true
      const filters = filter.map((item) => {
        if (item.field === 'STATUS') {
          isPresent = false
          return filter_payload[0]
        } else {
          return item
        }
      })
      if (isPresent) {
        filters.push(filter_payload[0])
      }
      setColumnFilters([...filters])
      fetchData({ pageSize, pageIndex }, JSON.stringify([...filters]))
    } else {
      let filter = columnFilters
      let filters = filter.map((item) => {
        if (item?.field !== 'STATUS') {
          return item
        }
      })
      filters = filters.filter((item) => item != null)
      fetchData({ pageSize, pageIndex }, JSON?.stringify([...filters]))
      setColumnFilters(filters)
    }
  }, [statusSelection])
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }
  function truncate(str, n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: ['id'],
        pageSize: available_page_size[1],
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  React.useEffect(() => {
    if (ingestionRequests?.loading === false) {
      let table_data = ingestionRequests?.ingestionRequests?.data
      setTotalPages(ingestionRequests?.ingestionRequests?.num_pages)
      setData(table_data)
    } else {
      setTotalPages(0)
      setData([])
    }
  }, [ingestionRequests])
  React.useEffect(() => {
    if (ingestionRequests?.ingestionRequests?.data) {
      setData(ingestionRequests?.ingestionRequests?.data)
    }
  }, [data])
  React.useEffect(() => {
    const fetchAndSetData = async () => {
      if (token != null) {
        const filters = JSON.stringify(columnFilters) || []
        await fetchData(
          { pageSize, pageIndex },
          filters,
          false,
          JSON.stringify(columnSort)
        )
      }
    }
    fetchAndSetData()
  }, [pageSize, pageIndex, columnSort, token])

  React.useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])
  const handleCloseModal = () => {
    setshowModal(false)
  }
  const handleupdateIngestionIntake = () => {
    if (status.toLowerCase() === 'approved' && dataOwnerApp === null) {
      toast('Please select the Approved On', toastTopRightError)
      return
    }
    const editedRow = updateRecord
    editedRow.EDIT_COMMENT = comments
    editedRow.STATUS = status
    if (status.toLowerCase() === 'approved') {
      editedRow.dataOwnerApp = dataOwnerApp.toLocaleDateString('en-US')
    }
    editedRow.REVIEW_COMMENTS = reviewComments
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    axios
      .put(
        `${endpoint}/api/ingestion_intake/edit_ingestion_intake_request`,
        editedRow,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        toast(res.data.message, toastTopRightSuccess)
        setTimeout(() => window.location.reload(), 5000)
      })
      .catch((e) => {
        toast(e?.response?.data?.message, toastTopRightError)
      })
  }
  const handleEdit = () => {
    if (editRows.length === 0) {
      toast(
        'No records selected, Please select the  record',
        toastTopRightError
      )
    } else {
      if (editRows[0].STATUS.toLowerCase() == 'approved') {
        toast('Record already Approved', toastTopRightError)
      } else {
        if (editRows.length > 0) {
          const editRecord = editRows[0]
          setUpdateRecord(editRecord)
          setshowModal(true)
          setStatus(editRows[0]?.STATUS)
          setComments(editRows[0]?.EDIT_COMMENT)
          setReviewComments(editRows[0]?.REVIEW_COMMENTS)
        } else {
          toast('No records selected', toastTopRightError)
        }
      }
    }
  }
  const handleSorting = (header) => {
    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }
  const fetchData = async (
    { pageSize = available_page_size[1], pageIndex = 0 },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    setLocalPageIndex(pageIndex)
    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value || JSON.stringify(columnSort),
    }
    dispatch(getIngestionRequests(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const handleEditChange = (e, field) => {
    const updatedComments = e.target.value
    if (field === 'edit_comment') setComments(updatedComments)
    else if (field === 'review_comments') setReviewComments(updatedComments)
  }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="table-container" style={{ textAlign: 'center' }}>
          <Table {...getTableProps()}>
            <thead className="sticky-table-header">
              {headerGroups.map((headerGroup, i) => (
                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) =>
                    column?.id === 'selection' ? (
                      <th key={column.id}>
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                            {
                              style: { width: column.size },
                            }
                          )}
                          className="table-header-badge"
                        >
                          {column.render('Header')}
                          {columnSort[0]?.field === column['Header'] && (
                            <span>
                              {columnSort[0]?.direction === 'asc' ? ' 🔽' : ''}
                              {columnSort[0]?.direction === 'desc' ? ' 🔼' : ''}
                            </span>
                          )}
                        </div>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    ) : column.id === 'STATUS' ? (
                      <th key={i}>
                        <div
                          onClick={() => handleSorting(column.id)}
                          className="table-header-badge"
                        >
                          {column.render('Header')}
                          {columnSort[0]?.field === column['id'] ? (
                            <span>
                              {columnSort[0]?.direction === 'asc'
                                ? ' 🔽'
                                : '' || columnSort[0]?.direction === 'desc'
                                ? ' 🔼'
                                : ''}
                            </span>
                          ) : null}
                        </div>
                        <div>
                          <select
                            className="form-control"
                            defaultValue={statusSelection}
                            onChange={(e) => setStatusSelection(e.target.value)}
                          >
                            <option>Select Status</option>
                            {StatusOptions?.map((pod) => {
                              return (
                                <option key={pod} value={pod}>
                                  {pod}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </th>
                    ) : removeFilterColumns.includes(column.id) ? (
                      <th key={i}>
                        <div
                          onClick={() => handleSorting(column.id)}
                          className="table-header-badge"
                        >
                          {column.render('Header')}
                          {columnSort[0]?.field === column['id'] ? (
                            <span>
                              {columnSort[0]?.direction === 'asc'
                                ? ' 🔽'
                                : '' || columnSort[0]?.direction === 'desc'
                                ? ' 🔼'
                                : ''}
                            </span>
                          ) : null}
                        </div>
                      </th>
                    ) : (
                      <th key={i}>
                        <div
                          onClick={() => handleSorting(column.id)}
                          className="table-header-badge"
                        >
                          {column.render('Header')}
                          {columnSort[0]?.field === column['id'] ? (
                            <span>
                              {columnSort[0]?.direction === 'asc'
                                ? ' 🔽'
                                : '' || columnSort[0]?.direction === 'desc'
                                ? ' 🔼'
                                : ''}
                            </span>
                          ) : null}
                        </div>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    )
                  )}
                </tr>
              ))}
            </thead>
            <tbody className="tbody mybadgesBody" {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row)
                return (
                  <tr
                    key={index}
                    // {...row.getRowProps()}
                    // onDoubleClick={() =>
                    //   handleEditableMode(index, row.values)
                    // }
                  >
                    {row.cells.map((cell) => {
                      return cell.column.id === 'review_comments' ? (
                        <BootstrapTooltip title={cell.render('Cell')}>
                          <td
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.size + '%',
                              },
                            })}
                          >
                            {truncate(cell.value, 100)}
                          </td>
                        </BootstrapTooltip>
                      ) : cell.column.id === 'evidence' ? (
                        <BootstrapTooltip title={cell.render('Cell')}>
                          <td
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.size + '%',
                              },
                            })}
                          >
                            {truncate(cell.value, 100)}
                          </td>
                        </BootstrapTooltip>
                      ) : cell.column.id === 'chat' ? (
                        <BootstrapTooltip title={cell.render('Cell')}>
                          <td
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.size + '%',
                              },
                            })}
                          >
                            {truncate(cell.value, 100)}
                          </td>
                        </BootstrapTooltip>
                      ) : (
                        <>
                          {' '}
                          <td
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.size + '%',
                              },
                            })}
                          >
                            {cell.render('Cell')}
                          </td>
                        </>
                      )
                    })}
                    {editModeState && index === rowIndex ? (
                      <>
                        <div className="inlineedit">
                          <button
                            className="inlineinternal"
                            onClick={handleUpdateEditMode}
                          >
                            &#10004;
                          </button>
                          <button
                            className="inlineinternal"
                            onClick={handleDeleteEditMode}
                          >
                            &#10060;
                          </button>
                        </div>
                      </>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <Row>
            <Col sm={6}>
              <div className="page-control">
                <div className="page-of">
                  Page{' '}
                  <em>
                    {pageIndex + 1} of {totalPages}
                  </em>
                </div>
                <div className="prev-next-btn">
                  <button
                    className="badge-btn"
                    onClick={() => onPrevious()}
                    disabled={!canPreviousPage}
                  >
                    {' '}
                    Prev{' '}
                  </button>
                  <button
                    onClick={() => onNext()}
                    className="badge-btn"
                    disabled={!canNextPage}
                  >
                    {' '}
                    Next{' '}
                  </button>
                </div>
                <div className="second-control">
                  <span>Go to page:</span>
                  <input
                    className="page-number-input"
                    type="number"
                    defaultValue={pageIndex || 1}
                    onBlur={(e) => {
                      const page = e.target.value ? Number(e.target.value) : 0
                      // handling zero indexing
                      gotoPage(page - 1)
                    }}
                  />
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {available_page_size.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: '50px',
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  {' '}
                  Total results:
                  {ingestionRequests?.ingestionRequests?.total_results}
                </div>
              </div>
            </Col>
            {/* Bottom-right Buttons */}
            <Col sm={6}>
              <Button
                className="m_r-5 block-btn1 main-button btn-sm"
                onClick={handleEdit}
              >
                EDIT
              </Button>
              {/* {editRows[0]?.status == 'Under Review' ? (
                      <Button
                        className="m_r-5 block-btn1 main-button btn-sm"
                        // onClick={() => handleDelete(editRows[0]?.id)}
                      >
                        DELETE
                      </Button>
                    ) : (
                      <></>
                    )} */}
            </Col>
          </Row>
          <Modal size="xl" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Request</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
              <>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      defaultValue={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option>Select Status</option>
                      {StatusOptions?.map((pod) => {
                        return (
                          <option key={pod} value={pod}>
                            {pod}
                          </option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>
                  {status.toLowerCase() === 'approved' && (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Approved On</Form.Label>
                      <div>
                        <DatePicker
                          selected={dataOwnerApp}
                          placeholderText="Enter Date in mm/dd/yyyy"
                          onChange={(date) => setDataOwnerApp(date)}
                          className="form-control"
                          style={{ width: '621px' }}
                        />
                      </div>
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Change Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Change comments"
                      value={comments}
                      onChange={(e) => handleEditChange(e, 'edit_comment')}
                    />
                  </Form.Group>
                  {false && (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Review Comments</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Review Comments"
                        value={reviewComments}
                        onChange={(e) => handleEditChange(e, 'review_comments')}
                      />
                    </Form.Group>
                  )}
                </Form>
                <Modal.Footer>
                  {editRows[0]?.status == 'Rejected' ? (
                    <Button
                      variant="primary"
                      className="addModalBtn"
                      type="submit"
                      // onClick={handleEditBadge}
                    >
                      Re-Submit
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="addModalBtn"
                      type="submit"
                      onClick={handleupdateIngestionIntake}
                      // onClick={handleEditBadge}
                    >
                      Submit
                    </Button>
                  )}

                  <Button
                    variant="danger"
                    type="submit"
                    className="addModalBtn"
                    onClick={handleCloseModal}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  )
}
export default IngestionTable

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
