import styled from 'styled-components'
import { TREE_MODE } from '../DataDictionary'

const SwitchButton = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? '#313539' : '#8b969e')};
  font-weight: ${(props) => (props.active ? 'bold' : null)};
  background: ${(props) => (props.active ? '#ffffff' : null)};
`

interface Props {
  treeMode: TREE_MODE
  setTreeMode: (value: TREE_MODE) => void
}

const TreeSwitch: React.FC<Props> = (props) => {
  return (
    <div id="tree-switch">
      <SwitchButton
        className="switch-btn"
        active={props.treeMode === TREE_MODE.PROJECT}
        onClick={() => {
          props.setTreeMode(TREE_MODE.PROJECT)
        }}
      >
        Project
      </SwitchButton>

      <SwitchButton
        className="switch-btn"
        active={props.treeMode === TREE_MODE.DATABASE}
        onClick={() => {
          props.setTreeMode(TREE_MODE.DATABASE)
        }}
      >
        {' '}
        Database
      </SwitchButton>
    </div>
  )
}

export default TreeSwitch
