import React from 'react'
import { MdHelpOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'
import { Button } from 'react-bootstrap'

import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
import Slide from '@mui/material/Slide'

export default function helpContent() {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ marginRight: '10px' }}>
      <OverlayTrigger
        overlay={<Tooltip id="tooltip">What is Data Lineage ?</Tooltip>}
      >
        <Button
          aria-label="how to use Data Lineage ?"
          variant="outlined"
          label="fd"
          onClick={handleClickOpen}
        >
          <MdHelpOutline style={{ fontSize: '28px', color: '#F9D949' }} />
        </Button>
      </OverlayTrigger>

      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="How to Use Data Lineage ?"
      >
        <DialogTitle>{'What is Data Lineage ?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography gutterBottom>
              1. You will see pre defined list of node types in the dropdown.
              Select any one node type to perform node name search.
            </Typography>

            <Typography gutterBottom>
              2. In node name you can search with atleast three characters based
              on the node type selection.
            </Typography>

            <Typography gutterBottom>
              3. After selecting node name you will see graph rendered on the
              screen. Here are some below color options which represent nodes:
              <Stack direction="row" spacing={1} style={{ marginTop: '20px' }}>
                <Chip
                  label="Source"
                  style={{ backgroundColor: '#e78826', color: '#fff' }}
                />
                <Chip
                  label="Snowflake Table"
                  style={{ backgroundColor: '#297895', color: '#fff' }}
                />
                <Chip
                  label="Snf Table or View"
                  style={{ backgroundColor: '#e7b500', color: '#fff' }}
                />
                <Chip
                  label="Tableau Table"
                  style={{ backgroundColor: 'brown', color: '#fff' }}
                />
                <Chip
                  label="Tableau Dashboard"
                  style={{ backgroundColor: '#932995', color: '#fff' }}
                />
                <Chip
                  label="Tableau Workbook"
                  style={{ backgroundColor: 'green', color: '#fff' }}
                />
                <Chip
                  label="Tableau Sheet"
                  style={{ backgroundColor: '#1f9f8d', color: '#fff' }}
                />
              </Stack>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
