import {
  EDIT_REF_TABLE_DATA,
  EDIT_REF_TABLE_DATA_LOADING,
  EDIT_REF_TABLE_DATA_ERROR,
  EDIT_RESET_STATE,
} from '../constants/types'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'

export const editRefTableData =
  (endpoint, table, payload, enabled, process, ocrApproval) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDIT_REF_TABLE_DATA_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      if (enabled == undefined && ocrApproval === undefined) {
        await axios
          .put(
            `${endpoint}/api/ref_data/update_object?table_name=${table}&${process}`,
            payload,
            {
              headers: post_headers,
            }
          )
          .then((res) => {
            dispatch({
              type: EDIT_REF_TABLE_DATA,
              payload: res.data,
            })

            toast(res.data.message, toastTopRightSuccess)
          })
      } else if (ocrApproval !== undefined) {
        await axios
          .put(
            `${endpoint}/api/ref_data/update_object?table_name=${table}&${process}&${ocrApproval}`,
            payload,
            {
              headers: post_headers,
            }
          )
          .then((res) => {
            dispatch({
              type: EDIT_REF_TABLE_DATA,
              payload: res.data,
            })

            toast(res.data.message, toastTopRightSuccess)
          })
      } else {
        await axios
          .put(
            `${endpoint}/api/ref_data/update_object?table_name=${table}&${enabled}`,
            payload,
            {
              headers: post_headers,
            }
          )
          .then((res) => {
            dispatch({
              type: EDIT_REF_TABLE_DATA,
              payload: res.data,
            })
            toast(res.data.message, toastTopRightSuccess)
          })
      }
    } catch (e) {
      dispatch({
        type: EDIT_REF_TABLE_DATA_ERROR,
        payload: e.response.data,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const editRefTableDataReset = () => async (dispatch) => {
  dispatch({
    type: EDIT_RESET_STATE,
  })
}
