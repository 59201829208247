import * as React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import './appbar.css'
import Link from '@mui/material/Link'
import Chart from './Chart'
import Orders from './Orders'
import Sidebar from './Appbar'
import Map from '../../../Map'
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const countryData = [
  {
    country: 'US',
    value: 226,
  },
  {
    country: 'CN',
    value: 1,
  },
  {
    country: 'GB',
    value: 14,
  },
  {
    country: 'NL',
    value: 8,
  },
]
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme()

export default function Dashboard() {
  return (
    <Sidebar
      Page="Dashboard"
      Index={
        <>
          <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />

              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                }}
              >
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                  <div style={{ margin: '5px' }}>
                    {' '}
                    <Button variant="contained" style={{ margin: '5px' }}>
                      Badge
                    </Button>
                    <Button style={{ margin: '5px' }} variant="contained">
                      Q&A Forum
                    </Button>
                  </div>

                  <Grid container spacing={3}>
                    {/* Chart */}
                    <Grid item xs={12} md={6} lg={7}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          height: 240,
                        }}
                      >
                        <Chart />
                      </Paper>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={6} lg={5}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          height: 240,
                        }}
                      >
                        <Map data={countryData} />
                      </Paper>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={12}>
                      <Paper
                        sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                      >
                        <Orders />
                      </Paper>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ pt: 4 }} />
                </Container>
              </Box>
            </Box>
          </ThemeProvider>
        </>
      }
    />
  )
}
