import React from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'

const IdeasHelpContent = ({ openModel, closeModel, descriptionElementRef }) => {
  return (
    <Dialog
      open={openModel}
      onClose={closeModel}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">About Ideas</DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Typography gutterBottom>
            The &quot;Idea Dropbox&quot; webpage is a platform where users can
            submit, discuss, and like/dislike ideas for improving framework and
            working culture within KraftHeinz. It allows users to share feedback
            and suggestions, which corresponding &quot;Chapter Leadership&quot;
            team can review and implement based on popularity and feasibility.
          </Typography>

          <Typography gutterBottom>
            <Alert key="info" variant="info">
              For any query related to Ideas Post it in Q&A Forum tool available
              in menu or
              <Alert.Link href="https://deweb.apps.kraftheinz.com/collab">
                <u> Click here </u>
              </Alert.Link>
            </Alert>
          </Typography>
          <Typography variant="h6" gutterBottom>
            <b>Idea cards:</b>
          </Typography>
          <Typography gutterBottom>
            On homepage of idea dropbox, users can see multiple cards. <br />
            Each card holds a single idea and also following information to
            corresponding idea:
            <ol>
              <li>Idea Title</li>
              <li>
                <b>Category:</b> Whether idea is technical, or related to work
                culture.
              </li>
              <li>
                <b>Status:</b> What is the current status of the idea. You can
                check below on each status definitions.
              </li>
              <li>
                <b>Like button:</b> Press if you like the idea.
              </li>
              <li>
                <b>Dislike button:</b> Press if you dislike the idea.
              </li>
              <li>Total number of likes & total number of views.</li>
              <li>Clicking on the card expands</li>
            </ol>
          </Typography>

          <Typography variant="h6" gutterBottom>
            <b>Status Definitions</b>
          </Typography>
          <TableContainer
            component={Paper}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <Table sx={{ minWidth: 430 }} aria-label="Grade Table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="grade-table-header"
                    style={{ padding: '5px' }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    className="grade-table-header"
                    style={{ padding: '5px' }}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="grade-table-body idea-table-body">
                <TableRow style={{ borderBottom: '4px solid #f6b100' }}>
                  <TableCell>
                    <span class="idea_category_badge badge-warning">New</span>
                  </TableCell>
                  <TableCell align="left">
                    Idea is newly created yet to be approved/rejected
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '4px solid #1b84ff' }}>
                  <TableCell>
                    <span class="idea_category_badge badge-primary">
                      Unassigned
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    Idea is approved, yet to be assigned to a developer
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '4px solid #7239ea' }}>
                  <TableCell>
                    <span class="idea_category_badge badge-info">
                      In Progress
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    Idea is approved & assigned, development in progress
                  </TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '4px solid #17c653' }}>
                  <TableCell>
                    <span class="idea_category_badge badge-success">
                      Completed
                    </span>
                  </TableCell>
                  <TableCell align="left">Idea is completed</TableCell>
                </TableRow>
                <TableRow style={{ borderBottom: '4px solid #f8285a' }}>
                  <TableCell>
                    <span class="idea_category_badge badge-danger">
                      Rejected
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    Idea is rejected, should not be pursued
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" gutterBottom>
            <b>Filters & buttons on homepage:</b>
          </Typography>
          <Typography gutterBottom>
            <ul style={{ listStyleType: 'none' }}>
              <li>
                <b>Category Filter:</b> If you want to filter ideas based of
                category. Currently 2 categorys are included,
                Technical(Framework/project/platform/tools related ideas) & Ways
                of Working(Work culture related ideas)
              </li>
              <li>
                <b>Status Filter:</b> If you want to filter based on status(eg.
                you just want to see unassigned ideas so you know how you can
                contribute.)
              </li>
              <li>
                <b>Search box:</b> To search idea based on text values.
              </li>
              <li>
                <b>create button:</b> To create new idea.
              </li>
            </ul>
          </Typography>
          <Typography variant="h6" gutterBottom>
            <b>Workflow:</b>
          </Typography>
          <Typography gutterBottom>
            <ol>
              <li>
                Once a new idea is created it is open for everyone to
                like/dislike.
              </li>
              <li>
                Clicking on Idea, can give more description of the idea if you
                are interested.
              </li>
              <li>
                Chapter leardership has approve/reject button on newly created
                idea&quot;s description page.
              </li>
              <li>
                If idea is rejected it cannot be assigned, and should not be
                pursued further.
              </li>
              <li>
                If idea is approved, it&quot;s status changes to
                &quot;Unassigned&quot;. Users who wish to contribute can reach
                leadership team and get it assigned to themselves. Note: Only
                Chapter leadership can assign the idea.
              </li>
              <li>
                Once the idea is completed, contributer can reach chapter
                leadership team with PR link(if technical) else evidence &
                chapter leadership will have a &quot;Mark as complete&quot;
                button with which he can share the evidence of completion and
                close the task.
              </li>
            </ol>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{ flex: '0.95 0 0' }} />
        <Button onClick={closeModel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default IdeasHelpContent
