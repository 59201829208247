import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
export default function npsMenu({ setSort, sort, setStatusSort, statusSort }) {
  const [selectedIndexNPS, setSelectedIndexNPS] = React.useState(0)
  let history = useNavigate()

  React.useEffect(() => {
    switch (window.location.pathname) {
      // case '/nps':
      //   setSelectedIndex(0)
      //   break
      case '/nps-survey/view-surveys':
        setSelectedIndexNPS(0)
        break
      case '/nps-survey/survey-admin':
        setSelectedIndexNPS(1)
        break
      case '/nps-survey/create-view-groups':
        setSelectedIndexNPS(2)
        break
      case '/nps-survey/create-chapter-survey':
        setSelectedIndexNPS(3)
        break
      case '/nps-survey/template':
        setSelectedIndexNPS(4)
        break
      case '/nps-survey/create-template':
        setSelectedIndexNPS(4)
        break
      case '/nps-survey/select-survey':
        setSelectedIndexNPS(3)
        break
      default:
        break
    }
  }, [])
  const handleListItemClick = (event, index) => {
    console.log(index)
    setSelectedIndexNPS(index)
    if (index === 0) {
      history('/nps-survey/view-surveys')
    } else if (index === 1) {
      history('/nps-survey/survey-admin')
    } else if (index === 2) {
      history('/nps-survey/create-view-groups')
    } else if (index === 3) {
      history('/nps-survey/select-survey')
    }else if (index === 4) {
      history('/nps-survey/template')
    }  else {
      history('/')
    }
  }

  const handleSort = (value) => {
    setSort(value)
  }
  const handleStatusSort = (value) => {
    setStatusSort(value)
  }
  return (
    <>
      <div className="nav-menu-nps">
        {/* <Button
          className={
            selectedIndex == 0
              ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
              : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
          }
          onClick={(event) => handleListItemClick(event, 0)}
        >
          Home
        </Button> */}

        <Button
          className={
            selectedIndexNPS == 0
              ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
              : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
          }
          onClick={(event) => handleListItemClick(event, 0)}
        >
          {' '}
          My Surveys
        </Button>
        <Button
          className={
            selectedIndexNPS == 1
              ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
              : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
          }
          onClick={(event) => handleListItemClick(event, 1)}
        >
          Survey Admin
        </Button>
        <Button
          className={
            selectedIndexNPS == 2
              ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
              : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
          }
          onClick={(event) => handleListItemClick(event, 2)}
        >
          Survey Group Admin
        </Button>
        <Button
          className={
            selectedIndexNPS == 3
              ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
              : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
          }
          onClick={(event) => handleListItemClick(event, 3)}
        >
          Create Survey 
        </Button>
        <Button
          className={
            selectedIndexNPS == 4
              ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
              : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
          }
          onClick={(event) => handleListItemClick(event, 4)}
        >
          Create Template 
        </Button>
        {selectedIndexNPS == 0 ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={sort}
                  label="badge-name"
                  onChange={(e) => handleSort(e.target.value)}
                >
                  {/* {badgeLevels?.map((res) => {
              return ( */}
                  <MenuItem key="test" value="asc">
                    Asc
                  </MenuItem>
                  <MenuItem key="test1" value="desc">
                    Desc
                  </MenuItem>
                  <MenuItem key="test2" value="latest">
                    Latest
                  </MenuItem>
                  {/* )
            })} */}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {selectedIndexNPS == 0 ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={statusSort}
                  label="badge-name"
                  onChange={(e) => handleStatusSort(e.target.value)}
                >
                  <MenuItem key="test1" value="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem key="test1" value="Completed">
                    Complete
                  </MenuItem>
                  <MenuItem key="test1" value="Not filled">
                    Not filled
                  </MenuItem>
                  {/* )
            })} */}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </div>{' '}
    </>
  )
}
