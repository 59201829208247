import { React, useState, useRef, useContext, useEffect } from 'react'
import Input from '../ChapterNPS/input'
import './indexChapter.css'
import Sidebar from '../AppBar/Appbar'
import HelpIcon from '@mui/icons-material/Help'
// import Navbar from '../../Navbar'
import { toast } from 'react-toastify'
import NpsMenu from './chapterNpsMenu'
import { Form } from 'react-bootstrap'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import { AuthContext } from '../../context/authProvider'
// import HelpContent from '../NPS/helpContent'
import ReactQuill from 'react-quill'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import {
  createTemplate,
  getSurveyTemplate,
} from '../../../redux/actions/npsActions/npsChapterAction'

function CreateForm() {
  const [authCreds] = useContext(AuthContext)
  const [formValues, setFormValues] = useState([])
  const [toggle, setToggle] = useState(false)
  // const [groupChoiceList, setGroupChoiceList] = useState([])
  const [fieldValue, setFieldValue] = useState('text')
  const [fieldValueRating, setFieldValueRating] = useState('star')

  const [counter, setCounter] = useState(1)
  const [npsValue, setNpsValue] = useState(false)
  const [description, setDescription] = useState('')
  const [npsCounter, setNpsCounter] = useState(0)
  // const [active, setActive] = useState(false)
  const [activeCheck, setActiveCheck] = useState(false)
  const [title, setTitle] = useState('')
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching Metadata details...',
  })

  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const getTemplate = useSelector((state) => state.getTemplate)
  console.log(getTemplate)
  console.log(formValues, 'formValues')

  // const [loadingStatus, setLoadingStatus] = useState({
  //   status: true,
  //   message: 'Fetching table records...',
  // })
  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=COLLAB`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])
  useEffect(() => {
    if (getTemplate?.loading === false) {
      setFormValues(getTemplate?.getTemplate?.template_json)
      setTitle(getTemplate?.getTemplate?.template_name)
      setDescription(getTemplate?.getTemplate?.template_description)
    }
  }, [getTemplate])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  // get endpoint from authCreds
  const endpoint = authCreds.restEndpoint
  // useRef for input and placeholder
  const inputRef = useRef()
  const placeholderRef = useRef()
  const inputRefs = useRef([])
  const pushRef = (el) => {
    inputRefs.current.push(el)
  }
  const selectRef = useRef()
  const selectRefRating = useRef()
  // const handleRichTextChange = (def) => {
  //   setFormData({
  //     ...formData,
  //     definition: def,
  //   })
  // }
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
      ['code'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
  ]
  // useDispatch
  const dispatch = useDispatch()
  // functions for handling form fields
  const handleChange = (e, index) => {
    const values = [...formValues]
    values[index].value = e.target.value
    setFormValues(values)
  }
  const handleDeleteField = (e, index) => {
    const values = [...formValues]
    values.splice(index, 1)
    setFormValues(values)
  }

  const clearArray = () => {
    inputRefs.current.length = 0
  }
  const handleAddField = (e) => {
    if (
      inputRef.current.value === '' ||
      inputRef.current.value === null ||
      placeholderRef?.current?.value === '' ||
      placeholderRef?.current?.value === null
    ) {
      toast.error('Please enter values')
    } else {
      e.preventDefault()
      const values = [...formValues]
      if (npsValue == true) {
        setNpsCounter(1)
      }
      let options = inputRefs?.current?.map((n) => {
        return n?.value
      })
      let newOption = options.filter(function (element) {
        return element !== undefined
      })
      let newOption1 = [...new Set(newOption)]
      if (newOption1[0] == '') {
        toast.error('Please enter options')
      } else {
        values.push({
          label: inputRef.current.value || 'label',
          placeholder: placeholderRef?.current?.value || '',
          type: selectRef.current.value || 'text',
          value: '',
          required: activeCheck,
          ratingType: fieldValueRating,
          options: newOption1 || [],
        })
        setFormValues(values)
        setToggle(false)
        setFieldValue(selectRef.current.value)
        setFieldValueRating('star')
        clearArray()
      }
    }
  }

  const addBtnClick = (e) => {
    e.preventDefault()
    setToggle(true)
    clearArray()
    setFieldValue('text')
    setCounter(1)
  }
  function optionFunc(c) {
    var MultiGold = []
    Array.from(Array(c), (e, i) => {
      MultiGold.push(
        <input
          className="options"
          type="text"
          placeholder="Add option"
          ref={pushRef}
          key={i}
          name={'option' + i}
        />
      )
    })
    return <div>{MultiGold}</div>
  }
  const handleDropdown = (selectRef) => {
    if (selectRef.current.value === 'Nps') {
      setNpsValue(true)
    }
    setFieldValue(selectRef.current.value)
  }
  const handleDropdownRating = (selectRefRating) => {
    setFieldValueRating(selectRefRating.current.value)
  }

  function toggleSwitch() {
    setActiveCheck(!activeCheck)
  }
  const handleSubmit = () => {
    // const groupID = groupChoiceList.map((object) => object.id)

    setLoadingStatus({ status: true, message: 'Adding Details...' })

    const params = {
      template_json: formValues,
      template_name: title,
      template_description: description,
      template_channel: channel,
    }
    dispatch(createTemplate(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Adding Details...' })
      setFormValues([])
      setNpsCounter(0)
      setNpsValue(false)
    })
  }
  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('template')
  console.log(id)
  useEffect(() => {
    if (id !== null) {
      setLoadingStatus({
        status: true,
        message: 'Fetching Metadata details...',
      })
      dispatch(getSurveyTemplate(endpoint, id)).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching Metadata details...',
        })
      })
    }
  }, [])

  // const handleChangeCheck = () => {
  //   setActive(!active)
  // }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div>
          <Sidebar
            Page="NPS"
            setChannel={setChannel}
            channel={channel}
            collabMetadata={collabMetadata}
            Index={
              <>
                <div className="nps">
                  {/* <Navbar title="NPS" HelpContent={HelpContent} /> */}
                  <NpsMenu />
                  <form className="nps-form-main">
                    <h2 className="head-main-nps">Create Template</h2>

                    <input
                      type="text"
                      placeholder="Add Title"
                      onChange={(e) => setTitle(e.target.value)}
                      className="titleInput"
                      value={title}
                    />
                    <textarea
                      id="w3review"
                      className="descInput"
                      name="w3review"
                      value={description}
                      rows="4"
                      cols="50"
                      placeholder="Add Description"
                      onChange={(e) => setDescription(e.target.value)}
                    />

                    {formValues?.map((obj, index) => (
                      <Input
                        key={index}
                        objValue={obj}
                        onChange={handleChange}
                        index={index}
                        // Add this
                        deleteField={handleDeleteField}
                        disabled={false}
                      />
                    ))}
                    {!toggle ? (
                      <>
                        <div className="center-nps">
                          <button
                            className="add-btn-nps-1"
                            onClick={addBtnClick}
                          >
                            Add new
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="dialog-box-nps">
                          <select
                            ref={selectRef}
                            onChange={() => handleDropdown(selectRef)}
                          >
                            <option value="text">Text</option>
                            <option value="textarea">Text Area</option>
                            <option value="text-read-only">
                              Read Only Text
                            </option>
                            <option value="radio">Radio</option>
                            <option value="checkbox">CheckBox</option>
                            <option value="rating">Rating</option>

                            {npsCounter > 0 ? (
                              <> </>
                            ) : (
                              <option value="Nps">Net Promoter Score</option>
                            )}
                          </select>
                          {fieldValue !== 'text-read-only' ? (
                            <input
                              type="text"
                              placeholder="Add label"
                              className="form-fields"
                              ref={inputRef}
                            />
                          ) : (
                            <></>
                          )}

                          {fieldValue === 'text' || fieldValue == 'textarea' ? (
                            <input
                              type="text"
                              placeholder="Add placeholder"
                              ref={placeholderRef}
                              className="form-fields"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{ marginTop: '11px', marginBottom: '11px' }}
                        >
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            onChange={toggleSwitch}
                            checked={activeCheck}
                          />
                          <label for="start" className="require">
                            {' '}
                            Required*
                          </label>
                        </div>
                        {fieldValue == 'radio' ? (
                          <>
                            <div className="option-block">
                              {optionFunc(counter)}
                              <button
                                type="button"
                                onClick={() => setCounter(counter + 1)}
                                className="option-btn"
                              >
                                +
                              </button>
                              {counter > 1 ? (
                                <button
                                  type="button"
                                  onClick={() => setCounter(counter - 1)}
                                  className="option-btn"
                                >
                                  -
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {fieldValue == 'checkbox' ? (
                          <>
                            <div className="option-block">
                              {optionFunc(counter)}
                              <button
                                type="button"
                                onClick={() => setCounter(counter + 1)}
                                className="option-btn"
                              >
                                +
                              </button>
                              {counter > 1 ? (
                                <button
                                  type="button"
                                  onClick={() => setCounter(counter - 1)}
                                  className="option-btn"
                                >
                                  -
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {fieldValue == 'text-read-only' ? (
                          <>
                            <ReactQuill
                              theme="snow"
                              style={{ background: '#fff' }}
                              // value={formData.definition}
                              // onChange={handleRichTextChange}
                              modules={modules}
                              formats={formats}
                              ref={inputRef}
                              className="rich-text-editor"
                              required
                            />

                            {/* <textarea
                      id="w3review"
                      className="descInput"
                      name="w3review"
                      rows="4"
                      cols="50"
                      placeholder="Add Read only text"
                      ref={inputRef}
                      // onChange={(e) => setDescription(e.target.value)}
                    /> */}
                          </>
                        ) : (
                          <></>
                        )}
                        {fieldValue == 'rating' ? (
                          <>
                            <select
                              ref={selectRefRating}
                              onChange={() =>
                                handleDropdownRating(selectRefRating)
                              }
                              style={{
                                width: '20%',
                                border: '1px solid #9d9eff',
                                padding: '5px',
                                outline: 'none',
                              }}
                            >
                              <option value="star">Star</option>
                              <option value="Heart">Heart</option>
                              <option value="faces">Faces</option>
                            </select>
                          </>
                        ) : (
                          <></>
                        )}
                        <button
                          className="add-btn-nps"
                          type="button"
                          onClick={handleAddField}
                        >
                          Add
                        </button>
                      </>
                    )}
                  </form>

                  {formValues?.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '25px',
                          justifyContent: 'center',
                        }}
                      >
                        <input
                          type="checkbox"
                          // checked={feedback}
                          // onChange={handleFeedback}
                        />
                        <p style={{ marginLeft: '10px' }}>
                          Set as global template{' '}
                          <Tooltip
                            arrow
                            placement="right"
                            title={
                              <h6>
                                Global templates can be used by other survey
                                creators and will be visible to anyone
                              </h6>
                            }
                          >
                            <IconButton>
                              <HelpIcon />{' '}
                            </IconButton>
                          </Tooltip>
                        </p>
                      </div>
                      <div className="nps-form-main-btn">
                        <button
                          type="button"
                          className="submit-btn-nps"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            }
          />
        </div>
      )}
    </>
  )
}
export default CreateForm
