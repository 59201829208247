import {
  GET_SURVEY_GROUP_LIST_ERROR,
  GET_SURVEY_GROUP_LIST_LOAD,
  GET_SURVEY_GROUP_LIST_POST,
} from '../../constants/types'

const initialState = {
  getSurveyGroupListData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_GROUP_LIST_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_SURVEY_GROUP_LIST_POST:
      return {
        ...state,
        loading: false,
        getSurveyGroupListData: action.payload,
      }
    case GET_SURVEY_GROUP_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
