import {
  EDIT_COLLAB_ERROR,
  EDIT_COLLAB_POST,
  EDIT_COLLAB_POST_LOADING,
} from '../constants/types'

const initialState = {
  editcollabData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_COLLAB_POST_LOADING:
      return {
        ...state,
        loading: true,
      }
    case EDIT_COLLAB_POST:
      return {
        ...state,
        loading: false,
        editcollabData: action.payload,
      }
    case EDIT_COLLAB_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
