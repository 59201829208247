import {
  ADD_REF_TABLE_DATA,
  ADD_REF_TABLE_DATA_LOADING,
  GET_ADD_ROW_ERROR,
  ADD_RESET_STATE,
} from '../constants/types'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'

export const addRefTableData =
  (endpoint, table, payload, enabled) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_REF_TABLE_DATA_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      if (enabled == undefined) {
        await axios
          .post(
            `${endpoint}/api/ref_data/create_collection?table_name=${table}&pre_process=true`,
            payload,
            {
              headers: post_headers,
            }
          )
          .then((res) => {
            dispatch({
              type: ADD_REF_TABLE_DATA,
              payload: res.data,
            })
            if (res.data.message !== 'Success') {
              toast(res.data.message, toastTopRightError)
            } else {
              toast('Data added successfully', toastTopRightSuccess)
            }
          })
      } else {
        await axios
          .post(
            `${endpoint}/api/ref_data/create_collection?table_name=${table}&${enabled}`,
            payload,
            {
              headers: post_headers,
            }
          )
          .then((res) => {
            dispatch({
              type: ADD_REF_TABLE_DATA,
              payload: res.data,
            })
            if (res.data.message !== 'Success') {
              toast(res.data.message, toastTopRightError)
            } else {
              toast('Data added successfully', toastTopRightSuccess)
            }
          })
      }
    } catch (e) {
      dispatch({
        type: GET_ADD_ROW_ERROR,
        payload: e.response.data,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const addRefTableDataReset = () => async (dispatch) => {
  dispatch({
    type: ADD_RESET_STATE,
  })
}
