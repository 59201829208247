import React, { useEffect, useState, useContext } from 'react'
import { useTable, useSortBy, useFilters } from 'react-table'

import InfiniteScroll from 'react-infinite-scroll-component'
import { TableHandler } from '../../Utils'
import { AuthContext } from '../../context/authProvider'
import { RefDataContext } from '../../context/refDataProvider'
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../Helper/ToastObjects'

function AuditTable({ columns, data, pageEnd, update, refreshData }) {
  let timeoutIds = []

  // clear all timeouts in timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // custom component for filtering columns | having 2 sec pause feature while typing
  function DefaultColumnFilter({
    column: { filterValue, setFilter, disableColumn },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      // checks if filtervalue contains some data and it is not an initial load
      if (filterValue === undefined || initLoad === true) {
        return
      }

      // clears previous timeouts
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload = []
        // for each filter inputs, creates respective filter objects (compatible for api input)
        headers.forEach((header_obj) => {
          if (header_obj.filterValue != undefined) {
            let local_obj = { op: 'like' }
            local_obj['field'] = header_obj.Header
            local_obj['value'] = header_obj.filterValue + '%'
            filter_payload.push(local_obj)
          }
        })
        setInitLoad(true)
        refreshData(filter_payload)
      }, 2000)
      // on creation of setTimeout, the timeout id is pushed
      timeoutIds.push(timeoutId)
    }, [filterValue])

    return (
      <input
        disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        manualFilters: true,
      },
      useFilters,
      useSortBy
    )

  return rows.length < 1 ? (
    <span>Loading....</span>
  ) : (
    <div className="audit-container">
      <Table responsive {...getTableProps()}>
        <InfiniteScroll
          dataLength={rows.length}
          next={update}
          hasMore={!pageEnd}
          loader={<p>Loading more rows...</p>}
          endMessage={<p>End of records</p>}
          scrollableTarget="parent-scroll"
          refreshFunction={refreshData}
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i}>
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="table-header"
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i} className="table-row-border" {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <OverlayTrigger
                        key={i}
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            {cell.render('Cell')}
                          </Tooltip>
                        }
                      >
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      </OverlayTrigger>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </InfiniteScroll>
      </Table>
    </div>
  )
}

function AuditLog({ table_name, closeAuditLog }) {
  /* eslint-disable no-unused-vars */
  const [authCreds, setAuthCreds] = useContext(AuthContext)
  const [refData, setRefData] = useContext(RefDataContext)

  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [tableData, setTableData] = useState([])
  const [tableCols, setTableCols] = useState([])

  const [pageEnd, setPageEnd] = useState(false)

  // generic function to fetch new data and update table
  const fetchAndUpdateTable = (
    request_uri,
    urlParams,
    audit_table_name,
    fresh
  ) => {
    axios
      .get(request_uri, {
        params: urlParams,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        let table_data = response.data.data
        if (table_data.length > 0) {
          setTableCols(
            TableHandler.createColumnMapping(
              Object.keys(table_data[0]),
              refData.columnMetadata,
              audit_table_name
            )
          )
          if (fresh) {
            setTableData(table_data)
            // sets opage end to restrict further loading
            setPageEnd(false)
          } else setTableData((value) => value.concat(table_data))
          setPageNumber(Number(response.data.page_number) + 1)
          setTotalPage(response.data.num_pages)
        } else {
          toast('Zero records fetched', toastTopRightError)
          closeAuditLog()
        }
      })
      .catch((error) => {
        toast(`Error: ${error}`, toastTopRightError)
        closeAuditLog()
      })
  }

  // Used by InfiniteScroll to fetch more data on top of previous one
  const fetchMoreData = (additional_filter = null, fresh = false) => {
    if (pageNumber > totalPage) {
      setPageEnd(true)
      return
    }
    let filter_payload = [
      { op: 'like', field: 'TABLE_NAME', value: table_name },
    ]
    let request_uri = `${authCreds.restEndpoint}/api/ref_data/get_collection`
    let audit_table_name = TableHandler.getAuditTableName(
      refData.columnMetadata
    )
    audit_table_name = audit_table_name.toUpperCase()

    if (additional_filter != null) {
      if (additional_filter.length > 0)
        filter_payload = filter_payload.concat(additional_filter)
    }

    filter_payload = JSON.stringify(filter_payload)
    const urlParams = {
      table_name: audit_table_name,
      page_size: 20,
      page_number: pageNumber,
      filters: filter_payload,
      show_audit_table: true,
    }

    fetchAndUpdateTable(request_uri, urlParams, audit_table_name, fresh)
  }

  // used as a refresh function for InfiniteScroll component | refer library doc
  const refreshData = (additional_filter) => {
    fetchMoreData(additional_filter, true)
  }

  const data = React.useMemo(() => tableData, [tableData])
  const columns = React.useMemo(() => tableCols, [tableCols])

  useEffect(() => {
    fetchMoreData()
  }, [])

  useEffect(() => {}, [totalPage, pageNumber])

  return (
    <>
      {/* ToastContainer component is nneccessary to render toasts as child component */}
      <ToastContainer containerId="auditlog" draggable={false} />
      <AuditTable
        columns={columns}
        data={data}
        pageEnd={pageEnd}
        update={fetchMoreData}
        refreshData={refreshData}
      />
    </>
  )
}

export default AuditLog
