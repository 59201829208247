import {
  RESTORE_DELETE_ERROR,
  RESTORE_DELETE_LOADING,
  RESTORE_DELETE_SUCCESS,
} from '../actions/restoreDeleteAction'
const initialState = {
  restoreDeleteData: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESTORE_DELETE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case RESTORE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.restoreDeleteData,
      }
    case RESTORE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: [],
      }
    default:
      return state
  }
}
