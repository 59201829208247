import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import React, { useEffect, useContext, useMemo } from 'react'
import '../../../App.css'
import '../RefData/RefTable.css'
import AuditLog from '../RefData/AuditLog'
import { getTables } from '../../../redux/actions/refDataAction'
import {
  getRefTableData,
  DeleteRefTableData,
} from '../../../redux/actions/reftableDataAction'
import { editRefTableData } from '../../../redux/actions/editRefTableDataAction'
import { addRefTableData } from '../../../redux/actions/addRowRefDataAction'
import { BiExport, BiImport } from 'react-icons/bi'
import {
  getMetaDataByTable,
  getTablesProfile,
} from '../../../redux/actions/refDataAction'
import { restoreDeleteRecord } from '../../../redux/actions/restoreDeleteAction'
import {
  importFile,
  exportFile,
} from '../../../redux/actions/importExportFileAction'
import {
  getAzureOcrPdf,
  getModels,
} from '../../../redux/actions/azureOcrAction'
import { AuthContext } from '../../context/authProvider'
import ErrorDialog from '../RefData/ErrorDialog'
import {
  MdOutlineDone,
  MdHighlightOff,
  MdDangerous,
  MdOutlineTableView,
} from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../Helper/ToastObjects'
import ImportPreview from '../../Helper/ImportPreview'
import EditTable from '../RefData/EditTable'
import { TableHandler } from '../../Utils'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import {
  Table,
  Modal,
  Button,
  Col,
  Row,
  Dropdown,
  Form,
  Nav,
} from 'react-bootstrap'
import LoadingStatus from '../../Helper/LoadingStatus'
import { useDispatch, useSelector } from 'react-redux'
import { RefDataContext } from '../../context/refDataProvider'
import jwt_decode from 'jwt-decode'
import Slide from '@mui/material/Slide'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function OcrTable() {
  // preview modal state and functions
  let tokenValue = jwt_decode(localStorage.getItem('token'))

  const [open, setOpen] = React.useState(false)
  const [validationRulesOpen, setValidationRulesOpen] = React.useState(false)
  const [modalData, setModalData] = React.useState([])
  const [openErrorLog, setOpenErrorLog] = React.useState(false)
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [profileUrl, setprofileUrl] = React.useState('')
  const [auditLog, setShowAuditLog] = React.useState(false)
  const [showDelete, setShowDelete] = React.useState(false)
  const [checkedShowDelete, setCheckedShowDelete] = React.useState(false)
  const [showDeleted, setShowDeleted] = React.useState(false)
  const [loadBtnStatus, setLoadBtnStatus] = React.useState(false)
  const [importPreview, setShowImportPreview] = React.useState(false)
  const importFileData = useSelector((state) => state.importFileData)
  const [importData, setImportData] = React.useState({})

  const handleValidationRulesOpen = () => {
    setValidationRulesOpen(true)
  }

  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = () => setShowDelete(true)

  const handleClickOpen = (index) => {
    const containerName =
      refTableData?.refTableData?.data[index]?.ARCHIVE_LOCATION.split('/')[0]

    const directoryName = `${
      refTableData?.refTableData?.data[index]?.ARCHIVE_LOCATION.split('/')[1]
    }/${
      refTableData?.refTableData?.data[index]?.ARCHIVE_LOCATION.split('/')[2]
    }/${
      refTableData?.refTableData?.data[index]?.ARCHIVE_LOCATION.split('/')[3]
    }`

    const fileName =
      refTableData?.refTableData?.data[index]?.ARCHIVE_LOCATION.split('/')[4]

    const params = {
      container_name: containerName,
      directory_name: directoryName,
      file_name: fileName,
    }
    setLoadingStatus({ status: true, message: 'Fetching PDF...' })

    dispatch(
      getAzureOcrPdf(endpoint, params, containerName, directoryName, fileName)
    ).then(() => {
      setOpen(true)
    })

    setLoadingStatus({ status: false, message: 'Fetching PDF...' })
  }

  const showAuditLog = () => {
    setShowAuditLog(true)
  }
  const closeAuditLog = () => {
    setShowAuditLog(false)
  }

  function handleTableSelect(e) {
    setSelectedTable(e.target.value)

    dispatch(getMetaDataByTable(endpoint, e.target.value.toUpperCase()))
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const [authCreds] = useContext(AuthContext)

  const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [enabledCols, setEnabledCols] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [editRows, setEditRows] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [refData, setRefData] = React.useContext(RefDataContext)
  const [datatype, setDatatype] = React.useState({})
  const [selectedTable, setSelectedTable] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [checkedShowAll, setCheckedShowAll] = React.useState(false)
  const [checkedShowApproved, setCheckedShowApproved] = React.useState(false)

  const [editModeState, setEditModeState] = React.useState(false)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [inlineValue, setInlineValue] = React.useState([])

  const tables = useSelector((state) => state.tables)
  const refTableData = useSelector((state) => state?.refTableData)
  const pdfUrl = useSelector((state) => state?.pdfUrl)
  const editRefTable = useSelector((state) => state.editRefTable)
  const profileData = useSelector((state) => state.profileData)
  const metaDataByTable = useSelector((state) => state?.metaDataByTable)
  const { restoreDeleteData } = useSelector((state) => ({
    restoreDeleteData: state.restoreDeleteData,
    loading: state.loading,
    error: state.error,
  }))
  const table_name = 'GREENSTOCK_MODELS'
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  const [picklist_value, setPicklist_value] = React.useState({})
  let default_filter_payload = [
    { op: 'eq', field: 'STATUS', value: null },
    { op: 'ilike', field: 'STATUS', value: '' },
  ]
  const [ifListExist, setIfListExist] = React.useState(false)

  const hiddenColumns = {
    GREENSTOCK_MODELS: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  useEffect(() => {
    dispatch(getTables(endpoint, token))
    dispatch(getModels(endpoint, token))
    dispatch(
      getTablesProfile(
        endpoint,
        localStorage.getItem('token'),
        table_name.toUpperCase()
      )
    )
  }, [])

  const handleShow = () => {
    setEnabledCols(
      TableHandler.getEditableColumnList(refData?.columnMetadata, table_name)
    )
    setEnabledColsMap(
      TableHandler.getEditableColumnMap(refData?.columnMetadata, table_name)
    )
    setDatatype(TableHandler.getDatatype(refData?.columnMetadata, table_name))
    if (editRows.length > 0) {
      setShow(true)
    } else {
      toast('No records selected', toastTopRightError)
    }
  }

  const handleClose = () => {
    setShow(false)
    setEditRows(selectedFlatRows.map((d) => d.original))
  }
  const handleCloseValidation = () => {
    setValidationRulesOpen(false)
  }

  // Import File Data
  // handles import data after user confirms the changes. Calls create_collection and update_object apis for inserts and updates
  const publishImportData = () => {
    closeImportPreview()

    if (Object.keys(importData).length < 1) return

    if (importData?.insert.length > 0) {
      setLoadingStatus({ status: true, message: 'creating table data...' })

      dispatch(
        addRefTableData(
          endpoint,
          table_name.toUpperCase(),
          importData?.insert,
          'enhanced_session=true'
        )
      ).then(() => {
        setLoadingStatus({ status: false, message: 'creating table data...' })
        fetchData({ pageSize, pageIndex })
      })
    }

    if (importData?.update.length > 0) {
      setLoadingStatus({ status: true, message: 'updating table data...' })

      dispatch(
        editRefTableData(
          endpoint,
          table_name.toUpperCase(),
          importData?.update,
          'enhanced_session=true'
        )
      ).then(() => {
        setLoadingStatus({ status: false, message: 'creating table data...' })
        fetchData({ pageSize, pageIndex })
      })
    }
  }

  // triggeres on a input change event. Calls bulk_import api
  const handleImportFile = (event) => {
    let file = event.target.files[0]
    let formData = new FormData()
    formData.append('file', file)

    setShowImportPreview(true)
    dispatch(importFile(endpoint, table_name.toUpperCase(), formData))
  }

  useEffect(() => {
    if (importFileData?.loading === false) {
      setImportData(importFileData?.importFileData)
    }
  }, [importFileData, importPreview])

  const closeImportPreview = () => {
    setShowImportPreview(false)
  }

  const handleCloseRestoreDelete = () => {
    closeRestoreDelete(false)
  }

  const closeRestoreDelete = () => {
    // setRestoreDelete(false)
  }

  // DELETE ROW
  const deleteRow = () => {
    let payload = []
    let deleteUri = table_name.toUpperCase()
    // provides key value in url param for deletion of 1 key else provides list of keys in body
    if (editRows.length === 0) {
      toast('No records to delete', toastTopRightError)
      return
    } else if (editRows.length === 1) {
      deleteUri += `&key=${editRows[0]['KH_UID']}`
    } else {
      payload = editRows.map((value) => {
        return value['KH_UID']
      })
    }

    setLoadingStatus({ status: true, message: 'Deleting table record(s)...' })
    dispatch(DeleteRefTableData(endpoint, deleteUri, payload)).then(() => {
      fetchData({ pageSize, pageIndex })
      setShowDelete(false)
    })
  }

  const handleShowSoftDelete = () => {
    setCheckedShowDelete(!checkedShowDelete)
    if (!checkedShowDelete) {
      let show_deleted = true
      setShowDeleted(true)
      fetchData({ pageSize, pageIndex }, [], show_deleted)
    } else {
      setShowDeleted(false)
      fetchData({ pageSize, pageIndex })
    }
  }

  //Restore Deleted Records
  const updateRecordsRestoreDelete = () => {
    let editUri = table_name.toUpperCase()
    let payload = []
    setLoadingStatus({
      status: true,
      message: 'Restoring deleted table records...',
    })
    // loops over editRow state and creates payload compatible with update_object api
    for (let i = 0; i < editRows.length; i++) {
      let local_object = {}
      local_object['KH_UID'] = editRows[i]['KH_UID']
      for (let j = 0; j < enabledCols.length; j++) {
        let value = editRows[i][enabledCols[j]]
        let key = enabledCols[j]
        local_object[key] = value
      }
      payload.push(local_object)
    }

    //Check if the the Edit rows are 1 or more
    if (payload.length === 0) {
      toast('No records to update', toastTopRightError)
      return
    }

    dispatch(restoreDeleteRecord(endpoint, payload, editUri))
    Promise.resolve(restoreDeleteData)
      .then(() => {
        toast('Restored deleted records!', toastTopRightSuccess)
        handleCloseRestoreDelete()
        setLoadingStatus({
          status: false,
          message: 'Restoring deleted table records...',
        })
        fetchData({ pageSize, pageIndex })
        handleShowSoftDelete()
      })
      .catch(() => {
        toast('Error in restoring deleted record(s)', toastTopRightError)
        setLoadingStatus({
          status: false,
          message: 'Restoring deleted table records...',
        })
      })
  }

  // Export Table Data
  // calls bulk_export api with columns to be visible and filters (if any)
  const exportTableData = () => {
    setLoadBtnStatus(true)

    let visibleCols = TableHandler.getVisibleColumns(
      refData.hiddenColumns[table_name],
      refData.columnMetadata,
      table_name
    )
    const params = {
      filters: columnFilters,
      sort: JSON.stringify(columnSort),
      show_deleted: showDeleted,
    }
    dispatch(
      exportFile(endpoint, visibleCols, params, table_name.toUpperCase())
    ).then(() => {
      setLoadBtnStatus(false)
    })
  }

  // given a column value, it updates hidden column list
  const hiddenColumnListHandler = (value) => {
    if (value == 'Select All') {
      if (refData.hiddenColumns[table_name].includes('SELECTALL')) {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = TableHandler.getAllColumns(
            refData.columnMetadata,
            table_name
          )
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      } else {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = ['SELECTALL']
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      }
      setTableColumns(
        TableHandler.createColumnMapping(
          Object.keys(tableData[0]),
          refData.columnMetadata,
          table_name
        )
      )
    } else {
      let item_index = -1
      let new_list = []
      refData.hiddenColumns[table_name].forEach((item, index) => {
        if (item === value) item_index = index
        else new_list.push(item)
      })

      if (item_index < 0) {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = [...prev_value.hiddenColumns[table_name], value]
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      } else {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = new_list
          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      }

      setTableColumns(
        TableHandler.createColumnMapping(
          Object.keys(tableData[0]),
          refData.columnMetadata,
          table_name
        )
      )
    }
  }

  const handleShowAll = () => {
    if (checkedShowApproved === true) {
      setCheckedShowApproved(false)
    }
    setCheckedShowAll(!checkedShowAll)
    if (!checkedShowAll) {
      fetchData({ pageSize, pageIndex })
    } else {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(default_filter_payload),
        false,
        []
      )
    }
  }

  const handleShowApproved = () => {
    if (checkedShowAll === true) {
      setCheckedShowAll(false)
    }
    setCheckedShowApproved(!checkedShowApproved)
    let filter_payload = [{ op: 'ilike', field: 'STATUS', value: '%approved%' }]
    if (!checkedShowApproved) {
      fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
    } else {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(default_filter_payload),
        false,
        []
      )
    }
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  const handleEditableMode = (id, rowValue) => {
    setEditModeState(true)
    setInlineValue(rowValue)
    setRowIndex(id)
  }

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload = []
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.Header,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.Header,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.Header
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.Header
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.Header
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(JSON.stringify(filter_payload))
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(
    () => tableData,
    [
      Object.keys(refData?.columnMetadata).length > 1
        ? hiddenColumns[table_name]
        : hiddenColumns[table_name],
      tableData,
    ]
  )
  const columns = useMemo(
    () => tableColumns,
    [
      Object.keys(refData?.columnMetadata).length > 1
        ? hiddenColumns[table_name]
        : hiddenColumns[table_name],
      tableColumns,
    ]
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns:
          Object.keys(refData?.columnMetadata).length > 1
            ? refData?.hiddenColumns[table_name]
            : hiddenColumns[table_name],
        pageSize: 50,
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  // Used to fetch new table data from server
  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    // if (pageIndex === 0) pageIndex += 1
    setEnabledCols(
      TableHandler.getEditableColumnList(refData?.columnMetadata, table_name)
    )
    setEnabledColsMap(
      TableHandler.getEditableColumnMap(refData?.columnMetadata, table_name)
    )
    setDatatype(TableHandler.getDatatype(refData?.columnMetadata, table_name))

    setLocalPageIndex(pageIndex)

    const params = {
      table_name: table_name.toUpperCase(),
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      show_deleted: show_deleted,
      filters: filters,
      sort: sort_value,
    }

    dispatch(getRefTableData(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    if (refTableData?.loading === false) {
      let table_data = refTableData?.refTableData?.data
      if (table_data?.length > 0) {
        for (let i = 0; i < table_data?.length; i++) {
          if (table_data[i] !== null) {
            if (table_data[i]['KH_ETL_IS_DELETED']) {
              table_data[i]['KH_ETL_IS_DELETED'] = 'true'
            } else {
              table_data[i]['KH_ETL_IS_DELETED'] = 'false'
            }
          }
        }

        setTotalPages(refTableData?.refTableData?.num_pages)
        setTotalRows(refTableData?.refTableData?.total_results)
        setTableData(refTableData?.refTableData?.data)
      } else {
        // toast("No records found!", toastTopRightError);
        // setTableColumns([])
        setTotalPages(0)
        setTotalRows(0)
        setTableData([])
      }
    }
  }, [refTableData, setTableData])

  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows, pdfUrl])

  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    if (selectedTable.length > 1 && metaDataByTable?.loading === false) {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      if (checkedShowAll) {
        fetchData({ pageSize, pageIndex }, false, JSON.stringify(columnSort))
      } else if (checkedShowApproved) {
        let filter_payload = [
          { op: 'ilike', field: 'STATUS', value: '%approved%' },
        ]
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      } else {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify(default_filter_payload),
          false,
          JSON.stringify(columnSort)
        )
      }
    }
  }, [pageSize, pageIndex, columnSort, selectedTable, metaDataByTable?.loading])

  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  //Update using Inline Editing

  useEffect(() => {
    if (refTableData?.loading === false) {
      setTableColumns(
        TableHandler.createColumnMapping(
          refTableData.refTableData.columns,
          refData?.columnMetadata,
          table_name
        )
      )
    }
  }, [refTableData?.loading])

  const updateRecords = () => {
    handleClose()
    setLoadingStatus({ status: true, message: 'Updating table record...' })
    let editUri = table_name.toUpperCase()
    let ifApproved = false

    let payload = []

    // loops over editRow state and creates payload compatible with update_object api
    for (let i = 0; i < editRows.length; i++) {
      let local_object = {}
      local_object['KH_UID'] = editRows[i]['KH_UID']
      local_object['KH_APPROVED_ON'] = editRows[i]['KH_APPROVED_ON']
      local_object['KH_APPROVED_BY'] = editRows[i]['KH_APPROVED_BY']
      local_object['MAIL_RECEIVED_TIME'] = editRows[i]['MAIL_RECEIVED_TIME']

      if (
        editRows[i]?.STATUS === 'approved' ||
        editRows[i]?.STATUS === 'Approved'
      ) {
        ifApproved = true
        break
      }

      for (let j = 0; j < enabledCols.length; j++) {
        let value = editRows[i][enabledCols[j]]
        let key = enabledCols[j]
        local_object[key] = value
      }
      payload.push(local_object)
    }

    if (ifApproved) {
      setLoadingStatus({ status: false, message: 'Approving changes...' })
      toast('Cannot edit approved record!', toastTopRightError)
    } else {
      dispatch(
        editRefTableData(endpoint, editUri, payload, 'pre_process=true')
      ).then(() => {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify(default_filter_payload)
        )
      })
    }
  }

  useEffect(() => {
    if (profileData.profileData.message !== 'Data profile not available.') {
      setprofileUrl(profileData?.profileData?.blob_sas_url)
    }
  }, [profileData])

  const handleRecordApproval = () => {
    if (editRows.length > 0) {
      setLoadingStatus({ status: true, message: 'Approving changes...' })

      let approvalUri = table_name.toUpperCase()

      let payload = []
      let ifApproved = false

      // loops over editRow state and creates payload compatible with update_object api
      for (let i = 0; i < editRows.length; i++) {
        let local_object = {}
        local_object['KH_UID'] = editRows[i]['KH_UID']

        let date = new Date()
        if (
          editRows[i]?.STATUS === 'approved' ||
          editRows[i]?.STATUS === 'Approved'
        ) {
          ifApproved = true
          break
        }

        local_object['MAIL_RECEIVED_TIME'] = editRows[i]['MAIL_RECEIVED_TIME']
        local_object['KH_APPROVED_ON'] = date
        local_object['KH_APPROVED_BY'] = tokenValue.name
        local_object['STATUS'] = 'approved'

        for (let j = 0; j < enabledCols.length; j++) {
          let value = editRows[i][enabledCols[j]]
          let key = enabledCols[j]
          local_object[key] = value
        }
        payload.push(local_object)
      }

      if (ifApproved) {
        setLoadingStatus({ status: false, message: 'Approving changes...' })
        toast('Record already approved!', toastTopRightError)
      } else {
        dispatch(
          editRefTableData(
            endpoint,
            approvalUri,
            payload,
            undefined,
            'pre_process=true',
            'ocr_is_approved=true'
          )
        ).then(() => {
          setLoadingStatus({ status: true, message: 'Approving changes...' })
          fetchData(
            { pageSize, pageIndex },
            JSON.stringify(default_filter_payload)
          )
        })
      }
    } else {
      toast('No records selected', toastTopRightError)
    }
  }

  useEffect(() => {
    if (editRefTable?.error) {
      if (editRefTable?.error?.list_of_failed_rules !== modalData) {
        if (editRefTable?.error?.message === 'Failed Rule Validation') {
          setOpenErrorLog(true)
          setModalData(editRefTable?.error?.list_of_failed_rules)
        }
      }
      setLoadingStatus({ status: false, message: 'Updating table record...' })
    } else {
      setOpenErrorLog(false)
    }
  }, [editRefTable?.error])

  useEffect(() => {
    // if authCreds gets updated, then hit table_list and metadata request
    if (token == null) return
    setIfListExist(true)
    if (metaDataByTable.loading === false) {
      Promise.all([tables, metaDataByTable])
        .then(([table_list_response, metadata_response]) => {
          setRefData({
            tableList: table_list_response?.tables.data,
            columnMetadata: metadata_response?.metaDataByTable?.data,
            hiddenColumns: TableHandler.createHiddenList(
              metadata_response?.metaDataByTable?.data
            ),
          })
          setPicklist_value(
            metadata_response?.metaDataByTable?.data[selectedTable]?.fields
          )
          setIfListExist(false)
        })
        .catch((error) => {
          setIfListExist(false)
        })
    }
  }, [authCreds, tables, setRefData, metaDataByTable])

  if (ifListExist) {
    return <LoadingStatus />
  }
  return (
    <>
      {refTableData?.error?.message.includes('authorized') ? (
        <>
          <Navbar
            variant="dark"
            expand="lg"
            className="mt-2 mb-1 dark-blue bg-gradient"
          >
            <Container fluid>
              <Navbar.Brand>Azure OCR</Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-dark-example" />
            </Container>
          </Navbar>
          <div
            class="bg-white text-dark text-center"
            style={{ height: '80vh', paddingTop: '150px' }}
          >
            <div>
              <MdDangerous style={{ fontSize: '60px', color: 'red' }} />
            </div>
            <div class="mt-3 fs-5">{refTableData.error.message}</div>
            <div class="mt-3 fs-5"></div>
          </div>
        </>
      ) : (
        <>
          {metaDataByTable?.loading ||
          loadingStatus?.status ||
          tables.loading ||
          pdfUrl?.loading ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : selectedTable.length < 1 ? (
            <>
              <Navbar
                variant="dark"
                expand="lg"
                className="mt-2 mb-1 dark-blue bg-gradient"
              >
                <Container fluid>
                  <Navbar.Brand>Azure Data</Navbar.Brand>
                  <Navbar.Toggle aria-controls="navbar-dark-example" />
                  <Navbar.Collapse id="navbar-dark-example">
                    <Nav>
                      <Form className="snowflake-param-container">
                        <Form.Select
                          size="sm"
                          defaultValue=""
                          onChange={handleTableSelect}
                        >
                          <option>Select Table</option>
                          {pdfUrl?.models?.map((table) => {
                            return (
                              <option key={table} value={table}>
                                {table}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              <div
                className="bg-white text-dark text-center"
                style={{ height: '69vh', paddingTop: '150px' }}
              >
                <div>
                  <MdOutlineTableView style={{ fontSize: '60px' }} />
                </div>
                <div className="mt-3 fs-5">
                  No table selected. Please select a table from the dropdown.
                </div>
              </div>
            </>
          ) : (
            <>
              {refTableData?.loading ? (
                <LoadingStatus status_message={loadingStatus.message} />
              ) : (
                <>
                  <ToastContainer containerId="qwerty" />
                  <Navbar
                    variant="dark"
                    expand="lg"
                    className="mt-2 mb-1 dark-blue bg-gradient"
                  >
                    <Container fluid>
                      <Navbar.Brand>Azure OCR</Navbar.Brand>
                      <Navbar.Toggle aria-controls="navbar-dark-example" />
                      <Navbar.Collapse id="navbar-dark-example">
                        <Nav>
                          <Form className="snowflake-param-container">
                            <Form.Select
                              size="sm"
                              defaultValue={selectedTable}
                              onChange={handleTableSelect}
                            >
                              <option>Select Table</option>
                              {pdfUrl?.models?.map((table) => {
                                return (
                                  <option key={table} value={table}>
                                    {table}
                                  </option>
                                )
                              })}
                            </Form.Select>
                          </Form>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                  <>
                    <>
                      <div
                        className="table-container"
                        style={{ textAlign: 'center' }}
                      ></div>
                      <div
                        className="table-container"
                        style={{ textAlign: 'center' }}
                      >
                        <div className="ocr-toolbar">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                              style={{
                                backgroundColor: 'white',
                                color: 'black',
                              }}
                            >
                              Hide/Show Columns
                            </Dropdown.Toggle>
                            {table_name.length > 1 ? (
                              <Dropdown.Menu>
                                <Form className="column-select-container">
                                  {TableHandler.getAllColumns(
                                    refData?.columnMetadata,
                                    table_name
                                  ).map((value, i) => {
                                    return (
                                      <Form.Check
                                        key={i}
                                        onChange={() => {
                                          hiddenColumnListHandler(value)
                                        }}
                                        type="switch"
                                        id="custom-switch"
                                        checked={
                                          !refData.hiddenColumns[
                                            table_name
                                          ].includes(value)
                                        }
                                        label={value}
                                      />
                                    )
                                  })}
                                </Form>
                              </Dropdown.Menu>
                            ) : (
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  no table selected
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                          <div className="right-toolbar">
                            <input
                              type="checkbox"
                              id="showdelete"
                              checked={checkedShowDelete}
                              onChange={handleShowSoftDelete}
                            />
                            <label htmlFor="showdelete">
                              &nbsp;
                              <strong>Show deleted records</strong>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>

                            <input
                              type="checkbox"
                              id="showupproved"
                              checked={checkedShowAll}
                              onChange={handleShowAll}
                            />
                            <label htmlFor="showall">
                              &nbsp;
                              <strong>Show All</strong>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>

                            <input
                              type="checkbox"
                              id="showupproved"
                              checked={checkedShowApproved}
                              onChange={handleShowApproved}
                            />
                            <label htmlFor="showapproved">
                              &nbsp;
                              <strong>Show Only Approved</strong>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>

                            {profileData.profileData.message !==
                            'Data profile not available.' ? (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    This data profile report is not generated at
                                    runtime. This version was last generated
                                    against data up to{' '}
                                    {profileData?.profileData?.timestamp}.
                                  </Tooltip>
                                }
                              >
                                <button
                                  variant="outline-primary"
                                  // onClick={handleClickOpenProfile}
                                  style={{
                                    background: 'green',
                                    padding: '5px',
                                    border: '1px solid green',
                                    color: 'white',
                                    borderRadius: '3px',
                                  }}
                                  id="profile-btn"
                                >
                                  <a
                                    href={profileUrl}
                                    style={{
                                      color: '#fff',
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Data Profile
                                  </a>
                                </button>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    Data Profile not available
                                  </Tooltip>
                                }
                              >
                                <button
                                  variant="outline-primary"
                                  disabled
                                  style={{
                                    background: 'grey',
                                    padding: '5px',
                                    border: '1px solid grey',
                                    color: 'white',
                                    borderRadius: '3px',
                                    marginRight: '4px',
                                  }}
                                  id="profile-btn"
                                >
                                  Data Profile
                                </button>
                              </OverlayTrigger>
                            )}

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip">
                                  {' '}
                                  See validation Rules
                                </Tooltip>
                              }
                            >
                              <button
                                variant="outline-primary"
                                onClick={handleValidationRulesOpen}
                                style={{
                                  background: 'green',
                                  padding: '5px',
                                  border: '1px solid green',
                                  color: 'white',
                                  borderRadius: '3px',
                                }}
                              >
                                Validation Rules
                              </button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip">
                                  Make sure you are uploading the data using
                                  template downloaded from export button.
                                </Tooltip>
                              }
                            >
                              <button
                                variant="outline-primary"
                                style={{
                                  backgroundColor: '#6388c9',
                                  padding: '1px 3px',
                                  borderRadius: '3px',
                                  border: '1px solid rgb(94, 170, 241)',
                                  margin: '0 4px',
                                  color: 'white',
                                  fontSize: 'inherit',
                                }}
                                id="import-btn"
                              >
                                <span className="toolbar-icon">
                                  <BiImport />
                                </span>
                                <input
                                  id="file-input"
                                  className="custom-file-input"
                                  type="file"
                                  name="file"
                                  onChange={(e) => {
                                    handleImportFile(e)
                                  }}
                                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                />
                              </button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip">
                                  Export Limit 10K Records only
                                </Tooltip>
                              }
                            >
                              <button
                                // title="Export Data"
                                className={loadBtnStatus ? 'load-btn' : ''}
                                variant="outline-primary"
                                style={{
                                  backgroundColor: '#6388c9',
                                  padding: '1px 3px',
                                  borderRadius: '3px',
                                  border: '1px solid rgb(94, 170, 241)',
                                  margin: '0 4px',
                                  color: 'white',
                                  fontSize: 'inherit',
                                }}
                                disabled={loadBtnStatus}
                                onClick={exportTableData}
                              >
                                <span className="toolbar-icon">
                                  <BiExport />
                                </span>{' '}
                                Export
                              </button>
                            </OverlayTrigger>

                            <Dialog
                              maxWidth="md"
                              open={validationRulesOpen}
                              TransitionComponent={Transition}
                              keepMounted
                              onClose={handleCloseValidation}
                            >
                              <DialogTitle>{'Validation Rules'}</DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                  <Table className="table-bg" responsive>
                                    <thead>
                                      <tr>
                                        <th className="cell-border">
                                          Rule Name
                                        </th>
                                        <th className="cell-border">
                                          Rule Value
                                        </th>
                                        <th className="cell-border">
                                          Rule Error Message
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {refTableData?.refTableData?.rules?.map(
                                        (rule, index) => {
                                          return (
                                            <tr
                                              className="cell-border"
                                              key={`${index} ${Math.random()}`}
                                            >
                                              <td
                                                className="cell-border"
                                                key={`${index} ${Math.random()}`}
                                              >
                                                {rule?.rule_name}
                                              </td>
                                              <td
                                                className="cell-border"
                                                key={`${index} ${Math.random()}`}
                                              >
                                                {rule?.rule_value}
                                              </td>
                                              <td
                                                className="cell-border"
                                                key={`${index} ${Math.random()}`}
                                              >
                                                {rule?.rule_failure_message}
                                              </td>
                                            </tr>
                                          )
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                  {/* {refTableData?.refTableData?.rules?.map((d, i) => {
                        {
                          // console.log(d)
                        }
                        return (
                          <Typography key={i} gutterBottom>
                            {d}
                          </Typography>
                        )
                      })} */}
                                </DialogContentText>
                              </DialogContent>{' '}
                              <DialogActions>
                                <Button onClick={handleCloseValidation}>
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </div>
                        <Table {...getTableProps()}>
                          <thead className="sticky-table-header">
                            {headerGroups.map((headerGroup, i) => (
                              <tr
                                key={i}
                                {...headerGroup.getHeaderGroupProps()}
                              >
                                {headerGroup.headers.map((column, i) =>
                                  column?.id === 'selection' ? (
                                    <th key={column.id}>
                                      <div
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                        className="table-header"
                                      >
                                        {column.render('Header')}

                                        {columnSort[0]?.field ===
                                        column['Header'] ? (
                                          <span>
                                            {columnSort[0]?.direction === 'asc'
                                              ? ' 🔽'
                                              : '' ||
                                                columnSort[0]?.direction ===
                                                  'desc'
                                              ? ' 🔼'
                                              : ''}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <div>
                                        {column.canFilter
                                          ? column.render('Filter')
                                          : null}
                                      </div>
                                    </th>
                                  ) : column?.id !== 'FILE_NAME' ? (
                                    <th key={i}>
                                      <div
                                        onClick={() =>
                                          handleSorting(column.Header)
                                        }
                                        className="table-header"
                                      >
                                        {column.render('Header')}

                                        {columnSort[0]?.field ===
                                        column['Header'] ? (
                                          <span>
                                            {columnSort[0]?.direction === 'asc'
                                              ? ' 🔽'
                                              : '' ||
                                                columnSort[0]?.direction ===
                                                  'desc'
                                              ? ' 🔼'
                                              : ''}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <div>
                                        {column.canFilter
                                          ? column.render('Filter')
                                          : null}
                                      </div>
                                    </th>
                                  ) : null
                                )}

                                {headerGroup.headers.map((column, i) =>
                                  column?.id === 'FILE_NAME' ? (
                                    <th key={i}>
                                      <div
                                        onClick={() =>
                                          handleSorting(column.Header)
                                        }
                                        className="table-header"
                                      >
                                        {column.render('Header')}

                                        {columnSort[0]?.field ===
                                        column['Header'] ? (
                                          <span>
                                            {columnSort[0]?.direction === 'asc'
                                              ? ' 🔽'
                                              : '' ||
                                                columnSort[0]?.direction ===
                                                  'desc'
                                              ? ' 🔼'
                                              : ''}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                      <div>
                                        {column.canFilter
                                          ? column.render('Filter')
                                          : null}
                                      </div>
                                    </th>
                                  ) : null
                                )}

                                <th colSpan="3" scope="colgroup">
                                  <center>
                                    <div className="table-header">
                                      Preview PDF
                                    </div>{' '}
                                    <br />
                                  </center>
                                </th>
                              </tr>
                            ))}
                          </thead>
                          <tbody className="tbody" {...getTableBodyProps()}>
                            {page.map((row, index) => {
                              prepareRow(row)
                              return (
                                <tr
                                  key={index}
                                  {...row.getRowProps()}
                                  onDoubleClick={() =>
                                    handleEditableMode(index, row.values)
                                  }
                                >
                                  {row.cells.map((cell) => {
                                    return cell.column.id !== 'STATUS' &&
                                      cell.column.id !== 'FILE_NAME' &&
                                      cell.column.id !==
                                        'MAIL_RECEIVED_TIME' ? (
                                      <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ) : null
                                  })}

                                  {row.cells.map((cell) => {
                                    return cell.column.id === 'STATUS' ? (
                                      <td {...cell.getCellProps()}>
                                        {cell.value === null ? (
                                          <MdHighlightOff
                                            style={{
                                              fontSize: '30px',
                                              color: 'red',
                                            }}
                                          />
                                        ) : (
                                          <MdOutlineDone
                                            style={{
                                              fontSize: '30px',
                                              color: 'green',
                                            }}
                                          />
                                        )}
                                      </td>
                                    ) : null
                                  })}

                                  {row.cells.map((cell) => {
                                    return cell.column.id ===
                                      'MAIL_RECEIVED_TIME' ? (
                                      <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ) : null
                                  })}

                                  {row.cells.map((cell) => {
                                    return cell.column.id === 'FILE_NAME' ? (
                                      <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ) : null
                                  })}

                                  <td
                                    key={index}
                                    onClick={() => handleClickOpen(index)}
                                  >
                                    <Button className="btnSnowflake">
                                      Preview PDF
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <Row>
                        <Col sm={6}>
                          <div className="page-control">
                            <div className="page-of">
                              Page{' '}
                              <em>
                                {pageIndex + 1} of {totalPages}
                              </em>
                            </div>
                            <div className="prev-next-btn">
                              <button
                                onClick={() => onPrevious()}
                                disabled={!canPreviousPage}
                              >
                                {' '}
                                Prev{' '}
                              </button>
                              <button
                                onClick={() => onNext()}
                                disabled={!canNextPage}
                              >
                                {' '}
                                Next{' '}
                              </button>
                            </div>
                            <div className="second-control">
                              <span>Go to page:</span>
                              <input
                                className="page-number-input"
                                type="number"
                                defaultValue={pageIndex || 1}
                                onBlur={(e) => {
                                  const page = e.target.value
                                    ? Number(e.target.value)
                                    : 0
                                  // handling zero indexing
                                  gotoPage(page - 1)
                                }}
                              />
                              <select
                                value={pageSize}
                                onChange={(e) => {
                                  setPageSize(Number(e.target.value))
                                }}
                              >
                                {available_page_size.map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <Button
                            className="m_r-5 block-btn main-button btn-sm"
                            onClick={handleShow}
                          >
                            Edit
                          </Button>

                          <Button
                            className="m_r-5 block-btn main-button btn-sm"
                            onClick={handleRecordApproval}
                          >
                            Approve
                          </Button>

                          <Button
                            className="m_r-5 block-btn main-button btn-sm"
                            onClick={handleShowDelete}
                          >
                            Delete
                          </Button>

                          <Button
                            className="m_r-5 block-btn main-button btn-sm"
                            onClick={showAuditLog}
                          >
                            Audit log
                          </Button>

                          {checkedShowDelete ? (
                            <Button
                              className="m_r-5 mt-3 block-btn main-button"
                              onClick={updateRecordsRestoreDelete}
                            >
                              Restore Deleted
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                    </>
                  </>

                  {/* EDIT MODAL----------------------------------------------------- */}
                  <Modal size="xl" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Data: </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                      <EditTable
                        table_data={editRows}
                        setEditRows={setEditRows}
                        table_cols={tableColumns}
                        enabledColsMap={enabledColsMap}
                        datatype={datatype}
                        table_name={table_name}
                        setLoadingStatus={setLoadingStatus}
                        picklist_value={picklist_value}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="outline-danger" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="outline-primary" onClick={updateRecords}>
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* AUDIT MODAL ---------------------------------------- */}
                  <Modal size="xl" show={auditLog} onHide={closeAuditLog}>
                    <Modal.Header closeButton>
                      <Modal.Title>Audit Log</Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      className="modal-body"
                      id="parent-scroll"
                      style={{ overflow: 'auto' }}
                    >
                      <AuditLog
                        table_name={table_name}
                        closeAuditLog={closeAuditLog}
                      />
                    </Modal.Body>
                  </Modal>

                  {/* IMPORT PREVIEW MODAL ---------------------------------------- */}
                  <Modal
                    size="xl"
                    show={importPreview}
                    onHide={closeImportPreview}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Import Preview{' '}
                        <p style={{ fontSize: '11px', color: 'brown' }}>
                          {' '}
                          (Preview 100 records only)
                        </p>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                      className="modal-body y-scroll"
                      style={{ overflow: 'auto' }}
                    >
                      <ImportPreview
                        table_name={table_name}
                        importData={importData}
                        closeImportPreview={closeImportPreview}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <h6 style={{ marginRight: 'auto' }}>
                        {' '}
                        Total Count : {importData?.total_count}
                      </h6>
                      <Button
                        variant="outline-success"
                        onClick={publishImportData}
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Delete MODAL ---------------------------------------- */}
                  <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ overflowY: 'hidden' }}>
                      Are you sure to delete this entry ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseDelete}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={deleteRow}>
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Validation rules modal */}
                  <ErrorDialog
                    modalData={modalData}
                    setOpenErrorLog={setOpenErrorLog}
                    openErrorLog={openErrorLog}
                    setModalData={setModalData}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
