import React from 'react'
import './css/Main.css'
export default function Filter({
  nq,
  oq,
  mq,
  uq,
  newactive,
  oldactive,
  mostviewactive,
  unansweredactive,
}) {
  let newClass = newactive ? 'main-tab-active' : 'main-tab'
  let oldclass = oldactive ? 'main-tab-active' : 'main-tab'
  let mostviewclass = mostviewactive ? 'main-tab-active' : 'main-tab'
  let unansweredactiveclass = unansweredactive ? 'main-tab-active' : 'main-tab'
  return (
    <div className="main-filter">
      <div className="main-tabs">
        <div className={newClass}>
          <button className="filterbtn" onClick={() => nq()}>
            Newest
          </button>
        </div>
        <div className={oldclass}>
          <button className="filterbtn" onClick={() => oq()}>
            Oldest
          </button>
        </div>
        <div className={mostviewclass}>
          <button className="filterbtn" onClick={() => mq()}>
            Most Viewed
          </button>
        </div>
        <div className={unansweredactiveclass}>
          <button className="filterbtn" onClick={() => uq()}>
            Unanswered Only
          </button>
        </div>
      </div>
    </div>
  )
}
