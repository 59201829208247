import {
  GET_AZURE_OCR_PDF_LOADING,
  GET_AZURE_OCR_PDF_DATA,
  GET_AZURE_OCR_PDF_ERROR,
  GET_AZURE_OCR_MODELS,
  GET_AZURE_OCR_MODELS_LOADING,
  GET_AZURE_OCR_MODELS_ERROR,
} from '../constants/types'

const initialState = {
  pdfUrl: [],
  models: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AZURE_OCR_PDF_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_AZURE_OCR_PDF_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_AZURE_OCR_PDF_DATA:
      return {
        ...state,
        pdfUrl: action.payload,
        loading: false,
      }
    case GET_AZURE_OCR_MODELS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_AZURE_OCR_MODELS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_AZURE_OCR_MODELS:
      return {
        ...state,
        models: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
