import React, { useState, useEffect, useMemo, useContext } from 'react'
import '../RefData/RefTable.css'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import dbtGradingHelpContent from './dbtGradingHelpContent'
import Navbar from '../../Navbar'
import { Table, Col, Row } from 'react-bootstrap'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'
import './dbtGrading.css'

import '../../../App.css'
import DBTGradingModelDashboard from './dbtGradingModels'
import GetAvgAlgorithmsScore from './getAvgAlgorithmsScore'

import { getDbtGradingProjects } from '../../../redux/actions/dbtGradingActions/dbtGradingAction'

export default function DBTGradingDashboard() {
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '12px',
      whiteSpace: 'break-spaces',
    },
  }))

  const [authCreds] = useContext(AuthContext)
  const [tableData, setTableData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = useState(0)
  const [tableColumns, setTableColumns] = useState([])
  const [localPageIndex, setLocalPageIndex] = useState(0)
  const [columnFilters, setColumnFilters] = useState([])
  const [uid, setUid] = useState('')
  const [projectName, setProjectName] = useState('')
  const [ifMyProjects, setIfMyProjects] = useState(false)
  const [ifMyPods, setIfMyPods] = useState(false)
  const handleCheckMyProjects = (e) => {
    setIfMyProjects(e.currentTarget.checked)
    if (e.currentTarget.checked && ifMyPods) {
      setIfMyPods(false)
    }
  }
  const handleCheckMyPods = (e) => {
    setIfMyPods(e.currentTarget.checked)
    if (e.currentTarget.checked && ifMyProjects) {
      setIfMyProjects(false)
    }
  }

  const [columnSort, setColumnSortValue] = useState([])
  // const [isSort, setSort] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const handleCloseModal = () => {
    setshowModal(false)
    setshowAvgModal(false)
  }
  const [showAvgModal, setshowAvgModal] = useState(false)
  const handleCloseAvgModal = () => {
    setshowModal(false)
    setshowAvgModal(false)
  }

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const dbtGradingProjects = useSelector((state) => state.getDbtProjectsReducer)

  const viewProjectModal = (e, uid, projectName) => {
    e.preventDefault()
    setshowModal(true)
    setUid(uid)
    setProjectName(projectName)
  }
  const viewProjectAlgorithmAvgScore = (e, uid, projectName) => {
    e.preventDefault()
    setshowAvgModal(true)
    setUid(uid)
    setProjectName(projectName)
  }

  const table_name = 'GRADING_PROJECTS'
  const columnMetadata = {
    GRADING_PROJECTS: {
      fields: [
        {
          name: 'PROJECT_NAME',
          headerTitle: 'PROJECT NAME',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'GRADE',
          headerTitle: 'GRADE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'POD_NAME',
          headerTitle: 'FUNCTIONAL AREA',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'PROJECT_DIR',
          headerTitle: 'PROJECT DIR',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
      ],
      tag: 'GRADING_PROJECTS',
    },
  }
  const hiddenColumns = {
    GRADING_PROJECTS: [
      'UID',
      'CREATED_ON',
      'CREATED_BY',
      'MODIFIED_ON',
      'MODIFIED_BY',
    ],
  }

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    let url = `${endpoint}/api/dbt_grading/get_dbt_grading_projects`
    if (ifMyProjects) {
      url = `${endpoint}/api/dbt_grading/get_my_projects`
    } else if (ifMyPods) {
      url = `${endpoint}/api/dbt_grading/get_my_projects_by_pods`
    }

    dispatch(getDbtGradingProjects(url, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    let table_data = dbtGradingProjects?.dbtGradingProjects?.data
    if (table_data?.length > 0) {
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          ['PROJECT_NAME', 'GRADE', 'POD_NAME', 'PROJECT_DIR'],
          columnMetadata,
          table_name
        )
      )
      setTotalPages(dbtGradingProjects?.dbtGradingProjects?.num_pages)
      setTotalRows(dbtGradingProjects?.dbtGradingProjects?.total_results)
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, dbtGradingProjects])

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    // setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }

  // Default Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload = []

        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])
    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {}
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  useEffect(() => {
    fetchData(
      { pageSize, pageIndex },
      JSON.stringify(columnFilters),
      JSON.stringify(columnSort)
    )
  }, [pageSize, pageIndex, columnSort, ifMyPods, ifMyProjects])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Navbar title="DBT Grading" HelpContent={dbtGradingHelpContent} />
          <div style={{ display: 'flex' }}>
            <BootstrapTooltip title="Click to Show my projects.">
              <div style={{ marginLeft: '10px', marginTop: '8px' }}>
                <Box sx={{ minWidth: 120 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={ifMyProjects}
                          onChange={handleCheckMyProjects}
                          sx={{
                            paddingRight: '8px',
                            letterSpacing: '1px',
                          }}
                        />
                      }
                      label="My Projects"
                    />
                  </FormGroup>
                </Box>
              </div>
            </BootstrapTooltip>
            <BootstrapTooltip title="Click to Show my projects, my pod members project.">
              <div style={{ marginLeft: '10px', marginTop: '8px' }}>
                <Box sx={{ minWidth: 120 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={ifMyPods}
                          onChange={handleCheckMyPods}
                          sx={{
                            paddingRight: '8px',
                            letterSpacing: '1px',
                          }}
                        />
                      }
                      label="My Pods"
                    />
                  </FormGroup>
                </Box>
              </div>
            </BootstrapTooltip>
          </div>

          <div className="table-container" style={{ textAlign: 'center' }}>
            <Table {...getTableProps()}>
              <thead className="sticky-table-header">
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) =>
                      column?.id === 'selection' ? (
                        <th key={column.id}>
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                              { style: { width: column.size } }
                            )}
                            className="table-header-badge"
                          >
                            {column.render('Header')}

                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : (
                        <th key={i}>
                          <div
                            onClick={() => handleSorting(column.id)}
                            className="table-header-badge"
                          >
                            {column.render('Header')}

                            {columnSort[0]?.field === column['id'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      )
                    )}
                  </tr>
                ))}
              </thead>
              {tableData?.length > 0 ? (
                <tbody className="tbody mybadgesBody" {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr key={index}>
                        {row.cells.map((cell) => {
                          return cell.column.id === 'PROJECT_NAME' ? (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              <a
                                href="#"
                                className="dbt_links"
                                title="Click to view DBT Grading Details"
                                onClick={(e) =>
                                  viewProjectModal(
                                    e,
                                    cell.row.original.UID,
                                    cell.row.original.PROJECT_NAME
                                  )
                                }
                              >
                                {cell.render('Cell')}
                              </a>
                            </td>
                          ) : cell.column.id === 'PROJECT_DIR' &&
                            cell.value &&
                            cell.value.length > 60 ? (
                            <BootstrapTooltip title={cell.render('Cell')}>
                              <td
                                key={cell}
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.size + '%',
                                    'max-width': '350px',
                                    'white-space': 'nowrap',
                                    overflow: 'hidden',
                                    'text-overflow': 'ellipsis',
                                  },
                                })}
                              >
                                {cell.render('Cell')}
                              </td>
                            </BootstrapTooltip>
                          ) : cell.column.id === 'GRADE' ? (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              <a
                                href="#"
                                className="dbt_links"
                                title="Click to view Average Algorithm Score"
                                onClick={(e) =>
                                  viewProjectAlgorithmAvgScore(
                                    e,
                                    cell.row.original.UID,
                                    cell.row.original.PROJECT_NAME
                                  )
                                }
                              >
                                {cell.render('Cell')}
                              </a>
                            </td>
                          ) : (
                            <td
                              key={cell}
                              {...cell.getCellProps({
                                style: {
                                  width: cell.column.size + '%',
                                },
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={4}>No DBT Grading Projects Available.</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
          {/* Bottom ToolBar of table */}
          <Row>
            <Col sm={12}>
              <div className="page-control">
                <div className="page-of">
                  Page{' '}
                  <em>
                    {pageIndex + 1} of {totalPages}
                  </em>
                </div>
                <div className="prev-next-btn">
                  <button
                    className="badge-btn"
                    onClick={() => onPrevious()}
                    disabled={!canPreviousPage}
                  >
                    {' '}
                    Prev{' '}
                  </button>
                  <button
                    onClick={() => onNext()}
                    className="badge-btn"
                    disabled={!canNextPage}
                  >
                    {' '}
                    Next{' '}
                  </button>
                </div>
                <div className="second-control">
                  <span>Go to page:</span>
                  <input
                    className="page-number-input"
                    type="number"
                    defaultValue={pageIndex || 1}
                    onBlur={(e) => {
                      const page = e.target.value ? Number(e.target.value) : 0
                      // handling zero indexing
                      gotoPage(page - 1)
                    }}
                  />
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value))
                    }}
                  >
                    {available_page_size.map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: '24px',
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  {' '}
                  Total results:{' '}
                  {dbtGradingProjects?.dbtGradingProjects?.total_results}
                </div>
              </div>
            </Col>
          </Row>
          <DBTGradingModelDashboard
            uid={uid}
            showModal={showModal}
            projectName={projectName}
            handleCloseModal={handleCloseModal}
          />
          <GetAvgAlgorithmsScore
            uid={uid}
            showModal={showAvgModal}
            projectName={projectName}
            handleCloseModal={handleCloseAvgModal}
          />
        </>
      )}
    </>
  )
}
