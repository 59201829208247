import Nav from './badgeNavbar'

import React, { useEffect, useMemo, useContext } from 'react'
import '../RefData/RefTable.css'
import jwt_decode from 'jwt-decode'
import LoadingStatus from '../../Helper/LoadingStatus'
import { styled } from '@mui/material/styles'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Button, Col, Row } from 'react-bootstrap'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'
import './index.css'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import '../../../App.css'
import Container from 'react-bootstrap/Container'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../Helper/ToastObjects'
import axios from 'axios'
import '../../../App.css'
import {
  deleteBadge,
  editMyBadge,
  getMyBadges,
} from '../../../redux/actions/badgeAction'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Sidebar from '../AppBar/Appbar'
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))
function MyBadges() {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [authCreds] = useContext(AuthContext)
  const [editRows, setEditRows] = React.useState([])
  //   const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [showModal, setshowModal] = React.useState(false)
  const [editModeState, setEditModeState] = React.useState(false)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [inlineValue, setInlineValue] = React.useState([])
  const [showModalChat, setShowChat] = React.useState(false)
  const [ChatData, setChatData] = React.useState()
  const [chat, setChat] = React.useState('')
  const [badgeName, setBadgeName] = React.useState('')
  const [badgeNames, setBadgeNames] = React.useState()
  const [channel, setChannel] = React.useState(localStorage.getItem('channel'))

  const [badgeValues, setBadgeLevelValues] = React.useState()
  const [badgeId, setBadgeId] = React.useState('')
  const [levelName, setLevelName] = React.useState('')
  const [evidence, setEvidence] = React.useState('')
  const [comment, setComment] = React.useState('')
  const [requirement, setRequirement] = React.useState('')
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  const myBadges = useSelector((state) => state.myBadges)
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  const table_name = 'BADGE'
  const columnMetadata = {
    BADGE: {
      fields: [
        {
          name: 'badge_name',
          headerTitle: 'BADGE NAME',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'badge_level_name',
          headerTitle: 'BADGE LEVEL NAME',
          is_editable: true,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'evidence',
          headerTitle: 'EVIDENCE',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'review_comments',
          headerTitle: 'REVIEW COMMENTS',
          is_editable: true,
          is_filterable: true,
          size: 17,
        },
        {
          name: 'chat',
          headerTitle: 'CHAT',
          is_editable: true,
          is_filterable: true,
          size: 17,
        },
        {
          name: 'status',
          headerTitle: 'STATUS',
          is_editable: true,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'KH_CREATED_BY',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_CREATED_ON',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_ETL_IS_DELETED',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_MODIFIED_BY',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_MODIFIED_ON',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_UID',
          is_editable: false,
          is_filterable: false,
        },
      ],
      tag: 'badge',
    },
  }
  const hiddenColumns = {
    BADGE: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  useEffect(() => {
    localStorage.setItem('menu', 'my-badges')
  }),
    []
  function truncate(str, n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str
  }
  function handleDelete(id) {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(deleteBadge(endpoint, id)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })

      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'role_type', value: `${channel}` }]),
        false,
        JSON.stringify(columnSort)
      )
    })
  }

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    dispatch(getMyBadges(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  useEffect(() => {
    if (myBadges?.loading === false) {
      let table_data = myBadges?.myBadges?.data
      if (table_data?.length > 0) {
        setTableColumns(
          TableHandler.createColumnMappingforKholab(
            [
              'KH_UID',
              'KH_CREATED_ON',
              'KH_CREATED_BY',
              'KH_MODIFIED_ON',
              'KH_MODIFIED_BY',
              'KH_ETL_IS_DELETED',
              'badge_name',
              'badge_level_name',
              'evidence',
              'review_comments',
              'chat',
              'status',
            ],
            columnMetadata,
            table_name
          )
        )
        setTotalPages(myBadges?.myBadges?.num_pages)
        setTotalRows(myBadges?.myBadges?.total_results)
        setTableData(table_data)
      } else {
        setTotalPages(0)
        setTotalRows(0)
        setTableData([])
      }
    }
  }, [myBadges, setTableData])

  // const handleEditableMode = (id, rowValue) => {
  //   setEditModeState(true)
  //   setInlineValue(rowValue)
  //   setRowIndex(id)
  // }
  const handleEdit = () => {
    if (editRows[0].status == 'Approved') {
      toast('Record already Approved', toastTopRightError)
    } else {
      if (editRows.length > 0) {
        setshowModal(true)
        setBadgeName(editRows[0].badge_name)
        setEvidence(editRows[0].evidence)
        setLevelName(editRows[0].badge_level_name)
        setComment(editRows[0].review_comments)
      } else {
        toast('No records selected', toastTopRightError)
      }
    }

    setBadgeLevelValues([])
    setRequirement('')
  }
  const handleCloseModal = () => {
    setshowModal(false)
  }
  const handleChatCloseModal = () => {
    setShowChat(false)
  }
  const handleDeleteEditMode = () => {
    setEditModeState(false)
  }

  //Update using Inline Editing
  const handleUpdateEditMode = () => {
    editRows.push(inlineValue)

    // updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }

  const handleValueEditableMode = (idx) => (e) => {
    setLoadingStatus({ status: false, message: 'Fetching table records...' })

    inlineValue[idx] = e.target.value
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(`${endpoint}/api/badges/get_static_badges?role_type=${channel}`, {
        headers: post_headers,
      })
      .then((res) => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })

        setBadgeNames(res?.data?.unique_badge_name)
      })
  }, [])

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload = [
          { op: 'eq', field: 'role_type', value: `${channel}` },
        ]
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }
  const showChat = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    axios
      .get(
        `${endpoint}/api/badges/get_badge_request_conversations?badge_award_id=${editRows[0].id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        setChatData(res?.data?.data)
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })

        setShowChat(true)
      })
      .catch((error) => {
        return { message: `Error: ${error}` }
      })
  }

  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    if (token != null) {
      //   setLoadingStatus({ status: true, message: 'Fetching table records...' })
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'role_type', value: `${channel}` }]),
        false,
        JSON.stringify(columnSort)
      )
    }
  }, [pageSize, pageIndex, columnSort, token, channel])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])
  const submitChat = async () => {
    if (chat == '') {
      toast('Please add message', toastTopRightError)
    } else {
      setLoadingStatus({
        status: true,
        message: 'Fetching table records...',
      })
      const params = {
        user_email: tokenValue.preferred_username.toLowerCase(),
        badge_award_id: editRows[0].id,
        chat: chat,
      }
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      await axios
        .post(
          `${endpoint}/api/badges/submit_badge_request_conversation`,
          params,

          {
            headers: post_headers,
          }
        )
        .then(() => {
          setShowChat(false)
          fetchData(
            { pageSize, pageIndex },
            JSON.stringify([
              { op: 'eq', field: 'role_type', value: `${channel}` },
            ]),
            false,
            JSON.stringify(columnSort)
          )
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    }
  }
  const handleEditBadge = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const payload = {
      id: editRows[0].id,
      user_email: tokenValue.preferred_username.toLowerCase(),
      badge_id: badgeId == '' ? editRows[0].badge_id : badgeId,
      evidence: evidence,
      badge_name: badgeName,
      review_comments: editRows[0].review_comments,
      badge_level_name: levelName,
      reviewed_by: editRows[0].reviewed_by,
      role_type: channel,
      reviewed_on: editRows[0].reviewed_on,
      created_on: editRows[0].created_on,
      created_by: editRows[0].created_by,
    }
    dispatch(editMyBadge(endpoint, payload))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
        handleCloseModal()
        setRequirement('')
        setBadgeId('')
        fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  const handleRequirement = (value) => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(
        `${endpoint}/api/badges/get_static_badges?filters=[{"op":"eq","field":"badge_name","value":"${badgeName}"},{"op":"eq","field":"badge_level_name","value":"${value}"},{"op":"eq","field":"role_type","value":"${channel}"}]`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        setLevelName(value)
        setBadgeId(res?.data?.data[0]?.id)
        setRequirement(res?.data?.data[0]?.requirements)
      })
  }

  const handleRequirementBadge = (value) => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(
        `${endpoint}/api/badges/get_static_badges?filters=[{"op":"eq","field":"badge_name","value":"${value}"},{"op":"eq","field":"role_type","value":"${channel}"}]`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        setLoadingStatus({ status: false, message: 'Adding Details...' })
        setBadgeName(value)
        setLevelName('')
        setRequirement('')
        setBadgeId('')
        setBadgeLevelValues(res?.data?.data)
      })
  }
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="My Badges"
            channel={channel}
            setChannel={setChannel}
            Index={
              <>
                <Nav channel={channel} setChannel={setChannel} />
                <div
                  className="table-container"
                  style={{ textAlign: 'center' }}
                >
                  <Table {...getTableProps()}>
                    <thead className="sticky-table-header">
                      {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, i) =>
                            column?.id === 'selection' ? (
                              <th key={column.id}>
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                    { style: { width: column.size } }
                                  )}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['Header'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : (
                              <th key={i}>
                                <div
                                  onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['id'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="tbody mybadgesBody"
                      {...getTableBodyProps()}
                    >
                      {page.map((row, index) => {
                        prepareRow(row)
                        return (
                          <tr
                            key={index}
                            // {...row.getRowProps()}
                            // onDoubleClick={() =>
                            //   handleEditableMode(index, row.values)
                            // }
                          >
                            {row.cells.map((cell) => {
                              return cell.column.id === 'review_comments' ? (
                                <BootstrapTooltip title={cell.render('Cell')}>
                                  <td
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    {truncate(cell.value, 100)}
                                  </td>
                                </BootstrapTooltip>
                              ) : cell.column.id === 'evidence' ? (
                                <BootstrapTooltip title={cell.render('Cell')}>
                                  <td
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    {truncate(cell.value, 100)}
                                  </td>
                                </BootstrapTooltip>
                              ) : cell.column.id === 'chat' ? (
                                <BootstrapTooltip title={cell.render('Cell')}>
                                  <td
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    {truncate(cell.value, 100)}
                                  </td>
                                </BootstrapTooltip>
                              ) : (
                                <>
                                  {' '}
                                  <td
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                </>
                              )
                            })}
                            {editModeState && index === rowIndex ? (
                              <>
                                <div className="inlineedit">
                                  <button
                                    className="inlineinternal"
                                    onClick={handleUpdateEditMode}
                                  >
                                    &#10004;
                                  </button>
                                  <button
                                    className="inlineinternal"
                                    onClick={handleDeleteEditMode}
                                  >
                                    &#10060;
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                {/* Bottom ToolBar of table */}
                <Row>
                  <Col sm={6}>
                    <div className="page-control">
                      <div className="page-of">
                        Page{' '}
                        <em>
                          {pageIndex + 1} of {totalPages}
                        </em>
                      </div>
                      <div className="prev-next-btn">
                        <button
                          className="badge-btn"
                          onClick={() => onPrevious()}
                          disabled={!canPreviousPage}
                        >
                          {' '}
                          Prev{' '}
                        </button>
                        <button
                          onClick={() => onNext()}
                          className="badge-btn"
                          disabled={!canNextPage}
                        >
                          {' '}
                          Next{' '}
                        </button>
                      </div>
                      <div className="second-control">
                        <span>Go to page:</span>
                        <input
                          className="page-number-input"
                          type="number"
                          defaultValue={pageIndex || 1}
                          onBlur={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value)
                              : 0
                            // handling zero indexing
                            gotoPage(page - 1)
                          }}
                        />
                        <select
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {available_page_size.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          marginLeft: '50px',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        {' '}
                        Total results: {myBadges?.myBadges?.total_results}
                      </div>
                    </div>
                  </Col>
                  {/* Bottom-right Buttons */}
                  <Col sm={6}>
                    <Button
                      className="m_r-5 block-btn1 main-button btn-sm"
                      onClick={handleEdit}
                    >
                      EDIT
                    </Button>
                    {editRows[0]?.status == 'Under Review' ? (
                      <Button
                        className="m_r-5 block-btn1 main-button btn-sm"
                        onClick={() => handleDelete(editRows[0]?.id)}
                      >
                        DELETE
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Modal size="xl" show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modalBody">
                    <>
                      <Form>
                        <Container className="m-0">
                          <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group
                              className=""
                              as={Col}
                              md="4"
                              controlId="validationFormik01"
                            >
                              <Form className="mt-2">
                                <Form.Label> </Form.Label>
                                <Form.Select
                                  // defaultValue=""
                                  value={badgeName}
                                  onChange={(e) =>
                                    handleRequirementBadge(e.target.value)
                                  }
                                  //   isInvalid={!!errors.vendor}
                                >
                                  <option>Select Badge</option>
                                  {badgeNames?.sort().map((badge) => {
                                    return (
                                      <option
                                        key={badge}
                                        value={badge}
                                        id={badge.id}
                                      >
                                        {badge}
                                      </option>
                                    )
                                  })}
                                </Form.Select>
                              </Form>
                            </Form.Group>
                            <Form.Group
                              className=""
                              as={Col}
                              md="4"
                              controlId="validationFormik01"
                            >
                              <Form className="mt-2">
                                <Form.Label> </Form.Label>
                                <Form.Select
                                  onChange={(e) => {
                                    handleRequirement(e.target.value)
                                  }}
                                  value={levelName}
                                  //   onChange={(e) => setField('vendor', e.target.value, e)}
                                  //   isInvalid={!!errors.vendor}
                                >
                                  <option>Select level</option>

                                  {badgeValues?.length > 0 ? (
                                    badgeValues?.map((bl) => {
                                      return (
                                        <option
                                          key={bl.badge_level_name}
                                          value={bl.badge_level_name}
                                        >
                                          {bl.badge_level_name}
                                        </option>
                                      )
                                    })
                                  ) : (
                                    <>
                                      <option value="Gold"> Gold</option>
                                      <option value="Silver"> Silver</option>
                                      <option value="Bronze"> Bronze</option>
                                    </>
                                  )}
                                </Form.Select>
                              </Form>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group
                              className=""
                              as={Col}
                              // md="5"
                              controlId="validationFormikUsername"
                            >
                              {requirement != '' ? (
                                <>
                                  <Form.Label>Requirements:</Form.Label>
                                  <p style={{ whiteSpace: 'break-spaces' }}>
                                    <b>{requirement}</b>
                                  </p>
                                  <br />
                                </>
                              ) : (
                                <></>
                              )}

                              <Form.Label>Evidence</Form.Label>
                              <InputGroup hasValidation>
                                <Form.Control
                                  type="text"
                                  placeholder="evidence"
                                  aria-describedby="inputGroupPrepend"
                                  name="evidence"
                                  as="textarea"
                                  defaultValue={evidence}
                                  rows={4}
                                  onChange={(e) => setEvidence(e.target.value)}
                                  // isInvalid={!!errors.de_mail}
                                />
                                {/* <Form.Control.Feedback type="invalid">
                    {errors.de_mail}
                  </Form.Control.Feedback> */}
                              </InputGroup>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group
                              // className="chat"
                              as={Col}
                              md="6"
                              controlId="validationFormikUsername"
                            >
                              <Form.Label>Comment</Form.Label>
                              {/* <InputGroup>
                          <Form.Control
                            disabled
                            type="text"
                            placeholder="hey i want to add"
                            aria-describedby="inputGroupPrepend"
                            name="mail"
                            defaultValue={comment}
                            // onChange={(e) => setField('de_mail', e.target.value, e)}
                            // isInvalid={!!errors.de_mail}
                          /> */}
                              {/* <Form.Control.Feedback type="invalid">
                    {errors.de_mail}
                  </Form.Control.Feedback> */}
                              {/* </InputGroup> */}
                              <Button
                                style={{ marginTop: '5px', display: 'flex' }}
                                onClick={showChat}
                              >
                                Open Chat
                              </Button>
                            </Form.Group>
                          </Row>
                        </Container>
                      </Form>
                      <Modal.Footer>
                        {editRows[0]?.status == 'Rejected' ? (
                          <Button
                            variant="primary"
                            className="addModalBtn"
                            type="submit"
                            onClick={handleEditBadge}
                          >
                            Re-Submit
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            className="addModalBtn"
                            type="submit"
                            onClick={handleEditBadge}
                          >
                            Submit
                          </Button>
                        )}

                        <Button
                          variant="danger"
                          type="submit"
                          className="addModalBtn"
                          onClick={handleCloseModal}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </>
                  </Modal.Body>
                </Modal>
                {/* {selectionModel.map(val =><h1 key={val}>{val}</h1>)} */}
                <Modal show={showModalChat} onHide={handleChatCloseModal}>
                  <Modal.Header closeButton>Chat</Modal.Header>
                  <Modal.Body
                    style={{ background: '#eaf4fd' }}
                    className="modalBody"
                  >
                    <>
                      <Form>
                        <Row>
                          <Form.Group
                            className=""
                            as={Col}
                            controlId="validationFormikUsername"
                          >
                            <div
                              style={{
                                fontSize: '17px',
                                listStyle: 'none',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {ChatData?.map((result) => {
                                return (
                                  <div key={result} className="main-chat">
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflowWrap: 'anywhere',
                                      }}
                                    >
                                      <p className="sent-by">
                                        sent by {result.user_name}
                                      </p>
                                      <li className="chat-msg">
                                        {' '}
                                        <p style={{ margin: '8px' }}>
                                          {' '}
                                          {result.chat}{' '}
                                        </p>
                                      </li>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Reply to chat"
                                name="name"
                                style={{ margin: '5px' }}
                                onChange={(e) => {
                                  setChat(e.target.value)
                                }}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Row>
                      </Form>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          className="addModalBtn"
                          type="submit"
                          style={{ borderTop: 'none !important' }}
                          onClick={submitChat}
                        >
                          Send
                        </Button>
                      </Modal.Footer>
                    </>
                  </Modal.Body>
                </Modal>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default MyBadges
