import { React, useState, useContext, useEffect } from 'react'
// import Input from '../NPS/input'
import './indexChapter.css'
import Sidebar from '../AppBar/Appbar'
// import Navbar from '../../Navbar'
// import { toast } from 'react-toastify'
import NpsMenu from './chapterNpsMenu'
// import { Form } from 'react-bootstrap'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
// import LoadingStatus from '../../Helper/LoadingStatus'
import { AuthContext } from '../../context/authProvider'
// import { createSurvey } from '../../../redux/actions/npsActions/npsSurveyAction'
// import SelectAsyncPaginate from '../Utilities/selectPaginate'
// import HelpContent from '../NPS/helpContent'
import { useNavigate } from 'react-router-dom'
// import ReactQuill from 'react-quill'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import CardsView from '../Utilities/cardView'
import {
  getSurveyTemplateList,
  deleteTemplate,
} from '../../../redux/actions/npsActions/npsChapterAction'
import LoadingStatus from '../../Helper/LoadingStatus'
import ReactPaginate from 'react-paginate'
import { Button, Modal } from 'react-bootstrap'
// import ChapterSurvey from './createChapterSurvey'
function CreateForm() {
  const [authCreds] = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(0)
  // const [sort, setSort] = useState('asc')
  const [totalPages, setTotalPages] = useState(0)

  const [collabMetadata, setCollabMetadata] = useState('')
  const [deleteTemplateId, setDeleteTemplateId] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const getTemplateList = useSelector((state) => state.getTemplateList)
  const [loadingStatus, setLoadingStatus] = useState({
    status: true,
    message: 'Fetching table records...',
  })
  function truncate(str, n) {
    return str?.length > n ? str?.slice(0, n - 1) + '...' : str
  }

  useEffect(() => {
    if (getTemplateList?.loading == false) {
      setTotalPages(
        Math.ceil(getTemplateList?.getTemplateList?.total_results / 10)
      )
    }
  }, [])

  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=COLLAB`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])
  const handlePageChange = (number) => {
    setCurrentPage(number.selected)
  }
  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  // get endpoint from authCreds
  const endpoint = authCreds.restEndpoint

  // useDispatch
  const dispatch = useDispatch()

  let history = useNavigate()

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      page_size: 10,
      page_number: currentPage + 1,
      filters: JSON.stringify([
        { op: 'ilike', field: 'template_channel', value: `%${channel}%` },
      ]),
    }
    dispatch(getSurveyTemplateList(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }, [currentPage, channel])

  const handleCard = () => {
    history('/nps-survey/create-template')
  }
  const handleCardTemp = (id) => {
    history(`/nps-survey/create-template?template=${id}`)
  }

  const handleDeleteTemplate = (e, id) => {
    e.stopPropagation()
    setModalShow(true)
    setDeleteTemplateId(id)
  }
  const handleCloseModal = () => {
    setModalShow(false)
  }
  const deleteTemplatefunc = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      id: deleteTemplateId,
    }
    dispatch(deleteTemplate(endpoint, params)).then(() => {
      setModalShow(false)
      setDeleteTemplateId('')
      dispatch(
        getSurveyTemplateList(endpoint, {
          page_size: 10,
          page_number: currentPage + 1,
        })
      ).then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
    })
  }
  return (
    <>
      {/* {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : ( */}
      <div>
        <Sidebar
          Page="NPS"
          setChannel={setChannel}
          channel={channel}
          collabMetadata={collabMetadata}
          Index={
            <>
              {loadingStatus.status ? (
                <LoadingStatus status_message={loadingStatus.message} />
              ) : (
                <div className="nps">
                  {/* <Navbar title="NPS" HelpContent={HelpContent} /> */}

                  <NpsMenu />
                  <div className="card-section-nps">
                    {getTemplateList?.getTemplateList?.data?.map((result) => {
                      return (
                        <CardsView
                          key="test"
                          title_content={
                            <>
                              {' '}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span className="survey_title">
                                    {result?.template_name}
                                  </span>
                                  {result?.is_base_template == true ? (
                                    <span
                                      className="survey_description"
                                      style={{ color: 'blue' }}
                                    >
                                      (Base Template)
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  <span className="survey_description">
                                    {truncate(
                                      result?.template_description,
                                      100
                                    )}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: 'fit-content',
                                    zIndex: '99999',
                                  }}
                                  onClick={(e) =>
                                    handleDeleteTemplate(e, result?.id)
                                  }
                                >
                                  {result?.is_base_template == false ? (
                                    <DeleteIcon style={{ color: 'red' }} />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </>
                          }
                          border_status_class={'border-primary card-height'}
                          action_content={<></>}
                          handleClick={() =>
                            handleCardTemp(result?.template_name)
                          }
                        />
                      )
                    })}

                    <CardsView
                      key="test1"
                      handleClick={() => handleCard()}
                      title_content={
                        <>
                          {' '}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                              }}
                            >
                              <span className="survey_title">+</span>
                              <span className="survey_description">
                                Click to create new template
                              </span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                height: 'fit-content',
                              }}
                            >
                              {/* <span className="date-label">STATUS</span>{' '} */}
                              {/* <span className={`survey_status`}>{statusSort}</span> */}
                            </div>
                          </div>
                        </>
                      }
                      border_status_class={'border-primary card-height'}
                      action_content={
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexGrow: '1',
                              justifyContent: 'flex-start',
                              color: '#0e0eed',
                            }}
                          >
                            {/* <span className="date-label">START DATE:</span> */}
                            {/* <span className="date-label-count">
                          {
                            new Date(result?.start_date)
                              .toISOString()
                              .split('T')[0]
                          }
                        </span> */}
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexGrow: '1',
                              justifyContent: 'flex-end',
                              color: '#d30000',
                            }}
                          >
                            {/* <span className="date-label">END DATE:</span> */}
                            {/* <span className="date-label-count">
                          {
                            new Date(result?.end_date)
                              .toISOString()
                              .split('T')[0]
                          }
                        </span> */}
                          </div>
                        </>
                      }
                    />
                  </div>
                  <ReactPaginate
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    forcePage={currentPage}
                    breakLabel={'...'}
                    previousLabel={'<<'}
                    nextLabel={'>>'}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </div>
              )}
            </>
          }
        />
      </div>
      <Modal show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Template</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'hidden' }}>
          Are you sure to delete template ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteTemplatefunc}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default CreateForm
