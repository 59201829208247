import {
  GET_AVG_ALGORITHMS_SCORE_ERROR,
  GET_AVG_ALGORITHMS_SCORE,
  GET_AVG_ALGORITHMS_SCORE_LOAD,
} from '../../constants/types'

const initialState = {
  avgAlgorithmsScore: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AVG_ALGORITHMS_SCORE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_AVG_ALGORITHMS_SCORE:
      return {
        ...state,
        avgAlgorithmsScore: action.payload,
        loading: false,
        error: null,
      }
    case GET_AVG_ALGORITHMS_SCORE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
