export const columnsDescription = {
  ACTUAL_ARRIVAL_DATE_DESC: 'Actual Arrival date as per PO',
  ACCOUNTING_PERIOD_DESC:
    'Accounting period for POs related to Target based on the PO date',
  CFR_DESC: 'Case fill rate %',
  CALCULATED_FINE_AMOUNT_DESC: '',
  CUSTOMER_PO_NO_DESC: 'Customer PO number',
  CFR_MISSED_DESC: 'Whether CFR is missed',
  CFR_FINE_AMOUNT_DESC: 'Fine Amount due to CFR Missed',
  CPU_DESC: 'Customer Pick up if requested yes else No',
  CASES_ORDERED_DESC: 'Number of cases ordered',
  DC_NO_KH_DESC:
    'Dc number KH side concatenated with KH location (KH DC no.-Shipped from Location)',
  DC_NO_RETAILER_DESC:
    'Dc number Retailer side concatenated with Retailer location (Retailer DC no.-Shipped To Location)',
  ES3_OR_NON_ES3_DESC:
    'whether PO is related with ES3 or non-ES3 transportation',
  ESTIMATED_ARRIVAL_DATE_DESC: 'Estimated Time of Arrival Date',
  EVIDENCE_REQUESTED_DESC:
    'Contains "Yes" if POD evidence Requested, else "No", this usually occurs in case of discrepencies',
  EVIDENCE_TYPE_DESC: 'Type of Evidence Required for the validation of fines',
  FINE_TYPE_DESC: '',
  FINE_AMOUNT_DERIVED_DESC: '',
  INVOICE_VALUE_DESC: 'Order Value that is present in the invoice',
  INVOICE_NUMBER_DESC: 'Invoice Number',
  IF_DISCREPENCIES_DESC: '',
  LIABLE_FINE_AMOUNT_DESC: 'Fine amount',
  NO_OF_UNITS_SHIPPED_DESC: 'Total number of units shipped as per each PO',
  NO_OF_UNITS_ORDERED_DESC: 'Number of units ordered',
  ORAD_MISSED_DESC: 'Whether ORAD is missed',
  ORAD_DESC: 'Original Requested Arrival Date',
  ORAD_FINE_AMOUNT_DESC: 'Fine Amount due to ORAD missed',
  PRIORITY_DESC: 'Priority in handling disputes related to fines',
  PO_DATE_DESC: 'Purchase order date',
  PO_TYPE_DESC: 'Purchase order type',
  SO_TYPE_DESC: 'Sales Order type',

  POSSIBLE_DISPUTE_DESC:
    'If the fine status is "pending" then the fine amount will be set to the potential dispute amount. Otherwise, it will be set to null',
  RETAILER_PO_DESC: 'PO number of retailer',
  REPORTED_ARRIVAL_DATE_DESC:
    'Reported arrival date from customer portal per PO',
  REPORTED_CFR_DESC: 'Reported CFR percentage  from customer portal as per PO',
  RETAILER_STATE_DESC: 'State(Retailer) related with each order',
  REPORTED_FINE_TYPE_DESC: 'Reported fine type  from customer per PO level',
  REASON_FOR_REJECTION_DESC: '',
  RETAILER_NAME_DESC: 'Retailer name',
  RETAILER_COUNTRY_DESC: 'Country(Retailer) related with each order',
  REPORTED_FINE_AMOUNT_DESC:
    'Reported fine amount from customer portal per PO level',
  SO_NO_DESC: 'Standard order number',
  SHIPPING_DATE_DESC: 'Date of shipment of order',
  SHIPPING_STATUS_DESC: 'Shipping status',
  SKU_CUTS_DESC: '',
  STATUS_DESC:
    'The current status of the fines validation process can be either "Completed" or "POD requested" (which will change to "Completed" once the POD is received). This status is determined through the SAP notes, the Kroger Customer-driven fines tracker (in case of kroger ORAD fine only)',
  SHIP_MODE_DETAILS_DESC: '',
  TRANSPORT_REFERENCE_DESC: 'Transport reference number',
  TRANSPORT_METHOD_DESC: 'Transport method of order ',
  TOTAL_VOLUME_DESC: 'Total volume of order (ft3)',
  TOTAL_WEIGHT_DESC: 'Total weight of order (lbs)',
  UNITS_SHIPPED_OUTSIDE_SHIPPING_WINDOW_DESC:
    'Number of Units Shipped Outside Shipping Window',
  VALUE_OF_SHIPPED_ITEMS_DESC: 'Value of units shipped as per each PO',
  VALID_INVALID_DESC:
    'When the status is marked as "Completed", it will be considered either "Valid" or "Invalid" depending on the evidence provided. However, if the status is "POD requested", it will be marked as "Pending".',
  VALUE_OF_ORDER_PLACED_DESC: 'Value of the order placed',
  ITEM_ID: 'kh_unique_id',
}

export default columnsDescription
