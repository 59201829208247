import React from 'react'

const Pagination = ({
  pageSize,
  totalResults,
  numPages,
  currentPage,
  pageChange,
  containerClass,
  buttonClass,
  buttonTextClass,
}) => {
  let numTermsShowed
  if (numPages === currentPage) {
    numTermsShowed = totalResults
  } else {
    numTermsShowed = pageSize * currentPage
  }

  return (
    <div className={containerClass}>
      <br />
      <button
        className={buttonClass}
        onClick={() => pageChange(currentPage - 1)}
        disabled={currentPage === 1 || totalResults === 0}
      >
        Previous
      </button>
      <button
        className={buttonClass}
        onClick={() => pageChange(currentPage + 1)}
        disabled={currentPage === numPages || totalResults === 0}
      >
        Next
      </button>
      <p className={buttonTextClass}>
        Showing: {numTermsShowed} of {totalResults} Terms
      </p>
    </div>
  )
}

export default Pagination
