import React, { useState, useEffect } from 'react'
import Stepsbar from './components/Stepsbar/Stepsbar.js'
import './Steps.scss'
import Step05 from './components/Step05/Step05.js'
import Step1 from './components/Step1/Step1.js'
import Step0 from './components/Step0/Step0.js'
import { useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

function Steps() {
  const { state } = useLocation()
  const [step, setStep] = useState('0')
  const tokenValue = jwt_decode(localStorage.getItem('token'))
  const requiredRole = 'khdapi.KHC_FeesFines_Group_Read'
  // const requiredGroup = 'KHC_FeesFines_ReadGroup'
  const requiredRole2 = 'khdapi.Admin'
  const isAllowedToAccess =
    tokenValue?.['roles']?.indexOf(requiredRole) >= 0 ||
    tokenValue?.['roles']?.indexOf(requiredRole2) >= 0

  useEffect(() => {
    if (state) {
      setStep(`${state}`)
    }
  }, [state])

  return (
    <>
      {isAllowedToAccess ? (
        <div className="fnf steps" data-testid="steps">
          <Stepsbar step={step} />
          {step === '0.5' ? <Step05 /> : step === '1' ? <Step1 /> : <Step0 />}
        </div>
      ) : (
        <div className="fnf noaccess">
          {/* <h4>
            You are not authorized to access Fees & Fines,<br></br> You need to
            be a member of <span>{requiredGroup}</span> AD Group to access Fees
            & Fines
          </h4> */}
        </div>
      )}
    </>
  )
}

export default Steps
