import React from 'react'
import logo from '../../../../media/comingsoon.gif'
import Sidebar from './StackOverflow/Sidebar'
export default function () {
  return (
    <div className="stack-index">
      <div className="stack-index-content">
        <Sidebar />
        <div style={{ display: 'flex', background: '#fff' }}>
          <img src={logo} alt="loading..." style={{ width: '70%' }} />
        </div>
      </div>
    </div>
  )
}
