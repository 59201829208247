import {
  GET_DBT_GRADING_SCORES_ERROR,
  GET_DBT_GRADING_SCORES,
  GET_DBT_GRADING_SCORES_LOAD,
} from '../../constants/types'

const initialState = {
  dbtGradingScores: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DBT_GRADING_SCORES_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_DBT_GRADING_SCORES:
      return {
        ...state,
        dbtGradingScores: action.payload,
        loading: false,
        error: null,
      }
    case GET_DBT_GRADING_SCORES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
