import React from 'react'
import { Link } from 'react-router-dom'

function formatTagData(tagData) {
  return tagData
    .slice(0, 3)
    .map((item) => item.tag_name)
    .join(', ')
}

export default function CardList({
  cardData,
  link,
  containerClass,
  cardClass,
}) {
  return (
    <div className={containerClass}>
      {cardData?.map((data) => (
        <Link key={data.id} to={`${link}${data.id}`}>
          <div className={cardClass}>
            <h2 style={{ color: '#1D3C6D' }}>{data.term}</h2>
            <p>
              Views: {data.view_count} <br />
              Tags: {formatTagData(data.tags)}
            </p>
          </div>
        </Link>
      ))}
    </div>
  )
}
