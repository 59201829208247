import React, { useContext, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactQuill from 'react-quill'
import { ForumHandler } from '../../Utils'
import { AuthContext } from '../../context/authProvider'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { toast } from 'react-toastify'
import jwt_decode from 'jwt-decode'
import { editPodEntry } from '../../../redux/actions/podsPageActions/podsAction'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  getPodFunctionalArea,
  getPodMembers,
} from '../../../redux/actions/podsPageActions/podsAction'
import MentionHandler from '../Utilities/quillSearch'

const EditPods = ({
  setShowAddPodMember,
  fetchData,
  pageSize,
  pageIndex,
  editorContentBuilder,
  setEditorContentBuilder,
  editorContentStakeholder,
  setEditorContentStakeholder,
  editorContentProductOwner,
  setEditorContentProductOwner,
  podName,
  setPodName,
  functionalArea,
  setFunctionalArea,
  description,
  setDescripiton,
  editRows,
  ifInsidePod,
}) => {
  const [authCreds] = useContext(AuthContext)
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  const dispatch = useDispatch()
  const podFunctionalAreas = useSelector((state) => state.podFunctionalAreas)
  // const podMembers = useSelector((state) => state.podMembers)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  let userFetchTimeOut
  const endpoint = authCreds.restEndpoint

  const handleClose = () => setShowAddPodMember(false)

  const handleQuillStakeholder = (value) => {
    setEditorContentStakeholder(value)
  }
  const handleQuillProductOwner = (value) => {
    setEditorContentProductOwner(value)
  }
  const handleQuillBuilder = (value) => {
    setEditorContentBuilder(value)
  }

  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getPodFunctionalArea(endpoint))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }, [])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getPodMembers(endpoint, editRows[0].id))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }, [])

  const handleEditPod = async () => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

    const builderMatches = editorContentBuilder.match(regex)
    let filter_payload = []

    // set the filter depending on if its pods view or pending requests page
    if (ifInsidePod) {
      filter_payload = JSON.stringify([
        { op: 'eq', field: 'status', value: `Approved` },
      ])
    } else {
      filter_payload = JSON.stringify([
        { op: 'eq', field: 'status', value: `Pending` },
        { op: 'eq', field: 'status', value: `Rejected` },
      ])
    }

    let builderValues = builderMatches
      ? builderMatches.map((mention) => mention.slice(0))
      : []

    if (builderValues.length !== 0 && podName !== '' && description !== '') {
      let body = {}
      //edit taking place in pending Requests
      if (editRows[0]['pod_id'] !== undefined) {
        body = {
          id: editRows[0].id,
          pod_id: editRows[0].pod_id,
          pod_name: podName,
          description: description,
          functional_area: functionalArea,
          is_active: editRows[0]?.is_active,
          status: 'Pending',
          builders_html: editorContentBuilder,
          stakeholders_html: editorContentStakeholder,
          product_owners_html: editorContentProductOwner,
          approved_on: editRows[0].approved_on,
          approved_by: editRows[0].approved_by,
          created_on: editRows[0].created_on,
          created_by: editRows[0].created_by,
          modified_on: editRows[0].modified_on,
          modified_by: editRows[0].modified_by,
          user_email: tokenValue?.preferred_username?.toLowerCase(),
          is_pods: false,
          comments: editRows[0]?.comments,
        }
      } else {
        // Edit taking place in pods
        body = {
          id: editRows[0].id,
          pod_name: podName,
          description: description,
          functional_area: functionalArea,
          is_active: editRows[0]?.is_active,
          status: 'Pending',
          builders_html: editorContentBuilder,
          stakeholders_html: editorContentStakeholder,
          product_owners_html: editorContentProductOwner,
          approved_on: editRows[0].approved_on,
          approved_by: editRows[0].approved_by,
          created_on: editRows[0].created_on,
          created_by: editRows[0].created_by,
          modified_on: editRows[0].modified_on,
          modified_by: editRows[0].modified_by,
          user_email: tokenValue?.preferred_username?.toLowerCase(),
          is_pods: true,
          comments: editRows[0]?.comments,
        }
      }

      if (
        podName !== editRows[0]?.pod_name ||
        functionalArea !== editRows[0]?.functional_area ||
        description !== editRows[0]?.description ||
        editorContentBuilder !== editRows[0]?.builders_html ||
        editorContentProductOwner !== editRows[0]?.product_owners_html ||
        editorContentStakeholder !== editRows[0]?.stakeholders_html
      ) {
        dispatch(editPodEntry(endpoint, body)).then(() => {
          setLoadingStatus({ status: false, message: 'Fetching data...' })
          handleClose()
          fetchData({ pageSize, pageIndex }, filter_payload)
        })
      } else {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        toast('No changes made in edit!', toastTopRightError)
      }
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Please fill all the required fields', toastTopRightError)
    }
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Form>
            <Container className="m-0">
              <Row className="mb-3 mr-3 rowFlex">
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>
                    Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter pod name"
                    autoFocus
                    defaultValue={editRows[0]?.pod_name}
                    onChange={(e) => {
                      setPodName(e.target.value)
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="flexItem col-1"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>Functional area</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={functionalArea}
                    onChange={(e) => {
                      setFunctionalArea(e.target.value)
                    }}
                  >
                    <option key="placeholder" value="">
                      Select functional area
                    </option>
                    {podFunctionalAreas?.podFunctionalAreas?.data?.functional_areas?.map(
                      (functionalarea) => (
                        <option key={Math.random()} value={functionalarea}>
                          {functionalarea}
                        </option>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3 mr-3 rowFlex">
                <Form.Group
                  className="flexItem"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>
                    Description<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    defaultValue={editRows[0].description}
                    onChange={(e) => {
                      setDescripiton(e.target.value)
                    }}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-5 mr-3 mt-2 rowFlex h-25">
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>
                    Builders<span className="text-danger">*</span>
                  </Form.Label>
                  <ReactQuill
                    style={{ background: '#fff' }}
                    theme="snow"
                    className="react-quill-badge"
                    defaultValue={editorContentBuilder}
                    onChange={handleQuillBuilder}
                    modules={module}
                    placeholder="select members using @ operator"
                  />
                </Form.Group>
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>Product owners</Form.Label>
                  <ReactQuill
                    style={{ background: '#fff' }}
                    theme="snow"
                    className="react-quill-badge"
                    defaultValue={editorContentProductOwner}
                    onChange={handleQuillProductOwner}
                    modules={module}
                    placeholder="select members using @ operator"
                  />
                </Form.Group>
                <Form.Group
                  className="flexItem col-4"
                  as={Col}
                  md="4"
                  controlId="validationFormikUsername"
                >
                  <Form.Label>Stakeholders</Form.Label>
                  <ReactQuill
                    style={{ background: '#fff' }}
                    theme="snow"
                    className="react-quill-badge"
                    onChange={handleQuillStakeholder}
                    defaultValue={editorContentStakeholder}
                    modules={module}
                    placeholder="select members using @ operator"
                  />
                </Form.Group>
              </Row>
            </Container>
          </Form>
          <Modal.Footer>
            <Button
              variant="primary"
              className="addModalBtn"
              type="submit"
              onClick={handleEditPod}
            >
              Submit
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="addModalBtn"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  )
}

export default EditPods
