import { useState, useEffect, useContext } from 'react'
import Input from '../input'
import '../indexChapter.css'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import { getSurveyResponseJSON } from '../../../../redux/actions/npsActions/npsSurveyAction'
import LoadingStatus from '../../../Helper/LoadingStatus'

function UserResponseForm({ cosmosId }) {
  const [formValues, setFormValues] = useState([])

  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [authCreds] = useContext(AuthContext)

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const getSurveyResponsejsonData = useSelector(
    (state) => state.getSurveyResponsejsonData
  )

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    if (getSurveyResponsejsonData?.loading == false) {
      setFormValues(
        getSurveyResponsejsonData?.getSurveyResponsejsonData?.data
          ?.survey_response_json
      )
      //   setTitle(getSurveyResponsejsonData?.getSurveyResponsejsonData?.data?.form_name)

      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    }
  }, [getSurveyResponsejsonData])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      cosmos_response_id: cosmosId,
    }
    dispatch(getSurveyResponseJSON(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }, [])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus message={loadingStatus.message} />
      ) : (
        <div className="nps">
          <form className="nps-form-main">
            <input
              type="text"
              placeholder="Add Title"
              className="titleInput"
              defaultValue={
                getSurveyResponsejsonData?.getSurveyResponsejsonData?.data
                  ?.form_name
              }
              disabled="true"
            />
            {formValues?.map((obj, index) => (
              <Input key={index} objValue={obj} index={index} disabled="true" />
            ))}
          </form>
        </div>
      )}
    </>
  )
}
export default UserResponseForm
