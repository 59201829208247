import {
  NEW_INGESTION_INTAKE_ERROR,
  NEW_INGESTION_INTAKE_LOAD,
  NEW_INGESTION_INTAKE_REQUEST,
  GET_INGESTION_INTAKE_DATA_SOURCE_TYPES,
  GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_LOAD,
  GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_ERROR,
  GET_INGESTION_INTAKE_DATA_CLASSIFICATION_ERROR,
  GET_INGESTION_INTAKE_DATA_CLASSIFICATION_LOAD,
  GET_INGESTION_INTAKE_DATA_CLASSIFICATION,
  GET_INGESTION_INTAKE_REQUESTS_LOAD,
  GET_INGESTION_INTAKE_REQUESTS,
  GET_INGESTION_INTAKE_REQUESTS_ERROR,
} from '../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const createIntakeRequest =
  (endpoint, payload, redirect = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: NEW_INGESTION_INTAKE_LOAD,
      })
      if (redirect !== true) {
        let post_headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          khuser: `${localStorage.getItem('user')}`,
        }
        await axios
          .post(
            `${endpoint}/api/ingestion_intake/submit_ingestion_intake_request`,
            payload,
            {
              headers: post_headers,
            }
          )
          .then((res) => {
            dispatch({
              type: NEW_INGESTION_INTAKE_REQUEST,
              payload: res.data,
            })
            toast('Ingestion Intake Request Submitted', toastTopRightSuccess)
          })
      } else {
        dispatch({
          type: NEW_INGESTION_INTAKE_REQUEST,
          payload: [],
        })
      }
    } catch (e) {
      dispatch({
        type: NEW_INGESTION_INTAKE_ERROR,
        payload: e.message,
      })
      toast(e?.response?.data?.message, toastTopRightError)
    }
  }

export const getIngestionJobTypes = (endpoint) => async (dispatch) => {
  let post_headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    khuser: `${localStorage.getItem('user')}`,
  }
  dispatch({
    type: GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_LOAD,
  })
  axios
    .get(`${endpoint}/api/ingestion_intake/get_ingestion_job_types`, {
      headers: post_headers,
    })
    .then((res) => {
      dispatch({
        type: GET_INGESTION_INTAKE_DATA_SOURCE_TYPES,
        payload: res.data,
      })
    })
    .catch((e) => {
      dispatch({
        type: GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_ERROR,
      })
      toast(e.message, toastTopRightError)
    })
}

export const getIngestionDataClassifications =
  (endpoint) => async (dispatch) => {
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    dispatch({
      type: GET_INGESTION_INTAKE_DATA_CLASSIFICATION_LOAD,
    })
    axios
      .get(`${endpoint}/api/ingestion_intake/get_data_classifications`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_INGESTION_INTAKE_DATA_CLASSIFICATION,
          payload: res.data,
        })
      })
      .catch((e) => {
        dispatch({
          type: GET_INGESTION_INTAKE_DATA_CLASSIFICATION_ERROR,
          payload: e.message,
        })
        toast(e.message, toastTopRightError)
      })
  }

export const getIngestionRequests = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_INGESTION_INTAKE_REQUESTS_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(`${endpoint}/api/ingestion_intake/get_ingestion_intake_request`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_INGESTION_INTAKE_REQUESTS,
          payload: res.data,
        })
      })
  } catch (e) {
    toast(e.response.data.message, toastTopRightError)
    dispatch({
      type: GET_INGESTION_INTAKE_REQUESTS_ERROR,
      payload: e.response.data.message,
    })
  }
}
