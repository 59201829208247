import { toast } from 'react-toastify'

export const toastTopRightError = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  type: toast.TYPE.ERROR,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
}

export const toastTopRightSuccess = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  type: toast.TYPE.SUCCESS,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
}

export const toastTopRightSuccessANZ = {
  position: 'top-right',
  autoClose: 20000,
  hideProgressBar: false,
  type: toast.TYPE.SUCCESS,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
}

export const toastTopRightErrorANZ = {
  position: 'top-right',
  autoClose: 20000,
  hideProgressBar: false,
  type: toast.TYPE.ERROR,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
}
