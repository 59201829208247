class DateHandler {
  static dateTimetoDateConverter = (value) => {
    const date = new Date(value)
    const futureDate = date.getDate()
    date.setDate(futureDate)
    const defaultValue = date.toLocaleDateString('en-CA')
    return defaultValue
  }
  static getCurrentDate = () => {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()

    return (today = yyyy + '-' + mm + '-' + dd)
  }
}

export { DateHandler }
