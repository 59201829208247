import JiraTracker from './addJiraTracker'
import EditJiraTracker from './editJiraTracker'
import Navbar from '../../Navbar'
import HelpContent from './jiraTrackerHelpContent'
import React, { useEffect, useMemo, useContext } from 'react'
import '../RefData/RefTable.css'
import { MdDangerous } from 'react-icons/md'

import {
  getJiraEntries,
  getModalMetadata,
  getCurrentSprint,
} from '../../../redux/actions/capacityTrackerAction'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Modal, Button, Col, Row } from 'react-bootstrap'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
// import LoadingStatus from '../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../Helper/ToastObjects'
// import './RefTable.css'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'

export default function JiraDashboard() {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [showJira, setShowJira] = React.useState(false)
  const [EditshowJira, setEditShowJira] = React.useState(false)
  const [authCreds] = useContext(AuthContext)
  const [editRows, setEditRows] = React.useState([])
  const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)

  const [editModeState, setEditModeState] = React.useState(false)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [inlineValue, setInlineValue] = React.useState([])
  const [open, setOpen] = React.useState(false)

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  const table_name = 'DE_CAPACITY_TRACKER'
  const columnMetadata = {
    DE_CAPACITY_TRACKER: {
      fields: [
        {
          name: 'BOARD',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'SPRINT_NAME',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'SPRINT_ALIAS',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'POD',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'DE_NAME',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'DE_EMAIL',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'VENDOR',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'SPRINT_WORKING_DAYS',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'SPRINT_NON_WORKING_DAYS',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'SPRINT_TOTAL_DAYS',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'COMMENTS',
          is_editable: true,
          is_filterable: true,
        },
        {
          name: 'KH_CREATED_BY',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_CREATED_ON',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_ETL_IS_DELETED',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_MODIFIED_BY',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_MODIFIED_ON',
          is_editable: false,
          is_filterable: false,
        },
        {
          name: 'KH_UID',
          is_editable: false,
          is_filterable: false,
        },
      ],
      tag: 'capacitytracker',
    },
  }
  const hiddenColumns = {
    DE_CAPACITY_TRACKER: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const modalMetaData = useSelector((state) => state.modalMetaData)
  const jiraAddMetadata = useSelector((state) => state.jiraAddMetadata)
  const currentSprint = useSelector((state) => state.currentSprint)

  // const handleEditableMode = (id, rowValue) => {
  //   setEditModeState(true)
  //   setInlineValue(rowValue)
  //   setRowIndex(id)
  // }

  const handleDeleteEditMode = () => {
    setEditModeState(false)
  }

  //Update using Inline Editing
  const handleUpdateEditMode = () => {
    editRows.push(inlineValue)

    // updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }

  const handleValueEditableMode = (idx) => (e) => {
    inlineValue[idx] = e.target.value
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="radio" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload = []
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              let local_obj = { op: 'ilike' }
              local_obj['field'] = header_obj.Header
              local_obj['value'] = '%' + value + '%'
              filter_payload.push(local_obj)
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  // given a column value, it updates hidden column list
  // const hiddenColumnListHandler = (value) => {
  //   if (value == 'Select All') {
  //     if (hiddenColumns.includes('SELECTALL')) {
  //       setRefData((prev_value) => {
  //         let obj = { ...prev_value.hiddenColumns }
  //         obj[table_name] = TableHandler.getAllColumns(
  //           columnMetadata,
  //           table_name
  //         )
  //         return {
  //           ...prev_value,
  //           hiddenColumns: obj,
  //         }
  //       })
  //     } else {
  //       setRefData((prev_value) => {
  //         let obj = { ...prev_value.hiddenColumns }
  //         obj[table_name] = ['SELECTALL']
  //         return {
  //           ...prev_value,
  //           hiddenColumns: obj,
  //         }
  //       })
  //     }
  //     setTableColumns(
  //       TableHandler.createColumnMapping(
  //         Object.keys(tableData[0]),
  //         columnMetadata,
  //         table_name
  //       )
  //     )
  //   } else {
  //     let item_index = -1
  //     let new_list = []
  //     hiddenColumns.forEach((item, index) => {
  //       if (item === value) item_index = index
  //       else new_list.push(item)
  //     })

  //     if (item_index < 0) {
  //       setRefData((prev_value) => {
  //         let obj = { ...prev_value.hiddenColumns }
  //         obj[table_name] = [...prev_value.hiddenColumns[table_name], value]
  //         return {
  //           ...prev_value,
  //           hiddenColumns: obj,
  //         }
  //       })
  //     } else {
  //       setRefData((prev_value) => {
  //         let obj = { ...prev_value.hiddenColumns }
  //         obj[table_name] = new_list
  //         return {
  //           ...prev_value,
  //           hiddenColumns: obj,
  //         }
  //       })
  //     }

  //     setTableColumns(
  //       TableHandler.createColumnMapping(
  //         Object.keys(tableData[0]),
  //         columnMetadata,
  //         table_name
  //       )
  //     )
  //   }
  // }

  // Used to fetch new table data from server
  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setEnabledColsMap(
      TableHandler.getEditableColumnMap(columnMetadata, table_name)
    )

    setLocalPageIndex(pageIndex)

    const params = {
      // table_name: table_name.toUpperCase(),
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      // show_deleted: show_deleted,
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }

    dispatch(getJiraEntries(endpoint, params, token))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  useEffect(() => {
    if (modalMetaData?.loading === false) {
      let table_data = modalMetaData?.metaData?.data
      if (table_data?.length > 0) {
        for (let i = 0; i < table_data?.length; i++) {
          if (table_data[i] !== null) {
            if (table_data[i]['KH_ETL_IS_DELETED']) {
              table_data[i]['KH_ETL_IS_DELETED'] = 'true'
            } else {
              table_data[i]['KH_ETL_IS_DELETED'] = 'false'
            }
          }
        }

        setTableColumns(
          TableHandler.createColumnMapping(
            modalMetaData?.metaData?.columns,
            columnMetadata,
            table_name
          )
        )
        setTotalPages(modalMetaData?.metaData?.num_pages)
        setTotalRows(modalMetaData?.metaData?.total_results)
        setTableData(modalMetaData?.metaData?.data)
      } else {
        setTotalPages(0)
        setTotalRows(0)
        setTableData([])
      }
    }
  }, [modalMetaData, setTableData])

  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    if (token != null) {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })
      fetchData(
        { pageSize, pageIndex },
        null,
        false,
        JSON.stringify(columnSort)
      )
    }
  }, [pageSize, pageIndex, columnSort, token])

  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  const handleEditShowJira = () => {
    if (editRows.length > 0) {
      setEditShowJira(true)
      setLoadingStatus({
        status: true,
        message: 'Fetching modal sprint data...',
      })

      dispatch(getCurrentSprint(endpoint, token))

      dispatch(getModalMetadata(endpoint, token))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching modal sprint data...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching modal sprint data...',
          })
        })
    } else {
      toast('No records selected', toastTopRightError)
    }
  }

  function OpenAddModal() {
    setShowJira(true)
    setLoadingStatus({
      status: true,
      message: 'Fetching modal sprint data...',
    })

    dispatch(getCurrentSprint(endpoint, token))

    dispatch(getModalMetadata(endpoint, token))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching modal sprint data...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching modal sprint data...',
        })
      })
  }

  // const handleShowJira = () => setShowJira(true)
  const handleCloseJira = () => {
    setShowJira(false)
  }

  const handleEditCloseJira = () => {
    setEditShowJira(false)
  }

  // const updateRecords_inline_editing = () => {
  //   let payload = []
  //   let editUri = table_name.toUpperCase()
  //   // loops over editRow state and creates payload compatible with update_object api
  //   for (let i = 0; i < editRows.length; i++) {
  //     let local_object = {}
  //     local_object['KH_UID'] = editRows[i]['KH_UID']
  //     for (let j = 0; j < enabledCols.length; j++) {
  //       let value = editRows[i][enabledCols[j]]
  //       let key = enabledCols[j]
  //       local_object[key] = value
  //     }
  //     payload.push(local_object)
  //   }

  //   //Check if the the Edit rows are 1 or more
  //   if (payload.length === 0) {
  //     toast('No records to update', toastTopRightError)
  //     return
  //   } else if (payload.length === 1) {
  //     //for 1 row, pass key in url
  //     editUri += `&key=${editRows[0]['KH_UID']}`
  //     payload = payload[0]
  //     delete payload['KH_UID']
  //   }
  //   dispatch(editRefTableData(endpoint, editUri, payload)).then(() => {
  //     if (refTableData.loading === true) {
  //       setLoadingStatus({ status: true, message: 'Updating table record...' })
  //     }
  //     handleClose()
  //     fetchData({ pageSize, pageIndex })
  //   })
  // }

  return (
    <>
      {modalMetaData?.error?.length > 1 ? (
        <>
          <Navbar title="Capacity Tracker" HelpContent={HelpContent} />
          <div
            class="bg-white text-dark text-center"
            style={{ height: '69vh', paddingTop: '150px' }}
          >
            <div>
              <MdDangerous style={{ fontSize: '60px' }} />
            </div>
            <div class="mt-3 fs-5">{modalMetaData.error}</div>
          </div>
        </>
      ) : (
        <>
          {modalMetaData.loading ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : (
            <>
              <Navbar title="Capacity Tracker" HelpContent={HelpContent} />
              <div className="table-container" style={{ textAlign: 'center' }}>
                <Table {...getTableProps()}>
                  <thead className="sticky-table-header">
                    {headerGroups.map((headerGroup, i) => (
                      <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, i) =>
                          column?.id === 'selection' ? (
                            <th key={column.id}>
                              <div
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                                className="table-header"
                              >
                                {column.render('Header')}

                                {columnSort[0]?.field === column['Header'] ? (
                                  <span>
                                    {columnSort[0]?.direction === 'asc'
                                      ? ' 🔽'
                                      : '' ||
                                        columnSort[0]?.direction === 'desc'
                                      ? ' 🔼'
                                      : ''}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </div>
                            </th>
                          ) : (
                            <th key={i}>
                              <div
                                onClick={() => handleSorting(column.Header)}
                                className="table-header"
                              >
                                {column.render('Header')}

                                {columnSort[0]?.field === column['Header'] ? (
                                  <span>
                                    {columnSort[0]?.direction === 'asc'
                                      ? ' 🔽'
                                      : '' ||
                                        columnSort[0]?.direction === 'desc'
                                      ? ' 🔼'
                                      : ''}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div>
                                {column.canFilter
                                  ? column.render('Filter')
                                  : null}
                              </div>
                            </th>
                          )
                        )}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="tbody" {...getTableBodyProps()}>
                    {page.map((row, index) => {
                      prepareRow(row)
                      return (
                        <tr
                          key={index}
                          // {...row.getRowProps()}
                          // onDoubleClick={() =>
                          //   handleEditableMode(index, row.values)
                          // }
                        >
                          {row.cells.map((cell) => {
                            return editModeState &&
                              index === rowIndex &&
                              enabledColsMap[cell.column.id] ? (
                              <td {...cell.getCellProps()}>
                                <input
                                  type="text"
                                  defaultValue={cell.value}
                                  onChange={handleValueEditableMode(
                                    cell.column.id
                                  )}
                                />
                              </td>
                            ) : (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            )
                          })}
                          {editModeState && index === rowIndex ? (
                            <>
                              <div className="inlineedit">
                                <button
                                  className="inlineinternal"
                                  onClick={handleUpdateEditMode}
                                >
                                  &#10004;
                                </button>
                                <button
                                  className="inlineinternal"
                                  onClick={handleDeleteEditMode}
                                >
                                  &#10060;
                                </button>
                              </div>
                            </>
                          ) : null}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
              {/* Bottom ToolBar of table */}
              <Row>
                <Col sm={6}>
                  <div className="page-control">
                    <div className="page-of">
                      Page{' '}
                      <em>
                        {pageIndex + 1} of {totalPages}
                      </em>
                    </div>
                    <div className="prev-next-btn">
                      <button
                        onClick={() => onPrevious()}
                        disabled={!canPreviousPage}
                      >
                        {' '}
                        Prev{' '}
                      </button>
                      <button onClick={() => onNext()} disabled={!canNextPage}>
                        {' '}
                        Next{' '}
                      </button>
                    </div>
                    <div className="second-control">
                      <span>Go to page:</span>
                      <input
                        className="page-number-input"
                        type="number"
                        defaultValue={pageIndex || 1}
                        onBlur={(e) => {
                          const page = e.target.value
                            ? Number(e.target.value)
                            : 0
                          // handling zero indexing
                          gotoPage(page - 1)
                        }}
                      />
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value))
                        }}
                      >
                        {available_page_size.map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
                {/* Bottom-right Buttons */}
                <Col sm={6}>
                  <Button
                    className="m_r-5 block-btn main-button btn-sm"
                    onClick={OpenAddModal}
                  >
                    Add
                  </Button>

                  <Button
                    className="m_r-5 block-btn main-button btn-sm"
                    onClick={handleEditShowJira}
                  >
                    Edit
                  </Button>
                </Col>
              </Row>
              {/* {selectionModel.map(val =><h1 key={val}>{val}</h1>)} */}
              {loadingStatus.status == true ? (
                <LoadingStatus status_message={loadingStatus.message} />
              ) : (
                <Modal size="xl" show={showJira} onHide={handleCloseJira}>
                  <Modal.Header closeButton>
                    <Modal.Title>Capacity Tracker</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modalBody">
                    <JiraTracker
                      jira_meta_data={jiraAddMetadata}
                      current_sprint={currentSprint}
                      setShowJira={setShowJira}
                      setLoadingStatus={setLoadingStatus}
                    />
                  </Modal.Body>
                </Modal>
              )}
              {loadingStatus.status == true ? (
                <LoadingStatus status_message={loadingStatus.message} />
              ) : (
                <Modal
                  size="xl"
                  show={EditshowJira}
                  onHide={handleEditCloseJira}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Capacity Tracker</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modalBody">
                    <EditJiraTracker
                      jira_meta_data={jiraAddMetadata}
                      current_sprint={currentSprint}
                      selectedRows={editRows}
                      setEditShowJira={setEditShowJira}
                      setLoadingStatus={setLoadingStatus}
                    />
                  </Modal.Body>
                </Modal>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
