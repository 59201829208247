//@ts-ignore
import dir_icon from '../assets/dir_icon.svg'
//@ts-ignore
import dir_icon_open from '../assets/dir_icon_open.svg'
//@ts-ignore
import doc_icon from '../assets/doc_icon.svg'
//@ts-ignore
// import doc_selected_icon from "../../../assets/doc_selected_icon.svg";

import { useReducer } from 'react'
import { Link } from 'react-router-dom'
import { TREE_MODE } from '../BQDataDictionary'

type LookupTable = { [key: string]: boolean }[]
type Action = { type: 'toggle'; index: number; id: string }

function* id_generator(id_list: string[]) {
  while (id_list.length) {
    yield id_list.pop() as string
  }
}

function buildLookupTable(tree: FlatTree): LookupTable {
  return tree.map((row) =>
    Object.fromEntries(
      row
        .flat(21)
        .filter((s) => s[0] === '!')
        .map(() => [Math.random().toString(36).slice(2, 7), false])
    )
  )
}

function reducer(lookupTable: LookupTable, action: Action): LookupTable {
  const { type, index, id } = action
  switch (type) {
    case 'toggle': {
      const newTable = lookupTable.slice()
      newTable[index][id] = !lookupTable[index][id]
      return newTable
    }
    default:
      throw new Error()
  }
}

interface TreeProps {
  index: number
  children: FlatData
  tree_mode: TREE_MODE
  default_open: boolean
  dispatch: (action: Action) => void
  lookupTable: { [key: string]: boolean }
  keygenerator: Generator<string, void, unknown>
}
const Tree: React.FC<TreeProps> = (props) => {
  const id = props.keygenerator.next().value || ''
  const isOpen = props.lookupTable[id]
  const [label, data] = props.children
  return (
    <>
      <li
        onClick={() => {
          props.dispatch({
            type: 'toggle',
            index: props.index,
            id: id,
          })
        }}
      >
        <span>
          <img
            width="16px"
            height="16px"
            src={isOpen ? dir_icon_open : dir_icon}
          />
          {label.slice(1)}
        </span>
      </li>

      {isOpen ? (
        <ul>
          {data.map((_data: any) =>
            typeof _data === 'string' ? (
              <li key={_data}>
                <span>
                  <img width="16px" height="16px" src={doc_icon} />
                  <Link to={_data}>{_data.split('.').pop()}</Link>
                </span>
              </li>
            ) : (
              <Tree
                key={_data[0]}
                index={props.index}
                default_open={false}
                dispatch={props.dispatch}
                tree_mode={props.tree_mode}
                lookupTable={props.lookupTable}
                keygenerator={props.keygenerator}
              >
                {_data as FlatData}
              </Tree>
            )
          )}
        </ul>
      ) : null}
    </>
  )
}

interface Props {
  data: FlatTree
  tree_mode: TREE_MODE
}
const DbTree: React.FC<Props> = (props) => {
  const [lookupTable, dispatch] = useReducer(
    reducer,
    buildLookupTable(props.data)
  )
  return (
    <div id="tree-scroll">
      <strong>Database</strong>
      <ul id="tree-root">
        {props.data.map((tree, i) => {
          return (
            <Tree
              index={i}
              key={tree[0]}
              dispatch={dispatch}
              default_open={true}
              tree_mode={props.tree_mode}
              lookupTable={lookupTable[i]}
              keygenerator={id_generator(Object.keys(lookupTable[i]))}
            >
              {tree}
            </Tree>
          )
        })}
      </ul>
    </div>
  )
}

export default DbTree
