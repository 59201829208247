import React, { useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getGlossaryTermByID } from '../../../../redux/actions/glossaryAction'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import {
  approveGlossaryTerm,
  rejectGlossaryTerm,
} from '../../../../redux/actions/glossaryAction'
import jwt_decode from 'jwt-decode'
import LoadingStatus from '../../../Helper/LoadingStatus'
import parse from 'html-react-parser'
import 'react-quill/dist/quill.snow.css'

export default function ApproveTerm({ isAdmin }) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const { id } = useParams()
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  const token = localStorage.getItem('token')
  const glossaryTermByID = useSelector((state) => state.glossaryTermByID)
  const [termData, setTermData] = React.useState([])

  function parseHtml() {
    const definition = termData.definition
    if (!definition || definition.length === 0) {
      return ''
    }
    return parse(definition)
  }

  function TagsSection({ title, content }) {
    if (!content || content.length === 0) {
      return (
        <div>
          <strong>{title}:</strong>
        </div>
      )
    }
    return (
      <div>
        <strong>{title}:</strong>{' '}
        {content.map((item) => item.tag_name).join(', ')}
      </div>
    )
  }

  function DateMetadataSection({ title, by, on }) {
    if (!by || by.length === 0) {
      return (
        <div>
          <strong>{title}:</strong>
        </div>
      )
    }
    return (
      <div>
        <strong>{title}:</strong> {by ? `${by} - ` : ''}
        {on}
      </div>
    )
  }

  const handleApprove = () => {
    let payload = {
      approval_status: 'Approved',
      approved_by: tokenValue.preferred_username,
      modified_by: tokenValue.preferred_username,
    }
    dispatch(approveGlossaryTerm(endpoint, payload, id))
  }

  const handleReject = () => {
    let payload = {
      approval_status: 'Rejected',
      modified_by: tokenValue.preferred_username,
    }
    dispatch(rejectGlossaryTerm(endpoint, payload, id))
  }

  const fetchData = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getGlossaryTermByID(endpoint, id))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    if (token != null) {
      fetchData()
    }
  }, [token])

  useEffect(() => {
    if (glossaryTermByID?.loading === false) {
      setTermData(glossaryTermByID?.term?.data)
    }
  }, [glossaryTermByID, setTermData])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="glossary-approve-container">
          {isAdmin ? (
            <div>
              <Link to="/glossary/pending">
                <Button
                  variant="success"
                  style={{ marginRight: '20px' }}
                  onClick={handleApprove}
                >
                  Approve
                </Button>
              </Link>
              <Link to="/glossary/pending">
                <Button variant="danger" onClick={handleReject}>
                  Reject
                </Button>
              </Link>
            </div>
          ) : null}
          <h1>{termData.term}</h1>
          <br />
          <hr className="glossary-term-border-line" />
          <strong>Channel:</strong> {termData.role_type}
          <br />
          <TagsSection title="Tags" content={termData.tags} />
          <DateMetadataSection
            title="Created"
            by={termData.created_by}
            on={termData.created_on}
          />
          <DateMetadataSection
            title="Modified"
            by={termData.modified_by}
            on={termData.modified_on}
          />
          <DateMetadataSection
            title="Approved"
            by={termData.approved_by}
            on={termData.approved_on}
          />
          <strong>Approval Status:</strong> {termData.approval_status}
          <hr className="glossary-term-border-line" />
          <div className="glossary-term-definition-container">
            <div className="ql-editor">{parseHtml(termData.definition)}</div>
          </div>
          <hr className="glossary-term-border-line" />
        </div>
      )}
    </>
  )
}
