import styled from 'styled-components'
import loader_gif from '../../../../media/loader.gif'

const StyledLoadingStatus = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-image: url('${loader_gif}');
  background-color: #cecece;
  background-size: 60px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9998;
  opacity: 0.7;

  #ddmessage {
    color: black;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 200%);
  }
`

interface Props {
  status_message: string
}

const LoadingStatus = (props: Props) => {
  return (
    <StyledLoadingStatus>
      <p id="ddmessage">{props.status_message}</p>
    </StyledLoadingStatus>
  )
}

export default LoadingStatus
