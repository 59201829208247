import React from 'react'
import loader_gif from '../../media/loader.gif'

const LoadingStatus = ({ status_message }) => {
  const loader = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    width: '100%',
    height: '100%',
    backgroundImage: `url("${loader_gif}")`,
    backgroundColor: 'none',
    backgroundSize: '60px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    zIndex: '9998',
    opacity: '0.7',
  }

  const message = {
    color: 'black',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, 200%)',
  }

  return (
    <div style={loader}>
      <p id="status-message" style={message}>
        {status_message}
      </p>
    </div>
  )
}

export default LoadingStatus
