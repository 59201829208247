import React, { useState, useEffect, useContext } from 'react'
import LoadingStatus from '../../Helper/LoadingStatus'
import { AuthContext } from '../../context/authProvider'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { getDbtGradingScores } from '../../../redux/actions/dbtGradingActions/dbtGradingAction'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { MdHelpOutline } from 'react-icons/md'

export default function DBTGradingScoreDashboard({
  model_id,
  modelName,
  handleScoreModal,
}) {
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [authCreds] = useContext(AuthContext)
  const [tableData, setTableData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  const [numTermsShowed, setNumTermsShowed] = useState(0)
  const endpoint = authCreds.restEndpoint
  const dbtGradingScores = useSelector((state) => state.getDbtScoresReducer)
  const handlePageChange = (number) => {
    setCurrentPage(number)
  }

  const fetchData = async ({
    pageSize = 18,
    filters = [{ op: 'eq', field: 'MODEL_ID', value: `${model_id}` }],
    sort_value = [{ field: 'ALGORITHM', direction: 'asc' }],
  } = {}) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    const params = {
      page_size: pageSize,
      page_number: currentPage, //handle 0 indexing
      model_id: model_id,
      filters: JSON.stringify(filters),
      sort: JSON.stringify(sort_value),
    }

    dispatch(getDbtGradingScores(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    let table_data = dbtGradingScores?.dbtGradingScores?.data
    if (table_data?.length > 0) {
      setTotalPages(dbtGradingScores?.dbtGradingScores?.num_pages)
      setTotalRows(dbtGradingScores?.dbtGradingScores?.total_results)
      setTableData(table_data)
      let numPages = dbtGradingScores?.dbtGradingScores?.num_pages
      if (numPages === currentPage) {
        setNumTermsShowed(totalRows)
      } else {
        setNumTermsShowed(18 * currentPage)
      }
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, dbtGradingScores])

  useEffect(() => {
    if (currentPage && model_id) {
      fetchData()
    }
  }, [currentPage, model_id])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div className="score-heading">
            <Button onClick={handleScoreModal} className="score-button">
              <ArrowBackIcon /> Go Back
            </Button>{' '}
            <div className="score-title">
              View {modelName}&apos;s Algorithm &amp; Scores
            </div>
          </div>
          <div style={{ background: '#fff' }}>
            <div className="glossary-card-list">
              {tableData.map(function (data) {
                return (
                  <div className="glossary-card" key={data.MODEL_ID}>
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip">Algorithm</Tooltip>}
                    >
                      <h2 style={{ color: '#1D3C6D' }}>
                        {data.ALGORITHM}{' '}
                        <MdHelpOutline
                          style={{ fontSize: '28px', color: '#0a58ca' }}
                        />
                      </h2>
                    </OverlayTrigger>
                    <p>SCORE: {data.SCORE}</p>
                  </div>
                )
              })}
            </div>
            <div className="glossary-pagination-container">
              <br />
              <button
                className="badge-btn"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1 || totalRows === 0}
              >
                Prev
              </button>
              <button
                className="badge-btn"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || totalRows === 0}
              >
                Next
              </button>
              <p className="glossary-pagination-text">
                Showing: {numTermsShowed} of {totalRows} Scores
              </p>
            </div>
          </div>
        </>
      )}
    </>
  )
}
