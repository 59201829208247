//@ts-ignore
import logo from '../assets/capLogo.png'
import { Link } from 'react-router-dom'
import { TREE_MODE } from '../DataDictionary'
import Manifest from '../Classes/Manifest'
import styled from 'styled-components'
import MasterTree from './MasterTree'
import TreeSwitch from './TreeSwitch'
import DbTree from './DbTree'

const StyledSideMenu = styled.div`
  position: relative;
  flex: 0 0 auto;
  height: calc(95vh - 60px);
  width: 20%;
  min-width: 300px;

  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  #logo {
    height: 3.5vh;
    padding: 30px 30px;
    background: #fff;
    background-image: url(${logo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: 0 1px 0 rgb(0 0 0 /8%);
  }

  #overview-btn {
    cursor: pointer;
    padding: 5px 0;
    padding-left: 10px;
    border-radius: 4px;
    font-weight: bold;
    color: #313539;
    &:hover {
      background-color: #f0f2f4;
    }
  }

  #sideMenu-body {
    padding-bottom: 20px;
    padding-left: 30px;
    padding-top: 20px;

    flex: 1 0;
    min-height: 0;

    display: flex;
    flex-direction: column;

    #tree-switch {
      display: flex;
      margin: 1.5em 0;
      border-radius: 4px;
      background: #edeff2;
      box-shadow: 0 0 0 1px rgb(0 0 0 / 3%), 0 4px 4px -2px rgb(0 0 0 / 4%);

      .switch-btn {
        transition: all 0.25s cubic-bezier(0.25, 0, 0, 1);

        flex: 1 1 auto;
        cursor: pointer;
        border-radius: 4px;
        padding: 0.45rem 1.25rem;
        box-shadow: inset 0 0 5px rgb(0 0 0 /5%);

        font-size: 0.8rem;
        text-align: center;

        &:hover {
          color: #313539;
        }
      }
    }

    #tree-scroll {
      overflow: auto;
      cursor: pointer;
      ul {
        margin: 0;
        list-style: none;
        padding-inline-start: 10px;
      }
      li {
        :hover {
          background: #f0f2f4;
        }
      }
      a {
        text-decoration: none;
        color: inherit;
      }
      img {
        vertical-align: middle;
        margin-right: 5px;
      }
      span {
        display: block;
        white-space: nowrap;
      }
      strong {
        display: block;
        :hover {
          background: #f0f2f4;
        }
      }
      #tree-root {
      }
    }
  }
`

interface Props {
  manifest: Manifest
  treeMode: TREE_MODE
  setTreeMode: (value: TREE_MODE) => void
}
const SideMenu: React.FC<Props> = ({ ...props }: Props) => {
  return (
    <StyledSideMenu>
      <div id="logo"></div>

      <div id="sideMenu-body">
        <div>
          <Link to={'/data-dictionary'}>
            <div id="overview-btn">Overview</div>
          </Link>
        </div>
        <TreeSwitch treeMode={props.treeMode} setTreeMode={props.setTreeMode} />
        {props.treeMode === TREE_MODE.PROJECT && (
          <MasterTree data={props.manifest._tree} tree_mode={props.treeMode} />
        )}
        {props.treeMode === TREE_MODE.DATABASE && (
          <DbTree data={props.manifest._dbTree} tree_mode={props.treeMode} />
        )}
      </div>
    </StyledSideMenu>
  )
}
export default SideMenu
