import {
  GET_TABLES_PROFILE_ERROR,
  GET_TABLES_PROFILE,
  GET_TABLES_PROFILE_LOADING,
} from '../constants/types'

const initialState = {
  profileData: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TABLES_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_TABLES_PROFILE:
      return {
        ...state,
        profileData: action.payload,
        loading: false,
        error: null,
      }
    case GET_TABLES_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
