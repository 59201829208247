import {
  SEEDING_DATA_BEGIN,
  SEEDING_DATA_BEGIN_SUCCESS,
  SEEDING_DATA_BEGIN_FAIL,
} from '../actions/seedingAction'

const initialState = {
  seedingData: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SEEDING_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case SEEDING_DATA_BEGIN_SUCCESS:
      return {
        ...state,
        auditLogData: action.payload,
        loading: false,
        error: null,
      }
    case SEEDING_DATA_BEGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
