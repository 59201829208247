import React from 'react'
import { MdHelpOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
// import { Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { Button } from 'react-bootstrap'

export default function dbtGradingHelpContent() {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ marginRight: '10px' }}>
      <Button
        aria-label="what is NPS Survey?"
        variant="outlined"
        label="fd"
        onClick={handleClickOpen}
      >
        <MdHelpOutline style={{ fontSize: '28px', color: '#F9D949' }} />
      </Button>

      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-describedby=""
      >
        <DialogTitle>DBT Grading Help</DialogTitle>
        <DialogContent>
          <span style={{ fontWeight: '500' }}>DBT Grading Calculation :</span>
          <TableContainer component={Paper} style={{ marginTop: '10px' }}>
            <Table sx={{ minWidth: 430 }} aria-label="Grade Table">
              <TableHead>
                <TableRow>
                  <TableCell className="grade-table-header">Grade</TableCell>
                  <TableCell align="left" className="grade-table-header">
                    Range
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="grade-table-body">
                <TableRow>
                  <TableCell>A</TableCell>
                  <TableCell align="left">Above 0.90</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>B</TableCell>
                  <TableCell align="left">
                    Above 0.80 and less than 0.90
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>C</TableCell>
                  <TableCell align="left">
                    Above 0.70 and less than 0.80
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>D</TableCell>
                  <TableCell align="left">
                    Above 0.60 and less than 0.70
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>F</TableCell>
                  <TableCell align="left">Below 0.60</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
