import {
  GET_FNF_STEP0_SUMMARY_DATA,
  GET_FNF_STEP05_SUMMARY_DATA,
  GET_FNF_STEP1_SUMMARY_DATA,
  GET_FNF_STEP0_LIKELIHOOD_DATA,
  GET_FNF_STEP05_LIKELIHOOD_DATA,
  GET_FNF_STEP1_LIKELIHOOD_DATA,
  GET_FNF_STEP0_UNIQUEVALUES_DATA,
  GET_FNF_STEP05_UNIQUEVALUES_DATA,
  GET_FNF_STEP1_UNIQUEVALUES_DATA,
  START_FNF_LOADING,
  GET_FNF_ERROR,
  GET_FNF_ORDER_ITEMS_DATA,
  CLEAR_FNF_ITEMS_DATA,
  GET_FNF_GRID_RULES,
  STOP_FNF_LOADING,
  GET_FNF_GRID_UNIQUEVALUES_DATA,
} from '../constants/types'

import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'

export const getStep0SummaryAction =
  (endpoint, payload) => async (dispatch) => {
    try {
      dispatch({
        type: START_FNF_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      let query = `filters=${payload.filters}`

      await axios
        .get(`${endpoint}/api/fnf/get_summary_report_0?${query}`, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_FNF_STEP0_SUMMARY_DATA,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_FNF_ERROR,
        payload: e.response.data.message,
      })
    }
  }

export const getStep05SummaryAction =
  (endpoint, payload) => async (dispatch) => {
    try {
      dispatch({
        type: START_FNF_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      let query = `filters=${payload.filters}`

      await axios
        .get(`${endpoint}/api/fnf/get_summary_report_05?${query}`, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_FNF_STEP05_SUMMARY_DATA,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_FNF_ERROR,
        payload: e.response.data.message,
      })
    }
  }

export const getStep1SummaryAction =
  (endpoint, payload) => async (dispatch) => {
    try {
      dispatch({
        type: START_FNF_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      let query = `filters=${payload.filters}`

      await axios
        .get(`${endpoint}/api/fnf/get_summary_report_1?${query}`, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_FNF_STEP1_SUMMARY_DATA,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_FNF_ERROR,
        payload: e.response.data.message,
      })
    }
  }

export const getStep0LikelihoodReportAction =
  (endpoint, payload) => async (dispatch) => {
    // Fetches Likelihood report data for Step 0
    try {
      dispatch({
        type: START_FNF_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      let query = `limit=${payload.limit}&page=${payload.page}&filters=${payload.filters}`

      await axios
        .get(`${endpoint}/api/fnf/get_likelihood_report_0?${query}`, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_FNF_STEP0_LIKELIHOOD_DATA,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_FNF_ERROR,
        payload: e.response.data.message,
      })
    }
  }

export const getStep05LikelihoodReportAction =
  (endpoint, payload) => async (dispatch) => {
    // Fetches Likelihood report data for Step 0.5
    try {
      dispatch({
        type: START_FNF_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      let query = `limit=${payload.limit}&page=${payload.page}&filters=${payload.filters}`

      await axios
        .get(`${endpoint}/api/fnf/get_likelihood_report_05?${query}`, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_FNF_STEP05_LIKELIHOOD_DATA,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_FNF_ERROR,
        payload: e.response.data.message,
      })
    }
  }

export const getStep1LikelihoodReportAction =
  (endpoint, payload) => async (dispatch) => {
    // Fetches Likelihood report data for Step 1
    try {
      dispatch({
        type: START_FNF_LOADING,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      let query = `limit=${payload.limit}&page=${payload.page}&filters=${payload.filters}`

      await axios
        .get(`${endpoint}/api/fnf/get_likelihood_report_1?${query}`, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: GET_FNF_STEP1_LIKELIHOOD_DATA,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_FNF_ERROR,
        payload: e.response.data.message,
      })
    }
  }

export const getUniqueValuesAction = (endpoint, step) => async (dispatch) => {
  try {
    dispatch({
      type: START_FNF_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/fnf/get_dropdown_values?step=${step}`, {
        headers: post_headers,
      })
      .then((res) => {
        if (step === 'step_0') {
          dispatch({
            type: GET_FNF_STEP0_UNIQUEVALUES_DATA,
            payload: res.data,
          })
        } else if (step === 'step_05') {
          dispatch({
            type: GET_FNF_STEP05_UNIQUEVALUES_DATA,
            payload: res.data,
          })
        } else if (step === 'step_1') {
          dispatch({
            type: GET_FNF_STEP1_UNIQUEVALUES_DATA,
            payload: res.data,
          })
        } else if (step === 'grid') {
          const existingpayload = {
            FINE_CATEGORY: [
              'ORAD_DELIVERY',
              'ORAD_CPU',
              'EDI_FAILURE',
              'FILL_RATE',
              'VNS',
              'VRE',
              'CFR_DELIVERY',
              'CFR_CPU',
              'IN_FULL',
              'OTFR',
              'Damaged Pallet',
              'Missed Delivery',
            ],
            CUSTOMER: [
              'Kroger',
              'Giant Eagle',
              "BJ's",
              'McLane',
              'Walgreens',
              'Target',
              'AWG',
            ],
            WAREHOUSE: ['OT', 'In Full'],
            OWING_ORG: ['OM', 'Warehouse', 'Transport', 'Others'],
            RULE_STATUS: ['WIP', 'Ready'],
          }
          dispatch({
            type: GET_FNF_GRID_UNIQUEVALUES_DATA,
            payload: { ...res.data, ...existingpayload },
          })
        }
      })
  } catch (e) {
    dispatch({
      type: GET_FNF_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const sendEmailAction = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: START_FNF_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    let query = `to_email=${payload.to_email}&subject=${payload.subject}&body_data=${payload.body_data}`

    await axios
      .get(`${endpoint}/api/fnf/send_email?${query}`, {
        headers: post_headers,
      })
      .then((res) => {
        if (res.data.message !== 'success') {
          toast(res.data.message, toastTopRightError)
        } else {
          toast('Email sent successfully', toastTopRightSuccess)
        }
        dispatch({
          type: STOP_FNF_LOADING,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_FNF_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getItemsAction = (endpoint, payload) => async (dispatch) => {
  // Fetches Item details
  try {
    dispatch({
      type: CLEAR_FNF_ITEMS_DATA,
    })
    dispatch({
      type: START_FNF_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    let query = `limit=${payload.limit}&page=${payload.page}&filters=${payload.filters}`

    await axios
      .get(`${endpoint}/api/fnf/get_items?${query}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_FNF_ORDER_ITEMS_DATA,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_FNF_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getGridRulesAction = (endpoint, payload) => async (dispatch) => {
  // Fetches Grid Rules
  try {
    dispatch({
      type: START_FNF_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    let query = `limit=${payload.limit}&page=${payload.page}&filters=${payload.filters}`

    await axios
      .get(`${endpoint}/api/fnf/get_grid_rules?${query}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_FNF_GRID_RULES,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_FNF_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const addGridRuleAction = (endpoint, payload) => async (dispatch) => {
  // Add Grid Rule
  try {
    dispatch({
      type: START_FNF_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/fnf/add_grid_rule`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        if (res.data.message !== 'Success') {
          toast(res.data.message, toastTopRightError)
        } else {
          toast('Rule added successfully', toastTopRightSuccess)
        }
        dispatch({
          type: STOP_FNF_LOADING,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_FNF_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const updateGridRuleAction = (endpoint, payload) => async (dispatch) => {
  // Update Grid Rule
  try {
    dispatch({
      type: START_FNF_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/fnf/update_grid_rule`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        if (!res.data.message.includes('rows updated')) {
          toast(res.data.message, toastTopRightError)
        } else {
          toast('Rule updated successfully', toastTopRightSuccess)
        }
        dispatch({
          type: STOP_FNF_LOADING,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_FNF_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const deleteGridRuleAction = (endpoint, payload) => async (dispatch) => {
  // Delete Grid Rule
  try {
    dispatch({
      type: START_FNF_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/fnf/delete_grid_rule`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        if (!res.data.message === 'Success') {
          toast(res.data.message, toastTopRightError)
        } else {
          toast('Rule Deleted successfully', toastTopRightSuccess)
        }
        dispatch({
          type: STOP_FNF_LOADING,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_FNF_ERROR,
      payload: e.response.data.message,
    })
  }
}
