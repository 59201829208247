import axios from 'axios'

export const getAuditLog = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch(getAuditLogDataBegin())
    let post_headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(`${endpoint}/api/ref_data/get_collection`, {
        params: payload,
        headers: post_headers,
      })
      .then((res) => {
        dispatch(getAuditLogDataSuccess(res.data))
      })
  } catch (e) {
    dispatch(getAuditLogDataFailure(e))
  }
}

export const GET_AUDIT_LOG_DATA_BEGIN = 'GET_AUDIT_LOG_DATA_BEGIN'
export const GET_AUDIT_LOG_DATA_SUCCESS = 'GET_AUDIT_LOG_DATA_SUCCESS'
export const GET_AUDIT_LOG_DATA_FAIL = 'GET_AUDIT_LOG_DATA_FAIL'

const getAuditLogDataBegin = () => ({
  type: GET_AUDIT_LOG_DATA_BEGIN,
})

const getAuditLogDataSuccess = (getData) => ({
  type: GET_AUDIT_LOG_DATA_SUCCESS,
  payload: getData,
})

const getAuditLogDataFailure = (error) => ({
  type: GET_AUDIT_LOG_DATA_FAIL,
  payload: { error },
})
