import React, { useRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import Graph from 'react-graph-vis'
import Table from 'react-bootstrap/Table'

import { motion, AnimatePresence } from 'framer-motion'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { v4 as uuidv4 } from 'uuid'
// import "./graph.css";
import './graph.css'

const defaultOptions = {
  height: '100%',
  interaction: {
    hover: true,
  },
  layout: {
    randomSeed: 1,
    improvedLayout: false,
    hierarchical: {
      enabled: true,
      levelSeparation: 800,
      nodeSpacing: 500,
      treeSpacing: 500,
      blockShifting: false,
      edgeMinimization: true,
      parentCentralization: true,
      direction: 'LR',
      sortMethod: 'directed',
      shakeTowards: 'roots',
    },
  },
  nodes: {
    shape: 'box',
    scaling: {
      min: 3,
      max: 50,
      label: {
        min: 8,
        max: 30,
        drawThreshold: 2,
        maxVisible: 200,
      },
    },

    shadow: false,
  },
  edges: {
    color: 'red',
    width: 1,
    smooth: {
      type: 'continuous',
    },
  },
  physics: {
    barnesHut: {
      springLength: 400,
      springConstant: 1,
      avoidOverlap: 0.6,
    },
    stabilization: {
      enabled: true,
      iterations: 5000,
    },
  },
}

const getEdgeColor = (value, isActive) => {
  // value > 0 ? green : red
  let rgb = isActive ? '76, 175, 80' : '244, 67, 54'
  let opacity = isActive ? '8' : '2'
  return `rgba(${rgb}, ${opacity})`
}

// // Create HTMLElement from string
// function htmlToElement(html) {
//   var template = document.createElement('template')
//   html = html.trim() // Never return a text node of whitespace as the result
//   template.innerHTML = html
//   return template.content.firstChild
// }

const ValueGraph = ({ nodes, edges, options, data }) => {
  const [tableState, setTableState] = useState(false)
  const [tableData, setTableData] = useState([])
  // const [activeNode, setActiveNode] = useState('')
  let container = useRef(null)

  const events = {
    selectNode: ({ nodes }) => {
      data.map((d) => {
        if (nodes[0] === d.id) {
          setTableState(true)
          setTableData([d])
        }
      })
    },
    deSelectNode: () => {
      setTableState(false)
    },
    // deselectNode: () => {
    //   setTableState(false)
    // },
    // initRedraw: () => {
    //   if (container.current && network.current) {
    //     let { width, height } = container.current.getBoundingClientRect();
    //     network.current.setSize(width + "px", height + "px");
    //   }
    // }
  }

  let displayNodes = nodes.map((node) => ({
    ...node,
    label: node.label,
    borderWidthSelected: 2,
    //   label: node.label + '\n\n' + '(' + node.node_type + ')',
    color:
      node.node_type == 'ingested_table'
        ? '#297895'
        : node.node_type == 'dbt_table_or_consumption_view'
        ? '#e7b500'
        : node.node_type == 'source'
        ? '#e78826'
        : node.node_type == 'tableau_dashboard'
        ? '#932995'
        : node.node_type == 'tableau_workbook'
        ? 'green'
        : node.node_type == 'tableau_sheet'
        ? '#1f9f8d'
        : node.node_type == 'tableau_table'
        ? 'brown'
        : 'black',

    font: {
      size: 24,
      color: '#fff',
    },
  }))

  let displayEdges = edges.map((edge) => ({
    ...edge,
    color: getEdgeColor(edge.value, edge.muted === false),
    label: String(edge.type),
  }))
  const version = useMemo(uuidv4)
  const handleClose = () => {
    setTableState(false)
  }
  const handleDrawer = () => {
    setTableState(true)
  }
  return (
    <AnimatePresence>
      <motion.div
        className="graph"
        id="mynetwork"
        ref={container}
        style={tableState ? { width: '68%' } : { width: '100%' }}
      >
        <Graph
          key={version}
          graph={{ nodes: displayNodes, edges: displayEdges }}
          options={{ ...defaultOptions, ...options }}
          // events={events}
          events={events}
          getNetwork={(nw) => {
            nw.once('stabilized', function () {
              var scaleOption = { scale: 0.5 }
              nw.moveTo(scaleOption)
            })
            // nw.on('selectNode', function (params) {
            //   var selectedNodeId = params.nodes[0]
            //   var node = nw.body.nodes[selectedNodeId]

            // }),
            // nw.on('deselectNode', function (params) {
            //   var deselectedNodeId = params.previousSelection.nodes[0]
            //   var node = nw.body.nodes[deselectedNodeId]
            //   node.setOptions({
            //     font: {
            //       size: 24,
            //       color: '#fff',
            //     },
            //   })
            // })
          }}
        />
      </motion.div>
      {displayNodes.length != 0 ? (
        tableState ? (
          <motion.div>
            {' '}
            <KeyboardDoubleArrowRightIcon onClick={handleClose} />{' '}
          </motion.div>
        ) : (
          <motion.div>
            {' '}
            <KeyboardDoubleArrowLeftIcon onClick={handleDrawer} />{' '}
          </motion.div>
        )
      ) : (
        ''
      )}

      {tableState && tableData.length > 0 ? (
        <motion.div
          className="tableData"
          ref={container}
          style={{ width: '30%', marginLeft: '10px', display: 'block' }}
        >
          {tableData.map((tdata, i) => {
            return (
              <Table
                striped
                bordered
                hover
                style={{ overflowWrap: 'anywhere' }}
                key={i}
              >
                <tbody>
                  {tdata.label ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>label</td>
                      <td>{tdata.label}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.type ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>type</td>
                      <td>{tdata.type}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.schema ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>schema</td>
                      <td>{tdata.schema}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.description ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        description
                      </td>
                      <td>{tdata.description}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.source ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>Source</td>
                      <td>{tdata.source}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.extract_metadata ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        extract_metadata
                      </td>
                      <td>{tdata.extract_metadata}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.publish_mode ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        publish_mode
                      </td>
                      <td>{tdata.publish_mode}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.publish_type ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        publish_type
                      </td>
                      <td>{tdata.publish_type}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.modified_on ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        modified_on
                      </td>
                      <td>{Date(tdata.modified_on)}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.modified_by ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        modified_by
                      </td>
                      <td>{tdata.modified_by}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.created_by ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>created_by</td>
                      <td>{tdata.created_by}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.created_on ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>created_on</td>
                      <td>{Date(tdata.created_on)}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.dbt_project ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        dbt_project
                      </td>
                      <td>{tdata.dbt_project}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.dbt_schema ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>dbt_schema</td>
                      <td>{tdata.dbt_schema}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.source_escalation ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        source_escalation
                      </td>
                      <td>{tdata.source_escalation}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.data_type ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>data_type</td>
                      <td>{tdata.data_type}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.database ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>database</td>
                      <td>{tdata.database}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.file_path ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>file_path</td>
                      <td>{tdata.file_path}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.tags ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>tags</td>
                      <td>{tdata.tags}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.developer_emails ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        developer_emails
                      </td>
                      <td>{tdata.developer_emails}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.extract_mode ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>
                        extract_mode
                      </td>
                      <td>{tdata.extract_mode}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {tdata.columns ? (
                    <tr>
                      <td style={{ overflowWrap: 'break-word' }}>columns</td>
                      <td>{tdata.columns}</td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
            )
          })}
        </motion.div>
      ) : tableState && tableData.length == 0 ? (
        <div className="nonodes"> No nodes selected</div>
      ) : (
        ''
      )}
    </AnimatePresence>
  )
}

let IdType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])
let BuildingType = PropTypes.shape({
  id: IdType,
  label: PropTypes.string,
  value: PropTypes.number,
})

let ValueType = PropTypes.shape({
  from: IdType,
  to: IdType,
  type: PropTypes.string,
})

ValueGraph.propTypes = {
  nodes: PropTypes.arrayOf(BuildingType),
  edges: PropTypes.arrayOf(ValueType),
  options: PropTypes.object,
  onSelect: PropTypes.func,
}

export default ValueGraph
