import {
  GET_POST_BY_ID_DATA,
  GET_POST_BY_ID_LOAD,
  GET_POST_BY_ID_ERROR,
} from '../constants/types'

const initialState = {
  getPostbyidcollabData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_POST_BY_ID_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_POST_BY_ID_DATA:
      return {
        ...state,
        loading: false,
        getPostbyidcollabData: action.payload,
      }
    case GET_POST_BY_ID_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
