import { Box, Button, Modal } from '@mui/material'
import './EmailModal.scss'
import { useRef, useState } from 'react'
import columnsMap from '../../../../data/ColumnsMapping'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import help from '../../Utilities/fnf_helpers'
import { useAuthAndDispatch } from '../../../../hooks/useAuthAndDispatch'
import fAndF from '../../../../services/feesFinesServices'

function EmailModal({ orderDetails }) {
  const { dispatch, endpoint } = useAuthAndDispatch()
  // const fromMail = useRef()
  const toMail = useRef()
  const subjectField = useRef()
  const bodyField = useRef()
  const [open, setOpen] = useState(false)
  const subject = `PO No: ${orderDetails?.[columnsMap?.CUSTOMER_PO_NO]}`
  const message = `This is to request for evidence for PO No: ${
    orderDetails?.[columnsMap?.CUSTOMER_PO_NO]
  }`

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const sendEmail = () => {
    if (help.validateEmail(toMail.current.value)) {
      let payload = {
        to_email: toMail.current.value,
        subject: subjectField.current.value,
        body_data: bodyField.current.value,
      }
      fAndF.sendEmail(dispatch, endpoint, payload)
      setOpen(false)
    } else {
      alert('Please provide email')
    }
  }

  return (
    <>
      <Button onClick={handleOpen}>
        {' '}
        <FontAwesomeIcon icon={faEnvelope} title="Send Email" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="email-modal"
        aria-describedby="email modal for POD"
        data-testid="email_modal"
      >
        <Box className="emailpopup">
          {/* <div>
            <h5>From:</h5>
            <input type="email" ref={fromMail} placeholder="From Email" />
          </div> */}
          <div>
            <h5>To:</h5>
            <input
              data-testid="email_input"
              type="email"
              ref={toMail}
              placeholder="To Email"
            />
          </div>
          <div>
            <h5>Subject:</h5>
            <input
              defaultValue={subject}
              ref={subjectField}
              type="text"
              placeholder="Subject"
              data-testid="subject_input"
            />
          </div>
          <div>
            <h5>Body:</h5>
            <textarea
              defaultValue={message}
              ref={bodyField}
              placeholder="Enter Email Content"
              data-testid="body_input"
            />
          </div>
          <button data-testid="send_email_button" onClick={sendEmail}>
            Send Email
          </button>
        </Box>
      </Modal>
    </>
  )
}

export default EmailModal
