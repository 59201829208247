import {
  FILE_LOADING,
  FILE_IMPORT_ERROR,
  FILE_EXPORT_SUCCESS,
  FILE_EXPORT_ERROR,
  FILE_IMPORT_SUCCESS,
} from '../constants/types'

const initialState = {
  importFileData: [],
  exportData: [],
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FILE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FILE_IMPORT_SUCCESS:
      return {
        ...state,
        importFileData: action.payload,
        loading: false,
      }
    case FILE_IMPORT_ERROR:
      return {
        ...state,
        loading: false,
      }
    case FILE_EXPORT_SUCCESS:
      return {
        ...state,
        exportData: action.payload,
        loading: false,
      }
    case FILE_EXPORT_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
