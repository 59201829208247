import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../components/Helper/ToastObjects'
export const getSnowflakeTableData = (endpoint, params) => async (dispatch) => {
  dispatch(readSnowflakeTableDataBegin())
  try {
    await axios
      .get(`${endpoint}/api/snowflake_clone/read_object`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch(readSnowflakeTableDataSuccess(res.data))
        if (res.data.data.length === 0) {
          toast('No records found!', toastTopRightError)
        }
      })
  } catch (e) {
    dispatch(readSnowflakeTableDataFailure())
  }
}
// export const handleCloneData = (endpoint, details) => async (dispatch) => {
//   dispatch(handleSnowflakeTableDataBegin());
//   try {
//     await axios
//       .post(`${endpoint}/api/snowflake_clone/clone_to_env_commit?database_name=${details.databaseName}&schema_name=${details.schemaName}&table_name=${details.tableName}&env=${details.env.toUpperCase()}&key=${details.key}`,[], {

//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       })
//       .then((res) => {
//         dispatch(handleSnowflakeTableDataSuccess(res.data))
//         return res.data

//       });
//   } catch (e) {
//     dispatch(handleSnowflakeTableDataFailure());
//   }
// };
// export const handleUpdateCloneData = (endpoint, editUri, payload) => async (dispatch) => {
//   dispatch(handleUpdateSnowflakeTableDataBegin());
//   try {
//     await axios
//       .put(`${editUri}`,payload, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       })
//       .then((res) => {
//         dispatch(handleUpdateSnowflakeTableDataSuccess(res.data))
//         return res.data

//       });
//   } catch (e) {
//     dispatch(handleUpdateSnowflakeTableDataFailure());
//   }
// };

export const READ_SNOWFLAKE_TABLE_DATA_BEGIN = 'READ_SNOWFLAKE_TABLE_DATA_BEGIN'
export const READ_SNOWFLAKE_TABLE_DATA_SUCCESS =
  'READ_SNOWFLAKE_TABLE_DATA_SUCCESS'
export const READ_SNOWFLAKE_TABLE_DATA_FAIL = 'READ_SNOWFLAKE_TABLE_DATA_FAIL'
// export const HANDLE_SNOWFLAKE_TABLE_DATA_BEGIN = "HANDLE_SNOWFLAKE_TABLE_DATA_BEGIN";
// export const HANDLE_SNOWFLAKE_TABLE_DATA_SUCCESS = "HANDLE_SNOWFLAKE_TABLE_DATA_SUCCESS";
// export const HANDLE_SNOWFLAKE_TABLE_DATA_FAIL = "HANDLE_SNOWFLAKE_TABLE_DATA_FAIL";
// export const HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_BEGIN = "HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_BEGIN";
// export const HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_SUCCESS = "HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_SUCCESS";
// export const HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_FAIL = "HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_FAIL";

const readSnowflakeTableDataBegin = () => ({
  type: READ_SNOWFLAKE_TABLE_DATA_BEGIN,
})

const readSnowflakeTableDataSuccess = (readData) => ({
  type: READ_SNOWFLAKE_TABLE_DATA_SUCCESS,
  payload: { readData },
})

const readSnowflakeTableDataFailure = (error) => ({
  type: READ_SNOWFLAKE_TABLE_DATA_FAIL,
  payload: { error },
})
// const handleSnowflakeTableDataBegin = () => ({
//   type: HANDLE_SNOWFLAKE_TABLE_DATA_BEGIN,
// });

// const handleSnowflakeTableDataSuccess = (readData) => ({
//   type: HANDLE_SNOWFLAKE_TABLE_DATA_SUCCESS,
//   payload: { readData },
// });

// const handleSnowflakeTableDataFailure = (error) => ({
//   type: HANDLE_SNOWFLAKE_TABLE_DATA_FAIL,
//   payload: { error },
// });

// const handleUpdateSnowflakeTableDataBegin = () => ({
//   type: HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_BEGIN,
// });

// const handleUpdateSnowflakeTableDataSuccess = (readData) => ({
//   type: HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_SUCCESS,
//   payload: { readData },
// });

// const handleUpdateSnowflakeTableDataFailure = (error) => ({
//   type: HANDLE_UPDATE_SNOWFLAKE_TABLE_DATA_FAIL,
//   payload: { error },
// });
