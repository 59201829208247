import {
  EDIT_REF_TABLE_DATA,
  EDIT_REF_TABLE_DATA_LOADING,
  EDIT_REF_TABLE_DATA_ERROR,
  EDIT_RESET_STATE,
} from '../constants/types'
const initialState = {
  editRefTable: [],
  loading: true,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_REF_TABLE_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case EDIT_REF_TABLE_DATA:
      return {
        ...state,
        editRefTable: action.payload,
        loading: false,
      }
    case EDIT_REF_TABLE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case EDIT_RESET_STATE:
      return {
        editRefTable: [],
        loading: true,
        error: null,
      }
    default:
      return state
  }
}
