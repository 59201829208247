import {
  GET_REF_TABLES_DATA,
  ADD_REF_TABLE_DATA_LOADING,
  ADD_REF_TABLE_DATA,
  DELETE_REF_TABLES_DATA,
  GET_REF_TABLES_DATA_LOADING,
  CLEAR_STATE,
  GET_ADD_ROW_ERROR,
  GET_REF_TABLES_DATA_ERROR,
} from '../constants/types'
const initialState = {
  refTableData: [],
  loading: true,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REF_TABLES_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_REF_TABLES_DATA:
      return {
        ...state,
        refTableData: action.payload,
        loading: false,
      }
    case DELETE_REF_TABLES_DATA:
      return {
        ...state,
        loading: false,
      }
    case ADD_REF_TABLE_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ADD_REF_TABLE_DATA:
      return {
        ...state,
        loading: false,
      }
    case GET_ADD_ROW_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_REF_TABLES_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case CLEAR_STATE:
      return initialState
    default:
      return state
  }
}
