import {
  FILE_LOADING,
  FILE_IMPORT_ERROR,
  FILE_EXPORT_SUCCESS,
  FILE_EXPORT_ERROR,
  FILE_IMPORT_SUCCESS,
} from '../constants/types'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
export const importFile = (endpoint, uri, payload) => async (dispatch) => {
  dispatch({
    type: FILE_LOADING,
  })
  try {
    let post_headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    await axios
      .post(`${endpoint}/api/ref_data/bulk_import?table_name=${uri}`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: FILE_IMPORT_SUCCESS,
          payload: res.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: FILE_IMPORT_ERROR,
          payload: error.message,
        })
      })
  } catch (e) {
    dispatch({
      type: FILE_IMPORT_ERROR,
      payload: console.log(e),
    })
  }
}

export const exportFile =
  (endpoint, payload, params, table_name, isFnf = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FILE_LOADING,
      })
      let post_headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      await axios
        .post(
          `${endpoint}/api/ref_data/bulk_export?table_name=${table_name}&isFnf=${isFnf}`,
          payload,
          {
            params: params,
            headers: post_headers,
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${table_name}.xlsx`)
          document.body.appendChild(link)
          link.click()
          toast('Export Successfully', toastTopRightSuccess)
          dispatch({
            type: FILE_EXPORT_SUCCESS,
            payload: response.data,
          })
        })
        .catch((e) => {
          if (e.response.status == 400) {
            toast(e.response.statusText, toastTopRightError)
            dispatch({
              type: FILE_EXPORT_ERROR,
              payload: e.response.statusText,
            })
          }
          toast(e, toastTopRightError)
        })
    } catch (e) {
      dispatch({
        type: FILE_EXPORT_ERROR,
        payload: console.log(e),
      })
      toast(e, toastTopRightError)
    }
  }
