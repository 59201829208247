import {
  GET_TEMPLATE_LIST_ERROR,
  GET_TEMPLATE_LIST_LOAD,
  GET_TEMPLATE_LIST_POST,
} from '../../../constants/types'

const initialState = {
  getTemplateList: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEMPLATE_LIST_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_TEMPLATE_LIST_POST:
      return {
        ...state,
        loading: false,
        getTemplateList: action.payload,
      }
    case GET_TEMPLATE_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
