import * as React from 'react'
import WorldMap from 'react-svg-worldmap'
import './App.css'

function createTextLabels(width) {
  const labels = [
    { label: 'Atlantic', x: 0.37 * width, y: 0.39 * width },
    { label: 'Indian', x: 0.69 * width, y: 0.57 * width },
    { label: 'Pacific', x: 0.083 * width, y: 0.48 * width },
    {
      label: 'Arctic',
      x: 0.75 * width,
      y: 0.058 * width,
    },
  ]
  if (width < 550) {
    return labels.map((label) => ({
      ...label,
      style: { ...label.style, fontSize: '70%' },
    }))
  }
  return labels
}
const getStyle = ({
  countryValue,
  //   countryCode,
  minValue,
  maxValue,
  //   color,
}) => ({
  fill: 'red',
  fillOpacity: countryValue
    ? 0.1 + (5.5 * (countryValue - minValue)) / (maxValue - minValue)
    : 0,
  stroke: 'black',
  strokeWidth: 1,
  strokeOpacity: 0.2,
  cursor: 'pointer',
  align: 'center',
})

export default function Map(data) {
  return (
    <>
      {data?.data && data?.data?.length > 0 ? (
        <div className="badge-map" style={{ border: '1px solid #ddd' }}>
          <WorldMap
            tooltipBgColor="#000"
            valueSuffix="employees"
            data={data?.data}
            size="md"
            styleFunction={getStyle}
            richInteraction
            textLabelFunction={createTextLabels}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
