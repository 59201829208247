import './GridRules.scss'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import GridColumnMapping from '../../../../data/GridColumnMapping.js'
import gridColumns from '../../../../data/GridData.js'
import { useSelector } from 'react-redux'
import help from '../../Utilities/fnf_helpers.js'
import { exportFile } from '../../../../redux/actions/importExportFileAction.js'
import jwt_decode from 'jwt-decode'
import { useAuthAndDispatch } from '../../../../hooks/useAuthAndDispatch.js'
import fAndF from '../../../../services/feesFinesServices.js'
import FnFTable from '../Steps/components/FnFTable/FnFTable.js'

function GridRules() {
  const { retailer } = useParams()
  const { dispatch, endpoint } = useAuthAndDispatch()
  const storeData = useSelector((state) => state)
  const navigate = useNavigate()
  const [canEditRules, setCanEditRules] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedCustomer, setSelectedCustomer] = useState('ALL')
  const gridData = storeData?.fnf?.gridRulesData?.data || []
  const totalRulesCount = storeData?.fnf?.gridRulesData?.count || 0
  const dropDownData = storeData?.fnf?.gridUniqueValuesData?.data || []
  const [isExporting, setIsExporting] = useState(false)
  const tokenValue = jwt_decode(localStorage.getItem('token'))
  const requiredWriteRole = 'khdapi.KHC_FeesFines_Group_Writer'
  const requiredReadRole = 'khdapi.KHC_FeesFines_Group_Read'
  const requiredAdminRole = 'khdapi.Admin'
  // const requiredReadGroup = 'KHC_FeesFines_ReadGroup'

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const openRule = (rule) => {
    const khuid = rule?.[GridColumnMapping.KH_UID.key]
    if (khuid) {
      navigate(`/gridRule/${khuid}`, { state: rule })
    }
  }

  const exportData = () => {
    const params = {
      filters: JSON.stringify([]),
      sort: JSON.stringify([]),
      show_deleted: false,
    }
    const payload = Object.keys(gridData?.[0] || [])
    setIsExporting(true)
    dispatch(exportFile(endpoint, payload, params, 'GRID_RULES', true)).finally(
      () => {
        setIsExporting(false)
      }
    )
  }

  const fetchGridRules = async () => {
    let distinctCustomer = ''
    if (selectedCustomer === 'ALL') {
      if (retailer) {
        distinctCustomer = retailer
      }
    } else {
      distinctCustomer = selectedCustomer
    }
    const filters =
      distinctCustomer !== ''
        ? help.prepareLikeFilter([
            {
              value: distinctCustomer,
              field: GridColumnMapping.CUSTOMER.key,
            },
            {
              value: false,
              field: GridColumnMapping.KH_ETL_IS_DELETED.key,
            },
          ])
        : help.prepareLikeFilter([
            {
              value: false,
              field: GridColumnMapping.KH_ETL_IS_DELETED.key,
            },
          ])
    const payload = {
      filters: filters,
      limit: `${rowsPerPage}`,
      page: `${page}`,
    }
    fAndF.getGridRules(dispatch, endpoint, payload)
  }

  const fetchGridUniqueValues = async () => {
    fAndF.getGridUniqueValues(dispatch, endpoint)
  }

  const navigateToSteps = () => {
    navigate(`/steps`)
  }

  useEffect(() => {
    if (tokenValue?.['roles']?.indexOf(requiredReadRole) >= 0) {
      fetchGridUniqueValues()
    }
  }, [])

  useEffect(() => {
    if (
      tokenValue?.['roles']?.indexOf(requiredWriteRole) >= 0 ||
      tokenValue?.['roles']?.indexOf(requiredAdminRole) >= 0
    ) {
      setCanEditRules(true)
    }
    if (tokenValue?.['roles']?.indexOf(requiredReadRole) >= 0) {
      fetchGridRules()
    }
  }, [selectedCustomer, rowsPerPage, page])

  const addRuleTapped = () => {
    navigate(`/gridRule/new`)
  }

  if (
    tokenValue?.['roles']?.indexOf(requiredReadRole) < 0 &&
    tokenValue?.['roles']?.indexOf(requiredAdminRole) < 0
  ) {
    return (
      <div className="fnf noaccess" data-testid="noaccess">
        {/* <h4>
          You are not authorized to access Fees & Fines,<br></br> You need to be
          a member of <span>{requiredReadGroup}</span> AD Group to access Fees &
          Fines
        </h4> */}
      </div>
    )
  }

  return (
    <div className="fnf grid">
      <div className="filterrow">
        <div className="eachfilter">
          <label>RETAILER</label>
          <select
            data-testid="retailer_dropdown"
            onChange={(e) => setSelectedCustomer(e.target.value)}
            value={selectedCustomer}
          >
            <option>ALL</option>
            {dropDownData?.customers?.map?.((cust, index) => {
              return <option key={index}>{cust}</option>
            })}
          </select>
          <button onClick={exportData}>Export</button>
        </div>
        <div className="eachfilter">
          {canEditRules && <button onClick={addRuleTapped}>Add Rule</button>}
          <button onClick={navigateToSteps}>Steps</button>
        </div>
      </div>
      <div className="bar1">
        <h4>
          GRID RULES{' '}
          <span data-testid="gridrules_heading">({gridData?.length})</span>
        </h4>
      </div>
      <div data-testid="test_report_table">
        <FnFTable
          columns={gridColumns}
          tableData={gridData}
          viewClicked={(row) => {
            openRule(row)
          }}
          availableDataCount={totalRulesCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isExporting={isExporting}
          isLoading={storeData?.fnf?.loading}
          tableType="steps"
        />
      </div>
    </div>
  )
}

export default GridRules
