import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
// import Navbar from '../../Navbar'
import NpsMenu from './chapterNpsMenu'
// import HelpContent from './helpContent'
import Sidebar from '../AppBar/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import { AuthContext } from '../../context/authProvider'

export default function index() {
  const [authCreds] = useContext(AuthContext)

  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = React.useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const dispatch = useDispatch()
  let history = useNavigate()

  const endpoint = authCreds.restEndpoint
  // const [loadingStatus, setLoadingStatus] = useState({
  //   status: true,
  //   message: 'Fetching table records...',
  // })
  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=COLLAB`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  const InternalSurvey = () => {
    localStorage.setItem('surveyType', 'internal')
    history('/nps-survey/create-chapter-survey')
  }
  const ExternalSurvey = () => {
    localStorage.setItem('surveyType', 'external')
    history('/nps-survey/create-chapter-survey')
  }
  return (
    <>
    <div>
          <Sidebar
            Page="NPS"
            setChannel={setChannel}
            channel={channel}
            collabMetadata={collabMetadata}
            Index={
              <>
              <NpsMenu />
              <div className="home-nps-chapter">
                <div>
                <h2 className='sub-head-nps'>Please choose survey type</h2>
                {/* <Link to="/nps-survey/create-chapter-survey"> */}
                  <Button variant="primary" style={{ width: '300px', margin: '8px' }} onClick={InternalSurvey}>
                    Internal
                  </Button>{' '}
                {/* </Link> */}
                {/* <Link to="/nps-survey/create-chapter-survey"> */}
                  <Button variant="secondary" style={{ width: '300px', margin: '8px' }} onClick={ExternalSurvey}>
                    External
                  </Button>
                {/* </Link> */}
                </div>
              </div>
              </>
            }
          />
        </div>
      {/* <Navbar title="NPS" HelpContent={HelpContent} /> */}
    
    </>
  )
}
