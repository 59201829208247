import React, { useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from '../../context/authProvider'
// import { toastTopRightError } from '../../Helper/ToastObjects'
// import { toast } from 'react-toastify'
import { recoverPod } from '../../../redux/actions/podsPageActions/podsAction'
import { useDispatch } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import Alert from '@mui/material/Alert'

const RecoverPod = ({
  setShowRecoverPod,
  fetchData,
  pageSize,
  pageIndex,
  podRecoverId,
}) => {
  const [authCreds] = useContext(AuthContext)
  const dispatch = useDispatch()

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const endpoint = authCreds.restEndpoint

  const handleClose = () => setShowRecoverPod(false)

  const handleRecoverPod = async () => {
    dispatch(recoverPod(endpoint, { pod_id: podRecoverId })).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      handleClose()
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'status', value: `Approved` }])
      )
    })
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Modal.Body>
            <Alert severity="info">
              A POD with this name <b>already exists in deleted state</b>. Do
              you wish to recover the pod? After recover is clicked a pod create
              request will be submitted.
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="addModalBtn"
              type="submit"
              onClick={handleRecoverPod}
            >
              Recover
            </Button>
            <Button
              variant="danger"
              type="submit"
              className="addModalBtn"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  )
}

export default RecoverPod
