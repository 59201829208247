import {
  GET_PODS,
  GET_PODS_ERROR,
  GET_PODS_LOADING,
  GET_PODS_TABLE,
  GET_PODS_TABLE_LOADING,
  GET_PODS_TABLE_ERROR,
  ADD_POD_ENTRY,
  ADD_POD_ENTRY_LOADING,
  ADD_POD_ENTRY_ERROR,
  EDIT_POD_ENTRY,
  EDIT_POD_ENTRY_LOADING,
  EDIT_POD_ENTRY_ERROR,
  MY_PODS,
  MY_PODS_ERROR,
  MY_PODS_LOADING,
  EDIT_POD_APPROVAL,
  EDIT_POD_APPROVAL_ERROR,
  EDIT_POD_APPROVAL_LOADING,
  GET_POD_APPROVERS,
  GET_POD_APPROVERS_ERROR,
  GET_POD_APPROVERS_LOADING,
  GET_POD_BY_ID,
  GET_POD_BY_ID_ERROR,
  GET_POD_BY_ID_LOADING,
  DELETE_POD,
  DELETE_POD_ERROR,
  DELETE_POD_LOADING,
  POD_DETAILS,
  POD_DETAILS_ERROR,
  POD_DETAILS_LOADING,
  RECOVER_POD,
  RECOVER_POD_LOADING,
  RECOVER_POD_ERROR,
} from '../../constants/types'

const initialState = {
  podsMenuData: [],
  podsData: [],
  myPodsData: [],
  addPods: [],
  editPods: [],
  podApprove: [],
  podApprovers: [],
  podById: [],
  podDelete: [],
  podDetails: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PODS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_PODS:
      return {
        ...state,
        loading: false,
        podsMenuData: action.payload,
      }
    case GET_PODS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_PODS_TABLE_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_PODS_TABLE:
      return {
        ...state,
        loading: false,
        podsData: action.payload,
      }
    case GET_PODS_TABLE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case ADD_POD_ENTRY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ADD_POD_ENTRY:
      return {
        ...state,
        loading: false,
        addPods: action.payload,
      }
    case ADD_POD_ENTRY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EDIT_POD_ENTRY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case EDIT_POD_ENTRY:
      return {
        ...state,
        loading: false,
        editPods: action.payload,
      }
    case EDIT_POD_ENTRY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case MY_PODS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case MY_PODS:
      return {
        ...state,
        loading: false,
        myPodsData: action.payload,
      }
    case MY_PODS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case EDIT_POD_APPROVAL_LOADING:
      return {
        ...state,
        loading: true,
      }
    case EDIT_POD_APPROVAL:
      return {
        ...state,
        loading: false,
        podApprove: action.payload,
      }
    case EDIT_POD_APPROVAL_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_POD_APPROVERS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_POD_APPROVERS:
      return {
        ...state,
        loading: false,
        podApprovers: action.payload,
      }
    case GET_POD_APPROVERS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_POD_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_POD_BY_ID:
      return {
        ...state,
        loading: false,
        podById: action.payload,
      }
    case GET_POD_BY_ID_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_POD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case DELETE_POD:
      return {
        ...state,
        loading: false,
        podDelete: action.payload,
      }
    case DELETE_POD_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POD_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case POD_DETAILS:
      return {
        ...state,
        loading: false,
        podDetails: action.payload,
      }
    case POD_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case RECOVER_POD_LOADING:
      return {
        ...state,
        loading: true,
      }
    case RECOVER_POD:
      return {
        ...state,
        loading: false,
        addPods: action.payload,
      }
    case RECOVER_POD_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
