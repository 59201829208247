import {
  GET_USER_KUDOS,
  GET_USER_KUDOS_LOADING,
  GET_USER_KUDOS_ERROR,
} from '../constants/types'

const initialState = {
  userKudos: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_KUDOS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_USER_KUDOS:
      return {
        ...state,
        userKudos: action.payload.data,
        loading: false,
        error: null,
      }
    case GET_USER_KUDOS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
