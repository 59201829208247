import help from '../components/pages/Utilities/fnf_helpers'
import columnsDescription from './ColumnsDescription'
import columnsMap from './ColumnsMapping'

const columns = [
  {
    id: 'ACTION',
    label: '',
    group: 'snowflake',
    description: 'Description for this parameter',
  },
  {
    id: columnsMap.INVOICE_NUMBER,
    label: 'Invoice No.',
    group: 'snowflake',
    description: columnsDescription.INVOICE_NUMBER_DESC,
  },
  {
    id: columnsMap.PO_DATE,
    label: 'PO Date',
    group: 'snowflake',
    description: columnsDescription.PO_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.CFR_MISSED,
    label: 'CFR Missed ?',
    group: 'snowflake',
    description: columnsDescription.CFR_MISSED_DESC,
  },
  {
    id: columnsMap.ORAD_MISSED,
    label: 'ORAD Missed',
    group: 'snowflake',
    description: columnsDescription.ORAD_MISSED_DESC,
  },
  {
    id: columnsMap.CFR_FINE_AMOUNT,
    label: 'CFR Fine Amount',
    group: 'snowflake',
    description: columnsDescription.CFR_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.ORAD_FINE_AMOUNT,
    label: 'ORAD Fine Amount',
    group: 'snowflake',
    description: columnsDescription.ORAD_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.PO_TYPE,
    label: 'PO Type',
    group: 'sap',
    description: columnsDescription.PO_TYPE_DESC,
  },
  {
    id: columnsMap.SO_TYPE,
    label: 'SO Type',
    group: 'sap',
    description: columnsDescription.SO_TYPE_DESC,
  },
  {
    id: columnsMap.REPORTED_ARRIVAL_DATE,
    label: 'Reported Arrival Date',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.REPORTED_CFR,
    label: 'Reported CFR %',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_CFR_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.REPORTED_FINE_TYPE,
    label: 'Reported Fine Type',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_FINE_TYPE_DESC,
  },
  {
    id: columnsMap.REPORTED_FINE_AMOUNT,
    label: 'Fine Amount',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CPU,
    label: 'CPU',
    group: 'snowflake',
    description: columnsDescription.CPU_DESC,
  },
  {
    id: columnsMap.CUSTOMER_PO_NO,
    label: 'PO No.',
    group: 'snowflake',
    description: columnsDescription.CUSTOMER_PO_NO_DESC,
  },
  {
    id: columnsMap.RETAILER_PO,
    label: 'Retailer PO No.',
    group: 'snowflake',
    description: columnsDescription.RETAILER_PO_DESC,
  },
  {
    id: columnsMap.RETAILER_NAME,
    label: 'Retailer',
    group: 'snowflake',
    description: columnsDescription.RETAILER_NAME_DESC,
  },
  {
    id: columnsMap.DC_NO_KH,
    label: 'KH DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_KH_DESC,
  },
  {
    id: columnsMap.DC_NO_RETAILER,
    label: 'Client DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_RETAILER_DESC,
  },

  {
    id: columnsMap.ES3_OR_NON_ES3,
    label: 'ES3/Non ES3',
    group: 'fourkites',
    description: columnsDescription.ES3_OR_NON_ES3_DESC,
  },
  {
    id: columnsMap.SHIPPING_DATE,
    label: 'Shipment Date',
    group: 'snowflake',
    description: columnsDescription.SHIPPING_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.ORAD,
    label: 'ORAD Date',
    group: 'snowflake',
    description: columnsDescription.ORAD_DESC,
    date: true,
  },
  {
    id: columnsMap.ACTUAL_ARRIVAL_DATE,
    label: 'Actual Arrival Date',
    group: 'fourkites',
    description: columnsDescription.ACTUAL_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.CFR,
    label: 'CFR',
    group: 'snowflake',
    description: columnsDescription.CFR_DESC,
    format: (value) => help.formatNumbers(value),
  },

  {
    id: columnsMap.STATUS,
    label: 'Status',
    group: 'snowflake',
    description: columnsDescription.STATUS_DESC,
  },
  {
    id: columnsMap.VALID_INVALID,
    label: 'Valid',
    group: 'snowflake',
    description: columnsDescription.VALID_INVALID_DESC,
  },
  {
    id: columnsMap.POSSIBLE_DISPUTE,
    label: 'Possible Dispute',
    group: 'snowflake',
    description: columnsDescription.POSSIBLE_DISPUTE_DESC,
  },
]

const columnsTarget = [
  {
    id: 'action',
    label: '',
    group: 'snowflake',
    description: '',
  },
  // {
  //     id: 'ACCOUNTING_PERIOD',
  //     label: 'Accounting Period',
  //     group: 'snowflake',
  //     description: 'Description for this parameter',
  // },
  {
    id: columnsMap.INVOICE_NUMBER,
    label: 'Invoice No.',
    group: 'snowflake',
    description: columnsDescription.INVOICE_NUMBER_DESC,
  },
  {
    id: columnsMap.PO_DATE,
    label: 'PO Date',
    group: 'snowflake',
    description: columnsDescription.PO_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.CFR_MISSED,
    label: 'CFR Missed ?',
    group: 'snowflake',
    description: columnsDescription.CFR_MISSED_DESC,
  },
  {
    id: columnsMap.ORAD_MISSED,
    label: 'ORAD Missed',
    group: 'snowflake',
    description: columnsDescription.ORAD_MISSED_DESC,
  },
  {
    id: columnsMap.CFR_FINE_AMOUNT,
    label: 'CFR Fine Amount',
    group: 'snowflake',
    description: columnsDescription.CFR_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.ORAD_FINE_AMOUNT,
    label: 'ORAD Fine Amount',
    group: 'snowflake',
    description: columnsDescription.ORAD_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.PO_TYPE,
    label: 'PO Type',
    group: 'sap',
    description: columnsDescription.PO_TYPE_DESC,
  },
  {
    id: columnsMap.SO_TYPE,
    label: 'SO Type',
    group: 'sap',
    description: columnsDescription.SO_TYPE_DESC,
  },
  {
    id: columnsMap.REPORTED_ARRIVAL_DATE,
    label: 'Reported Arrival Date',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.REPORTED_CFR,
    label: 'Reported CFR %',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_CFR_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.REPORTED_FINE_TYPE,
    label: 'Reported Fine Type',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_FINE_TYPE_DESC,
  },
  {
    id: columnsMap.REPORTED_FINE_AMOUNT,
    label: 'Fine Amount',
    group: 'levante_greenfield',
    description: columnsDescription.REPORTED_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CPU,
    label: 'CPU',
    group: 'snowflake',
    description: columnsDescription.CPU_DESC,
  },
  {
    id: columnsMap.CUSTOMER_PO_NO,
    label: 'PO No.',
    group: 'snowflake',
    description: columnsDescription.CUSTOMER_PO_NO_DESC,
  },
  {
    id: columnsMap.RETAILER_PO,
    label: 'Retailer PO No.',
    group: 'snowflake',
    description: columnsDescription.RETAILER_PO_DESC,
  },
  {
    id: columnsMap.RETAILER_NAME,
    label: 'Retailer',
    group: 'snowflake',
    description: columnsDescription.RETAILER_NAME_DESC,
  },
  {
    id: columnsMap.DC_NO_KH,
    label: 'KH DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_KH_DESC,
  },
  {
    id: columnsMap.DC_NO_RETAILER,
    label: 'Client DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_RETAILER_DESC,
  },

  {
    id: columnsMap.ES3_OR_NON_ES3,
    label: 'ES3/Non ES3',
    group: 'fourkites',
    description: columnsDescription.ES3_OR_NON_ES3_DESC,
  },
  {
    id: columnsMap.SHIPPING_DATE,
    label: 'Shipment Date',
    group: 'snowflake',
    description: columnsDescription.SHIPPING_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.ORAD,
    label: 'ORAD Date',
    group: 'snowflake',
    description: columnsDescription.ORAD_DESC,
    date: true,
  },
  {
    id: columnsMap.ACTUAL_ARRIVAL_DATE,
    label: 'Actual Arrival Date',
    group: 'fourkites',
    description: columnsDescription.ACTUAL_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.CFR,
    label: 'CFR',
    group: 'snowflake',
    description: columnsDescription.CFR_DESC,
    format: (value) => help.formatNumbers(value),
  },

  {
    id: columnsMap.STATUS,
    label: 'Status',
    group: 'snowflake',
    description: columnsDescription.STATUS_DESC,
  },
  {
    id: columnsMap.VALID_INVALID,
    label: 'Valid',
    group: 'snowflake',
    description: columnsDescription.VALID_INVALID_DESC,
  },
  {
    id: columnsMap.POSSIBLE_DISPUTE,
    label: 'Possible Dispute',
    group: 'snowflake',
    description: columnsDescription.POSSIBLE_DISPUTE_DESC,
  },
]

export { columns, columnsTarget }
