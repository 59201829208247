import React, { useEffect, useState, useMemo, useContext } from 'react'
import '../../RefData/RefTable.css'
import Sidebar from '../../AppBar/Appbar'

import '../indexChapter.css'
import './surveyGroup.css'
import NpsMenu from '../chapterNpsMenu'
import ManageUser from './manageGroupUsersChapter'
import LoadingStatus from '../../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { Table, Button, Col, Row } from 'react-bootstrap'

import { AuthContext } from '../../../context/authProvider'
import { TableHandler } from '../../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'

import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { MdHighlightOff, MdOutlineDone } from 'react-icons/md'
import { getSurveyGroup } from '../../../../redux/actions/npsActions/npsSurveyAction'
import { RoleHandler } from '../../Utilities/roleCheck'
import { getForumMetadata } from '../../../../redux/actions/forumMetadataAction'

export default function SurveyGroup() {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [editRows, setEditRows] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [groupNameUser, setGroupNameUser] = React.useState('')
  const [groupId, setGroupId] = React.useState('')
  const [columnSort, setColumnSortValue] = React.useState([])
  const [showModal, setshowModal] = React.useState(false)
  // const [createSurveryModal, setshowCreateSurveyModal] = React.useState(false)
  const [addusermodal, setshowUserModal] = React.useState(false)
  const [groupName, setGroupName] = React.useState('')
  const [checkBox, setCheckBox] = React.useState(false)
  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = React.useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const dispatch = useDispatch()

  const [authCreds] = useContext(AuthContext)

  const endpoint = authCreds.restEndpoint
  const getSurveyGroupData = useSelector((state) => state.getSurveyGroupData)
  const forumMetadata = useSelector((state) => state.forumMetadata)

  let tokenValue = localStorage?.getItem('token')
  const isAdmin = RoleHandler.checkAdminRole(tokenValue)
  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=COLLAB`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  const table_name = 'USER'
  const columnMetadata = {
    USER: {
      fields: [
        {
          name: 'group_name',
          headerTitle: 'SURVEY GROUP',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'total_users',
          headerTitle: 'USER COUNT',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'total_active_users',
          headerTitle: 'ACTIVE USER',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'total_inactive_users',
          headerTitle: 'INACTIVE USER',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
      ],
      tag: 'user',
    },
  }
  const hiddenColumns = {
    USER: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    dispatch(getSurveyGroup(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    let table_data = getSurveyGroupData?.getSurveyGroupData?.data
    if (table_data?.length > 0) {
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'group_name',
            'total_users',
            'total_active_users',
            'total_inactive_users',
          ],
          columnMetadata,
          table_name
        )
      )
      setTotalPages()
      setTotalRows()
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, getSurveyGroupData])

  // const handleShowCreateSurveyGroup = () => {
  //   setshowCreateSurveyModal(true)
  // }
  const handleShowUserModal = () => {
    setGroupNameUser(editRows[0].group_name)
    setGroupId(editRows[0].id)
    setshowUserModal(true)
  }
  const handleCloseModal = () => {
    setshowModal(false)
  }
  // const handleCloseSurveyModal = () => {
  //   setshowCreateSurveyModal(false)
  // }
  const handleCloseUserModal = () => {
    setshowUserModal(false)
    fetchData(
      { pageSize, pageIndex },
      JSON.stringify([{ op: 'eq', field: 'role_type', value: `${channel}` }])
    )
  }

  // const handleSubmit = async () => {
  //   setLoadingStatus({ status: true, message: 'Fetching table records...' })

  //   const payload = {
  //     group_name: groupName,
  //   }
  //   dispatch(createSurveyGroup(endpoint, payload))
  //     .then(() => {
  //       setLoadingStatus({
  //         status: false,
  //         message: 'Fetching table records...',
  //       })

  //       setshowCreateSurveyModal(false)

  //       fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
  //     })
  //     .catch(() => {
  //       setLoadingStatus({
  //         status: false,
  //         message: 'Fetching table records...',
  //       })
  //     })
  // }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="radio" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload =
          checkBox === false
            ? [{ op: 'eq', field: 'role_type', value: `${channel}` }]
            : [
                { op: 'eq', field: 'is_active', value: checkBox.toString() },
                { op: 'eq', field: 'role_type', value: `${channel}` },
              ]
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }
  const handleClickCheckbox = (e) => {
    if (e.target.checked) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }
  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  useEffect(() => {
    //   setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (checkBox === false) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'role_type', value: `${channel}` }]),
        JSON.stringify(columnSort)
      )
    } else if (checkBox === true) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([
          { op: 'eq', field: 'is_active', value: 'true' },
          { op: 'eq', field: 'role_type', value: `${channel}` },
        ]),
        JSON.stringify(columnSort)
      )
    } else {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'role_type', value: `${channel}` }]),
        // JSON.stringify(columnFilters),
        JSON.stringify(columnSort)
      )
    }
  }, [pageSize, pageIndex, columnSort, checkBox, channel])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="NPS"
            setChannel={setChannel}
            channel={channel}
            collabMetadata={collabMetadata}
            Index={
              <div style={{ background: '#fff', minHeight: '100vh' }}>
                <NpsMenu />
                <div
                  className="table-container"
                  style={{ textAlign: 'center' }}
                >
                  <Table {...getTableProps()}>
                    <thead className="sticky-table-header">
                      {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, i) =>
                            column?.id === 'selection' ? (
                              <th key={column.id}>
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                    { style: { width: column.size } }
                                  )}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['Header'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : column.id == 'is_active' ? (
                              <th key={i}>
                                <div className="table-header-badge">
                                  {column.render('Header')}
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    onClick={handleClickCheckbox}
                                    checked={checkBox}
                                  />
                                </div>
                              </th>
                            ) : (
                              <th key={i}>
                                <div
                                  onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['id'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="tbody npsAdminBody"
                      {...getTableBodyProps()}
                    >
                      {page.map((row, index) => {
                        prepareRow(row)
                        return (
                          <tr key={index}>
                            {row.cells.map((cell) => {
                              return cell.column.id === 'is_active' ? (
                                cell.value == true ? (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    <MdOutlineDone
                                      style={{
                                        fontSize: '25px',
                                        color: 'green',
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    <MdHighlightOff
                                      style={{
                                        fontSize: '25px',
                                        color: 'red',
                                      }}
                                    />
                                  </td>
                                )
                              ) : (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  {cell.render('Cell')}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                {/* Bottom ToolBar of table */}
                <Row>
                  <Col sm={6}>
                    <div className="page-control">
                      <div className="page-of">
                        Page{' '}
                        <em>
                          {pageIndex + 1} of {totalPages}
                        </em>
                      </div>
                      <div className="prev-next-btn">
                        <button
                          className="badge-btn"
                          onClick={() => onPrevious()}
                          disabled={!canPreviousPage}
                        >
                          {' '}
                          Prev{' '}
                        </button>
                        <button
                          onClick={() => onNext()}
                          className="badge-btn"
                          disabled={!canNextPage}
                        >
                          {' '}
                          Next{' '}
                        </button>
                      </div>
                      <div className="second-control">
                        <span>Go to page:</span>
                        <input
                          className="page-number-input"
                          type="number"
                          defaultValue={pageIndex || 1}
                          onBlur={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value)
                              : 0
                            // handling zero indexing
                            gotoPage(page - 1)
                          }}
                        />
                        <select
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {available_page_size.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          marginLeft: '24px',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        {' '}
                        Total results:{' '}
                        {getSurveyGroupData?.getSurveyGroupData?.total_results}
                      </div>
                    </div>
                  </Col>
                  {/* Bottom-right Buttons */}
                  <Col sm={6}>
                    {(editRows.length > 0 && editRows[0].is_owner) ||
                    (editRows.length > 0 && isAdmin) ? (
                      <>
                        <Button
                          className="m_r-5 = main-button btn-sm"
                          onClick={handleShowUserModal}
                          style={{ marginTop: '10px' }}
                        >
                          Manage users
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* <Button
                  className="m_r-5 main-button btn-sm"
                  style={{ marginTop: '10px' }}
                  onClick={handleShowCreateSurveyGroup}
                >
                  Create Survey Group
                </Button> */}
                  </Col>
                </Row>
                {/* <Modal
              size="xl"
              show={createSurveryModal}
              onHide={handleCloseSurveyModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create Survey Group</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modalBody">
                <>
                  <Form>
                    <Container className="m-0">
                      <Row
                        className="mr-3 rowFlex"
                        style={{
                          marginBottom: '4rem',
                          justifyContent: 'center',
                        }}
                      >
                        <Form.Group
                          className=""
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                        >
                          <Form.Label>Add Survey Group Name</Form.Label>
                          <InputGroup style={{ width: '100% !important' }}>
                            <Form.Control
                              type="text"
                              aria-describedby="inputGroupPrepend"
                              name="user_name"
                              className="surveyGroupNameInput"
                              // defaultValue={userName}
                              onChange={(e) => setGroupName(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                    </Container>
                  </Form>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      type="submit"
                      className="addModalBtn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="danger"
                      type="submit"
                      className="addModalBtn"
                      onClick={handleCloseSurveyModal}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </>
              </Modal.Body>
            </Modal> */}
                <Modal
                  size="xl"
                  show={addusermodal}
                  className="group-nps-modal"
                  onHide={handleCloseUserModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Manage users</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ overflowY: 'scroll' }}>
                    <>
                      <Form>
                        <Row
                          className="rowFlex"
                          style={{ marginBottom: '20px' }}
                        >
                          <Form.Group
                            className=""
                            as={Col}
                            controlId="validationFormik01"
                          >
                            <ManageUser
                              groupNameUser={groupNameUser}
                              groupId={groupId}
                            />
                          </Form.Group>
                        </Row>
                      </Form>
                      <Modal.Footer>
                        <Button
                          variant="danger"
                          type="submit"
                          className="addModalBtn"
                          onClick={handleCloseUserModal}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </>
                  </Modal.Body>
                </Modal>
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Delete user</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ overflowY: 'hidden' }}>
                    Are you sure to delete this entry ?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                    <Button variant="primary">Confirm</Button>
                  </Modal.Footer>
                </Modal>
              </div>
            }
          />
        </>
      )}
    </>
  )
}
