import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
// import Slide from '@mui/material/Slide'
import SubmitRequest from './submitRequest'
import Modal from 'react-bootstrap/Modal'
import LoadingStatus from '../../Helper/LoadingStatus'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useLocation } from 'react-router-dom'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export default function podNavbar({
  ifMyPods,
  setIfMyPods,
  requestStatus,
  setRequestStatus,
}) {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const location = useLocation()
  const route = location.pathname.split('/')[2]

  // Submit Request modal states and functions
  const [showModal, setShowModal] = React.useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleCheckMyPods = (e) => {
    setIfMyPods(e.currentTarget.checked)
  }

  return (
    <>
      <div className="nav-menu" style={{ display: 'flex', paddingTop: '8px' }}>
        <Link to="/pods">
          <Button
            className={
              localStorage.getItem('menu') == 'pods'
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
            }
          >
            Home
          </Button>
        </Link>
        <Link to="/pods/pending-request">
          {' '}
          <Button
            className={
              localStorage.getItem('menu') == 'pods-pending-request'
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
            }
          >
            {' '}
            Pending Requests
          </Button>
        </Link>
        {localStorage.getItem('menu') == 'pods' ? (
          <div style={{ marginLeft: '10px', marginTop: '5px' }}>
            <Box sx={{ minWidth: 120 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={ifMyPods}
                      onChange={handleCheckMyPods}
                      sx={{
                        paddingRight: '8px',
                        letterSpacing: '1px',
                      }}
                    />
                  }
                  label="My PODs"
                />
              </FormGroup>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {route == 'pending-request' ? (
          <div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Request type
                </InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={requestStatus}
                  label="badge-name"
                  onChange={(e) => setRequestStatus(e.target.value)}
                >
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                  <MenuItem key="edit" value="edit">
                    Edit
                  </MenuItem>
                  <MenuItem key="create" value="create">
                    Create
                  </MenuItem>
                  <MenuItem key="rejected" value="rejected">
                    Rejected
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </div>
      {loadingStatus.status == true ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Modal size="xl" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Submit Request</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
              <SubmitRequest
                setShowModal={setShowModal}
                setLoadingStatus={setLoadingStatus}
                // channel={channel}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
