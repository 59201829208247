import jwt_decode from 'jwt-decode'

class RoleHandler {
  static checkAdminRole = (token) => {
    try {
      const value = jwt_decode(token)
      if (value?.roles?.includes('khdapi.Admin')) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }
  static checkCollabAPIAdminRole = (token) => {
    try {
      const tokenValue = jwt_decode(token)
      if (
        tokenValue?.roles?.includes('khdapi.Admin') ||
        tokenValue?.roles?.includes('khdapi.collabadmin')
      ) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }
}

export { RoleHandler }
