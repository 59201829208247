import jwt_decode from 'jwt-decode'

export const checkAdmin = (token) => {
  try {
    const value = jwt_decode(token)
    if (
      value?.roles?.includes('khdapi.Admin') ||
      value?.preferred_username?.toLowerCase() === 'grant.culp@kraftheinz.com'
    ) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}
