import React, { useEffect, useMemo, useContext, useState } from 'react'
import '../RefData/RefTable.css'
import './pods.css'
import jwt_decode from 'jwt-decode'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import {
  Table,
  Button,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'
import '../Badge/index.css'
import Modal from 'react-bootstrap/Modal'
import 'react-toastify/dist/ReactToastify.css'
import Sidebar from '../AppBar/Appbar'
import { MdHighlightOff, MdOutlineDone } from 'react-icons/md'
import Nav from '../Badge/podNavbar'
import {
  getPodsPendingRequests,
  getPodApprovers,
} from '../../../redux/actions/podsPageActions/podsAction'
import { toastTopRightError } from '../../Helper/ToastObjects'
import { toast } from 'react-toastify'
import EditPods from './editPod'
import PodRequestDetails from './podRequestDetails'

function Pods() {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

  const [authCreds] = useContext(AuthContext)
  const [editRows, setEditRows] = React.useState([])
  //   const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([
    { op: 'eq', field: 'status', value: `Pending` },
    { op: 'eq', field: 'status', value: `Rejected` },
  ])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [channel, setChannel] = React.useState(localStorage.getItem('channel'))
  const [checkBox, setCheckBox] = React.useState(false)
  const [showPodDetails, setShowPodDetails] = React.useState(false)
  const [podDetails, setPodDetails] = React.useState('')
  const [isSort, setSort] = React.useState(false)
  const [editorContentBuilder, setEditorContentBuilder] = useState('')
  const [editorContentStakeholder, setEditorContentStakeholder] = useState('')
  const [editorContentProductOwner, setEditorContentProductOwner] = useState('')

  const [podName, setPodName] = React.useState('')
  const [functionalArea, setFunctionalArea] = React.useState('')
  const [description, setDescripiton] = React.useState('')
  const [requestStatus, setRequestStatus] = React.useState('all')
  const [showRequestDetails, setShowRequestDetails] = React.useState(false)

  const podsData = useSelector((state) => state.podsData)
  const podApprovers = useSelector((state) => state.podApprovers)

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  const table_name = 'PODS'

  const columnMetadata = {
    PODS: {
      fields: [
        {
          name: 'pod_name',
          headerTitle: 'POD NAME',
          is_editable: true,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'description',
          headerTitle: 'DESCRIPTION',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'functional_area',
          headerTitle: 'FUNCTIONAL AREA',
          is_editable: true,
          is_filterable: true,
          size: 17,
        },
        {
          name: 'pod_id',
          headerTitle: 'POD ID',
          is_editable: false,
          is_filterable: false,
          size: 5,
        },
        {
          name: 'created_on',
          headerTitle: 'CREATED ON',
          is_editable: false,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'builder_count',
          headerTitle: 'BUILDER COUNT',
          is_editable: false,
          is_filterable: false,
          size: 20,
        },
        {
          name: 'product_owner_count',
          headerTitle: 'PRODUCT OWNER COUNT',
          is_editable: false,
          is_filterable: false,
          size: 5,
        },
        {
          name: 'stakeholder_count',
          headerTitle: 'STAKEHOLDER COUNT',
          is_editable: false,
          is_filterable: false,
          size: 5,
        },
        {
          name: 'is_active',
          headerTitle: 'IS ACTIVE',
          is_editable: false,
          is_filterable: true,
          size: 17,
        },
        {
          name: 'status',
          headerTitle: 'STATUS',
          is_editable: false,
          is_filterable: false,
          size: 5,
        },
        {
          name: 'comments',
          headerTitle: 'COMMENTS',
          is_editable: false,
          is_filterable: false,
          size: 5,
        },
        {
          name: 'created_by',
          headerTitle: 'CREATED BY',
          is_editable: false,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'modified_on',
          headerTitle: 'MODIFIED ON',
          is_editable: false,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'modified_by',
          headerTitle: 'MODIFIED BY',
          is_editable: false,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'approved_by',
          headerTitle: 'APPROVED BY',
          is_editable: false,
          is_filterable: true,
          size: 5,
        },
        {
          name: 'approved_on',
          headerTitle: 'APPROVED ON',
          is_editable: false,
          is_filterable: true,
          size: 5,
        },
      ],
      tag: 'pods',
    },
  }
  const hiddenColumns = {
    PODS: [
      'id',
      'pod_id',
      'is_active',
      'modified_on',
      'modified_by',
      'approved_on',
      'approved_by',
      'created_on',
      'created_by',
    ],
  }
  const [showAddPodMember, setShowAddPodMember] = React.useState(false)
  const handleCloseAddPodMember = () => {
    setShowAddPodMember(false)
  }

  const OpenEditPod = () => {
    if (editRows.length > 0) {
      setShowAddPodMember(true)
    } else {
      toast('No records selected', toastTopRightError)
    }
  }

  const openRequestDetails = () => {
    if (editRows.length > 0) {
      setShowRequestDetails(true)
    } else {
      toast('No records selected', toastTopRightError)
    }
  }

  const handleCloseRequestDetails = () => {
    setShowRequestDetails(false)
  }

  const handleClickCheckbox = (e) => {
    if (e.target.checked) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }

  const handleOpen = (cc) => {
    setPodDetails(cc.original)
  }

  useEffect(() => {
    localStorage.setItem('menu', 'pods-pending-request')
  }),
    []

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    let ifShowMyPods = true
    let is_admin = false

    if (tokenValue?.roles?.includes('khdapi.Admin')) {
      ifShowMyPods = false
      is_admin = true
    }

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
      is_my_pods: ifShowMyPods,
      user_email: tokenValue.preferred_username.toLowerCase(),
    }
    dispatch(
      getPodsPendingRequests(
        endpoint,
        params,
        tokenValue?.preferred_username?.toLowerCase(),
        is_admin
      )
    )
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  useEffect(() => {
    if (podsData?.loading === false) {
      let table_data = podsData?.podsData?.data
      if (table_data?.length > 0) {
        setTableColumns(
          TableHandler.createColumnMappingforKholab(
            [
              'modified_on',
              'modified_by',
              'approved_on',
              'approved_by',
              'description',
              'is_active',
              'functional_area',
              'pod_name',
              'status',
              'created_on',
              'created_by',
              'stakeholder_count',
              'builder_count',
              'product_owner_count',
            ],
            columnMetadata,
            table_name
          )
        )
        setTotalPages(podsData?.podsData?.num_pages)
        setTotalRows(podsData?.podsData?.total_results)
        setTableData(table_data)
      } else {
        setTotalPages(0)
        setTotalRows(0)
        setTableData([])
      }
    }
  }, [podsData, setTableData])

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()

      let timeoutId = setTimeout(() => {
        let filter_payload = [
          { op: 'eq', field: 'status', value: `Pending` },
          { op: 'eq', field: 'status', value: `Rejected` },
        ]
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div style={{ width: '20px' }}></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    if (token != null) {
      if (requestStatus === 'all') {
        fetchData({ pageSize, pageIndex }, false, JSON.stringify(columnSort))
      } else if (requestStatus === 'edit') {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([
            { op: 'eq', field: 'is_active', value: 'true' },
            { op: 'eq', field: 'status', value: `Pending` },
          ]),
          false,
          JSON.stringify(columnSort)
        )
      } else if (requestStatus === 'create') {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([
            { op: 'eq', field: 'is_active', value: 'false' },
            { op: 'eq', field: 'status', value: `Pending` },
          ]),
          false,
          JSON.stringify(columnSort)
        )
      } else if (requestStatus === 'rejected') {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([{ op: 'eq', field: 'status', value: `Rejected` }]),
          false,
          JSON.stringify(columnSort)
        )
      }
    }
  }, [pageSize, pageIndex, columnSort, token, channel, requestStatus])

  useEffect(() => {
    if (token != null) {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })

      dispatch(getPodApprovers(endpoint))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    }
  }, [])

  useEffect(() => {
    setEditorContentBuilder(editRows[0]?.builders_html)
    setEditorContentProductOwner(editRows[0]?.product_owners_html)
    setEditorContentStakeholder(editRows[0]?.stakeholders_html)
    setPodName(editRows[0]?.pod_name)
    setDescripiton(editRows[0]?.description)
    setFunctionalArea(editRows[0]?.functional_area)
  }, [editRows])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="PODs"
            channel={channel}
            setChannel={setChannel}
            Index={
              <>
                <Nav
                  requestStatus={requestStatus}
                  setRequestStatus={setRequestStatus}
                />
                <div
                  className="table-container"
                  style={{ textAlign: 'center' }}
                >
                  <Table {...getTableProps()}>
                    <thead className="sticky-table-header">
                      {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, i) =>
                            column?.id === 'selection' ? (
                              <th key={column.id} style={{ width: '10px' }}>
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                    { style: { width: '10px' } }
                                  )}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['Header'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : column.id == 'is_active' ? (
                              <th key={i} style={{ width: '50px' }}>
                                <div className="table-header-badge">
                                  {column.render('Header')}
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    onClick={handleClickCheckbox}
                                    checked={checkBox}
                                  />
                                </div>
                              </th>
                            ) : (
                              <th key={i}>
                                <div
                                  onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['id'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="tbody mybadgesBody"
                      {...getTableBodyProps()}
                    >
                      {page.map((row, index) => {
                        prepareRow(row)
                        return (
                          <tr
                            key={index}
                            onClick={(event) => {
                              // Check if clicked element is a checkbox
                              if (event.target.type !== 'checkbox') {
                                handleOpen(row)
                              }
                            }}
                          >
                            {row.cells.map((cell) => {
                              return cell.column.id === 'is_active' ? (
                                cell.value == true ? (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                    style={{ textAlign: 'center' }}
                                  >
                                    <MdOutlineDone
                                      style={{
                                        fontSize: '25px',
                                        color: 'green',
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                    style={{ textAlign: 'center' }}
                                  >
                                    <MdHighlightOff
                                      style={{
                                        fontSize: '25px',
                                        color: 'red',
                                      }}
                                    />
                                  </td>
                                )
                              ) : cell.column.id === 'builder_count' ? (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                      textAlign: 'center',
                                    },
                                  })}
                                >
                                  {row?.original?.builders_html
                                    ? row?.original?.builders_html?.match(regex)
                                        ?.length
                                    : 0}
                                </td>
                              ) : cell.column.id === 'product_owner_count' ? (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                      textAlign: 'center',
                                    },
                                  })}
                                >
                                  {row?.original?.product_owners_html
                                    ? row?.original?.product_owners_html?.match(
                                        regex
                                      )?.length
                                    : 0}
                                </td>
                              ) : cell.column.id === 'stakeholder_count' ? (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                      textAlign: 'center',
                                    },
                                  })}
                                >
                                  {row?.original?.stakeholders_html
                                    ? row?.original?.stakeholders_html?.match(
                                        regex
                                      )?.length
                                    : 0}
                                </td>
                              ) : (cell.column.id === 'description' ||
                                  cell.column.id === 'pod_name') &&
                                cell.value.length > 60 ? (
                                <OverlayTrigger
                                  key={Math.random()}
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-tooltip-2">
                                      {cell.render('Cell')}
                                    </Tooltip>
                                  }
                                >
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                        maxWidth: '350px',
                                        textAlign: 'center',
                                      },
                                    })}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                </OverlayTrigger>
                              ) : cell.column.id === 'comments' &&
                                cell.value?.length > 60 ? (
                                <OverlayTrigger
                                  key={Math.random()}
                                  placement="top"
                                  overlay={
                                    <Tooltip id="button-tooltip-2">
                                      {cell.render('Cell')}
                                    </Tooltip>
                                  }
                                >
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: '200px',
                                        maxWidth: '200px',
                                        textAlign: 'center',
                                      },
                                    })}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                </OverlayTrigger>
                              ) : (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                      textAlign: 'center',
                                    },
                                  })}
                                >
                                  {cell.render('Cell')}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                {/* Bottom ToolBar of table */}
                <Row>
                  <Col sm={6}>
                    <div className="page-control">
                      <div className="page-of">
                        Page{' '}
                        <em>
                          {pageIndex + 1} of {totalPages}
                        </em>
                      </div>
                      <div className="prev-next-btn">
                        <button
                          className="badge-btn"
                          onClick={() => onPrevious()}
                          disabled={!canPreviousPage}
                        >
                          {' '}
                          Prev{' '}
                        </button>
                        <button
                          onClick={() => onNext()}
                          className="badge-btn"
                          disabled={!canNextPage}
                        >
                          {' '}
                          Next{' '}
                        </button>
                      </div>
                      <div className="second-control">
                        <span>Go to page:</span>
                        <input
                          className="page-number-input"
                          type="number"
                          defaultValue={pageIndex || 1}
                          onBlur={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value)
                              : 0
                            // handling zero indexing
                            gotoPage(page - 1)
                          }}
                        />
                        <select
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {available_page_size.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          marginLeft: '50px',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        {' '}
                        Total results: {podsData?.podsData?.total_results}
                      </div>
                    </div>
                  </Col>
                  {/* Bottom-right Buttons */}
                  <Col sm={6}>
                    <Button
                      className="m_r-5 mt-3 main-button btn-sm w-25"
                      onClick={openRequestDetails}
                    >
                      Review
                    </Button>
                    <Button
                      className="m_r-5 mt-3 main-button btn-sm w-25"
                      onClick={OpenEditPod}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>

                {/* ADD MEMBER TO A POD MODAL  */}
                <Modal
                  size="xl"
                  show={showAddPodMember}
                  onHide={handleCloseAddPodMember}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit POD</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modalBody">
                    <EditPods
                      setShowAddPodMember={setShowAddPodMember}
                      setLoadingStatus={setLoadingStatus}
                      channel={channel}
                      fetchData={fetchData}
                      pageSize={pageSize}
                      pageIndex={pageIndex}
                      editorContentBuilder={editorContentBuilder}
                      setEditorContentBuilder={setEditorContentBuilder}
                      editorContentStakeholder={editorContentStakeholder}
                      setEditorContentStakeholder={setEditorContentStakeholder}
                      editorContentProductOwner={editorContentProductOwner}
                      setEditorContentProductOwner={
                        setEditorContentProductOwner
                      }
                      podName={podName}
                      setPodName={setPodName}
                      functionalArea={functionalArea}
                      setFunctionalArea={setFunctionalArea}
                      description={description}
                      setDescripiton={setDescripiton}
                      editRows={editRows}
                      ifInsidePod={false}
                    />
                  </Modal.Body>
                </Modal>

                <Modal
                  size="lg"
                  show={showRequestDetails}
                  onHide={handleCloseRequestDetails}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>REQUEST DETAILS</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modalBody">
                    <>
                      <PodRequestDetails
                        editRows={editRows}
                        podApprovers={podApprovers}
                        fetchData={fetchData}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        setShowRequestDetails={setShowRequestDetails}
                      />
                    </>
                  </Modal.Body>
                </Modal>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default Pods
