import {
  GET_BADGES_USER,
  GET_BADGES_USER_LOAD,
  GET_BADGES_USER_ERROR,
} from '../constants/types'

const initialState = {
  userBadges: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BADGES_USER_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_BADGES_USER:
      return {
        ...state,
        userBadges: action.payload,
        loading: false,
        error: null,
      }
    case GET_BADGES_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
