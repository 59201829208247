import { useEffect, useRef } from 'react'

const Overview: React.FC<OverviewProps> = (props) => {
  const _ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (_ref.current) _ref.current.innerHTML = props.doc.block_contents
  })
  return (
    <div className="overview-wrapper">
      <div ref={_ref} className="overview-content" />
    </div>
  )
}
export default Overview
