import {
  GET_ALL_USER,
  GET_ALL_USER_ERROR,
  GET_ALL_USER_LOAD,
} from '../constants/types'

const initialState = {
  getAllUser: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USER_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_USER:
      return {
        ...state,
        loading: false,
        getAllUser: action.payload,
      }
    case GET_ALL_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
