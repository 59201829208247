import {
  GET_AUDIT_LOG_DATA_BEGIN,
  GET_AUDIT_LOG_DATA_SUCCESS,
  GET_AUDIT_LOG_DATA_FAIL,
} from '../actions/auditLogAction'

const initialState = {
  auditLogData: [],
  loading: true,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AUDIT_LOG_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_AUDIT_LOG_DATA_SUCCESS:
      return {
        ...state,
        auditLogData: action.payload,
        loading: false,
        error: null,
      }
    case GET_AUDIT_LOG_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
