import React, { useState } from 'react'
import LoadingStatus from '../../Helper/LoadingStatus'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import '../../../App.css'
import { AuthContext } from '../../context/authProvider'
import { useDispatch } from 'react-redux'
import { pgpUploadFile } from '../../../redux/actions/pgpEncryptionAction'

export default function PgpEncryptionApp() {
  // const [show, setShow] = useState(false)
  const [errors, setErrors] = useState({})
  const [columnsToMask, setColumnValues] = React.useState('')
  const [headerRow, setHeaderRow] = React.useState(1)
  const [delimiter, setDelimiter] = React.useState(',')
  const [useHRKey, setUseHRKey] = React.useState(false)
  const [form, setForm] = useState({})
  const [authCreds] = React.useContext(AuthContext)
  const [file1, setExcelFile] = React.useState('')
  const [file2, setPublicKey] = React.useState('')

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Downloading file...',
  })
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  //Function for finding the error
  const findFormErrors = () => {
    const { excel, pubkey } = form
    const newErrors = {}
    // excel errors
    if (!excel || excel === '') newErrors.excel = 'No file selected'
    else if (!(excel.endsWith('.csv') || excel.endsWith('.xlsx')))
      newErrors.excel = 'Please select a .csv or .xlsx file'

    // pubkey errors
    if (!useHRKey)
      if (!pubkey || pubkey === '') newErrors.pubkey = 'No file selected'
      else if (!pubkey.includes('.asc'))
        newErrors.pubkey = 'Please select a .asc file'

    return newErrors
  }

  const uploadFile = () => {
    let formData = new FormData()
    formData.append('input_file', file1)
    if (useHRKey) {
      formData.append('use_hr_key', useHRKey)
    } else {
      formData.append('pubkey_file', file2)
    }
    if (columnsToMask !== '') {
      formData.append('cols_to_mask', columnsToMask)
    }
    if (headerRow > 1) {
      formData.append('header_row', headerRow)
    }
    if (delimiter !== ',') {
      formData.append('delimiter', delimiter)
    }
    setLoadingStatus({ status: true, message: 'Processing file...' })

    dispatch(pgpUploadFile(endpoint, formData, file1))
      .then(() => {
        setLoadingStatus({ status: false, message: 'Downloading file...' })
        handleClose()
      })
      .catch((error) => {
        return error
      })
  }
  //handle pgp modal
  const handleClose = () => {
    // setShow(false)
    setErrors({})
    setForm(new Object())
    if (headerRow > 1) {
      // reset header row to 1
      setHeaderRow(1)
    }
  }
  //handle form submit
  const handleSubmit = (e) => {
    e.preventDefault()
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      uploadFile()
    }
  }
  //update error and form states, and handle onChange form inputs
  const setField = (field, value, e) => {
    setForm({
      ...form,
      [field]: value,
    })
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field])
      setErrors({
        ...errors,
        [field]: null,
      })

    if (field === 'excel') {
      let excelfile = e.target.files[0]
      setExcelFile(excelfile)
    } else {
      let pubkeyfile = e.target.files[0]
      setPublicKey(pubkeyfile)
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>PGP Encryption App</Modal.Title>
      </Modal.Header>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label className="frm-label">
                Select Excel File(.csv or .xlsx file)
              </Form.Label>
              <Form.Control
                type="file"
                size="sm"
                onChange={(e) => setField('excel', e.target.value, e)}
                isInvalid={!!errors.excel}
              />
              <Form.Control.Feedback type="invalid">
                {errors.excel}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label className="frm-label">
                Select PGP Pub key(.asc file)
              </Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="file"
                  size="sm"
                  onChange={(e) => setField('pubkey', e.target.value, e)}
                  isInvalid={!!errors.pubkey && !useHRKey}
                  disabled={useHRKey}
                  style={{ width: '75%' }}
                  className="mr-3"
                />
                <span className="text-muted mx-3 mb-2">or</span>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="HR PGP Pub key"
                    onChange={(e) => setUseHRKey(e.target.checked)}
                    checked={useHRKey}
                  />
                </Form.Group>
              </div>
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.pubkey && !useHRKey ? errors.pubkey : null}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="frm-label">Columns to Mask</Form.Label>
              <Form.Control
                as="textarea"
                onChange={(e) => setColumnValues(e.target.value)}
                rows={2}
                placeholder="Column1, Column2, Column3..."
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.formBasicRadio">
              <Form.Label className="frm-label">Delimiter</Form.Label>
              <Form.Check
                inline
                className="mx-3"
                label="Comma ( , )"
                type="radio"
                id="comma"
                name="delimiter"
                value=","
                checked={delimiter === ','}
                onChange={(e) => setDelimiter(e.target.value)}
              />
              <Form.Check
                inline
                label="Pipe ( | )"
                type="radio"
                id="pipe"
                name="delimiter"
                value="|"
                checked={delimiter === '|'}
                onChange={(e) => setDelimiter(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea2"
            >
              <Form.Label className="frm-label">Header row</Form.Label>
              <Form.Control
                type="number"
                min="1"
                value={headerRow}
                onChange={(e) => setHeaderRow(e.target.value)}
                placeholder="1"
                style={{ color: headerRow > 1 ? '#000000' : '#808080' }}
              />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Encrypt
            </Button>
          </Form>
        </Modal.Body>
      )}
    </>
  )
}
