import axios from 'axios'

export const restoreDeleteRecord =
  (endpoint, payload, table) => async (dispatch) => {
    dispatch(restoreDeleteBegins())
    try {
      await axios.post(
        `${endpoint}/api/ref_data/restore_soft_deletes?table_name=${table}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      dispatch(restoreDeleteSuccess())
    } catch (e) {
      dispatch(restoreDeleteFailure())
    }
  }

export const RESTORE_DELETE_LOADING = 'RESTORE_DELETE_LOADING'
export const RESTORE_DELETE_SUCCESS = 'RESTORE_DELETE_SUCCESS'
export const RESTORE_DELETE_ERROR = 'RESTORE_DELETE_ERROR'

const restoreDeleteBegins = () => ({
  type: RESTORE_DELETE_LOADING,
})

const restoreDeleteSuccess = (editData) => ({
  type: RESTORE_DELETE_SUCCESS,
  payload: { editData },
})

const restoreDeleteFailure = (error) => ({
  type: RESTORE_DELETE_ERROR,
  payload: { error },
})
