import {
  GET_JOB_POSTINGS_ERROR,
  GET_JOB_POSTINGS,
  GET_JOB_POSTINGS_LOAD,
} from '../../constants/types'

const initialState = {
  getJobPostings: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JOB_POSTINGS_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_JOB_POSTINGS:
      return {
        ...state,
        getJobPostings: action.payload,
        loading: false,
        error: null,
      }
    case GET_JOB_POSTINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
