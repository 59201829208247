import { useEffect, useMemo, useContext } from 'react'
import React from 'react'
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from 'react-table'
import {
  Table,
  Col,
  Row,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import axios from 'axios'
import SelectedTables from './SnowflakeCloneTables'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
import LoadingStatus from '../../Helper/LoadingStatus'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../Helper/ToastObjects'
import './SnowflakeClone.css'
const SnowflakeClone = ({
  enabledCols,
  enabledColsMap,
  adminRole,
  metaDataByTable,
  setShowTable,
  setMessage,
}) => {
  // context states
  /* eslint-disable no-unused-vars */
  const access_token = localStorage.getItem('token')

  const table_name = 'SNOWFLAKE_CLONE'
  const current_timestamp_minus_twelve_hours = new Date(
    Date.now() - 12 * 60 * 60 * 1000
  )
  const [authCreds] = useContext(AuthContext)
  const [cloneData, setCloneData] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [totalPages, setTotalPages] = React.useState(1)

  //sort states
  const [columnSort, setColumnSortValue] = React.useState([])
  // const [isSort, setSort] = React.useState(false)

  const [show, setShow] = React.useState(false)
  const [showSelectedTables, setShowSelectedTables] = React.useState(false)
  const [showMultiCloneResponse, setShowMultiCloneResponse] =
    React.useState(false)
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [columnFilters, setColumnFilters] = React.useState([])
  const [editRows, setEditRows] = React.useState([])
  const [selectedRows, setSelectedRows] = React.useState([])
  const [multiCloneResponse, setMultiCloneResponse] = React.useState([])
  const columnPriorHeader = {
    DATABASE_NAME: true,
    SCHEMA_NAME: true,
    TABLE_NAME: true,
  }
  const columnHideHeader = {
    LAST_CLONED_BY_DEV: true,
    LAST_CLONED_BY_UAT: true,
    LAST_CLONED_BY_SBX: true,
    LAST_CLONED_ON_DEV: true,
    LAST_CLONED_ON_UAT: true,
    LAST_CLONED_ON_SBX: true,
    KH_CREATED_BY: true,
    KH_CREATED_ON: true,
    KH_MODIFIED_BY: true,
    KH_MODIFIED_ON: true,
  }

  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  const handleClone = async (env, rowValue) => {
    setMultiCloneResponse([])
    setLoadingStatus({
      status: true,
      message: 'Manually cloning the table(s)...',
    })
    let tables = rowValue ? [rowValue] : selectedRows
    tables = JSON.stringify(tables)

    let editUri = `${authCreds.restEndpoint}/api/snowflake_clone/clone_to_env_commit`
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`,
      khuser: authCreds.username,
    }
    let body = {
      tables: tables,
      env: env,
    }
    axios
      .post(editUri, body, { headers: post_headers })
      .then((response) => {
        response?.data?.forEach((res) => {
          setMultiCloneResponse((prevState) => [
            ...prevState,
            {
              result: res?.result,
              DATABASE_NAME: res?.DATABASE_NAME,
              SCHEMA_NAME: res?.SCHEMA_NAME,
              TABLE_NAME: res?.TABLE_NAME,
            },
          ])
        })
        handleShowSelectedTables()
        handleShowMultiCloneResponse()
        setLoadingStatus({ status: false })
      })
      .catch((error) => {
        toast(error.message, toastTopRightError)
        setLoadingStatus({ status: false })
        fetchData({ pageSize, pageIndex })
      })
    handleClose()
  }

  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }

  const available_page_size = ['50', '100', '200', '500']

  const handleCheckboxAll = (idx, val, row) => () => {
    let rowValue = row.values
    rowValue[idx] = !val
    editRows.push(rowValue)
    updateRecords_inline_editing()
    //setEditModeState(false)
    setEditRows([])
  }

  const handleClose = () => {
    setShow(false)
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        {
          field: `${header}`,
          direction: columnSort[0].direction,
          nullslast: 'True',
        },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([
          { field: `${header}`, direction: 'asc', nullslast: 'True' },
        ])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([
          { field: `${header}`, direction: 'desc', nullslast: 'True' },
        ])
      } else {
        setColumnSortValue([])
      }
    }
  }

  //handle automate clone sorting for SBX DEV and UAT
  const handleAutomateClone = (headers) => {
    if (columnSort.length === 0) {
      setColumnSortValue([
        { field: `${headers[0]}`, direction: 'asc', nullslast: 'True' },
        { field: `${headers[1]}`, direction: 'asc', nullslast: 'True' },
        { field: `${headers[2]}`, direction: 'asc', nullslast: 'True' },
      ])
    } else if (columnSort[0].direction === 'asc') {
      setColumnSortValue([
        { field: `${headers[0]}`, direction: 'desc', nullslast: 'True' },
        { field: `${headers[1]}`, direction: 'desc', nullslast: 'True' },
        { field: `${headers[2]}`, direction: 'desc', nullslast: 'True' },
      ])
    } else {
      setColumnSortValue([])
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }

      clearAllTimeout()
      let timeoutId = setTimeout(() => {
        let filter_payload = []
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let local_obj = { op: 'ilike' }
            local_obj['field'] = header_obj.Header
            local_obj['value'] = '%' + header_obj.filterValue + '%'
            filter_payload.push(local_obj)
          }
        })

        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const data = useMemo(() => cloneData)
  const columns = useMemo(() => tableColumns)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: localPageIndex, pageSize: 50 },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // updates selectedRows state on select row checkbox event
  useEffect(() => {
    setSelectedRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // Used to fetch new table data from server
  useEffect(() => {
    if (access_token != null && metaDataByTable?.metaDataByTable?.data) {
      fetchData({ pageSize, pageIndex }, null, JSON.stringify(columnSort))
    }
  }, [pageSize, pageIndex, columnSort, metaDataByTable])

  const fetchData = (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)
    let request_uri = `${authCreds.restEndpoint}/api/snowflake_clone/read_object`
    axios
      .get(request_uri, {
        params: {
          page_size: pageSize,
          page_number: pageIndex + 1,
          filters: filters || JSON.stringify(columnFilters),
          sort: sort_value,
        },
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
        let table_data = response.data.data
        let table_name = 'SNOWFLAKE_CLONE'

        if (table_data.length > 0) {
          setCloneData(table_data)
          setTableColumns(
            TableHandler.createColumnMapping(
              Object.keys(table_data[0]),
              metaDataByTable?.metaDataByTable?.data,
              table_name
            )
          )
          setTotalPages(response.data.num_pages)
          setTotalRows(response.data.total_results)

          setLoadingStatus({
            status: false,
            message: 'Fetching Snowflake_clone table details...',
          })
        } else {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
          toast('No records found!', toastTopRightError)
          setCloneData([])
          setTotalPages(0)
          setTotalRows(0)
        }
      })
      .catch((error) => {
        setShowTable(false)
        setMessage(error?.response?.data?.message)
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  // Multi Manual Clone
  const handleShowSelectedTables = () => {
    setShowSelectedTables(true)
  }

  const handleCloseSelectedTables = () => {
    setShowSelectedTables(false)
    handleCloseMultiCloneResponse()
  }

  const handleShowMultiCloneResponse = () => {
    setShowMultiCloneResponse(true)
  }

  const handleCloseMultiCloneResponse = () => {
    setShowMultiCloneResponse(false)
  }

  const handleMultiClone = async (env) => {
    handleCloseSelectedTables()
    await handleClone(env)
  }

  // UPDATE RECORDS......................
  const updateRecords_inline_editing = () => {
    let editUri = `${authCreds.restEndpoint}/api/snowflake_clone/update_object`
    let payload = []

    // loops over editRow state and creates payload compatible with update_object api
    for (let i = 0; i < editRows.length; i++) {
      let local_object = {}
      local_object['KH_UID'] = editRows[i]['KH_UID']
      for (let j = 0; j < enabledCols.length; j++) {
        let value = editRows[i][enabledCols[j]]
        let key = enabledCols[j]
        local_object[key] = value
      }
      payload.push(local_object)
    }

    //Check if the the Edit rows are 1 or more
    if (payload.length === 0) {
      toast('No records to update', toastTopRightError)
      return false
    } else if (payload.length === 1) {
      //for 1 row, pass key in url
      editUri += `?key=${editRows[0]['KH_UID']}`
      payload = payload[0]
      delete payload['KH_UID']
    }

    setLoadingStatus({ status: true, message: 'Updating table record...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`,
      khuser: authCreds.username,
    }

    // makes a PUT request
    axios
      .put(editUri, payload, {
        headers: post_headers,
      })
      .then(() => {
        toast('Rows updated!', toastTopRightSuccess)
        setLoadingStatus({
          status: false,
          message: 'Updating table record...',
        })
        //fetchData();
      })
      .catch(() => {
        toast('Error in updating record(s)', toastTopRightError)
        fetchData({ pageSize, pageIndex })
        return false
      })
    handleClose()
  }

  return (
    <React.Fragment>
      <ToastContainer containerId="qwerty" />
      {/* <div className="header-snowflake-clone"><h4> Snowflake Clone</h4></div> */}
      <div className="table-container">
        {loadingStatus.status ? (
          <LoadingStatus status_message={loadingStatus.message} />
        ) : (
          <>
            {/* Displays complete table */}

            <Table {...getTableProps()}>
              <thead className="sticky-table-header-sf">
                {headerGroups.map((headerGroup, i) => (
                  <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) =>
                      column?.id === 'selection' ? (
                        <th key={column.id}>
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="table-header"
                          >
                            {column.render('Header')}

                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : columnPriorHeader[column.id] === true ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}
                    <th colSpan="3" scope="colgroup">
                      <center>
                        <div className="table-header">MANUAL CLONE</div> <br />
                      </center>
                    </th>

                    <th colSpan="3" scope="colgroup">
                      <center>
                        <div
                          onClick={() =>
                            handleAutomateClone([
                              'AUTOMATE_SBX',
                              'AUTOMATE_DEV',
                              'AUTOMATE_UAT',
                            ])
                          }
                          className="table-header"
                        >
                          AUTOMATE CLONE
                          {columnSort[0]?.field === 'AUTOMATE_SBX' ? (
                            <span>
                              {columnSort[0]?.direction === 'asc'
                                ? ' 🔽'
                                : '' || columnSort[0]?.direction === 'desc'
                                ? ' 🔼'
                                : ''}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <br />
                      </center>
                    </th>

                    {headerGroup.headers.map((column, i) =>
                      column['Header'] === 'IS_ACTIVE' ? (
                        <th scope="colgroup" key={i}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'LAST_CLONED_BY_SBX' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'LAST_CLONED_ON_SBX' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'LAST_CLONED_BY_DEV' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'LAST_CLONED_ON_DEV' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'LAST_CLONED_BY_UAT' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'LAST_CLONED_ON_UAT' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'KH_CREATED_BY' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'KH_CREATED_ON' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'KH_MODIFIED_BY' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}

                    {headerGroup.headers.map((column) =>
                      columnHideHeader[column.id] === true &&
                      column['Header'] === 'KH_MODIFIED_ON' ? (
                        <th key={column.id}>
                          <div
                            onClick={() => handleSorting(column.Header)}
                            className="table-header"
                          >
                            {column.render('Header')}
                            {columnSort[0]?.field === column['Header'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {' '}
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ) : null
                    )}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr key={rowIndex} {...row.getRowProps()}>
                      {row.cells.map((cell, cellIndex) => {
                        return cell.column.id === 'selection' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            <IndeterminateCheckbox
                              {...row.getToggleRowSelectedProps()}
                            />
                          </td>
                        ) : columnPriorHeader[cell.column.id] === true ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      <td key={`${rowIndex} ${Math.random()}`}>
                        {!row.values['IS_ACTIVE'] ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="inactive_tooltip_sbx">
                                Table is not active
                              </Tooltip>
                            }
                          >
                            <span>
                              <Button
                                className="btnSnowflake"
                                type="submit"
                                disabled
                              >
                                To SBX
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : new Date(row.values['LAST_CLONED_ON_SBX']) >
                          current_timestamp_minus_twelve_hours ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="cloned_tooltip_sbx">
                                Already cloned in last 12 hours
                              </Tooltip>
                            }
                          >
                            <span>
                              <Button
                                className="btnSnowflake"
                                type="submit"
                                disabled
                              >
                                To SBX
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            className="btnSnowflake"
                            type="submit"
                            onClick={() => {
                              handleClone('SBX', row.values)
                            }}
                          >
                            To SBX
                          </Button>
                        )}
                      </td>

                      <td key={`${rowIndex} ${Math.random()}`}>
                        {!row.values['IS_ACTIVE'] ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="inactive_tooltip_dev">
                                Table is not active
                              </Tooltip>
                            }
                          >
                            <span>
                              <Button
                                className="btnSnowflake"
                                type="submit"
                                disabled
                              >
                                To DEV
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : new Date(row.values['LAST_CLONED_ON_DEV']) >
                          current_timestamp_minus_twelve_hours ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="cloned_tooltip_dev">
                                Already cloned in last 12 hours
                              </Tooltip>
                            }
                          >
                            <span>
                              <Button
                                className="btnSnowflake"
                                type="submit"
                                disabled
                              >
                                To DEV
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            className="btnSnowflake"
                            type="submit"
                            onClick={() => {
                              handleClone('DEV', row.values)
                            }}
                          >
                            To DEV
                          </Button>
                        )}
                      </td>

                      <td key={`${rowIndex} ${Math.random()}`}>
                        {!row.values['IS_ACTIVE'] ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="inactive_tooltip_uat">
                                Table is not active
                              </Tooltip>
                            }
                          >
                            <span>
                              <Button
                                className="btnSnowflake"
                                type="submit"
                                disabled
                              >
                                To UAT
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : new Date(row.values['LAST_CLONED_ON_UAT']) >
                          current_timestamp_minus_twelve_hours ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="cloned_tooltip_uat">
                                Already cloned in last 12 hours
                              </Tooltip>
                            }
                          >
                            <span>
                              <Button
                                className="btnSnowflake"
                                type="submit"
                                disabled
                              >
                                To UAT
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            className="btnSnowflake"
                            type="submit"
                            onClick={() => {
                              handleClone('UAT', row.values)
                            }}
                          >
                            To UAT
                          </Button>
                        )}
                      </td>

                      {row.cells.map((cell, cellIndex) => {
                        return enabledColsMap[cell.column.id] === true &&
                          cell.column.id === 'AUTOMATE_SBX' ? (
                          <td key={`${cellIndex} ${Math.random()}`}>
                            <input
                              type="checkbox"
                              id="AutomateCloneSbx"
                              checked={cell.row.values.AUTOMATE_SBX}
                              disabled={!adminRole}
                              onChange={handleCheckboxAll(
                                cell.column.id,
                                cell.row.values.AUTOMATE_SBX,
                                row
                              )}
                            />
                            <label htmlFor="AutomateCloneSbx">
                              &nbsp; To SBX
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return enabledColsMap[cell.column.id] === true &&
                          cell.column.id === 'AUTOMATE_DEV' ? (
                          <td key={`${cellIndex} ${Math.random()}`}>
                            <input
                              type="checkbox"
                              id="AutomateCloneDev"
                              checked={cell.row.values.AUTOMATE_DEV}
                              disabled={!adminRole}
                              onChange={handleCheckboxAll(
                                cell.column.id,
                                cell.row.values.AUTOMATE_DEV,
                                row
                              )}
                            />
                            <label htmlFor="AutomateCloneDev">
                              &nbsp; To DEV
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return enabledColsMap[cell.column.id] === true &&
                          cell.column.id === 'AUTOMATE_UAT' ? (
                          <td key={`${cellIndex} ${Math.random()}`}>
                            <input
                              type="checkbox"
                              id="AutomateCloneUat"
                              checked={cell.row.values.AUTOMATE_UAT}
                              disabled={!adminRole}
                              onChange={handleCheckboxAll(
                                cell.column.id,
                                cell.row.values.AUTOMATE_UAT,
                                row
                              )}
                            />
                            <label htmlFor="AutomateCloneUat">
                              &nbsp; To UAT
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </label>
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return enabledColsMap[cell.column.id] === true &&
                          cell.column.id === 'IS_ACTIVE' ? (
                          <td key={`${cellIndex} ${Math.random()}`}>
                            <center>
                              <input
                                type="checkbox"
                                id="isActive"
                                checked={cell.row.values.IS_ACTIVE}
                                onChange={handleCheckboxAll(
                                  cell.column.id,
                                  cell.row.values.IS_ACTIVE,
                                  row
                                )}
                              />
                            </center>
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'LAST_CLONED_BY_SBX' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'LAST_CLONED_ON_SBX' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'LAST_CLONED_BY_DEV' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'LAST_CLONED_ON_DEV' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'LAST_CLONED_BY_UAT' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'LAST_CLONED_ON_UAT' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'KH_CREATED_BY' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'KH_CREATED_ON' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'KH_MODIFIED_BY' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}

                      {row.cells.map((cell, cellIndex) => {
                        return columnHideHeader[cell.column.id] === true &&
                          cell.column.id === 'KH_MODIFIED_ON' ? (
                          <td
                            key={`${cellIndex} ${Math.random()}`}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ) : null
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </>
        )}
      </div>
      <Row>
        <Col sm={6}>
          <div className="page-control">
            <div className="page-of">
              Page{' '}
              <em>
                {pageIndex + 1} of {totalPages}
              </em>
            </div>
            <div className="prev-next-btn">
              <button onClick={() => onPrevious()} disabled={!canPreviousPage}>
                {' '}
                Prev{' '}
              </button>
              <button onClick={() => onNext()} disabled={!canNextPage}>
                {' '}
                Next{' '}
              </button>
            </div>
            <div className="second-control">
              <span>Go to page:</span>
              <input
                className="page-number-input"
                type="number"
                defaultValue={pageIndex || 1}
                onBlur={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 0
                  // handling zero indexing
                  gotoPage(page - 1)
                }}
              />
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {available_page_size.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Col>
        {/* Bottom-right Buttons */}
        <Col>
          {selectedRows.length >= 2 && (
            <Button
              className="m_r-5 block-btn main-button btn-sm btm-right-btn"
              style={{ width: '30%' }}
              onClick={() => {
                handleShowSelectedTables()
              }}
            >
              MULTI MANUAL CLONE
            </Button>
          )}
        </Col>
      </Row>
      {
        <Modal
          size="xl"
          show={showSelectedTables}
          onHide={handleCloseSelectedTables}
        >
          <Modal.Header closeButton>
            <Modal.Title>MULTI MANUAL CLONE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showMultiCloneResponse ? (
              <SelectedTables
                selectedRows={multiCloneResponse}
                headers={[
                  'DATABASE NAME',
                  'SCHEMA NAME',
                  'TABLE NAME',
                  'CLONE STATUS',
                ]}
              />
            ) : (
              <SelectedTables
                selectedRows={selectedRows}
                headers={['DATABASE NAME', 'SCHEMA NAME', 'TABLE NAME']}
              />
            )}
          </Modal.Body>
          {showMultiCloneResponse ? null : (
            <Modal.Footer>
              <Button
                className="m_r-5 block-btn main-button btn-sm btm-right-btn"
                onClick={() => {
                  handleMultiClone('SBX')
                }}
              >
                To SBX
              </Button>
              <Button
                className="m_r-5 block-btn main-button btn-sm btm-right-btn"
                onClick={() => {
                  handleMultiClone('DEV')
                }}
              >
                To DEV
              </Button>
              <Button
                className="m_r-5 block-btn main-button btn-sm btm-right-btn"
                onClick={() => {
                  handleMultiClone('UAT')
                }}
              >
                To UAT
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      }
    </React.Fragment>
  )
}

export default SnowflakeClone
