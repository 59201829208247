import { EDIT_USER_ERROR, EDIT_USER, EDIT_USER_LOAD } from '../constants/types'

const initialState = {
  editUserData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_USER_LOAD:
      return {
        ...state,
        loading: true,
      }
    case EDIT_USER:
      return {
        ...state,
        loading: false,
        editUserData: action.payload,
      }
    case EDIT_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
