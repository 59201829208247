var redirectUri = 'http://localhost:3000'
if (process.env.NODE_ENV === 'production') {
  redirectUri = process.env.REACT_APP_REDIRECT_URI
}

export const msalConfig = {
  auth: {
    clientId: 'e388d357-06e9-4f16-9677-54594d7ebcff',
    authority:
      'https://login.microsoftonline.com/3ce34e42-c07d-47bb-b72a-4ce606de6b88',
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://56bb89f5-b358-481a-91b9-279f1eb9f1a8/user_impersonation'],
}

// "User.Read" stands as shorthand for "graph.microsoft.com/User.Read"
export const protectedResources = {
  graphUser: {
    endpoint: 'https://graph.microsoft.com/v1.0/users',
    scopes: ['User.ReadBasic.All'],
  },
  graphMe: {
    endpoint: 'https://graph.microsoft.com/v1.0/me',
    scopes: ['User.Read'],
  },
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
}
