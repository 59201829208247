import {
  GET_MODAL_LOADING,
  GET_MODAL_ERROR,
  CREATE_JIRA_TRACKER_ENTRY,
  CREATE_JIRA_TRACKER_ENTRY_LOADING,
  CREATE_JIRA_TRACKER_ENTRY_ERROR,
  GET_JIRA_TRACKER_ENTRIES,
  GET_JIRA_TRACKER_ENTRIES_ERROR,
  GET_JIRA_TRACKER_ENTRIES_LOADING,
} from '../constants/types'

const initialState = {
  metaData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MODAL_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_MODAL_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_JIRA_TRACKER_ENTRY_LOADING:
      return {
        ...state,
        loading: true,
      }
    case CREATE_JIRA_TRACKER_ENTRY:
      return {
        ...state,
        loading: false,
      }
    case CREATE_JIRA_TRACKER_ENTRY_ERROR:
      return {
        ...state,
        loading: false,
      }
    case GET_JIRA_TRACKER_ENTRIES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_JIRA_TRACKER_ENTRIES:
      return {
        ...state,
        metaData: action.payload,
        loading: false,
      }
    case GET_JIRA_TRACKER_ENTRIES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
