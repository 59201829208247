import React from 'react'
import './FnFTable.scss'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import help from '../../../../Utilities/fnf_helpers'
import LoadingStatus from '../../../../../Helper/LoadingStatus'
import columnsMap from '../../../../../../data/ColumnsMapping'
import GridColumnMapping from '../../../../../../data/GridColumnMapping'

function FnFTable({
  columns,
  tableData,
  viewClicked,
  availableDataCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  isExporting,
  isLoading,
  tableType,
}) {
  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            data-testid="test_report_table"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <Tooltip title={column.description} key={column.id}>
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                      className={`${column.group}_data`}
                    >
                      {column.label}
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={
                    tableType === 'steps'
                      ? row?.[columnsMap.KH_UID]
                      : row[GridColumnMapping.KH_UID.key]
                  }
                  data-testid="test_tablerow"
                >
                  {columns.map((column, index) => {
                    let value = ''
                    if (column?.id?.toLowerCase() === 'action') {
                      value = (
                        <button
                          onClick={() => {
                            viewClicked(row)
                          }}
                          className="viewbtn"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      )
                    } else if (
                      row[column.id] === null ||
                      row[column.id] === undefined ||
                      row[column.id] === ''
                    ) {
                      value = '-'
                    } else {
                      value = row[column.id]
                    }
                    if (column.date === true && value != '-') {
                      value = help.formatDate(value)
                    }

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={help.paintCell(column.id, value, index)}
                      >
                        {column.format &&
                        (typeof value === 'number' ||
                          typeof value === 'boolean')
                          ? column.format(value)
                          : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {(isLoading == true || isExporting === true) && (
            <LoadingStatus
              id="loader"
              data-testid="test_loader"
              status_message="Loading Data ..."
            />
          )}
          {isLoading == false && tableData?.length === 0 && (
            <h4 id="nodatawarn">No Data Found</h4>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[3, 5, 10, 100]}
          component="div"
          count={Number(availableDataCount)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}

export default FnFTable
