import React from 'react'
import { toast } from 'react-toastify'
import { toastTopRightError } from './ToastObjects'
import LoadingStatus from './LoadingStatus'
const InternetFrame = ({ source_url }) => {
  const [loadingStatus, setLoadingStatus] = React.useState(true)
  const [status, setStatus] = React.useState()
  const iframe_container = {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%',
  }
  const responsive_iframe = {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
  }
  const responsive_iframe_error = {
    position: 'absolute',
    top: '33%',
    left: '10%',
    right: '10%',
    textAlign: 'center',
  }
  React.useEffect(() => {
    async function test() {
      try {
        setLoadingStatus(true)
        const response = await fetch(source_url)
        setStatus(response.status)
        setLoadingStatus(false)
      } catch (error) {
        toast(`Failed to fetch the data: ${error?.message}`, toastTopRightError)
      }
    }
    test()
  }, [])
  if (status === 404) {
    return (
      <div style={iframe_container}>
        <div style={responsive_iframe_error}>
          <h3>
            The Data Engineering Documentation you are looking for is only
            accessible within KHC network.
          </h3>
          <h3>
            But, You can still navigate to other pages using Menu Bar. Please
            connect to your Zscalar VPN.{' '}
          </h3>
          <h3>
            If it is already connected please restart your Zscalar service by
            following below steps:
          </h3>
          <h4>
            Open Zscalar --{'>'} More --{'>'} Restart Service
          </h4>
        </div>
      </div>
    ) // Render error message if there's an error
  } else if (status === 200) {
    return (
      <div style={iframe_container}>
        <iframe style={responsive_iframe} src={source_url}></iframe>
      </div>
    )
  } else {
    return (
      <>
        {loadingStatus && <LoadingStatus status_message={'loading...'} />}
        <div style={iframe_container}>
          {!loadingStatus && (
            <div style={responsive_iframe_error}>
              <h1>Something went wrong</h1>
              <p>
                <ul>
                  <li>HttpStatusCode: {status}</li>
                </ul>
              </p>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default InternetFrame
