import React from 'react'
import { Link } from 'react-router-dom'
// import { MdHelpOutline } from 'react-icons/md'
// import Dialog from '@mui/material/Dialog'
// import DialogActions from '@mui/material/DialogActions'
// import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
// import DialogTitle from '@mui/material/DialogTitle'
// import Container from 'react-bootstrap/Container'
// import jwt_decode from 'jwt-decode'
import { BiExport } from 'react-icons/bi'
// import Navbar from 'react-bootstrap/Navbar'
import FormGroup from '@mui/material/FormGroup'
import Table from '@mui/material/Table'
import { useNavigate } from 'react-router-dom'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Map from '../../../Map'
// import Paper from '@mui/material/Paper'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Typography from '@mui/material/Typography'
import { Button } from 'react-bootstrap'
// import Slide from '@mui/material/Slide'
import SubmitRequest from './submitRequest'
import Modal from 'react-bootstrap/Modal'
import LoadingStatus from '../../Helper/LoadingStatus'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useEffect } from 'react'
import { APPROVED_STATUS, PENDING_STATUS } from '../../../redux/constants/types'
import { useDispatch } from 'react-redux'

export default function badgeNavbar({
  channel,
  uqBadges,
  badgeFilter,
  badgeLevelFunc,
  badgeLevels,
  badgeLevel,
  setBadgeLevel,
  setBadgeFilter,
  setChannel,
  summaryKraft,
  summaryVendor,
  exportFunc,
  countryData,
  setPendingRequestStatus,
  setPod,
  pod,
  setIfMyPods,
  podsMenu,
  ifMyPods,
}) {
  const location = useLocation()
  const route = location.pathname.split('/')[2]
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const dispatch = useDispatch()

  const [showModal, setShowModal] = React.useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }
  function OpenAddModal() {
    setShowModal(true)
  }

  const handleBadgeFilter = (value) => {
    setBadgeFilter(value)
    badgeLevelFunc(value)
    setBadgeLevel('')
  }

  const handleBadgeStatusFilter = (value) => {
    if (value == 'Approved') {
      dispatch({ type: APPROVED_STATUS })
      setBadgeFilter(value)
      setPendingRequestStatus(value)
    } else if (value == 'Pending') {
      dispatch({ type: PENDING_STATUS })
      setBadgeFilter(value)
      setPendingRequestStatus(value)
    }
  }

  const handleBadgeLevel = (value) => {
    setBadgeLevel(value)
  }

  const handleSelectedPod = (value) => {
    setPod(value)
  }

  const handleCheckMyPods = (e) => {
    // setChecked(e.currentTarget.checked)
    setIfMyPods(e.currentTarget.checked)
    setPod('')
  }

  useEffect(() => {
    if (localStorage.getItem('channel') == null) {
      localStorage.setItem('channel', 'Data Engineering')
    } else {
      setChannel(localStorage.getItem('channel'))
    }
  }, [])

  let history = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('badge_metadata')) {
      history('/badge')
    }
  }, [])

  return (
    <>
      {route == undefined ? (
        <div className="badge-home">
          <Accordion
            defaultExpanded="true"
            style={{
              flexDirection: 'column',
              display: 'flex',
              background: '#f1f6ff',
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <p style={{ fontWeight: 700, fontFamily: 'sans-serif' }}>
                  {' '}
                  Summary View
                </p>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className="summary"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                {countryData && countryData.length > 0 ? (
                  <Map data={countryData} />
                ) : (
                  <></>
                )}

                <TableContainer>
                  <Table aria-label="simple table" className="leftable">
                    <TableHead>
                      <TableRow>
                        <TableCell className="muiHead" align="center">
                          KRAFT HEINZ
                        </TableCell>
                        <TableCell className="muiHead" align="center">
                          # OF EMPLOYEES
                        </TableCell>
                        <TableCell className="muiHead gold" align="center">
                          GOLD
                        </TableCell>
                        <TableCell className="muiHead silver" align="center">
                          SILVER
                        </TableCell>
                        <TableCell className="muiHead bronze" align="center">
                          BRONZE
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summaryKraft?.map((row) => (
                        <TableRow
                          key={row.company}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <>
                            <TableCell className="muiTdata" align="center">
                              {row.company}
                            </TableCell>

                            <TableCell className="muiTdata" align="center">
                              <p> {row.no_of_employess}</p>
                            </TableCell>
                            <TableCell className="muiTdata gold" align="center">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    {row.no_of_goldbadges} gold earned by{' '}
                                    {row.no_of_goldholders} employees
                                  </Tooltip>
                                }
                              >
                                <p className="count-main">
                                  {row.no_of_goldbadges}{' '}
                                  <p className="inner-count">
                                    ({row.no_of_goldholders})
                                  </p>
                                </p>
                              </OverlayTrigger>
                            </TableCell>
                            <TableCell
                              className="muiTdata silver"
                              align="center"
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    {row.no_of_silverbadges} silver earned by{' '}
                                    {row.no_of_silverholders} employees
                                  </Tooltip>
                                }
                              >
                                <p className="count-main">
                                  {row.no_of_silverbadges}{' '}
                                  <p className="inner-count">
                                    ({row.no_of_silverholders})
                                  </p>
                                </p>
                              </OverlayTrigger>
                            </TableCell>
                            <TableCell
                              className="muiTdata bronze"
                              align="center"
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    {row.no_of_bronzebadges} bronze earned by{' '}
                                    {row.no_of_bronzeholders} employees
                                  </Tooltip>
                                }
                              >
                                <p className="count-main">
                                  {row.no_of_bronzebadges}{' '}
                                  <p className="inner-count">
                                    ({row.no_of_bronzeholders})
                                  </p>
                                </p>
                              </OverlayTrigger>
                            </TableCell>
                          </>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Table aria-label="simple table" className="secondtable">
                    <TableHead>
                      <TableRow>
                        <TableCell className="muiHead" align="center">
                          VENDORS
                        </TableCell>
                        <TableCell className="muiHead" align="center">
                          # OF EMPLOYEES
                        </TableCell>
                        <TableCell className="muiHead gold" align="center">
                          GOLD
                        </TableCell>
                        <TableCell className="muiHead silver" align="center">
                          SILVER
                        </TableCell>
                        <TableCell className="muiHead bronze" align="center">
                          BRONZE
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {summaryVendor?.map((row) => (
                        <TableRow
                          key={row.company}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <>
                            <TableCell className="muiTdata" align="center">
                              {row.company}
                            </TableCell>

                            <TableCell className="muiTdata" align="center">
                              <p> {row.no_of_employess}</p>
                            </TableCell>
                            <TableCell className="muiTdata gold" align="center">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    {row.no_of_goldbadges} gold earned by{' '}
                                    {row.no_of_goldholders} employees
                                  </Tooltip>
                                }
                              >
                                <p className="count-main">
                                  {row.no_of_goldbadges}{' '}
                                  <p className="inner-count">
                                    ({row.no_of_goldholders})
                                  </p>
                                </p>
                              </OverlayTrigger>
                            </TableCell>
                            <TableCell
                              className="muiTdata silver"
                              align="center"
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    {row.no_of_silverbadges} silver earned by{' '}
                                    {row.no_of_silverholders} employees
                                  </Tooltip>
                                }
                              >
                                <p className="count-main">
                                  {row.no_of_silverbadges}{' '}
                                  <p className="inner-count">
                                    ({row.no_of_silverholders})
                                  </p>
                                </p>
                              </OverlayTrigger>
                            </TableCell>
                            <TableCell
                              className="muiTdata bronze"
                              align="center"
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip">
                                    {row.no_of_bronzebadges} bronze earned by{' '}
                                    {row.no_of_bronzeholders} employees
                                  </Tooltip>
                                }
                              >
                                <p className="count-main">
                                  {row.no_of_bronzebadges}{' '}
                                  <p className="inner-count">
                                    ({row.no_of_bronzeholders})
                                  </p>
                                </p>
                              </OverlayTrigger>
                            </TableCell>
                          </>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        ''
      )}

      <div className="nav-menu" style={{ display: 'flex', paddingTop: '8px' }}>
        <Link to="/badge">
          <Button
            className={
              localStorage.getItem('menu') == 'badge'
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
            }
          >
            Home
          </Button>
        </Link>
        <Link to="/badge/admin-badge">
          <Button
            className={
              localStorage.getItem('menu') == 'admin-badge'
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
            }
          >
            {' '}
            Badge List
          </Button>
        </Link>
        <Button
          className="m_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn"
          onClick={OpenAddModal}
        >
          Submit Request
        </Button>

        {localStorage.getItem('isApprover') == 'true' ? (
          <Link to="/badge/pending-request">
            {' '}
            <Button
              className={
                localStorage.getItem('menu') == 'pending-request'
                  ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                  : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
              }
            >
              {' '}
              Pending Requests
            </Button>
          </Link>
        ) : (
          <></>
        )}
        <Link to="/badge/my-badges">
          <Button
            className={
              localStorage.getItem('menu') == 'my-badges'
                ? 'm_r-5 m_t-10 nav-btn btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
            }
          >
            {' '}
            My Badges
          </Button>
        </Link>

        {route == undefined ? (
          <div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Badge Name
                </InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={badgeFilter}
                  label="badge-name"
                  onChange={(e) => handleBadgeFilter(e.target.value)}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {uqBadges?.sort().map((res) => {
                    return (
                      <MenuItem key={res} value={res}>
                        {res}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {route == 'pending-request' ? (
          <div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={badgeFilter}
                  label="badge-name"
                  onChange={(e) => handleBadgeStatusFilter(e.target.value)}
                >
                  {/*<MenuItem value="">
                      <em>Pending</em>
                    </MenuItem>*/}
                  <MenuItem key="Approved" value="Approved">
                    Approved
                  </MenuItem>
                  <MenuItem key="Pending" value="Pending">
                    Pending
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {route == undefined ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Badge Level
                </InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={badgeLevel}
                  label="badge-name"
                  onChange={(e) => handleBadgeLevel(e.target.value)}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {badgeLevels?.map((res) => {
                    return (
                      <MenuItem
                        key={res?.badge_level_name}
                        value={res?.badge_level_name}
                      >
                        {res?.badge_level_name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {route == undefined ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 90 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">PODs</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={pod}
                  label="pods"
                  onChange={(e) => handleSelectedPod(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {podsMenu?.sort()?.map((res) => {
                    return (
                      <MenuItem key={Math.random()} value={res}>
                        {res}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {route == undefined ? (
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">
                Show only my pods, see the pods you are part of and members in
                it
              </Tooltip>
            }
          >
            <div style={{ marginLeft: '10px', marginTop: '8px' }}>
              <Box sx={{ minWidth: 120 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={ifMyPods}
                        onChange={handleCheckMyPods}
                        sx={{
                          paddingRight: '8px',
                          letterSpacing: '1px',
                        }}
                      />
                    }
                    label="My PODs"
                  />
                </FormGroup>
              </Box>
            </div>
          </OverlayTrigger>
        ) : (
          <></>
        )}

        {route == undefined ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">Export Badges</Tooltip>}
          >
            <div style={{ marginLeft: 'auto' }}>
              <Button
                variant="outline-primary"
                // title="Export Data"
                className="export-btn m_r-5 m_t-10 "
                onClick={() => exportFunc()}
              >
                <span className="toolbar-icon">
                  <BiExport />
                </span>{' '}
                Export
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          <></>
        )}
        {route === 'admin-badge' ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">Export Badges</Tooltip>}
          >
            <div style={{ marginLeft: 'auto' }}>
              <Button
                variant="outline-primary"
                // title="Export Data"
                className="export-btn m_r-5 m_t-10 "
                onClick={() => exportFunc()}
              >
                <span className="toolbar-icon">
                  <BiExport />
                </span>{' '}
                Export
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          <></>
        )}
        {route === 'users' ? (
          <OverlayTrigger
            overlay={<Tooltip id="tooltip">Export Users</Tooltip>}
          >
            <div style={{ marginLeft: 'auto' }}>
              <Button
                variant="outline-primary"
                // title="Export Data"
                className="export-btn m_r-5 m_t-10 "
                onClick={() => exportFunc()}
              >
                <span className="toolbar-icon">
                  <BiExport />
                </span>{' '}
                Export
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          <></>
        )}
      </div>
      {loadingStatus.status == true ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Modal size="xl" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Submit Request</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
              <SubmitRequest
                setShowModal={setShowModal}
                setLoadingStatus={setLoadingStatus}
                channel={channel}
              />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  )
}
