import {
  GET_BADGES_USER,
  GET_BADGES_USER_ERROR,
  GET_BADGES_USER_LOAD,
  GET_BADGES_ADMIN,
  GET_BADGES_ADMIN_ERROR,
  GET_ADMIN_BADGES_LOAD,
  GET_MY_BADGES_LOAD,
  GET_MY_BADGES_ERROR,
  GET_MY_BADGES,
  GET_PENDING_BADGES_LOAD,
  GET_PENDING_BADGES,
  GET_PENDING_BADGE_ERROR,
  ADD_BADGE_LOAD,
  ADD_BADGE_ERROR,
  ADD_BADGE_POST,
  EDIT_BADGE_LOAD,
  EDIT_BADGE_ERROR,
  EDIT_BADGE_POST,
  EDIT_ADMIN_BADGE_ERROR,
  EDIT_ADMIN_BADGE_POST,
  EDIT_ADMIN_BADGE_LOAD,
  GET_SUMMARY_BADGES,
  GET_SUMMARY_BADGES_LOAD,
  GET_BADGE_TOP_EARNERS,
  GET_BADGE_TOP_EARNERS_LOAD,
  GET_BADGE_TOP_EARNERS_ERROR,
  GET_SUMMARY_BADGE_ERROR,
  FILE_EXPORT_BADGE_ERROR,
  FILE_EXPORT_BADGE_SUCCESS,
  FILE_LOADING_BADGE,
  EDIT_MY_BADGE_ERROR,
  EDIT_MY_BADGE_LOAD,
  EDIT_MY_BADGE_POST,
  BADGE_REQUEST_ERROR,
  BADGE_REQUEST_LOAD,
  BADGE_REQUEST_POST,
  EDIT_BADGE_APPROVAL_LOAD,
  EDIT_BADGE_APPROVAL_ERROR,
  EDIT_BADGE_APPROVAL_POST,
  IS_APPROVER_ERROR,
  IS_APPROVER_LOAD,
  IS_APPROVER_POST,
  BADGE_RESET,
  DELETE_BADGE_ERROR,
  DELETE_BADGE,
  DELETE_BADGE_LOAD,
  GET_ALL_USER_ERROR,
  GET_ALL_USER,
  GET_ALL_USER_LOAD,
  EDIT_USER_ERROR,
  EDIT_USER,
  EDIT_USER_LOAD,
  GET_ALL_COUNTRY_ERROR,
  GET_ALL_COUNTRY,
  GET_ALL_COUNTRY_LOAD,
  GET_USER_KUDOS,
  GET_USER_KUDOS_LOADING,
  GET_USER_KUDOS_ERROR,
} from '../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getBadgeusers = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BADGES_USER_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/badges/get_user_profiles`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_BADGES_USER,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_BADGES_USER_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getBadgesForAdmin = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADMIN_BADGES_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/badges/get_static_badges`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_BADGES_ADMIN,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_BADGES_ADMIN_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getMyBadges = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MY_BADGES_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/badges/get_my_badges`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_MY_BADGES,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_MY_BADGES_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getPendingBadges = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PENDING_BADGES_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/badges/get_pending_badges`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_PENDING_BADGES,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_PENDING_BADGE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const createBadge = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_BADGE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/badges/create_badge`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_BADGE_POST,
          payload: res.data,
        })
        toast('Badge Added', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_BADGE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const editBadge = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BADGE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/badges/edit_badge`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_BADGE_POST,
          payload: res.data,
        })
        toast('Badge Edited', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: EDIT_BADGE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getBadgesSummary = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUMMARY_BADGES_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/badges/get_company_khc_group_summary`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SUMMARY_BADGES,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_SUMMARY_BADGE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getBadgeTopEarners = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_BADGE_TOP_EARNERS_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/badges/get_badge_top_earners`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_BADGE_TOP_EARNERS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_BADGE_TOP_EARNERS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const exportFileBadge =
  (endpoint, payload, params) => async (dispatch) => {
    try {
      dispatch({
        type: FILE_LOADING_BADGE,
      })
      let post_headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      await axios
        .post(
          `${endpoint}/api/badges/export_get_user_profiles_view_export`,
          payload,
          {
            params: params,
            headers: post_headers,
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `badgeData.xlsx`)
          document.body.appendChild(link)
          link.click()
          toast('Export Successfully', toastTopRightSuccess)
          dispatch({
            type: FILE_EXPORT_BADGE_SUCCESS,
            payload: response.data,
          })
        })
    } catch (e) {
      dispatch({
        type: FILE_EXPORT_BADGE_ERROR,
        payload: console.log(e),
      })
      toast(e, toastTopRightError)
    }
  }

export const exportbadgelist =
  (endpoint, payload, params) => async (dispatch) => {
    try {
      dispatch({
        type: FILE_LOADING_BADGE,
      })
      let post_headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      await axios
        .post(`${endpoint}/api/badges/export_badge_list`, payload, {
          params: params,
          headers: post_headers,
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `BadgeList.xlsx`)
          document.body.appendChild(link)
          link.click()
          toast('Export Successfully', toastTopRightSuccess)
          dispatch({
            type: FILE_EXPORT_BADGE_SUCCESS,
            payload: response.data,
          })
        })
    } catch (e) {
      dispatch({
        type: FILE_EXPORT_BADGE_ERROR,
        payload: console.log(e),
      })
      toast(e, toastTopRightError)
    }
  }

export const exportAdminUsers =
  (endpoint, payload, params) => async (dispatch) => {
    try {
      dispatch({
        type: FILE_LOADING_BADGE,
      })
      let post_headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Disposition': 'attachment; filename=template.xlsx',
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      await axios
        .post(`${endpoint}/api/badges/export_users_admin`, payload, {
          params: params,
          headers: post_headers,
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Users.xlsx`)
          document.body.appendChild(link)
          link.click()
          toast('Export Successfully', toastTopRightSuccess)
          dispatch({
            type: FILE_EXPORT_BADGE_SUCCESS,
            payload: response.data,
          })
        })
    } catch (e) {
      dispatch({
        type: FILE_EXPORT_BADGE_ERROR,
        payload: console.log(e),
      })
      toast(e, toastTopRightError)
    }
  }

export const editMyBadge = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_MY_BADGE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/badges/edit_badge_request`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_MY_BADGE_POST,
          payload: res.data,
        })
        toast('Badge Edited', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: EDIT_MY_BADGE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const editBadgeApproved = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_ADMIN_BADGE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/badges/edit_badge_approval`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_ADMIN_BADGE_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: EDIT_ADMIN_BADGE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const createBadgeRequest = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: BADGE_REQUEST_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/badges/submit_badge_request`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: BADGE_REQUEST_POST,
          payload: res.data,
        })
        toast('Badge Request Submitted', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: BADGE_REQUEST_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const badgeApproval = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_BADGE_APPROVAL_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/badges/handle_badge_approval`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_BADGE_APPROVAL_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: EDIT_BADGE_APPROVAL_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const addDataResetBadge = () => async (dispatch) => {
  dispatch({
    type: BADGE_RESET,
  })
}
export const isBadgeApprover = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: IS_APPROVER_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/badges/is_authorized_badge_approver`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: IS_APPROVER_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: IS_APPROVER_ERROR,
      payload: e.response.data.message,
    })
  }
}
export const deleteBadge = (endpoint, id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_BADGE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .delete(`${endpoint}/api/badges/delete_badge_request?id=${id}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: DELETE_BADGE,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: DELETE_BADGE_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getAllusers = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USER_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/user/get_users_admin`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_USER,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_ALL_USER_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const editUser = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_USER_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/user/edit_user`, params, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_USER,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: EDIT_USER_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getCountryCount = (endpoint) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_COUNTRY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}`, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_COUNTRY,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_ALL_COUNTRY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getUserKudos = (endpoint, value) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_KUDOS_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(
        `${endpoint}/api/badges/get_user_kudos?filters=[{"op":"eq","field":"receiver_email","value":"${value}"}]`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_USER_KUDOS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_USER_KUDOS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
