import {
  PGP_FILE_UPLOAD_BEGIN,
  PGP_FILE_UPLOAD_FAIL,
  PGP_FILE_UPLOAD_SUCCESS,
} from '../actions/pgpEncryptionAction'

const initialState = {
  pgpUploadData: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PGP_FILE_UPLOAD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case PGP_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        pgpUploadData: action.payload,
        loading: false,
        error: null,
      }
    case PGP_FILE_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
