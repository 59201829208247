import React, { useEffect, useContext } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from '../../context/authProvider'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../Helper/ToastObjects'
import { toast } from 'react-toastify'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  getPodById,
  podApproval,
} from '../../../redux/actions/podsPageActions/podsAction'
import { ForumHandler } from '../../Utils'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

const PodRequestDetails = ({
  editRows,
  podApprovers,
  fetchData,
  pageSize,
  pageIndex,
  setShowRequestDetails,
}) => {
  const [authCreds] = useContext(AuthContext)
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  const dispatch = useDispatch()
  const podById = useSelector((state) => state.podById)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  // existing
  const [podBuilders, setPodBuilders] = React.useState([])
  const [podStakeholders, setPodStakeholders] = React.useState([])
  const [podProductOwners, setPodProductOwners] = React.useState([])

  // new
  const [builders, setBuilders] = React.useState([])
  const [stakeholders, setStakeholders] = React.useState([])
  const [productOwners, setProductOwners] = React.useState([])

  const [comments, setComments] = React.useState(editRows[0]?.comments)

  const endpoint = authCreds.restEndpoint

  const handlePodApproval = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (
      tokenValue?.roles?.includes('khdapi.Admin') ||
      podApprovers?.podApprovers?.data?.includes(
        tokenValue?.preferred_username.toLowerCase()
      ) ||
      (podBuilders ?? [])
        .concat(podProductOwners ?? [], podStakeholders ?? [])
        .includes(tokenValue?.preferred_username.toLowerCase())
    ) {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      const body = {
        id: editRows[0].id,
        pod_id: editRows[0].pod_id,
        pod_name: editRows[0].pod_name,
        description: editRows[0].description,
        functional_area: editRows[0].functional_area,
        is_active: editRows[0].is_active,
        status: editRows[0].status,
        builders_html: editRows[0].builders_html,
        stakeholders_html: editRows[0].stakeholders_html,
        product_owners_html: editRows[0].product_owners_html,
        approved_on: editRows[0].approved_on,
        approved_by: editRows[0].approved_by,
        created_on: editRows[0].created_on,
        created_by: editRows[0].created_by,
        modified_on: editRows[0].modified_on,
        modified_by: editRows[0].modified_by,
        user_email: tokenValue.preferred_username.toLowerCase(),
        comments: '',
        is_approve: true,
        pod_members: {
          builders: builders,
          stakeholders: stakeholders,
          product_owners: productOwners,
        },
      }

      dispatch(podApproval(endpoint, body)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        toast('Pod approved', toastTopRightSuccess)
        setShowRequestDetails(false)
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([
            { op: 'eq', field: 'status', value: `Pending` },
            { op: 'eq', field: 'status', value: `Rejected` },
          ])
        )
      })
    } else {
      toast(
        `Only admins and Pod approvers are allowed to approve. For List of pod approvers check in Pods help: click the '❔' symbol`,
        toastTopRightError
      )
    }
  }

  const handlePodRejection = async () => {
    if (
      tokenValue?.roles?.includes('khdapi.Admin') ||
      podApprovers?.podApprovers?.data?.includes(
        tokenValue?.preferred_username.toLowerCase()
      ) ||
      (podBuilders ?? [])
        .concat(podProductOwners ?? [], podStakeholders ?? [])
        .includes(tokenValue?.preferred_username.toLowerCase())
    ) {
      if (
        comments !== undefined &&
        comments?.length !== 0 &&
        comments !== null
      ) {
        setLoadingStatus({ status: true, message: 'Fetching data...' })
        const body = {
          id: editRows[0].id,
          pod_id: editRows[0].pod_id,
          pod_name: editRows[0].pod_name,
          description: editRows[0].description,
          functional_area: editRows[0].functional_area,
          is_active: editRows[0].is_active,
          status: editRows[0].status,
          builders_html: editRows[0].builders_html,
          stakeholders_html: editRows[0].stakeholders_html,
          product_owners_html: editRows[0].product_owners_html,
          approved_on: editRows[0].approved_on,
          approved_by: editRows[0].approved_by,
          created_on: editRows[0].created_on,
          created_by: editRows[0].created_by,
          modified_on: editRows[0].modified_on,
          modified_by: editRows[0].modified_by,
          user_email: tokenValue.preferred_username.toLowerCase(),
          comments: comments,
          is_approve: false,
          pod_members: {
            builders: builders,
            stakeholders: stakeholders,
            product_owners: productOwners,
          },
        }

        dispatch(podApproval(endpoint, body)).then(() => {
          setLoadingStatus({ status: false, message: 'Fetching data...' })
          toast('Pod Rejected', toastTopRightError)
          setShowRequestDetails(false)
          fetchData(
            { pageSize, pageIndex },
            JSON.stringify([
              { op: 'eq', field: 'status', value: `Pending` },
              { op: 'eq', field: 'status', value: `Rejected` },
            ])
          )
        })
      } else {
        toast(
          `Please add a comment before rejecting the request`,
          toastTopRightError
        )
      }
    } else {
      toast(
        `Only admins and Pod approvers are allowed to reject. For List of pod approvers check in Pods help: click the '❔' symbol`,
        toastTopRightError
      )
    }
  }

  useEffect(() => {
    if (editRows[0]?.is_active === true) {
      setLoadingStatus({ status: true, message: 'Fetching table records...' })

      dispatch(getPodById(endpoint, editRows[0]?.pod_id))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
    }
  }, [])

  useEffect(() => {
    if (!podById.loading) {
      //set members data from pods table
      const podStakeholderMatches =
        podById?.podById?.data?.pod_data?.stakeholders_html?.match(
          ForumHandler.userHTMLCharacters
        )
      const podProductOwnerMatches =
        podById?.podById?.data?.pod_data?.product_owners_html?.match(
          ForumHandler.userHTMLCharacters
        )
      const podBuilderMatches =
        podById?.podById?.data?.pod_data?.builders_html?.match(
          ForumHandler.userHTMLCharacters
        )

      let podStakeholderValues = podStakeholderMatches
        ? podStakeholderMatches.map((mention) => mention.slice(0))
        : []
      setPodStakeholders(podStakeholderValues)
      let podProductOwnerValues = podProductOwnerMatches
        ? podProductOwnerMatches.map((mention) => mention.slice(0))
        : []
      setPodProductOwners(podProductOwnerValues)

      let podBuilderValues = podBuilderMatches
        ? podBuilderMatches.map((mention) => mention.slice(0))
        : []
      setPodBuilders(podBuilderValues)
    }
  }, [podById])

  useEffect(() => {
    //set members data from pod_edits table
    const stakeholderMatches = editRows[0]?.stakeholders_html?.match(
      ForumHandler.userHTMLCharacters
    )
    const productOwnerMatches = editRows[0]?.product_owners_html?.match(
      ForumHandler.userHTMLCharacters
    )
    const builderMatches = editRows[0]?.builders_html?.match(
      ForumHandler.userHTMLCharacters
    )

    let stakeholderValues = stakeholderMatches
      ? stakeholderMatches.map((mention) => mention.slice(0))
      : []
    setStakeholders(stakeholderValues)
    let productOwnerValues = productOwnerMatches
      ? productOwnerMatches.map((mention) => mention.slice(0))
      : []
    setProductOwners(productOwnerValues)

    let builderValues = builderMatches
      ? builderMatches.map((mention) => mention.slice(0))
      : []
    setBuilders(builderValues)
  }, [editRows])

  const handleComments = (e) => {
    setComments(e.target.value)
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <div className="pod-top-bar">
            <>
              {editRows[0]?.is_active === true ? (
                <p className="pod-text">
                  Created by:{' '}
                  <span className="created-by">{editRows[0]?.modified_by}</span>
                </p>
              ) : (
                <p className="pod-text">
                  Created by:{' '}
                  <span className="created-by">{editRows[0]?.created_by}</span>
                </p>
              )}
            </>
            <div className="legend-container">
              <span className="legend-edited">Edited/Removed</span>
              <span className="legend-added">Added</span>
            </div>
          </div>
          <div className="pod-request-details">
            {editRows[0]?.is_active === true ? (
              <>
                <div className="pod-card">
                  <p className="card-head">CURRENT</p>
                  <div className="details-container">
                    <p className="card-sub-head">Pod Name</p>
                    <p
                      className={
                        podById?.podById?.data?.pod_data?.pod_name !==
                        editRows[0]?.pod_name
                          ? 'pod-text edited'
                          : 'pod-text'
                      }
                    >
                      {podById?.podById?.data?.pod_data?.pod_name}
                    </p>

                    <p className="card-sub-head">Description</p>
                    <p
                      className={
                        podById?.podById?.data?.pod_data?.description !==
                        editRows[0]?.description
                          ? 'pod-text edited'
                          : 'pod-text'
                      }
                    >
                      {podById?.podById?.data?.pod_data?.description.length == 0
                        ? 'None'
                        : podById?.podById?.data?.pod_data?.description}
                    </p>

                    <p className="card-sub-head">Functional Area</p>
                    <p
                      className={
                        podById?.podById?.data?.pod_data?.functional_area !==
                        editRows[0]?.functional_area
                          ? 'pod-text edited'
                          : 'pod-text'
                      }
                    >
                      {podById?.podById?.data?.pod_data?.functional_area
                        ?.length == 0
                        ? 'None'
                        : podById?.podById?.data?.pod_data?.functional_area}
                    </p>

                    <div className="members-container">
                      <p className="card-sub-head">Builders</p>
                      <div className="pod-member-container">
                        {podBuilders?.map((builder) => {
                          return (
                            <p key={Math.random()} className="pod-member">
                              {builder}
                            </p>
                          )
                        })}
                      </div>

                      <p className="card-sub-head">Product Owners</p>
                      <div className="pod-member-container">
                        {podProductOwners?.map((owner) => {
                          return (
                            <p key={Math.random()} className="pod-member">
                              {owner}
                            </p>
                          )
                        })}
                      </div>

                      <p className="card-sub-head">Stakeholders</p>
                      <div className="pod-member-container">
                        {podStakeholders?.map((stakeholder) => {
                          return (
                            <p key={Math.random()} className="pod-member">
                              {stakeholder}
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="pod-card">
              <p className="card-head">INCOMING</p>
              <div className="details-container">
                <p className="card-sub-head">Pod Name</p>
                <p
                  className={
                    podById?.podById?.data?.pod_data?.pod_name !==
                    editRows[0]?.pod_name
                      ? 'pod-text changed'
                      : 'pod-text'
                  }
                >
                  {editRows[0]?.pod_name}
                </p>

                <p className="card-sub-head">Description</p>
                <p
                  className={
                    podById?.podById?.data?.pod_data?.description !==
                    editRows[0]?.description
                      ? 'pod-text changed'
                      : 'pod-text'
                  }
                >
                  {editRows[0]?.description?.length == 0
                    ? 'None'
                    : editRows[0]?.description}
                </p>

                <p className="card-sub-head">Functional Area</p>
                <p
                  className={
                    podById?.podById?.data?.pod_data?.functional_area !==
                    editRows[0]?.functional_area
                      ? 'pod-text changed'
                      : 'pod-text'
                  }
                >
                  {editRows[0]?.functional_area?.length == 0
                    ? 'None'
                    : editRows[0]?.functional_area}
                </p>

                <div className="members-container">
                  <p className="card-sub-head">Builders</p>
                  <div className="pod-member-container">
                    {builders?.map((builder) => {
                      return (
                        <>
                          {!podBuilders.includes(builder) ? (
                            <p
                              key={Math.random()}
                              className="pod-member changed"
                            >
                              {builder}
                            </p>
                          ) : (
                            <p key={Math.random()} className="pod-member">
                              {builder}
                            </p>
                          )}
                        </>
                      )
                    })}

                    {editRows[0]?.is_active === true ? (
                      <>
                        {podBuilders?.map((builder) => {
                          return (
                            <>
                              {!builders.includes(builder) ? (
                                <p
                                  key={Math.random()}
                                  className="pod-member edited"
                                >
                                  {builder}
                                </p>
                              ) : (
                                <></>
                              )}
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <p className="card-sub-head">Product Owners</p>
                  <div className="pod-member-container">
                    {productOwners?.map((owner) => {
                      return (
                        <>
                          {!podProductOwners.includes(owner) ? (
                            <p
                              key={Math.random()}
                              className="pod-member changed"
                            >
                              {owner}
                            </p>
                          ) : (
                            <p key={Math.random()} className="pod-member">
                              {owner}
                            </p>
                          )}
                        </>
                      )
                    })}

                    {editRows[0]?.is_active === true ? (
                      <>
                        {podProductOwners?.map((owner) => {
                          return (
                            <>
                              {!productOwners.includes(owner) ? (
                                <p
                                  key={Math.random()}
                                  className="pod-member edited"
                                >
                                  {owner}
                                </p>
                              ) : (
                                <></>
                              )}
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <p className="card-sub-head">Stakeholders</p>
                  <div className="pod-member-container">
                    {stakeholders?.map((stakeholder) => {
                      return (
                        <>
                          {!podStakeholders.includes(stakeholder) ? (
                            <p
                              key={Math.random()}
                              className="pod-member changed"
                            >
                              {stakeholder}
                            </p>
                          ) : (
                            <p key={Math.random()} className="pod-member">
                              {stakeholder}
                            </p>
                          )}
                        </>
                      )
                    })}

                    {editRows[0]?.is_active === true ? (
                      <>
                        {podStakeholders?.map((stakeholder) => {
                          return (
                            <>
                              {!stakeholders.includes(stakeholder) ? (
                                <p
                                  key={Math.random()}
                                  className="pod-member edited"
                                >
                                  {stakeholder}
                                </p>
                              ) : (
                                <></>
                              )}
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Box
            sx={{
              width: '98%',
              maxWidth: '100%',
              margin: '20px 10px',
            }}
          >
            <TextField
              multiline
              fullWidth
              label="Add a comment"
              id="comments"
              value={comments}
              onChange={handleComments}
            />
          </Box>

          <Modal.Footer>
            <Button
              variant="primary"
              className="addModalBtn"
              type="submit"
              onClick={handlePodApproval}
            >
              Approve
            </Button>
            {editRows[0]?.status !== 'Rejected' ? (
              <Button
                variant="primary"
                className="addModalBtn"
                type="submit"
                onClick={handlePodRejection}
              >
                Reject
              </Button>
            ) : (
              <Button
                disabled
                variant="primary"
                className="addModalBtn"
                type="submit"
                onClick={handlePodRejection}
              >
                Reject
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </>
  )
}

export default PodRequestDetails
