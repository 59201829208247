import {
  GET_GLOSSARY_TERMS,
  GET_GLOSSARY_TERMS_ERROR,
  GET_GLOSSARY_TERMS_LOAD,
  GET_SEARCH_GLOSSARY_TERMS,
  GET_SEARCH_GLOSSARY_TERMS_LOAD,
  GET_SEARCH_GLOSSARY_TERMS_ERROR,
} from '../constants/types'

const initialState = {
  glossaryTerms: [],
  error: null,
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GLOSSARY_TERMS_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_GLOSSARY_TERMS:
      return {
        ...state,
        loading: false,
        glossaryTerms: action.payload,
        error: null,
      }
    case GET_GLOSSARY_TERMS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_SEARCH_GLOSSARY_TERMS_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_SEARCH_GLOSSARY_TERMS:
      return {
        ...state,
        loading: false,
        glossaryTerms: action.payload,
        error: null,
      }
    case GET_SEARCH_GLOSSARY_TERMS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
