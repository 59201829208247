import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CelebrationIcon from '@mui/icons-material/Celebration'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'

const MembersTable = ({ columns, members, openUserprofile }) => {
  // return gold badges
  function getGoldComp(n) {
    if (n > 3) {
      var MultiGold = []
      Array.from(Array(3), (e, i) => {
        MultiGold.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#FFD700' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {MultiGold}{' '}
          <p
            style={{
              background: '#ffd70085',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleGold = []
      Array.from(Array(n), (e, i) => {
        singleGold.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#FFD700' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleGold}
        </div>
      )
    }
  }
  // return silver badges
  function getSilverComp(n) {
    if (n > 3) {
      var multiSilver = []
      Array.from(Array(3), (e, i) => {
        multiSilver.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {multiSilver}{' '}
          <p
            style={{
              background: '#C0C0C0',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleSilver = []
      Array.from(Array(n), (e, i) => {
        singleSilver.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleSilver}
        </div>
      )
    }
  }
  // return bronze badges
  function getBronzeComp(n) {
    if (n > 3) {
      var multiBronze = []
      Array.from(Array(3), (e, i) => {
        multiBronze.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#CD7F32' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {multiBronze}{' '}
          <p
            style={{
              background: '#CD7F32',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleBronze = []
      Array.from(Array(n), (e, i) => {
        singleBronze.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#CD7F32' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleBronze}
        </div>
      )
    }
  }
  // return kudos count
  function getKudosComp(n) {
    if (n > 3) {
      var multiKudos = []
      Array.from(Array(3), (e, i) => {
        multiKudos.push(
          <CelebrationIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {multiKudos}{' '}
          <p
            style={{
              background: '#C0C0C0',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleKudos = []
      Array.from(Array(n), (e, i) => {
        singleKudos.push(
          <CelebrationIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleKudos}
        </div>
      )
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={Math.random()} className="cell" align="center">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {members?.map((row) => (
            <TableRow
              key={row?.user_email}
              hover
              onClick={() => {
                openUserprofile(row)
              }}
              style={{ cursor: 'pointer' }}
            >
              <TableCell className="cell" align="center">
                {row?.user_email}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.role_type}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.title}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.Gold === 0 ? <p>--</p> : <>{getGoldComp(row?.Gold)}</>}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.Silver === 0 ? (
                  <p>--</p>
                ) : (
                  <>{getSilverComp(row?.Silver)}</>
                )}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.Bronze === 0 ? (
                  <p>--</p>
                ) : (
                  <>{getBronzeComp(row?.Bronze)}</>
                )}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.kudos_count === 0 ? (
                  <p>--</p>
                ) : (
                  <>{getKudosComp(row?.kudos_count)}</>
                )}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.company}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.khc_group}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.country}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.city}
              </TableCell>
              <TableCell className="cell" align="center">
                {row?.time_in_position}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MembersTable
