import Nav from './badgeNavbar'
import React, { useEffect, useMemo, useContext, useState } from 'react'
import CelebrationIcon from '@mui/icons-material/Celebration'
import '../RefData/RefTable.css'
import '../Kudos/kudos.css'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightSuccess } from '../../Helper/ToastObjects'

import UserProfile from '../Users/userProfileModal'
//import { useIsAuthenticated } from "@azure/msal-react"
import { Table, Col, Row, Dropdown, Form, Modal, Button } from 'react-bootstrap'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import '../../../App.css'
import './index.css'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../AppBar/Appbar'
import {
  getBadgeusers,
  getBadgesSummary,
  exportFileBadge,
  isBadgeApprover,
  getCountryCount,
  getUserKudos,
} from '../../../redux/actions/badgeAction'
import { getPods } from '../../../redux/actions/podsPageActions/podsAction'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '10px',
  },
}))

export function BadgeDashboard() {
  const table_name = 'BADGE'
  const columnMetadata = {
    BADGE: {
      fields: [
        {
          name: 'user_email',
          headerTitle: 'USER EMAIL',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'Gold',
          headerTitle: 'GOLD',
          is_editable: true,
          is_filterable: false,
          enableSorting: false,
          size: 10,
        },
        {
          name: 'Silver',
          headerTitle: 'SILVER',
          is_editable: true,
          is_filterable: false,
          enableSorting: false,
          size: 10,
        },
        {
          name: 'Bronze',
          headerTitle: 'BRONZE',
          is_editable: true,
          is_filterable: false,
          enableSorting: false,
          size: 10,
        },
        {
          name: 'kudos_count',
          headerTitle: 'KUDOS',
          is_editable: true,
          is_filterable: false,
          enableSorting: false,
          size: 10,
        },
        {
          name: 'company',
          headerTitle: 'COMPANY',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'khc_group',
          headerTitle: 'KHC GROUP',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'pod_name',
          headerTitle: 'PODS',
          is_editable: false,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'title',
          headerTitle: 'TITLE',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'country',
          headerTitle: 'COUNTRY',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
        {
          name: 'time_in_position',
          headerTitle: 'TIME IN POSITION',
          is_editable: true,
          is_filterable: true,
          size: 10,
        },
      ],
      tag: 'badge',
    },
  }

  const hiddenColumns = {
    BADGE: [],
  }
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [authCreds] = useContext(AuthContext)
  const [editRows, setEditRows] = React.useState([])
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [refData, setRefData] = useState({
    columnMetadata: columnMetadata,
    hiddenColumns: hiddenColumns,
  })
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [uqBadges, setUqBadges] = React.useState()
  const [summaryKraft, setBadgeSumKraft] = React.useState()
  const [summaryVendor, setBadgeSumVendor] = React.useState()
  const [badgeFilter, setBadgeFilter] = React.useState('')
  const [editModeState, setEditModeState] = React.useState(false)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [inlineValue, setInlineValue] = React.useState([])
  const [badgeLevels, setBadgeLevels] = React.useState()
  const [badgeLevel, setBadgeLevel] = React.useState('')
  const [isAboutMeEdited, setIsAboutMeEdited] = React.useState(false)
  const [isLocalUser, setIsLocalUser] = React.useState(false)
  const [pod, setPod] = React.useState('')
  const [ifMyPods, setIfMyPods] = React.useState(false)
  const [podsMenu, setPodsMenu] = React.useState([])

  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const userBadges = useSelector((state) => state.userBadges)
  const isApprover = useSelector((state) => state.isApprover)
  const adminBadges = useSelector((state) => state.adminBadges)
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const countryData = useSelector((state) => state.countryData)
  const [showModal, setShowModal] = React.useState(false)
  const [emailModal, setemailModal] = React.useState(false)
  const [badgeData, setbadgeData] = React.useState()
  const [countryVal, setCountryVal] = React.useState()
  const summaryData = useSelector((state) => state.summaryData)
  const podsMenuData = useSelector((state) => state.podsMenuData)
  const userKudos = useSelector((state) => state.userKudos)

  let tokenValue
  let tokenValues = localStorage.getItem('token')
  if (tokenValues) {
    tokenValue = jwt_decode(tokenValues)
  }

  const [channel, setChannel] = React.useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )

  //main code

  const handleCloseEmailModal = () => {
    setemailModal(false)
  }

  const visibleCol = [
    'user_email',
    'Gold',
    'Silver',
    'Bronze',
    'company',
    'khc_group',
    'title',
    'country',
    'time_in_position',
  ]

  useEffect(() => {
    async function forumMetadata() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })
      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=BADGE`
      dispatch(getForumMetadata(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    async function podsMetadata() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      dispatch(
        getPods(endpoint, tokenValue?.preferred_username?.toLowerCase())
      ).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    podsMetadata()
  }, [])

  useEffect(() => {
    if (ifMyPods) {
      setPodsMenu(podsMenuData?.podsMenuData?.data?.my_pods)
    } else {
      setPodsMenu(podsMenuData?.podsMenuData?.data?.all_pods)
    }
  }, [ifMyPods, podsMenuData?.podsMenuData])

  useEffect(() => {
    if (!localStorage.getItem('badge_metadata')) {
      toast('Redirecting to home for getting metadata', toastTopRightSuccess)
    }
  }, [])

  useEffect(() => {
    async function CountryAPI() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })
      let url =
        endpoint +
        `/api/badges/users_count_by_country?filters=[{"op":"eq","field":"role_type","value":"${channel}"}]`
      dispatch(getCountryCount(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    CountryAPI()
  }, [channel])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    if (countryData?.loading === false) {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      setCountryVal(countryData?.countryData?.data)
    }
  }, [countryData, channel, countryVal])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    if (forumMetadata?.loading === false) {
      setLoadingStatus({ status: false, message: 'Fetching data...' })

      localStorage.setItem(
        'badge_metadata',
        JSON.stringify(forumMetadata?.forumMetadata)
      )
    }
  }, [forumMetadata])
  function getKudosComp(n) {
    if (n > 3) {
      var multiKudos = []
      Array.from(Array(3), (e, i) => {
        multiKudos.push(
          <CelebrationIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {multiKudos}{' '}
          <p
            style={{
              background: '#C0C0C0',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleKudos = []
      Array.from(Array(n), (e, i) => {
        singleKudos.push(
          <CelebrationIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleKudos}
        </div>
      )
    }
  }
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    if (userKudos?.loading === false) {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    }
  }, [userKudos])
  // given a column value, it updates hidden column list
  const hiddenColumnListHandler = (value) => {
    if (value == 'Select All') {
      if (refData.hiddenColumns[table_name].includes('SELECTALL')) {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = TableHandler.getAllColumns(
            columnMetadata,
            table_name
          )

          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      } else {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = ['SELECTALL']

          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      }
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'user_email',
            'Gold',
            'Silver',
            'Bronze',
            'company',
            'title',
            'khc_group',
            'country',
            'time_in_position',
          ],
          columnMetadata,
          table_name
        )
      )
    } else {
      let item_index = -1
      let new_list = []
      refData.hiddenColumns[table_name].forEach((item, index) => {
        if (item === value) item_index = index
        else new_list.push(item)
      })

      if (item_index < 0) {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = [...prev_value.hiddenColumns[table_name], value]

          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      } else {
        setRefData((prev_value) => {
          let obj = { ...prev_value.hiddenColumns }
          obj[table_name] = new_list

          return {
            ...prev_value,
            hiddenColumns: obj,
          }
        })
      }

      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'user_email',
            'Gold',
            'Silver',
            'Bronze',
            'company',
            'title',
            'khc_group',
            'country',
            'time_in_position',
          ],
          columnMetadata,
          table_name
        )
      )
    }
  }
  const getUserName = (email) => {
    if (!email) return ''
    return email.split('@')[0]
  }
  const navigateToAddQuestion = (userID, userName) => {
    history('/add-question', { state: { from: 'users', userID, userName } })
  }
  function getGoldComp(n) {
    if (n > 3) {
      var MultiGold = []
      Array.from(Array(3), (e, i) => {
        MultiGold.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#FFD700' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {MultiGold}{' '}
          <p
            style={{
              background: '#ffd70085',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleGold = []
      Array.from(Array(n), (e, i) => {
        singleGold.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#FFD700' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleGold}
        </div>
      )
    }
  }
  function getSilverComp(n) {
    if (n > 3) {
      var multiSilver = []
      Array.from(Array(3), (e, i) => {
        multiSilver.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {multiSilver}{' '}
          <p
            style={{
              background: '#C0C0C0',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleSilver = []
      Array.from(Array(n), (e, i) => {
        singleSilver.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#C0C0C0' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleSilver}
        </div>
      )
    }
  }
  function getBronzeComp(n) {
    if (n > 3) {
      var multiBronze = []
      Array.from(Array(3), (e, i) => {
        multiBronze.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#CD7F32' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {multiBronze}{' '}
          <p
            style={{
              background: '#CD7F32',
              borderRadius: '32px',
              padding: '4px',
              margin: '1px',
              fontWeight: 700,
            }}
          >
            {' '}
            + {n - 3}
          </p>
        </div>
      )
    } else {
      var singleBronze = []
      Array.from(Array(n), (e, i) => {
        singleBronze.push(
          <MilitaryTechIcon
            key={i}
            className="icons"
            style={{ background: '#CD7F32' }}
          />
        )
      })
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {singleBronze}
        </div>
      )
    }
  }
  useEffect(() => {
    localStorage.setItem('menu', 'badge')
  }, [])
  useEffect(() => {
    if (adminBadges?.loading === false) {
      setUqBadges(adminBadges?.adminBadges?.unique_badge_name)
    }
  }, [adminBadges])
  let history = useNavigate()

  const handleDeleteEditMode = () => {
    setEditModeState(false)
  }

  //Update using Inline Editing
  const handleUpdateEditMode = () => {
    editRows.push(inlineValue)

    // updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }
  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)
    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload =
          badgeFilter == ''
            ? [{ op: 'eq', field: 'role_type', value: `${channel}` }]
            : badgeFilter != '' && badgeLevel == ''
            ? [
                { op: 'eq', field: 'role_type', value: `${channel}` },
                { op: 'ilike', field: 'badge_name', value: `%${badgeFilter}%` },
              ]
            : [
                { op: 'eq', field: 'role_type', value: `${channel}` },
                { op: 'ilike', field: 'badge_name', value: `%${badgeFilter}%` },
                {
                  op: 'ilike',
                  field: 'badge_level_name',
                  value: `%${badgeLevel}%`,
                },
              ]
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 0) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }

  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [refData.hiddenColumns, tableData])
  const columns = useMemo(
    () => tableColumns,
    [refData.hiddenColumns, tableColumns]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: refData.hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    }
  )

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }

  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }
  const badgeLevelFunc = (value) => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(
        `${endpoint}/api/badges/get_static_badges?filters=[{"op":"eq","field":"badge_name","value":"${value}"},{"op":"eq","field":"role_type","value":"${channel}"}]`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
        setBadgeLevels(res?.data?.data)
      })
  }
  const exportTableData = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    let visibleCols = visibleCol
    let columnRawFilters = columnFilters

    if (columnRawFilters === null || columnRawFilters.length === 0) {
      columnRawFilters = [{ op: 'eq', field: 'role_type', value: `${channel}` }]
    }

    if (ifMyPods && pod.length === 0) {
      if (podsMenuData?.podsMenuData?.data?.my_pods?.length !== 0) {
        podsMenuData?.podsMenuData?.data?.my_pods.map((pod) => {
          columnRawFilters.push({
            op: 'ilike',
            field: 'pod_name',
            value: `${pod}`,
          })
        })
      }
    }

    if (pod) {
      columnRawFilters.push({ op: 'ilike', field: 'pod_name', value: `${pod}` })
    }

    const params = {
      filters: JSON.stringify(columnRawFilters),
      sort: JSON.stringify(columnSort),
    }
    dispatch(exportFileBadge(endpoint, visibleCols, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }

  // Used to fetch new table data from server
  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    show_deleted = false,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    setLocalPageIndex(pageIndex)
    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    dispatch(getBadgeusers(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  useEffect(() => {
    setLoadingStatus({
      status: true,
      message: 'Fetching table records...',
    })
    if (userBadges?.loading === false) {
      setLoadingStatus({
        status: false,
        message: 'Fetching table records...',
      })
      let table_data = userBadges?.userBadges?.data
      if (table_data?.length > 0) {
        setTableColumns(
          TableHandler.createColumnMappingforKholab(
            [
              'user_email',
              'Gold',
              'Silver',
              'Bronze',
              'company',
              'title',
              'khc_group',
              'country',
              'time_in_position',
            ],
            columnMetadata,
            table_name
          )
        )
        setTotalPages(userBadges?.userBadges?.num_pages)
        setTotalRows(userBadges?.userBadges?.total_results)
        setTableData(table_data)
      } else {
        setTotalPages(0)
        setTotalRows(0)
        setTableData([])
      }
    }
  }, [userBadges])
  useEffect(() => {
    if (isApprover?.loading === false) {
      localStorage.setItem(
        'isApprover',
        isApprover?.isApprover?.is_authorized_user
      )
    }
  }, [isApprover])

  const handleOpen = (cc) => {
    setbadgeData(cc.original)
    if (
      localStorage.getItem('user').toUpperCase() ==
      cc.original?.user_name.toUpperCase()
    ) {
      setIsLocalUser(true)
    } else {
      setIsLocalUser(false)
    }
    dispatch(getUserKudos(endpoint, cc.original?.user_email)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
    })
  }

  const openModal = () => {
    setShowModal(true)
  }
  const openemailModal = () => {
    setemailModal(true)
  }

  useEffect(() => {
    const param = {
      filters: JSON.stringify([
        { op: 'eq', field: 'role_type', value: `${channel}` },
      ]),
    }
    dispatch(getBadgesSummary(endpoint, param))
    dispatch(isBadgeApprover(endpoint))
  }, [channel])
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching Details...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    axios
      .get(`${endpoint}/api/badges/get_static_badges?role_type=${channel}`, {
        headers: post_headers,
      })
      .then((res) => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records....',
        })
        setUqBadges(res?.data?.unique_badge_name)
      })
  }, [channel])
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    if (summaryData?.loading == false) {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })

      setBadgeSumKraft(summaryData?.summaryData?.khc_summary_view?.data)
      setBadgeSumVendor(summaryData?.summaryData?.vendors_summary_view?.data)
    }
  }, [summaryData])

  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  // fetches new table data on pageIndex, pageSize change
  // fetches new table data on pageIndex, pageSize change
  useEffect(() => {
    let filter_payload = []
    if (badgeFilter == '') {
      if (pod) {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([
            { op: 'eq', field: 'role_type', value: `${channel}` },
            { op: 'ilike', field: 'pod_name', value: `${pod}` },
          ]),
          false,
          JSON.stringify(columnSort)
        )
      } else {
        filter_payload = [{ op: 'eq', field: 'role_type', value: `${channel}` }]
        if (ifMyPods) {
          if (podsMenuData?.podsMenuData?.data?.my_pods?.length !== 0) {
            podsMenuData?.podsMenuData?.data?.my_pods.map((pod) => {
              filter_payload.push({
                op: 'ilike',
                field: 'pod_name',
                value: `${pod}`,
              })
            })
          } else {
            filter_payload = [
              {
                op: 'ilike',
                field: 'pod_name',
                value: `None`,
              },
            ]
          }
        }
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify(filter_payload),
          false,
          JSON.stringify(columnSort)
        )
      }
    } else if (badgeFilter !== '' && badgeLevel == '') {
      if (pod) {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([
            { op: 'eq', field: 'role_type', value: `${channel}` },
            { op: 'ilike', field: 'badge_name', value: `%${badgeFilter}%` },
            { op: 'ilike', field: 'pod_name', value: `${pod}` },
          ]),
          false,
          JSON.stringify(columnSort)
        )
      } else {
        filter_payload = [
          { op: 'eq', field: 'role_type', value: `${channel}` },
          { op: 'ilike', field: 'badge_name', value: `%${badgeFilter}%` },
        ]
        if (ifMyPods) {
          if (podsMenuData?.podsMenuData?.data?.my_pods?.length !== 0) {
            podsMenuData?.podsMenuData?.data?.my_pods.map((pod) => {
              filter_payload.push({
                op: 'ilike',
                field: 'pod_name',
                value: `${pod}`,
              })
            })
          } else {
            filter_payload = [
              {
                op: 'ilike',
                field: 'pod_name',
                value: `None`,
              },
            ]
          }
        }
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify(filter_payload),
          false,
          JSON.stringify(columnSort)
        )
      }
    } else {
      if (pod) {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify([
            { op: 'eq', field: 'role_type', value: `${channel}` },
            { op: 'ilike', field: 'badge_name', value: `%${badgeFilter}%` },
            { op: 'ilike', field: 'pod_name', value: `${pod}` },
            {
              op: 'ilike',
              field: 'badge_level_name',
              value: `%${badgeLevel}%`,
            },
          ]),
          false,
          JSON.stringify(columnSort)
        )
      } else {
        filter_payload = [
          { op: 'eq', field: 'role_type', value: `${channel}` },
          { op: 'ilike', field: 'badge_name', value: `%${badgeFilter}%` },
          {
            op: 'ilike',
            field: 'badge_level_name',
            value: `%${badgeLevel}%`,
          },
        ]
        if (ifMyPods) {
          if (podsMenuData?.podsMenuData?.data?.my_pods !== 0) {
            podsMenuData?.podsMenuData?.data?.my_pods.map((pod) => {
              filter_payload.push({
                op: 'ilike',
                field: 'pod_name',
                value: `${pod}`,
              })
            })
          } else {
            filter_payload = [
              {
                op: 'ilike',
                field: 'pod_name',
                value: `None`,
              },
            ]
          }
        }

        fetchData(
          { pageSize, pageIndex },
          JSON.stringify(filter_payload),
          false,
          JSON.stringify(columnSort)
        )
      }
    }
  }, [
    pageSize,
    pageIndex,
    columnSort,
    channel,
    badgeFilter,
    badgeLevel,
    pod,
    ifMyPods,
  ])

  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem(
      'hidden_columns',
      JSON.stringify(refData.hiddenColumns)
    )
  }, [refData.hiddenColumns])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div>
          <Sidebar
            Page="Badge"
            setChannel={setChannel}
            setBadgeFilter={setBadgeFilter}
            channel={channel}
            Index={
              <>
                <Nav
                  uqBadges={uqBadges}
                  badgeFilter={badgeFilter}
                  setBadgeFilter={setBadgeFilter}
                  badgeLevelFunc={badgeLevelFunc}
                  badgeLevels={badgeLevels}
                  badgeLevel={badgeLevel}
                  setBadgeLevel={setBadgeLevel}
                  channel={channel}
                  setChannel={setChannel}
                  summaryKraft={summaryKraft}
                  summaryVendor={summaryVendor}
                  exportFunc={exportTableData}
                  countryData={countryVal}
                  podsMenuData={podsMenuData}
                  setPod={setPod}
                  pod={pod}
                  setIfMyPods={setIfMyPods}
                  podsMenu={podsMenu}
                  ifMyPods={ifMyPods}
                />

                <div
                  className=" table-container badge-table-container"
                  style={{ textAlign: 'center' }}
                >
                  <div className="top-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        style={{ backgroundColor: 'white', color: 'black' }}
                      >
                        Hide/Show Columns
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Form className="column-select-container">
                          {TableHandler.getAllColumns(
                            columnMetadata,
                            table_name
                          ).map((value, i) => {
                            return (
                              <Form.Check
                                key={i}
                                onChange={() => {
                                  hiddenColumnListHandler(value)
                                }}
                                type="switch"
                                id="custom-switch"
                                checked={
                                  !refData.hiddenColumns[table_name].includes(
                                    value
                                  )
                                }
                                label={value}
                              />
                            )
                          })}
                        </Form>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Table {...getTableProps()} className="badge-table">
                    <thead className="sticky-table-header">
                      {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, i) =>
                            column?.id === 'selection' ? (
                              <th key={column.id}>
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                    { style: { width: column.size + '%' } }
                                  )}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['Header'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : column.id == 'Gold' ? (
                              <th key={i}>
                                <div
                                  // onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {/* {columnSort[0]?.field === column['id'] ? (
                              <span>
                                {columnSort[0]?.direction === 'asc'
                                  ? ' 🔽'
                                  : '' || columnSort[0]?.direction === 'desc'
                                  ? ' 🔼'
                                  : ''}
                              </span>
                            ) : (
                              ''
                            )} */}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : column.id === 'Silver' ? (
                              <th key={i}>
                                <div
                                  // onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {/* {columnSort[0]?.field === column['id'] ? (
                          <span>
                            {columnSort[0]?.direction === 'asc'
                              ? ' 🔽'
                              : '' || columnSort[0]?.direction === 'desc'
                              ? ' 🔼'
                              : ''}
                          </span>
                        ) : (
                          ''
                        )} */}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : column.id === 'Bronze' ? (
                              <th key={i}>
                                <div
                                  // onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {/* {columnSort[0]?.field === column['id'] ? (
                        <span>
                          {columnSort[0]?.direction === 'asc'
                            ? ' 🔽'
                            : '' || columnSort[0]?.direction === 'desc'
                            ? ' 🔼'
                            : ''}
                        </span>
                      ) : (
                        ''
                      )} */}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : (
                              <th key={i}>
                                <div
                                  onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['id'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      ))}
                    </thead>
                    <tbody className="tbody" {...getTableBodyProps()}>
                      {page.map((row, index) => {
                        prepareRow(row)
                        return (
                          <tr key={index} onClick={() => handleOpen(row)}>
                            {row.cells.map((cell) => {
                              return cell.column.id === 'Gold' ? (
                                <>
                                  <td
                                    onClick={openModal}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    {cell.value === 0 ? (
                                      <p>--</p>
                                    ) : (
                                      <>{getGoldComp(cell.value)}</>
                                    )}
                                  </td>
                                </>
                              ) : cell.column.id == 'Silver' ? (
                                <td
                                  onClick={openModal}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  {cell.value === 0 ? (
                                    <p>--</p>
                                  ) : (
                                    <>{getSilverComp(cell.value)}</>
                                  )}
                                </td>
                              ) : cell.column.id == 'Bronze' ? (
                                <td
                                  onClick={openModal}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  {cell.value === 0 ? (
                                    <p>--</p>
                                  ) : (
                                    <>{getBronzeComp(cell.value)}</>
                                  )}
                                </td>
                              ) : cell.column.id == 'kudos_count' ? (
                                <td
                                  onClick={openModal}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  {cell.value === 0 ? (
                                    <p>--</p>
                                  ) : (
                                    <>{getKudosComp(cell.value)}</>
                                  )}
                                </td>
                              ) : cell.column.id === 'user_email' ? (
                                <>
                                  <td
                                    onClick={openemailModal}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                        cursor: 'pointer',
                                        color: '#0c00c3',
                                      },
                                    })}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                </>
                              ) : cell.column.id === 'pod_name' &&
                                cell?.value?.length > 60 ? (
                                <>
                                  <BootstrapTooltip
                                    key={Math.random()}
                                    title={cell.render('Cell')}
                                  >
                                    <td
                                      onClick={openemailModal}
                                      {...cell.getCellProps({
                                        style: {
                                          maxWidth: '350px',
                                          cursor: 'pointer',
                                        },
                                      })}
                                    >
                                      {cell.render('Cell')}
                                    </td>
                                  </BootstrapTooltip>
                                </>
                              ) : (
                                <>
                                  <td
                                    onClick={openModal}
                                    {...cell.getCellProps({
                                      style: {
                                        'max-width': '350px',
                                        'white-space': 'nowrap',
                                        overflow: 'hidden',
                                        'text-overflow': 'ellipsis',
                                      },
                                    })}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                </>
                              )
                            })}
                            {editModeState && index === rowIndex ? (
                              <>
                                <div className="inlineedit">
                                  <button
                                    className="inlineinternal"
                                    onClick={handleUpdateEditMode}
                                  >
                                    &#10004;
                                  </button>
                                  <button
                                    className="inlineinternal"
                                    onClick={handleDeleteEditMode}
                                  >
                                    &#10060;
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                {/* Bottom ToolBar of table */}
                <Row>
                  <Col sm={6}>
                    <div className="page-control">
                      <div className="page-of">
                        Page{' '}
                        <em>
                          {pageIndex + 1} of {totalPages}
                        </em>
                      </div>
                      <div className="prev-next-btn">
                        <button
                          className="badge-btn"
                          onClick={() => onPrevious()}
                          disabled={!canPreviousPage}
                        >
                          {' '}
                          Prev{' '}
                        </button>
                        <button
                          className="badge-btn"
                          onClick={() => onNext()}
                          disabled={!canNextPage}
                        >
                          {' '}
                          Next{' '}
                        </button>
                      </div>
                      <div className="second-control">
                        <span>Go to page:</span>
                        <input
                          className="page-number-input"
                          type="number"
                          defaultValue={pageIndex || 1}
                          onBlur={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value)
                              : 0
                            // handling zero indexing
                            gotoPage(page - 1)
                          }}
                        />
                        <select
                          value={pageSize}
                          className="round"
                          onChange={(e) => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {available_page_size.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          marginLeft: '50px',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        {' '}
                        Total results: {userBadges?.userBadges?.total_results}
                      </div>
                    </div>
                  </Col>
                </Row>
                {showModal ? (
                  <>
                    <UserProfile
                      selectedUser={badgeData.user_name}
                      badgeData={badgeData}
                      setBadgeData={setbadgeData}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      isLocalUser={isLocalUser}
                      history={history}
                      pageSize={1}
                      pageIndex={localPageIndex}
                      userKudos={userKudos}
                    />
                  </>
                ) : (
                  ''
                )}
                <Modal show={emailModal} onHide={handleCloseEmailModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Do you want to send an Email or Post in Q&A Forum?
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ overflowY: 'hidden' }}>
                    If you have a generic question that others can benefit from,
                    please post it in the Q&A Forum forum, if not continue to
                    send email.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() =>
                        navigateToAddQuestion(
                          badgeData?.user_email,
                          badgeData?.user_email
                            ? getUserName(badgeData.user_email)
                            : ''
                        )
                      }
                      variant="primary"
                    >
                      Post in Q&A Forum
                    </Button>
                    <a href={'mailto:' + badgeData?.user_email}>
                      <Button>Send Email</Button>
                    </a>
                    <Button variant="secondary" onClick={handleCloseEmailModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            }
          />
        </div>
      )}
    </>
  )
}

export default BadgeDashboard
