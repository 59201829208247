import {
  GET_INGESTION_INTAKE_DATA_SOURCE_TYPES,
  GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_LOAD,
  GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_ERROR,
  GET_INGESTION_INTAKE_DATA_CLASSIFICATION,
  GET_INGESTION_INTAKE_DATA_CLASSIFICATION_LOAD,
  GET_INGESTION_INTAKE_DATA_CLASSIFICATION_ERROR,
  GET_INGESTION_INTAKE_REQUESTS_LOAD,
  GET_INGESTION_INTAKE_REQUESTS,
  GET_INGESTION_INTAKE_REQUESTS_ERROR,
  NEW_INGESTION_INTAKE_REQUEST,
  NEW_INGESTION_INTAKE_LOAD,
  NEW_INGESTION_INTAKE_ERROR,
} from '../constants/types'

const initialState = {
  ingestionRequests: [],
  newIngestionRequest: [],
  sourceOptions: [],
  dataClasifications: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case NEW_INGESTION_INTAKE_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case NEW_INGESTION_INTAKE_ERROR:
      return {
        ...state,
        newIngestionRequest: action.payload,
        loading: false,
        error: null,
      }
    case NEW_INGESTION_INTAKE_REQUEST:
      return {
        ...state,
        newIngestionRequest: action.payload,
        loading: false,
        error: null,
      }
    case GET_INGESTION_INTAKE_REQUESTS_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case GET_INGESTION_INTAKE_REQUESTS: {
      return {
        ...state,
        ingestionRequests: action.payload,
        loading: false,
        error: null,
      }
    }
    case GET_INGESTION_INTAKE_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_INGESTION_INTAKE_DATA_SOURCE_TYPES: {
      return {
        ...state,
        sourceOptions: action.payload,
        loading: false,
        error: null,
      }
    }
    case GET_INGESTION_INTAKE_DATA_SOURCE_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_INGESTION_INTAKE_DATA_CLASSIFICATION_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_INGESTION_INTAKE_DATA_CLASSIFICATION:
      return {
        ...state,
        dataClasifications: action.payload,
        loading: false,
        error: null,
      }
    case GET_INGESTION_INTAKE_DATA_CLASSIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
