// import Card from 'react-bootstrap/Card'
import CardsView from '../../Utilities/cardView'
import { useNavigate } from 'react-router-dom'
// import HelpContent from '../helpContent'
import NpsMenu from '../chapterNpsMenu'
import Sidebar from '../../AppBar/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import { useContext, useEffect, useState } from 'react'
import { getUserSurvey } from '../../../../redux/actions/npsActions/npsSurveyAction'
import ReactPaginate from 'react-paginate'
import LoadingStatus from '../../../Helper/LoadingStatus'
import '../indexChapter.css'
import './userForm.css'
import { toast } from 'react-toastify'
import { getForumMetadata } from '../../../../redux/actions/forumMetadataAction'
function ShowAllUserForms() {
  const [authCreds] = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(0)
  const [cardData, setCardData] = useState([])
  const [sort, setSort] = useState('asc')
  const [totalPages, setTotalPages] = useState(0)
  const [statusSort, setStatusSort] = useState('Pending')
  const [bgcolor, setbgcolor] = useState('')
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: '',
  })
  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const endpoint = authCreds.restEndpoint
  const getUserSurveyData = useSelector((state) => state.getUserSurveyData)

  let history = useNavigate()
  const dispatch = useDispatch()

  const handleCard = (id) => {
    if (statusSort == 'Pending') {
      history(`/survey/${id}`)
    } else {
      return toast.error('Form already submitted')
    }
  }

  useEffect(() => {
    if (getUserSurveyData?.loading === false) {
      setCardData(getUserSurveyData?.getUserSurveyData?.data)
      setTotalPages(
        Math.ceil(getUserSurveyData?.getUserSurveyData?.total_results / 10)
      )
    }
  }, [getUserSurveyData, setCardData])

  useEffect(() => {
    if (statusSort == 'Pending') {
      setbgcolor('#234783')
    } else {
      setbgcolor('#048732')
    }
  }, [bgcolor, setbgcolor, statusSort])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      page_size: 10,
      page_number: currentPage + 1,
      sort:
        sort == 'latest'
          ? []
          : JSON.stringify([{ field: 'form_name', direction: sort }]),
      filters: JSON.stringify([
        { op: 'ilike', field: 'status', value: `%${statusSort}%` },{ op: 'eq', field: 'channel', value: `%${channel}%` },
      ]),
    }
    dispatch(getUserSurvey(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }, [sort, currentPage, statusSort, channel])

  const handlePageChange = (number) => {
    setCurrentPage(number.selected)
  }
  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=COLLAB`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
         <Sidebar
            Page="NPS"
            setChannel={setChannel}
            channel={channel}
            collabMetadata={collabMetadata}
            Index={
          <div className="main-section-nps">
            <NpsMenu
              sort={sort}
              setSort={setSort}
              setStatusSort={setStatusSort}
              statusSort={statusSort}
            />
            <div className="card-section-nps">
              {cardData?.map((result) => (
                <CardsView
                  key={result.id}
                  handleClick={() => handleCard(result?.id)}
                  // onClick={() => handleCard(result?.id)}
                  title_content={
                    <>
                      {' '}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                          }}
                        >
                          <span className="survey_title">
                            {result?.form_name}
                          </span>
                          <span className="survey_description">
                            {result?.description}
                          </span>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: 'fit-content',
                          }}
                        >
                          <span className="date-label">STATUS</span>{' '}
                          <span className={`survey_status`}>{statusSort}</span>
                        </div>
                      </div>
                    </>
                  }
                  border_status_class={
                    statusSort == 'Pending'
                      ? 'border-primary card-height'
                      : 'border-success card-height'
                  }
                  action_content={
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: '1',
                          justifyContent: 'flex-start',
                          color: '#0e0eed',
                        }}
                      >
                        <span className="date-label">START DATE:</span>
                        <span className="date-label-count">
                          {
                            new Date(result?.start_date)
                              .toISOString()
                              .split('T')[0]
                          }
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: '1',
                          justifyContent: 'flex-end',
                          color: '#d30000',
                        }}
                      >
                        <span className="date-label">END DATE:</span>
                        <span className="date-label-count">
                          {
                            new Date(result?.end_date)
                              .toISOString()
                              .split('T')[0]
                          }
                        </span>
                      </div>
                    </>
                  }
                />
                // <Card
                //   bg="red"
                //   key={result}
                //   text="white"
                //   style={{
                //     width:
                //       getUserSurveyData.getUserSurveyData.total_results % 3 !==
                //       0
                //         ? '26rem'
                //         : '18rem',
                //     margin: '20px',
                //     minHeight: '100px',
                //     flex:
                //       getUserSurveyData.getUserSurveyData.total_results % 3 !==
                //       0
                //         ? 'none'
                //         : '1 0 300px',
                //     cursor: 'pointer',
                //     background: bgcolor,
                //   }}
                //   className="mb-2 all-form-card"
                //   onClick={() => handleCard(result?.id)}
                // >
                //   <Card.Body
                //     style={{
                //       background:
                //         statusSort == 'Pending' ? '#3566b9' : '#179f46',
                //     }}
                //   >
                //     <Card.Title>{result?.form_name}</Card.Title>
                //   </Card.Body>
                //   <Card.Header
                //     style={{ display: 'flex', flexDirection: 'row' }}
                //   >
                //     <div>
                //       Start Date -{' '}
                //       {new Date(result?.start_date).toISOString().split('T')[0]}{' '}
                //     </div>{' '}
                //     <div style={{ marginLeft: 'auto' }}>
                //       End Date -{' '}
                //       {new Date(result?.end_date).toISOString().split('T')[0]}{' '}
                //     </div>
                //   </Card.Header>
                //   <Card.Footer>Status - {statusSort}</Card.Footer>
                // </Card>
              ))}
            </div>
            <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage}
              breakLabel={'...'}
              previousLabel={'<<'}
              nextLabel={'>>'}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
            } />
        </>
      )}
    </>
  )
}

export default ShowAllUserForms
