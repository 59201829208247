import React, { useEffect, useState } from 'react'
import './Step0.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { columns, columnsTarget } from '../../../../../../data/Step0Data.js'
import help from '../../../../Utilities/fnf_helpers.js'
import columnsMap from '../../../../../../data/ColumnsMapping.js'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import fAndF from '../../../../../../services/feesFinesServices.js'
import { useAuthAndDispatch } from '../../../../../../hooks/useAuthAndDispatch.js'
import FnFTable from '../FnFTable/FnFTable.js'
import Step0DetailedView from './Step0DetailedView.js'

function Step0() {
  const navigate = useNavigate()
  const { dispatch, endpoint } = useAuthAndDispatch()
  const stepsData = useSelector((state) => state)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [showModal, setShowModal] = useState(false)
  const [selectedRetailer, setSelectedRetailer] = useState('')
  const [selectedDateRange, setSelectedDateRange] = useState('range')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [selectedModalData, setSelectedModalData] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [isExporting, setIsExporting] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')

  const likelihoodreportdata = stepsData?.fnf?.step0LikelihoodData
  const summaryData = stepsData?.fnf?.step0SummaryData?.data
  const dropDownItems = stepsData?.fnf?.step0UniqueValuesData?.data

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const getSummaryData = async () => {
    let payload = {
      date_type:
        selectedDateRange === 'range' && (fromDate === '' || toDate === '')
          ? ''
          : selectedDateRange,
      from_date: fromDate,
      to_date: toDate,
      retailer: selectedRetailer?.toUpperCase(),
      country: selectedCountry?.toUpperCase(),
      state: selectedState?.toUpperCase(),
    }
    const formattedFilters = help.prepareFilters(payload)
    let modifiedFiltersPayload = {
      filters: formattedFilters,
    }
    fAndF.getStep0Summary(dispatch, endpoint, modifiedFiltersPayload)
  }

  const getLikelihoodReport = async () => {
    let payload = {
      date_type:
        selectedDateRange === 'range' && (fromDate === '' || toDate === '')
          ? ''
          : selectedDateRange,
      from_date: fromDate,
      to_date: toDate,
      retailer: selectedRetailer?.toUpperCase(),
      country: selectedCountry?.toUpperCase(),
      state: selectedState?.toUpperCase(),
      search_term: searchTerm,
    }
    const formattedFilters = help.prepareFilters(payload)
    let modifiedFiltersPayload = {
      filters: formattedFilters,
      limit: `${rowsPerPage}`,
      page: `${page}`,
    }
    fAndF.getLikelihoodReportStep0(dispatch, endpoint, modifiedFiltersPayload)
  }

  const searchTapped = () => {
    getLikelihoodReport()
  }

  const viewgridrulesclicked = () => {
    navigate(
      `/grid/${selectedModalData?.[columnsMap.RETAILER_NAME]?.split(' ')?.[0]}`
    )
  }

  const exportData = () => {
    let filters = {
      date_type:
        selectedDateRange === 'range' && (fromDate === '' || toDate === '')
          ? ''
          : selectedDateRange,
      from_date: fromDate,
      to_date: toDate,
      retailer: selectedRetailer?.toUpperCase(),
      search_term: searchTerm,
    }
    const formattedFilters = help.prepareFilters(filters)
    const params = {
      filters: formattedFilters,
      sort: JSON.stringify([]),
      show_deleted: false,
      export_count: likelihoodreportdata?.count || 10000,
    }
    const payload = Object.keys(likelihoodreportdata?.data?.[0] || [])
    setIsExporting(true)
    fAndF
      .exportFile(
        dispatch,
        endpoint,
        payload,
        params,
        'LIKELIHOOD_SUMMARY_REPORT_FACT',
        true
      )
      .finally(() => {
        setIsExporting(false)
      })
  }

  useEffect(() => {
    getSummaryData()
    getLikelihoodReport()
  }, [selectedRetailer, page, rowsPerPage, selectedCountry, selectedState])

  useEffect(() => {
    async function getUniqueValues() {
      try {
        await fAndF.getUniqueValues(dispatch, endpoint, 'step_0')
      } catch (err) {
        console.log(err)
      }
    }
    getUniqueValues()
  }, [])

  useEffect(() => {
    if (selectedDateRange === 'range') {
      if (fromDate && toDate) {
        if (fromDate <= toDate) {
          getSummaryData()
          getLikelihoodReport()
        }
      }
    } else {
      getSummaryData()
      getLikelihoodReport()
    }
  }, [fromDate, toDate, selectedDateRange])

  return (
    <div className="screen0">
      {showModal && (
        <Step0DetailedView
          showModal={showModal}
          selectedModalData={selectedModalData}
          viewgridrulesclicked={viewgridrulesclicked}
          closeView={() => {
            setShowModal(false)
          }}
        />
      )}
      <div className="filterrow">
        <div className="eachfilter">
          <label>DATE</label>
          <select
            onChange={(e) => setSelectedDateRange(e.target.value)}
            data-testid="date_range_select"
          >
            <option data-testid="range_test" value="range">
              Range
            </option>
            <option data-testid="last_week_test" value="last_week">
              Last Week
            </option>
            <option data-testid="last_month_test" value="last_month">
              Last Month
            </option>
            <option data-testid="3_months_test" value="3_months">
              Last 3 Months
            </option>
            <option data-testid="6_months_test" value="6_months">
              Last 6 Months
            </option>
          </select>
        </div>
        {selectedDateRange === 'range' && (
          <div className="eachfilter">
            <label>FROM</label>
            <input
              type="date"
              data-testid="from_date_input"
              onChange={(e) => setFromDate(help.filterDate(e.target.value))}
            />
          </div>
        )}
        {selectedDateRange === 'range' && (
          <div className="eachfilter">
            <label>TO</label>
            <input
              type="date"
              data-testid="to_date_input"
              onChange={(e) => setToDate(help.filterDate(e.target.value))}
            />
          </div>
        )}
        <div className="eachfilter">
          <label>RETAILER</label>
          <select
            onChange={(e) => setSelectedRetailer(e.target.value?.toUpperCase())}
            data-testid="retailer_select"
          >
            <option value="">ALL</option>
            <option>KROGER</option>
            <option>TARGET</option>
          </select>
        </div>
        <div className="eachfilter">
          <label>COUNTRY</label>
          <select onChange={(e) => setSelectedCountry(e.target.value)}>
            <option value="">ALL</option>
            {dropDownItems?.countries?.map((cust, index) => {
              return (
                <option key={index} selected={selectedCountry === cust}>
                  {cust}
                </option>
              )
            })}
          </select>
        </div>
        <div className="eachfilter">
          <label>STATE</label>
          <select onChange={(e) => setSelectedState(e.target.value)}>
            <option value="">ALL</option>
            {dropDownItems?.states?.map((cust, index) => {
              return (
                <option key={index} selected={selectedState === cust}>
                  {cust}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="bar1">
        <h4>FEES & FINES SUMMARY - STEP 0</h4>
      </div>
      <div className="bar1data">
        <div className="eachdata">
          <label>#Orders</label>
          <h4 data-testid="test_orders">
            {help.formatNumbers(summaryData?.[columnsMap.ORDERS], 0) || 0}
          </h4>
        </div>
        <div className="eachdata">
          <label>Total Order Value</label>
          <h4 className="appendDollar" data-testid="test_totalordervalue0">
            {help.formatNumbers(summaryData?.[columnsMap.TOTAL_ORDER_VALUE]) ||
              0}
          </h4>
        </div>
        <div className="eachdata">
          <label>Shipped Orders</label>
          <h4 data-testid="test_shippedorders">
            {help.formatNumbers(summaryData?.[columnsMap.SHIPPED_ORDERS], 0) ||
              0}
          </h4>
        </div>
        <div className="eachdata">
          <label>Pending Orders</label>
          <h4 data-testid="test_pendingorders">
            {help.formatNumbers(summaryData?.[columnsMap.PENDING_ORDERS], 0) ||
              0}
          </h4>
        </div>
        <div className="eachdata">
          <label>#Possible Compliances</label>
          <h4 data-testid="test_possiblecompliances">
            {help.formatNumbers(
              summaryData?.[columnsMap.POSSIBLE_COMPLIANCE],
              0
            ) || 0}
          </h4>
        </div>
        <div className="eachdata">
          <label>#Possible Non Compliances</label>
          <h4 data-testid="test_possiblenoncompliances">
            {help.formatNumbers(
              summaryData?.[columnsMap.POSSIBLE_NON_COMPLIANCE],
              0
            ) || 0}
          </h4>
        </div>
        <div className="eachdata">
          <label>Possible Fine Value</label>
          <h4 className="appendDollar" data-testid="test_possiblefinevalue">
            {help.formatNumbers(
              summaryData?.[columnsMap.POSSIBLE_FINE_VALUE]
            ) || 0}
          </h4>
        </div>
      </div>
      <div className="bar1">
        <h4>FEES & FINES LIKELIHOOD REPORT - STEP 0</h4>
      </div>
      <div className="actionitems">
        <div className="searchbar">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by PO No."
            data-testid="test_search_input"
          />
          <div
            className="searchbtnwrapper"
            data-testid="test_searchbtn"
            onClick={searchTapped}
          >
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>
        <div className="colorcodes">
          <div>
            <div className="colorbox snowflakebox"></div>
            <label data-testid="source1">Snowflake</label>
          </div>
          <div>
            <div className="colorbox sapbox"></div>
            <label data-testid="source2">SAP</label>
          </div>
          <div>
            <div className="colorbox levante_greenfieldbox"></div>
            <label data-testid="source3">Levante / Greenfield</label>
          </div>
          <div>
            <div className="colorbox fourkitesbox"></div>
            <label data-testid="source4">ES3 / Fourkites</label>
          </div>
        </div>
        <button data-testid="export_button" onClick={exportData}>
          Export
        </button>{' '}
      </div>
      <div data-testid="test_report_table">
        <FnFTable
          columns={
            selectedRetailer?.toUpperCase === 'TARGET' ? columnsTarget : columns
          }
          tableData={likelihoodreportdata?.data || []}
          viewClicked={(row) => {
            setSelectedModalData(row)
            setShowModal(true)
          }}
          availableDataCount={likelihoodreportdata?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          isExporting={isExporting}
          isLoading={stepsData?.fnf?.loading}
          tableType="steps"
        />
      </div>
    </div>
  )
}

export default Step0
