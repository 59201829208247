import {
  GET_JIRA_MODAL_METADATA,
  GET_JIRA_MODAL_METADATA_LOADING,
  GET_JIRA_MODAL_METADATA_ERROR,
} from '../constants/types'

const initialState = {
  jiraAddMetadata: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JIRA_MODAL_METADATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_JIRA_MODAL_METADATA:
      return {
        ...state,
        jiraAddMetadata: action.payload,
        loading: false,
      }
    case GET_JIRA_MODAL_METADATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
