import {
  CREATE_GLOSSARY_TERM_ERROR,
  CREATE_GLOSSARY_TERM_LOAD,
  CREATE_GLOSSARY_TERM_POST,
  APPROVE_GLOSSARY_TERM_POST,
  APPROVE_GLOSSARY_TERM_LOAD,
  APPROVE_GLOSSARY_TERM_ERROR,
  REJECT_GLOSSARY_TERM_ERROR,
  REJECT_GLOSSARY_TERM_LOAD,
  REJECT_GLOSSARY_TERM_POST,
  EDIT_GLOSSARY_TERM_ERROR,
  EDIT_GLOSSARY_TERM_LOAD,
  EDIT_GLOSSARY_TERM_POST,
} from '../constants/types'

const initialState = {
  glossaryTerm: [],
  error: null,
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_GLOSSARY_TERM_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case CREATE_GLOSSARY_TERM_POST:
      return {
        ...state,
        loading: false,
        glossaryTerm: action.payload,
        error: null,
      }
    case CREATE_GLOSSARY_TERM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case APPROVE_GLOSSARY_TERM_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case APPROVE_GLOSSARY_TERM_POST:
      return {
        ...state,
        loading: false,
        glossaryTerm: action,
        error: null,
      }
    case APPROVE_GLOSSARY_TERM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case REJECT_GLOSSARY_TERM_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case REJECT_GLOSSARY_TERM_POST:
      return {
        ...state,
        loading: false,
        glossaryTerm: action.payload,
        error: null,
      }
    case REJECT_GLOSSARY_TERM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case EDIT_GLOSSARY_TERM_LOAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case EDIT_GLOSSARY_TERM_POST:
      return {
        ...state,
        loading: false,
        glossaryTerm: action.payload,
        error: null,
      }
    case EDIT_GLOSSARY_TERM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
