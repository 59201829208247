import {
  BADGE_REQUEST_ERROR,
  BADGE_REQUEST_LOAD,
  BADGE_REQUEST_POST,
} from '../constants/types'

const initialState = {
  badgeRequest: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case BADGE_REQUEST_LOAD:
      return {
        ...state,
        loading: true,
      }
    case BADGE_REQUEST_POST:
      return {
        ...state,
        loading: false,
        badgeRequest: action.payload,
      }
    case BADGE_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
