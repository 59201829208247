import { lazy, Suspense } from 'react'
import { Route, Routes as Switch } from 'react-router-dom'
import styled from 'styled-components'
import Overview from './Overview'
import ContentHeader from './ContentHeader'
const ModelView = lazy(() => import('./model_view/ModelView'))
const SearchResults = lazy(() => import('./SearchResults'))
const Contact = lazy(() => import('./Contact'))

const StyledContent = styled.div`
  flex: 1 0 0px;
  min-width: 0;
  width: 80%;
  height: 90vh;
  display: flex;
  flex-direction: column;

  #content-header {
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 3.5vh;
    min-height: 3.5vh;

    padding: 30px 30px;
    background: #fff;
    box-shadow: 0 1px 0 rgb(0 0 0 /8%);

    form {
      width: 90%;

      input {
        width: 100%;
        height: 1.8rem;
        line-height: 1.6;
        padding: 0.2rem 1.25rem;

        border: none;
        border-radius: 4px;

        color: #484e53;
        background: rgb(240, 242, 244);

        &:hover {
          background: #e9ebef;
        }

        &:focus {
          outline: none;
          background: #fff;
          border-color: transparent;
          box-shadow: 0 0 0 1px #0bb, 0 15px 15px -7.5px rgb(0 0 0 / 15%);
        }

        transition: all 0.25s cubic-bezier(0.25, 0, 0, 1);
      }
    }

    #clear-search-btn {
      border: none;
      color: inherit;
      background: none;
      margin-left: 10px;
      font-size: x-large;
      border-radius: 4px;
      :hover {
        background: lightgray;
      }
    }

    #contact-link {
      height: 1.8rem;
      padding: 0px 5px;
      margin-left: 10px;

      font-size: inherit;
      white-space: nowrap;
      text-decoration: none;

      border-radius: 4px;

      color: #484e53;
      background-color: #fff;
      border-color: transparent;
      box-shadow: 0 0 0 1px #0bb, 0 15px 15px -7.5px rgb(0 0 0 / 15%);
      outline: none;

      &:hover {
        background-color: #e9ebef;
      }
    }
  }

  #content-scroll {
    flex: 1 0;
    overflow: auto;
    padding-bottom: 20px;

    #content-body {
      min-height: 0;

      margin: auto;
      padding: 30px;
      padding-top: 0;

      #contact-wrapper {
        top: 15vh;
        position: relative;

        #contact {
          align-items: baseline;
          justify-items: center;

          display: grid;
          grid: 1fr 1fr / 1fr 1fr;
          row-gap: 30px;

          h3 {
            margin: 0;
          }

          #form-frame {
            width: 60%;
            height: 100%;
            button {
              right: 60%;
              padding: 5px 7px;

              font-size: large;

              border: none;
              background: none;
              position: absolute;

              :hover {
                background-color: rgba(0, 0, 0, 0.25);
              }
            }
            iframe {
              border: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .overview-wrapper {
        height: fit-content;
        margin-top: 30px;
        padding: 20px 30px;
        background: #fff;
        border-radius: 4px;
        overflow: auto;
        box-shadow: 0 0 0 1px rgba(0, 30, 60, 3%),
          0 3px 3px -1.5px rgba(0, 0, 0, 3%);

        .overview-content {
          color: #313539;
          code {
            background: #e7e7e7;
          }
          a {
            color: #0aa;
            text-decoration: none;
          }
        }
      }
    }
  }

  #search-results {
    padding: 0 30px;
    h1 {
      font-size: 1.5rem;
      span {
        color: #313539;
      }
    }
    label {
      margin-right: 10px;
    }
    a {
      text-decoration: none;

      .result-item {
        padding: 10px;
        margin-bottom: 5px;

        > :first-child {
          color: #0bb;
        }

        > p {
          margin: 0;
          font-size: 0.9rem;

          > :first-child {
            color: #313539;
          }

          span {
            color: #919599;
          }
        }

        .columns mark {
          background: #8687801f;
        }

        :hover {
          background: #e7e7e7;
        }
      }
    }
    .sticky {
      top: 0px;
      z-index: 5;
      position: sticky;
      background: #f9fafb;
    }
  }
`

const Content: React.FC<ContentProps> = (props) => {
  const defaultOverview = props.manifest._docs.get(
    props.manifest._defaultOverview
  )
  return (
    <StyledContent id="app-content">
      <ContentHeader
        manifest={props.manifest}
        isSearching={props.isSearching}
        setSearching={props.setSearching}
        searchQuery={props.searchQuery}
        setSearchQuery={props.setSearchQuery}
      />

      <div id="content-scroll">
        {props.isSearching ? (
          <Suspense fallback={<div>...loading</div>}>
            <SearchResults
              manifest={props.manifest}
              setSearching={props.setSearching}
              searchOptions={props.searchOptions}
              setSearchOptions={props.setSearchOptions}
            />
          </Suspense>
        ) : (
          <div id="content-body">
            <Suspense fallback={<div>...loading</div>}>
              <Switch>
                <Route
                  path="/"
                  element={
                    defaultOverview && <Overview doc={defaultOverview} />
                  }
                />

                {[...props.manifest._docs].map(([key, doc]) => (
                  <Route
                    key={key}
                    path={`/${doc.unique_id}`}
                    element={<Overview doc={doc} />}
                  />
                ))}

                <Route path="/contact" element={<Contact />} />

                {[
                  ...props.manifest._nodes.values(),
                  ...props.manifest._macros.values(),
                  ...props.manifest._sources.values(),
                  ...props.manifest._exposures.values(),
                ].map((node) => (
                  <Route
                    key={node.unique_id}
                    path={`/${node.unique_id}`}
                    element={
                      <ModelView
                        manifest={props.manifest}
                        setSearching={props.setSearching}
                        setSearchQuery={props.setSearchQuery}
                        setSearchOptions={props.setSearchOptions}
                        updateCacheList={props.updateCacheList}
                        cacheList={props.cacheList}
                      >
                        {node}
                      </ModelView>
                    }
                  ></Route>
                ))}
              </Switch>
            </Suspense>
          </div>
        )}
      </div>
    </StyledContent>
  )
}
export default Content
