import React from 'react'
import Sidebar from '../../../AppBar/Appbar'
import './index.css'
// import LoadingStatus from '../../../../Helper/LoadingStatus'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
// import { useSelector } from 'react-redux'
import AddQuestion from './AddQuestion'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: theme.palette.text.secondary,
  height: '100%',
}))
export function Index() {
  //let ifChannel = false
  return (
    <>
      <Sidebar
        Page="Add Question"
        Index={
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Item>
                    <AddQuestion />
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </>
        }
      />
    </>
  )
}

export default Index
