import {
  GET_SURVEY_GROUP_ERROR,
  GET_SURVEY_GROUP_LOAD,
  GET_SURVEY_GROUP_POST,
} from '../../constants/types'

const initialState = {
  getSurveyGroupData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_GROUP_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_SURVEY_GROUP_POST:
      return {
        ...state,
        loading: false,
        getSurveyGroupData: action.payload,
      }
    case GET_SURVEY_GROUP_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
