import React from 'react'
import { Link } from 'react-router-dom'
import Search from '../Utilities/search'
import Dropdown from 'react-bootstrap/Dropdown'
import { Container, Row, Col } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function HeaderBar({
  searchTerm,
  handleChange,
  handleSubmit,
  openSubmitForum,
  numActions,
  clearSearch,
  handleSortByChange,
  handleOrderByChange,
  sortBy,
  orderBy,
}) {
  const sortByMapping = {
    approved_on: 'Approved Date',
    view_count: 'Number of Views',
    term: 'Alphabetically',
  }
  const orderByMapping = {
    'asc nulls last': 'Asc',
    'desc nulls last': 'Desc',
  }

  return (
    <Container fluid>
      <Row className="glossary-header-container">
        <Col className="glossary-sort-buttons">
          <DropdownButton
            id="dropdown-sort-by"
            title={`Sort By: ${sortByMapping[sortBy]}`}
            className="glossary-sort-buttons"
            onSelect={(eventKey) => handleSortByChange(eventKey)}
          >
            {Object.keys(sortByMapping).map((key) => (
              <Dropdown.Item eventKey={key} key={key}>
                {sortByMapping[key]}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <DropdownButton
            id="dropdown-order-by"
            title={`${orderByMapping[orderBy]}`}
            className="glossary-sort-buttons"
            onSelect={(eventKey) => handleOrderByChange(eventKey)}
          >
            {Object.keys(orderByMapping).map((key) => (
              <Dropdown.Item eventKey={key} key={key}>
                {orderByMapping[key]}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        <Col className="glossary-search-form">
          <Search
            searchTerm={searchTerm}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            clearSearch={clearSearch}
            containerClass="glossary-search-bar"
            formClass="glossary-search-form"
            clearButtonClass="glossary-search-clear-button"
            searchButtonClass="glossary-search-button"
          />
        </Col>
        <Col className="glossary-header-buttons">
          <button
            className="btn btn-primary glossary-btn-primary"
            style={{ marginLeft: '15px' }}
            onClick={openSubmitForum}
          >
            Submit a New Term
          </button>
          <div className="glossary-button-approval-container">
            <div>
              <Link to="/glossary/pending">
                <button className="btn btn-primary glossary-btn-primary">
                  Pending Terms
                </button>
                {numActions > 0 && (
                  <span className="glossary-bubble-approval">{numActions}</span>
                )}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
