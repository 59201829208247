import React from 'react'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from 'react-bootstrap'

const HelperComponent = ({ openModel, closeModel, transiton }) => {
  return (
    <Dialog
      maxWidth="md"
      open={openModel}
      TransitionComponent={transiton}
      keepMounted
      onClose={closeModel}
      aria-describedby=""
    >
      <DialogTitle>
        Welcome to Badges, a place for recognizing employee achievements earned
        through the real-world application of their knowledge and skills! <br />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography gutterBottom>
            <b>
              {' '}
              - Identify potential mentors or experts for a given area <br />-
              Identify individuals best suited for specific project deliverables
              and requirements <br /> - Discover specific skills and
              competencies identified as critical for specific Chapters <br />-
              Incorporate into growth plans or performance metrics <br />
            </b>
          </Typography>
          <br />
          <Typography gutterBottom>
            <b>
              <u>Badge List:</u>
            </b>
            <br />
            <b>Explore Badges: </b>
            Browse all existing badges for a given Chapter. Each badge
            represents a unique name or level with specific requirements to
            achieve. <br /> <br />
            <b> Badge Creation & Management (Admins Only): </b>
            Create new badges to recognize emerging skills, company values, or
            specific achievements. Edit existing badges to ensure their criteria
            remain relevant and meaningful.
          </Typography>
          <br />
          <Typography gutterBottom>
            <b>
              <u> Browse Awarded Badges:</u>
            </b>
            <br />
            Explore a dynamic list of Chapter members who have earned badges!
            Filter by email, company, group and their titles and see their
            badges (Gold, Silver, Bronze) to discover specific levels of
            expertise.
          </Typography>
          <br />
          <Typography gutterBottom>
            <b>
              {' '}
              <u>Submit a Badge Nomination:</u>
            </b>
            <br />
            <b>Employees: </b> Submit badge nominations for yourself and provide
            a description and evidence of how you have met the requirements -
            highlight specific contributions (code references, peer references,
            etc). <br /> <br />
            <b>Admins: </b> Review submitted badge nominations and make informed
            approvals of nominations. Use detailed descriptions and provide
            comments to assess each nominees merit.
          </Typography>{' '}
          <br />
          <Typography gutterBottom>
            <b>
              {' '}
              <u>How to use badge features:</u>
            </b>
            <br />
            <br />
            <b>Channel: </b> On the navbar you can see channel selection
            dropdown which is default set to Data Engineering. You can switch to
            other channels by selecting any channel. <br /> <br />
            <b> User Filter: </b> You can filter data by typing in the column
            text fields. For badge name and badge level filter select values
            from dropdown.
            <br /> <br />
            <b> Badge View: </b> To view the badges earned by user click on row
            and the modal will appear.
            <br /> <br />
            <b> Send Email: </b> To send email to user just click on user_email
            showing in table.
            <br /> <br />
            <b> Badge Request: </b> Once you earned high level of badge you can
            not submit request for lower levels. Also if you want to earn high
            level badge submit new request by providing evidence. If your
            request got rejected you can re-submit by providing more evidence
            for the requested badge. You can delete your badge request until
            your status is under review after that you are not allowed to delete
            request.
            <br /> <br />
          </Typography>{' '}
          <br />
          <Typography gutterBottom>
            <b>If you need any assistance regarding badges. Please email at </b>
            <a
              href="mailto:khcdataengineeringcoe@kraftheinz.com"
              className="email-link"
            >
              khcdataengineeringcoe@kraftheinz.com
            </a>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default HelperComponent
