import help from '../components/pages/Utilities/fnf_helpers'
import columnsDescription from './ColumnsDescription'
import columnsMap from './ColumnsMapping'

const columns = [
  {
    id: 'ACTION',
    label: '',
    group: 'snowflake',
    description: '',
  },
  {
    id: columnsMap.SO_NO,
    label: 'Standard Order No.',
    group: 'snowflake',
    description: columnsDescription.SO_NO_DESC,
  },
  {
    id: columnsMap.CFR_MISSED,
    label: 'CFR Missed ?',
    group: 'snowflake',
    format: (value) => (value === true ? 'Yes' : 'No'),
    description: columnsDescription.CFR_MISSED_DESC,
  },
  {
    id: columnsMap.ORAD_MISSED,
    label: 'ORAD Missed',
    group: 'snowflake',
    format: (value) => (value === true ? 'Yes' : 'No'),
    description: columnsDescription.ORAD_MISSED_DESC,
  },
  {
    id: columnsMap.CFR_FINE_AMOUNT,
    label: 'CFR Fine Amount',
    group: 'snowflake',
    description: columnsDescription.CFR_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.ORAD_FINE_AMOUNT,
    label: 'ORAD Fine Amount',
    group: 'snowflake',
    description: columnsDescription.ORAD_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CUSTOMER_PO_NO,
    label: 'PO No.',
    group: 'snowflake',
    description: columnsDescription.CUSTOMER_PO_NO_DESC,
  },
  {
    id: columnsMap.RETAILER_NAME,
    label: 'Retailer',
    group: 'snowflake',
    description: columnsDescription.RETAILER_NAME_DESC,
  },
  {
    id: columnsMap.DC_NO_KH,
    label: 'KH DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_KH_DESC,
  },
  {
    id: columnsMap.DC_NO_RETAILER,
    label: 'Client DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_RETAILER_DESC,
  },
  {
    id: columnsMap.PO_DATE,
    label: 'PO Date',
    group: 'sap',
    description: columnsDescription.PO_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.PO_TYPE,
    label: 'PO Type',
    group: 'sap',
    description: columnsDescription.PO_TYPE_DESC,
  },
  {
    id: columnsMap.SO_TYPE,
    label: 'SO Type',
    group: 'sap',
    description: columnsDescription.SO_TYPE_DESC,
  },
  {
    id: columnsMap.NO_OF_UNITS_ORDERED,
    label: 'Requested Units',
    group: 'snowflake',
    description: columnsDescription.NO_OF_UNITS_ORDERED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.NO_OF_UNITS_SHIPPED,
    label: 'Shipped Units',
    group: 'snowflake',
    description: columnsDescription.NO_OF_UNITS_SHIPPED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CPU,
    label: 'CPU',
    group: 'snowflake',
    description: columnsDescription.CPU_DESC,
    format: (value) => (value === 'Y' ? 'Yes' : 'No'),
  },
  {
    id: columnsMap.VALUE_OF_ORDER_PLACED,
    label: 'Order Value',
    group: 'snowflake',
    description: columnsDescription.VALUE_OF_ORDER_PLACED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CASES_ORDERED,
    label: 'Ordered Cases',
    group: 'snowflake',
    description: columnsDescription.CASES_ORDERED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.ES3_OR_NON_ES3,
    label: 'ES3/Non ES3',
    group: 'fourkites',
    format: (value) => (value === true ? 'ES3' : 'Non ES3'),
    description: columnsDescription.ES3_OR_NON_ES3_DESC,
  },
  {
    id: columnsMap.SHIPPING_DATE,
    label: 'Shipment Date',
    group: 'snowflake',
    description: columnsDescription.SHIPPING_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.TRANSPORT_REFERENCE,
    label: 'Transport Reference',
    group: 'snowflake',
    description: columnsDescription.TRANSPORT_REFERENCE_DESC,
  },
  {
    id: columnsMap.ORAD,
    label: 'ORAD Date',
    group: 'snowflake',
    description: columnsDescription.ORAD_DESC,
    date: true,
  },
  {
    id: columnsMap.ESTIMATED_ARRIVAL_DATE,
    label: 'ETA Date',
    group: 'snowflake',
    description: columnsDescription.ESTIMATED_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.ACTUAL_ARRIVAL_DATE,
    label: 'Actual Arrival Date',
    group: 'fourkites',
    description: columnsDescription.ACTUAL_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.CFR,
    label: 'CFR',
    group: 'snowflake',
    description: columnsDescription.CFR_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.SHIPPING_STATUS,
    label: 'Status',
    group: 'snowflake',
    description: columnsDescription.SHIPPING_STATUS_DESC,
  },
]

const columnsTarget = [
  {
    id: 'action',
    label: '',
    group: 'snowflake',
    description: '',
  },
  {
    id: columnsMap.SO_NO,
    label: 'Standard Order No.',
    group: 'snowflake',
    description: columnsDescription.SO_NO_DESC,
  },
  {
    id: columnsMap.CFR_MISSED,
    label: 'CFR Missed ?',
    group: 'snowflake',
    format: (value) => (value === true ? 'Yes' : 'No'),
    description: columnsDescription.CFR_MISSED_DESC,
  },
  {
    id: columnsMap.ORAD_MISSED,
    label: 'ORAD Missed',
    group: 'snowflake',
    format: (value) => (value === true ? 'Yes' : 'No'),
    description: columnsDescription.ORAD_MISSED_DESC,
  },
  {
    id: columnsMap.CFR_FINE_AMOUNT,
    label: 'CFR Fine Amount',
    group: 'snowflake',
    description: columnsDescription.CFR_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.ORAD_FINE_AMOUNT,
    label: 'ORAD Fine Amount',
    group: 'snowflake',
    description: columnsDescription.ORAD_FINE_AMOUNT_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CUSTOMER_PO_NO,
    label: 'PO No.',
    group: 'snowflake',
    description: columnsDescription.CUSTOMER_PO_NO_DESC,
  },
  {
    id: columnsMap.RETAILER_NAME,
    label: 'Retailer',
    group: 'snowflake',
    description: columnsDescription.RETAILER_NAME_DESC,
  },
  {
    id: columnsMap.DC_NO_KH,
    label: 'KH DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_KH_DESC,
  },
  {
    id: columnsMap.DC_NO_RETAILER,
    label: 'Client DC',
    group: 'snowflake',
    description: columnsDescription.DC_NO_RETAILER_DESC,
  },
  {
    id: columnsMap.PO_DATE,
    label: 'PO Date',
    group: 'sap',
    description: columnsDescription.PO_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.PO_TYPE,
    label: 'PO Type',
    group: 'sap',
    description: columnsDescription.PO_TYPE_DESC,
  },
  {
    id: columnsMap.SO_TYPE,
    label: 'SO Type',
    group: 'sap',
    description: columnsDescription.SO_TYPE_DESC,
  },
  {
    id: columnsMap.NO_OF_UNITS_ORDERED,
    label: 'Requested Units',
    group: 'snowflake',
    description: columnsDescription.NO_OF_UNITS_ORDERED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.NO_OF_UNITS_SHIPPED,
    label: 'Shipped Units',
    group: 'snowflake',
    description: columnsDescription.NO_OF_UNITS_SHIPPED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CPU,
    label: 'CPU',
    group: 'snowflake',
    description: columnsDescription.CPU_DESC,
    format: (value) => (value === 'Y' ? 'Yes' : 'No'),
  },
  {
    id: columnsMap.VALUE_OF_ORDER_PLACED,
    label: 'Order Value',
    group: 'snowflake',
    description: columnsDescription.VALUE_OF_ORDER_PLACED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.CASES_ORDERED,
    label: 'Ordered Cases',
    group: 'snowflake',
    description: columnsDescription.CASES_ORDERED_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.ES3_OR_NON_ES3,
    label: 'ES3/Non ES3',
    group: 'fourkites',
    format: (value) => (value === true ? 'ES3' : 'Non ES3'),
    description: columnsDescription.ES3_OR_NON_ES3_DESC,
  },
  {
    id: columnsMap.SHIPPING_DATE,
    label: 'Shipment Date',
    group: 'snowflake',
    description: columnsDescription.SHIPPING_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.TRANSPORT_REFERENCE,
    label: 'Transport Reference',
    group: 'snowflake',
    description: columnsDescription.TRANSPORT_REFERENCE_DESC,
  },
  {
    id: columnsMap.ORAD,
    label: 'ORAD Date',
    group: 'snowflake',
    description: columnsDescription.ORAD_DESC,
    date: true,
  },
  {
    id: columnsMap.ESTIMATED_ARRIVAL_DATE,
    label: 'ETA Date',
    group: 'snowflake',
    description: columnsDescription.ESTIMATED_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.ACTUAL_ARRIVAL_DATE,
    label: 'Actual Arrival Date',
    group: 'fourkites',
    description: columnsDescription.ACTUAL_ARRIVAL_DATE_DESC,
    date: true,
  },
  {
    id: columnsMap.CFR,
    label: 'CFR',
    group: 'snowflake',
    description: columnsDescription.CFR_DESC,
    format: (value) => help.formatNumbers(value),
  },
  {
    id: columnsMap.SHIPPING_STATUS,
    label: 'Status',
    group: 'snowflake',
    description: columnsDescription.SHIPPING_STATUS_DESC,
  },
]

export { columns, columnsTarget }
