import {
  GET_POPULAR_ANSWER,
  GET_ANSWER_ERROR,
  GET_ANSWER_LOADING,
} from '../constants/types'

const initialState = {
  ansData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ANSWER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_POPULAR_ANSWER:
      return {
        ...state,
        loading: false,
        ansData: action.payload,
      }
    case GET_ANSWER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
