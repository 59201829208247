import {
  GET_TABLES,
  GET_META_DATA,
  GET_META_DATA_LOADING,
  GET_TABLES_DATA_LOADING,
  GET_META_DATA_ERROR,
  GET_TABLES_DATA_ERROR,
  GET_TABLES_PROFILE_ERROR,
  GET_TABLES_PROFILE,
  GET_TABLES_PROFILE_LOADING,
} from '../constants/types'
import axios from 'axios'

export const getTables = (endpoint, token) => async (dispatch) => {
  dispatch({
    type: GET_TABLES_DATA_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/ref_data/get_ref_tables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_TABLES,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_TABLES_DATA_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getMetaData = (endpoint, token) => async (dispatch) => {
  dispatch({
    type: GET_META_DATA_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/ref_data/metadata`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_META_DATA,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_META_DATA_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const GET_META_DATA_BY_TABLE_BEGIN = 'GET_META_DATA_BY_TABLE_BEGIN'
export const GET_META_DATA_BY_TABLE_SUCCESS = 'GET_META_DATA_BY_TABLE_SUCCESS'
export const GET_META_DATA_BY_TABLE_FAIL = 'GET_META_DATA_BY_TABLE_FAIL'

const getMetaDataByTableBegin = () => ({
  type: GET_META_DATA_BY_TABLE_BEGIN,
})

const getMetaDataByTableSuccess = (data) => ({
  type: GET_META_DATA_BY_TABLE_SUCCESS,
  payload: { data },
})

const getMetaDataByTableFailure = (error) => ({
  type: GET_META_DATA_BY_TABLE_FAIL,
  payload: { error },
})

export const getMetaDataByTable = (endpoint, tableName) => async (dispatch) => {
  dispatch(getMetaDataByTableBegin())
  try {
    await axios
      .get(`${endpoint}/api/ref_data/metadata?table_name=${tableName}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch(getMetaDataByTableSuccess(res.data))
      })
  } catch (e) {
    dispatch(getMetaDataByTableFailure(e))
  }
}
export const GET_META_DATA_BY_PICKLIST_BEGIN = 'GET_META_DATA_BY_PICKLIST_BEGIN'
export const GET_META_DATA_BY_PICKLIST_SUCCESS =
  'GET_META_DATA_BY_PICKLIST_SUCCESS'
export const GET_META_DATA_BY_PICKLIST_FAIL = 'GET_META_DATA_BY_PICKLIST_FAIL'

const getMetaDataByPicklistBegin = () => ({
  type: GET_META_DATA_BY_PICKLIST_BEGIN,
})

const getMetaDataByPicklistSuccess = (data) => ({
  type: GET_META_DATA_BY_PICKLIST_SUCCESS,
  payload: { data },
})

const getMetaDataByPicklistFailure = (error) => ({
  type: GET_META_DATA_BY_PICKLIST_FAIL,
  payload: { error },
})

export const getMetaDataByPicklist = (endpoint) => async (dispatch) => {
  dispatch(getMetaDataByPicklistBegin())
  try {
    await axios
      .get(`${endpoint}/api/ref_data/metadata?is_picklist=false`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch(getMetaDataByPicklistSuccess(res.data))
      })
  } catch (e) {
    dispatch(getMetaDataByPicklistFailure(e))
  }
}

export const getTablesProfile =
  (endpoint, token, table_name) => async (dispatch) => {
    dispatch({
      type: GET_TABLES_PROFILE_LOADING,
    })
    try {
      await axios
        .get(
          `${endpoint}/api/ref_data/get_data_profile?table_name=${table_name}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: GET_TABLES_PROFILE,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_TABLES_PROFILE_ERROR,
        payload: e.response.data.message,
      })
    }
  }
