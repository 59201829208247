import React, { useEffect, useState, useContext } from 'react'
import LoadingStatus from '../../Helper/LoadingStatus'
import Navbar from '../../Navbar'
import { MdDangerous } from 'react-icons/md'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
import SnowflakeClone from './SnowflakeClone'
import jwt_decode from 'jwt-decode'
import './SnowflakeClone.css'
import { getMetaDataByTable } from '../../../redux/actions/refDataAction'
import { useDispatch, useSelector } from 'react-redux'

const SnowflakeCloneParam = () => {
  /* eslint-disable no-unused-vars */
  const [showTable, setShowTable] = useState(true)
  const metaDataByTable = useSelector((state) => state?.metaDataByTable)
  const dispatch = useDispatch()
  const token = localStorage.getItem('token')

  const [authCreds] = useContext(AuthContext)
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching Snowflake_clone table details...',
  })
  const [message, setMessage] = React.useState('')
  const [hiddenColsMap, setHiddenColsMap] = React.useState([])
  const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [enabledCols, setEnabledCols] = React.useState([])
  const [adminRole, setAdminRole] = useState(false)
  const access_token = localStorage.getItem('token')

  // return promise to get metadata information for all tables

  useEffect(() => {
    dispatch(getMetaDataByTable(authCreds.restEndpoint, 'SNOWFLAKE_CLONE'))
  }, [])

  useEffect(() => {
    // if authCreds gets updated, then hit table_list and metadata requests.
    if (access_token != null) {
      let token = jwt_decode(access_token)
      if ('roles' in token) {
        let roleString = token['roles']
        setAdminRole(roleString !== null && roleString.includes('khdapi.Admin'))
      } else {
        setAdminRole(false)
      }
    } else {
      setLoadingStatus({
        status: true,
        message: 'Fetching Snowflake_clone table details...',
      })
    }

    if (metaDataByTable.loading === false) {
      Promise.all([metaDataByTable]).then(([metadata_response]) => {
        setEnabledCols(
          TableHandler.getEditableColumnList(
            metadata_response?.metaDataByTable?.data,
            'SNOWFLAKE_CLONE'
          )
        )
        setEnabledColsMap(
          TableHandler.getEditableColumnMap(
            metadata_response?.metaDataByTable?.data,
            'SNOWFLAKE_CLONE'
          )
        )
        setHiddenColsMap(
          TableHandler.createHiddenList(
            metadata_response?.metaDataByTable?.data
          )
        )
      })
    }
  }, [authCreds, metaDataByTable?.loading])

  return showTable ? (
    metaDataByTable.loading ? (
      <LoadingStatus status_message={loadingStatus.message} />
    ) : (
      <>
        <Navbar title="Snowflake Clone" />
        <SnowflakeClone
          enabledCols={enabledCols}
          enabledColsMap={enabledColsMap}
          adminRole={adminRole}
          metaDataByTable={metaDataByTable}
          setShowTable={setShowTable}
          setMessage={setMessage}
        />
      </>
    )
  ) : (
    <>
      <Navbar title="Snowflake Clone" />
      <div
        class="bg-white text-dark text-center"
        style={{ height: '69vh', paddingTop: '150px' }}
      >
        <div>
          <MdDangerous style={{ fontSize: '60px' }} />
        </div>
        <div class="mt-3 fs-5">{message}</div>
      </div>
    </>
  )
}
export default SnowflakeCloneParam
