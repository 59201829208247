import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import '../../../App.css'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import './jiraTracker.css'
import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import {
  editJiraEntry,
  getJiraEntries,
} from '../../../redux/actions/capacityTrackerAction'
import { AuthContext } from '../../context/authProvider'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

const EditJiraTracker = ({
  jira_meta_data,
  current_sprint,
  selectedRows,
  setEditShowJira,
  setLoadingStatus,
}) => {
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  //form states
  const [errors, setErrors] = useState({})
  const [authCreds] = React.useContext(AuthContext)
  const [roleValue, setRolevalue] = useState(false)
  const [userName, setUserName] = useState(selectedRows[0].DE_NAME)
  const [userMail, setUserMail] = useState(selectedRows[0].DE_EMAIL)
  const [sprintName, setSprintName] = useState(selectedRows[0].SPRINT_NAME)
  const [podName, setPodName] = useState(selectedRows[0].POD)
  const [boardName, setBoardName] = useState(selectedRows[0].BOARD)
  const [vendorName, setvendorName] = useState(selectedRows[0].VENDOR)
  const [workingDays, setWorkingDays] = useState(
    selectedRows[0].SPRINT_WORKING_DAYS
  )
  const [nonWorkingDays, setNonWorkingDays] = useState(
    selectedRows[0].SPRINT_NON_WORKING_DAYS
  )
  const [sprintAlias, setSprintAlias] = useState(selectedRows[0].SPRINT_ALIAS)
  const [comments, setComments] = useState(selectedRows[0].COMMENTS)

  const [currentSprint, getCurrentSprint] = useState('')

  useEffect(() => {
    getCurrentSprint(current_sprint?.currentSprint?.data?.sprint_name)
  }, [current_sprint])

  const [form, setForm] = useState({
    de_name: tokenValue.name,
    de_mail: tokenValue.preferred_username,
    sprint_name: sprintName,
    board: boardName,
    pod: podName,
    vendor: vendorName,
    sprint_working_days: +workingDays,
    sprint_non_working_days: +nonWorkingDays,
    comments: comments,
  })
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const token = localStorage.getItem('token')
  const jiraPayload = {
    BOARD: boardName,
    SPRINT_NAME: sprintName,
    SPRINT_ALIAS: sprintAlias,
    POD: podName,
    DE_EMAIL: userMail,
    VENDOR: vendorName,
    SPRINT_WORKING_DAYS: +workingDays,
    SPRINT_NON_WORKING_DAYS: +nonWorkingDays,
    SPRINT_TOTAL_DAYS: +workingDays + +nonWorkingDays,
    DE_NAME: userName,
    COMMENTS: comments,
  }

  // form validation
  const findFormErrors = () => {
    let {
      de_name,
      de_mail,
      sprint_name,
      board,
      pod,
      vendor,
      sprint_working_days,
      sprint_non_working_days,
      comments,
    } = form
    let newErrors = {}
    if (!de_mail || de_mail === '') newErrors.de_mail = 'Please enter mail'
    if (!de_name || de_name === '') newErrors.de_name = 'Please enter name'
    if (tokenValue?.roles?.includes('khdapi.Admin')) {
      if (!sprint_name || sprint_name === '') {
        newErrors.sprint_name = 'No Sprint Selected'
      } else if (sprint_name === 'Select Sprint') {
        newErrors.sprint_name = 'No Sprint Selected'
      }
    }

    if (!pod || pod === '') {
      newErrors.pod = 'No pod Selected'
    } else if (pod === 'Select Pod') {
      newErrors.pod = 'No pod Selected'
    }
    if (!vendor || vendor === '') {
      newErrors.vendor = 'No vendor Selected'
    } else if (vendor === 'Select Vendor') {
      newErrors.vendor = 'No vendor Selected'
    }
    if (!board || board === '') {
      newErrors.board = 'No board Selected'
    } else if (board === 'Select Board') {
      newErrors.board = 'No board Selected'
    }
    if (sprint_working_days < 0 || sprint_working_days > 14) {
      newErrors.sprint_working_days = 'Incorrect value'
    }

    if (sprint_non_working_days < 0 || sprint_non_working_days > 10) {
      newErrors.sprint_non_working_days = 'Incorrect value'
    }

    if (Number(sprint_working_days) + Number(sprint_non_working_days) < 10) {
      newErrors.sprint_total_days = 'Sprint Capacity should not be less than 10'
    }
    if (Number(sprint_working_days) + Number(sprint_non_working_days) > 14) {
      newErrors.sprint_total_days =
        'Sprint Capacity should not be greater than 14'
    }

    if (
      Number(sprint_non_working_days) > 0 ||
      Number(sprint_working_days) > 10
    ) {
      if (typeof comments === 'undefined') {
        newErrors.comments = 'Please enter the reason'
      } else if (!comments.replace(/\s/g, '').length) {
        newErrors.comments = 'Please enter the reason'
      } else if (comments.length > 150) {
        newErrors.comments = 'comment cannot be larger than 150 characters'
      }
    }

    return newErrors
  }

  // API function
  function createJiraEntry() {
    dispatch(
      editJiraEntry(
        endpoint,
        JSON.stringify(jiraPayload),
        selectedRows[0].KH_UID
      )
    ).then(() => {
      const params = {
        page_size: 50,
        page_number: 1, //handle 0 indexing
        filters: [],
        sort: [],
      }

      dispatch(getJiraEntries(endpoint, params, token))

      setEditShowJira(false)
      setLoadingStatus({ status: false, message: 'Adding Details...' })
    })
  }

  const handleCloseModal = () => {
    setEditShowJira(false)
    setErrors({})
    setForm(new Object())
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    // get our new errors
    let newErrors = findFormErrors()

    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      // console.log(newErrors, "error true: ")
      setErrors(newErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      // console.log(newErrors, "errors: ")
      createJiraEntry()
      setLoadingStatus({ status: true, message: 'Adding Details...' })
      handleCloseModal()
    }
  }

  //update error and form states, and handle onChange form inputs
  const setField = (field, value, e) => {
    setForm({
      ...form,
      [field]: value,
    })
    // Check and see if errors exist, and remove them from the error object:
    if (errors[field])
      setErrors({
        ...errors,
        [field]: null,
      })

    if (field === 'de_name') {
      setUserName(e.target.value)
    } else if (field === 'de_mail') {
      setUserMail(e.target.value)
    } else if (field === 'sprint_name') {
      setSprintName(e.target.value)
    } else if (field === 'alias') {
      setSprintAlias(e.target.value)
    } else if (field === 'pod') {
      setPodName(e.target.value)
    } else if (field === 'vendor') {
      setvendorName(e.target.value)
    } else if (field === 'board') {
      setBoardName(e.target.value)
    } else if (field === 'sprint_working_days') {
      setWorkingDays(e.target.value)
    } else if (field === 'sprint_non_working_days') {
      setNonWorkingDays(e.target.value)
    } else if (field === 'comments') {
      setComments(e.target.value)
    }
  }

  function handleSprintAlias(sprint, e) {
    setSprintAlias(sprint)
    setSprintName(sprint)
    setField('sprint_name', sprint, e)
  }
  useEffect(() => {
    if (tokenValue?.roles?.includes('khdapi.Admin')) {
      setRolevalue(true)
    } else {
      setRolevalue(false)
    }
  }, [])

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Container className="m-0">
          {sprintName !== currentSprint ? (
            roleValue !== true ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">
                  Only Admins are allowed to edit old sprint data!
                </Alert>
              </Stack>
            ) : (
              ' '
            )
          ) : (
            ''
          )}
          <Row className="mb-3 mr-3 rowFlex">
            {roleValue == true ? (
              <Form.Group
                className="flexItem"
                as={Col}
                md="4"
                controlId="validationFormik03"
              >
                <Form.Label>JIRA DE NAME ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  defaultValue={userName}
                  onChange={(e) => setField('de_name', e.target.value, e)}
                  isInvalid={!!errors.de_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.de_name}
                </Form.Control.Feedback>
              </Form.Group>
            ) : (
              <Form.Group
                className="flexItem"
                as={Col}
                md="4"
                controlId="validationFormik03"
              >
                <Form.Label>JIRA DE NAME ID</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  defaultValue={userName}
                  onChange={(e) => setField('de_name', e.target.value, e)}
                  isInvalid={!!errors.de_name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.de_name}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            {roleValue == true ? (
              <Form.Group
                className="flexItem"
                as={Col}
                md="4"
                controlId="validationFormikUsername"
              >
                <Form.Label>KHC Email address</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    aria-describedby="inputGroupPrepend"
                    name="mail"
                    defaultValue={userMail}
                    onChange={(e) => setField('de_mail', e.target.value, e)}
                    isInvalid={!!errors.de_mail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.de_mail}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            ) : (
              <Form.Group
                className="flexItem"
                as={Col}
                md="4"
                controlId="validationFormikUsername"
              >
                <Form.Label>KHC Email address</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Username"
                    aria-describedby="inputGroupPrepend"
                    name="mail"
                    defaultValue={userMail}
                    onChange={(e) => setField('de_mail', e.target.value, e)}
                    isInvalid={!!errors.de_mail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.de_mail}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            )}
          </Row>

          <Row className="mb-3 rowFlex">
            {roleValue == true ? (
              <Form.Group
                className="flexItem"
                as={Col}
                md="2"
                controlId="validationFormik01"
              >
                <Form style={{ marginTop: '26px' }}>
                  <Form.Select
                    value={sprintName}
                    onChange={(e) => {
                      handleSprintAlias(e.target.value, e)
                    }}
                    isInvalid={!!errors.sprint_name}
                  >
                    <option>Select Sprint</option>
                    {jira_meta_data?.jiraAddMetadata?.sprints?.map((sprint) => {
                      return (
                        <option
                          key={sprint?.sprint_name}
                          value={sprint?.sprint_name}
                        >
                          {sprint?.sprint_name}
                        </option>
                      )
                    })}
                  </Form.Select>
                </Form>
              </Form.Group>
            ) : (
              <Form.Group
                className="flexItem"
                as={Col}
                md="2"
                controlId="validationFormik03"
              >
                <Form.Label>Sprint Name</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  placeholder=""
                  name="sprint_name"
                  value={sprintName}
                  onChange={(e) => setField('sprint_name', e.target.value, e)}
                  isInvalid={!!errors.sprint_name}
                />
              </Form.Group>
            )}

            <Form.Group
              className="flexItem"
              as={Col}
              md="2"
              controlId="validationFormik01"
            >
              <Form className="mt-2">
                <Form.Label> </Form.Label>
                <Form.Select
                  value={boardName}
                  onChange={(e) => setField('board', e.target.value, e)}
                  isInvalid={!!errors.board}
                >
                  <option>Select Board</option>
                  {jira_meta_data?.jiraAddMetadata?.boards?.map((board) => {
                    return (
                      <option key={board} value={board}>
                        {board}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form>
            </Form.Group>

            <Form.Group
              className="flexItem"
              as={Col}
              md="2"
              controlId="validationFormik01"
            >
              <Form className="mt-2">
                <Form.Label> </Form.Label>
                <Form.Select
                  value={vendorName}
                  onChange={(e) => setField('vendor', e.target.value, e)}
                  isInvalid={!!errors.vendor}
                >
                  <option>Select Vendor</option>
                  {jira_meta_data?.jiraAddMetadata?.vendors?.map((vendor) => {
                    return (
                      <option key={vendor} value={vendor}>
                        {vendor}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form>
            </Form.Group>

            <Form.Group
              className="flexItem"
              as={Col}
              md="2"
              controlId="validationFormik01"
            >
              <Form className="mt-2">
                <Form.Label> </Form.Label>
                <Form.Select
                  value={podName}
                  onChange={(e) => setField('pod', e.target.value, e)}
                  isInvalid={!!errors.pod}
                >
                  <option>Select Pod</option>
                  {jira_meta_data?.jiraAddMetadata?.pods?.map((pod) => {
                    return (
                      <option key={pod} value={pod}>
                        {pod}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form>
            </Form.Group>
          </Row>

          <Row className="mb-3 rowFlex">
            <Form.Group
              className="flexItem"
              as={Col}
              md="2"
              controlId="validationFormik03"
            >
              <Form.Label>Sprint Alias</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder=""
                name="alias"
                value={sprintAlias}
                onChange={(e) => setField('alias', e.target.value, e)}
                isInvalid={!!errors.alias}
              />
            </Form.Group>
            <Form.Group
              className="flexItem"
              as={Col}
              md="2"
              controlId="validationFormik04"
            >
              <Form.Label>Working Days</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="working"
                value={workingDays}
                onChange={(e) =>
                  setField('sprint_working_days', e.target.value, e)
                }
                isInvalid={!!errors.sprint_working_days}
              />
              <Form.Control.Feedback type="invalid">
                {errors.sprint_working_days}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="flexItem"
              as={Col}
              md="2"
              controlId="validationFormik05"
            >
              <Form.Label>Non Working Days</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="nonWorking"
                value={nonWorkingDays}
                onChange={(e) => {
                  setField('sprint_non_working_days', e.target.value, e)
                }}
                isInvalid={!!errors.sprint_non_working_days}
              />

              <Form.Control.Feedback type="invalid">
                {errors.sprint_non_working_days}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              className="flexItem"
              as={Col}
              md="2"
              controlId="validationFormik05"
            >
              <Form.Label>Total Working Days</Form.Label>
              <Form.Control
                disabled
                type="number"
                placeholder=""
                name="totalWorking"
                value={Number(workingDays) + Number(nonWorkingDays)}
                onChange={(e) =>
                  setField('sprint_total_days', e.target.value, e)
                }
                isInvalid={!!errors.sprint_total_days}
              />

              <Form.Control.Feedback type="invalid">
                {errors.sprint_total_days}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          {Number(nonWorkingDays) > 0 || Number(workingDays) > 10 ? (
            <Row className="mb-3 rowFlex">
              <FloatingLabel
                controlId="floatingTextarea"
                label="Comments"
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  value={comments}
                  name="comments"
                  onChange={(e) => setField('comments', e.target.value, e)}
                  isInvalid={!!errors.comments}
                  as="textarea"
                  placeholder="Leave a comment here"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.comments}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Row>
          ) : (
            ' '
          )}
        </Container>
      </Form>
      <Modal.Footer>
        {sprintName === currentSprint ? (
          <Button
            variant="primary"
            className="addModalBtn"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : roleValue === true ? (
          <Button
            variant="primary"
            className="addModalBtn"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        ) : (
          <Button
            disabled
            variant="primary"
            className="addModalBtn"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
        <Button
          variant="danger"
          type="submit"
          className="addModalBtn"
          onClick={handleCloseModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </>
  )
}
export default EditJiraTracker
