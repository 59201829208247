import React from 'react'
import { useState, useRef } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { AuthContext } from '../../context/authProvider'
import Navbar from '../../Navbar'
import jwt_decode from 'jwt-decode'
import LineageHelpContent from './LineageHelpContent'
import ValueGraph from './ValueGraph'
import Select from './Selector'
import SelectNode from './SelectNode'
import LoadingStatus from '../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../Helper/ToastObjects'
import './graph.css'
import { v4 as uuidv4 } from 'uuid'

// function to pass graph data and behavior of graph
const getOptions = (network, selected, showAll, nodes, edges) => {
  if (selected && network.current) {
    let nodeMap = nodes.reduce(
      (res, node) => ({
        ...res,
        [node.id]: {
          ...node,
          hidden: !showAll,
          muted: true,
        },
      }),
      {}
    )

    // Make all connected nodes + the selected node visible
    let connected = network.current.getConnectedNodes(selected, 'from')
    connected.forEach((id) => {
      nodeMap[id] = { ...nodeMap[id], hidden: false, muted: false }
    })
    nodeMap[selected] = {
      ...nodeMap[selected],
      hidden: false,
      muted: false,
    }

    // Mute all edges
    let edgeMap = edges.reduce((res, edge) => {
      return {
        ...res,
        [edge.id]: {
          ...edge,
          muted: true,
        },
      }
    }, {})

    // Get all edges connected to the selected node, make edges that point to selected node active
    let connectedEdges = network.current.getConnectedEdges(selected)
    connectedEdges.forEach((id) => {
      let edge = edgeMap[id]
      let isActive = edge.to === selected
      edgeMap[id] = { ...edge, muted: !isActive }
    })

    return {
      nodes: Object.values(nodeMap),
      edges: Object.values(edgeMap),
    }
  }

  return { nodes, edges }
}

// main function for handling graph search and render data
const SearchBar = () => {
  /* eslint-disable no-unused-vars */

  const [searchTerm, setSearchTerm] = useState('')
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)

  const [filteredData, setFilteredData] = useState([])
  const [labelName, setLabelName] = useState('')
  const [authCreds, setAuthCreds] = React.useContext(AuthContext)
  const [valueSearch, setValueSearch] = React.useState(false)
  const [searchNodename, setsearchNodename] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [ndata, setNdata] = useState([])
  const [nodesValue, setNodesValue] = useState([])
  const [edgeValue, setEdgeValue] = useState([])
  const [lineageData, setLineageData] = useState(false)
  let [selected, setSelected] = useState(null)
  const [open, setOpen] = React.useState(false)

  let [selectedNode, setSelectedNode] = useState(null)
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching data...',
  })
  let [showAll, setShowAll] = useState(true)
  let network = useRef(null)
  let tokenValue = jwt_decode(localStorage.getItem('token'))
  let user_email = tokenValue.preferred_username
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const idValues = edgeValue.map((v) => ({ ...v, id: uuidv4() }))

  let graphOptions = getOptions(
    network,
    selected,
    showAll,
    nodesValue,
    idValues
  )

  //get label api
  const fetchData = async () => {
    setLoadingStatus({
      status: true,
      message: 'Fetching data...',
    })
    const url = `${authCreds.restEndpoint}/api/neo4j_lineage/get_labels`
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    const response = await axios
      .get(url, { headers: post_headers })
      .catch((err) => {
        setLoadingStatus({
          status: false,
          message: 'Fetching data...',
        })
        return err
      })

    if (response) {
      if (response.data.data.length) {
        setNodesValue([])
        setEdgeValue([])
        setFilteredData([])
        setNdata(response.data.data)
        setLoadingStatus({
          status: false,
          message: 'Fetching data...',
        })
      } else {
        setLoadingStatus({
          status: false,
          message: 'Fetching data...',
        })
        toast('No data available', toastTopRightError)
      }
      // console.log("API connected");
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  //get search API
  const fetchDataSearch = async (data) => {
    setLoadingStatus({
      status: true,
      message: 'Fetching graph...',
    })
    // if (loading) {
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    const url = `${authCreds.restEndpoint}/api/neo4j_lineage/search_schema?nodetype=${labelName}&query=${data}`
    const response = await axios
      .get(url, { headers: post_headers })
      .catch((err) => {
        setLoadingStatus({
          status: false,
          message: 'Fetching data...',
        })
        return err
      })
    if (response) {
      if (response.data.data.length) {
        setFilteredData(response.data.data)
        setLoadingStatus({
          status: false,
          message: 'Fetching data....',
        })
      } else {
        setLoadingStatus({
          status: false,
          message: 'Fetching data...',
        })
        toast('No data available', toastTopRightError)
      }
      // }
    }
  }

  // get lineage API
  const fetchDataLineage = async () => {
    setLoadingStatus({
      status: true,
      message: 'Fetching graph...',
    })
    if (searchTerm == null) {
      setLoadingStatus({
        status: false,
        message: 'Fetching graph...',
      })
      setNodesValue([])
      setEdgeValue([])
      setFilteredData([])
    }
    if (valueSearch && searchTerm != null) {
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
      const url = `${authCreds.restEndpoint}/api/neo4j_lineage/get_lineage?node_type=${labelName}&node_name=${searchTerm}&executed_by=${user_email}`
      const response = await axios
        .get(url, { headers: post_headers })
        .catch((err) => {
          toast(err.response.data.message, toastTopRightError)
          setValueSearch(false)
          setLoadingStatus({
            status: false,
            message: 'Fetching data...',
          })
        })
      if (response.data.nodes.length > 1) {
        setNodesValue(response.data.nodes)
        setEdgeValue(response.data.edges)
        setLineageData(true)
        setLoadingStatus({
          status: false,
          message: 'Fetching data...',
        })
        setValueSearch(false)
      } else {
        setNodesValue([])
        setEdgeValue([])
        setValueSearch(false)
        setLoadingStatus({
          status: false,
          message: 'Fetching data...',
        })
        toast('No data available', toastTopRightError)
      }
    }
  }
  useEffect(() => {
    if (valueSearch) {
      fetchDataLineage()
    }
  }, [valueSearch])

  const optionNode = ndata
    .map(({ value: value, label }) => ({ value, label }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const options = filteredData
    .map(({ value: value, label }) => ({ value, label }))
    .sort((a, b) => a.label.localeCompare(b.label))

  let selectedOption = options.find((o) => selected === o.value)
  let selectedOptionNode = optionNode.find((o) => selectedNode === o.value)

  let handleSelectNode = (option) => {
    let id = option ? option.value : null
    setSelectedNode(id)
    setLabelName(id)
    setNodesValue([])
    setEdgeValue([])
    setFilteredData([])
  }
  let handleSelect = (option) => {
    let id = option ? option.value : null
    setSelected(id)
    setSearchTerm(id)
    // setsearchNodename(id)
    setValueSearch(true)
    // fetchDataLineage();
  }

  // const height = 35

  // class MenuList extends Component {
  //   render() {
  //     const { options, children, maxHeight, getValue } = this.props
  //     const [value] = getValue()
  //     const initialOffset = options.indexOf(value) * height

  //     return (
  //       <List
  //         height={maxHeight}
  //         itemCount={children.length}
  //         itemSize={height}
  //         initialScrollOffset={initialOffset}
  //       >
  //         {({ index, style }) => <div style={style}>{children[index]}</div>}
  //       </List>
  //     )
  //   }
  // }

  useEffect(() => {
    if (searchNodename.length >= 3) {
      const delayDebounceFn = setTimeout(() => {
        fetchDataSearch(searchNodename) // Send Axios request here
      }, 1000)
      setLoading(true)

      return () => clearTimeout(delayDebounceFn)
    } else {
      setValueSearch(false)
    }
  }, [searchNodename])

  return (
    <>
      {/* <div className="mt-2 mb-1 dark-blue bg-gradient navbar-brand header-snowflake-clone">
        <Container fluid>Neo4j Data Lineage</Container>
        <div >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip">What is Capacity Tracker ?</Tooltip>
                    }
                  >
                    <Button
                      aria-label="how to use capacity tracker ?"
                      variant="outlined"
                      label="fd"
                      onClick={handleClickOpen}
                    >
                      <MdHelpOutline
                        style={{ fontSize: '28px', color: '#F9D949' }}
                      />
                    </Button>
                  </OverlayTrigger>

                  <Dialog
                    maxWidth="md"
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="How to Use Capacity Tracker ?"
                  >
                    <DialogTitle>{'What is Capacity Tracker ?'}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        <Typography gutterBottom>
                          1. Capacity Tracker allows you to Add your Sprint
                          details by click on add button and fill information.
                          By default current sprint date will be shown in the
                          modal. Only Admins can select other sprint dates from
                          the list and also they can modify it.
                        </Typography>

                        <Typography gutterBottom>
                          2. In Capacity Tracker you can perform filter and
                          multi filter across the columns. You can also sort the
                          data by clicking on the column name.
                        </Typography>

                        <Typography gutterBottom>
                          3. For searching multiple values in a single column,
                          enter values separated by a comma with no spaces in
                          between. For example inside board column type
                          KIDEO,CA.
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </div>
      </div> */}
      <Navbar title="Data Lineage" HelpContent={LineageHelpContent} />
      <div style={{ background: '#fff' }}>
        <div className="container ">
          <div className="row container">
            <div className=" app col-sm-4">
              <div className="controls">
                <SelectNode
                  value={selectedOptionNode}
                  onChange={handleSelectNode}
                  options={optionNode}
                  className="controls-select"
                />
              </div>
            </div>
            <div className="app col-sm-8">
              <div className="controls">
                <Select
                  onInputChange={(e) => setsearchNodename(e)}
                  value={selectedOption}
                  onChange={handleSelect}
                  options={options}
                  className="controls-select"
                />
                {/* <Checkbox
                  label="Show all"
                  isChecked={showAll}
                  onCheck={setShowAll}
                /> */}
              </div>
            </div>
            {/* {lineageData ? (
              <div className="tabs">
                {ndata.map((item, i) => {
                  const color =
                    item.label == 'snowflake_table' ||
                    item.label == 'snf_table_or_view'
                      ? '#297895'
                      : item.label == 'source'
                      ? '#e78826'
                      : '#932995'
                  return (
                    <button
                      key={i}
                      className="tab1"
                      onClick={window.location.reload}
                      style={{ background: color }}
                    >
                      {item.label}
                    </button>
                  )
                })}
              </div>
            ) : (
              ''
            )} */}
            <div
              style={{ marginBottom: '5px', marginTop: '2px', display: 'flex' }}
            >
              {loadingStatus.status != true ? (
                <ValueGraph
                  onSelect={setSelected}
                  getNetwork={(nw) => (network.current = nw)}
                  {...graphOptions}
                  data={nodesValue}
                />
              ) : (
                <LoadingStatus status_message={loadingStatus.message} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchBar
