import { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const navigate = useNavigate()

  const [buffer, setBuffer] = useState<string[]>([])
  const [timer, setTimer] = useState<number>()
  useEffect(() => {
    if (buffer.length) {
      clearTimeout(timer)
      setTimer(
        window.setTimeout(() => {
          const str = buffer.pop()
          props.setSearchQuery(str || '')
          navigate(`?q=${str}`)
          setBuffer([])
        }, 500)
      )
    }
  }, [buffer])

  return (
    <form
      action="/"
      method="get"
      autoComplete="off"
      onSubmit={(e) => {
        navigate(`?q=${props.searchQuery}`)
        e.preventDefault()
      }}
    >
      <input
        value={buffer.length === 0 ? props.searchQuery : buffer.slice(-1).pop()}
        onFocus={(e) => {
          props.setSearching(true)
          navigate(`?q=${e.target.value}`)
        }}
        onInput={(e) => {
          const value = (e.target as HTMLInputElement).value
          setBuffer([...buffer, value])
        }}
        name="q"
        type="text"
        id="search-bar"
        placeholder="Search For Models..."
      />
    </form>
  )
}

const ContentHeader: React.FC<ContentHeaderProps> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div id="content-header">
      <SearchBar
        isSearching={props.isSearching}
        setSearching={props.setSearching}
        searchQuery={props.searchQuery}
        setSearchQuery={props.setSearchQuery}
      />
      <button
        id="clear-search-btn"
        onClick={() => {
          navigate(location.pathname)
          props.setSearching(false)
          props.setSearchQuery('')
        }}
      >
        X
      </button>
      <Link to="/data-dictionary/contact" id="contact-link">
        Contact Us
      </Link>
    </div>
  )
}

export default ContentHeader
