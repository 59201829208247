import {
  GET_ALL_IDEAS_ERROR,
  GET_ALL_IDEAS,
  GET_ALL_IDEAS_LOAD,
  GET_IDEA_DETAIL_ERROR,
  GET_IDEA_DETAIL,
  GET_IDEA_DETAIL_LOAD,
  ADD_IDEAS_VOTE_LOAD,
  ADD_IDEAS_VOTE_ERROR,
  ADD_IDEAS_VOTE,
  ADD_IDEA_LOAD,
  ADD_IDEA,
  ADD_IDEA_ERROR,
  EDIT_IDEA_LOAD,
  EDIT_IDEA,
  EDIT_IDEA_ERROR,
  APPROVE_REJECT_IDEA_LOAD,
  APPROVE_REJECT_IDEA,
  APPROVE_REJECT_IDEA_ERROR,
  ASSIGN_IDEA_LOAD,
  ASSIGN_IDEA,
  ASSIGN_IDEA_ERROR,
  MARK_IDEA_COMPLETION_LOAD,
  MARK_IDEA_COMPLETION,
  MARK_IDEA_COMPLETION_ERROR,
  GET_FILTER_IDEAS_ERROR,
  GET_FILTER_IDEAS,
  GET_FILTER_IDEAS_LOAD
} from '../../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const getAllFilters = (filters) => async (dispatch) => {
  dispatch({
    type: GET_FILTER_IDEAS_LOAD,
  })
  try {
    dispatch({
      type: GET_FILTER_IDEAS,
      payload: filters,
    })
  } catch (e) {
    dispatch({
      type: GET_FILTER_IDEAS_ERROR,
      payload: 'Error in updating filter',
    })
  }
}

export const getAllIdeas = (URL, params) => async (dispatch) => {
  dispatch({
    type: GET_ALL_IDEAS_LOAD,
  })
  try {
    await axios
      .get(URL, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_ALL_IDEAS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_ALL_IDEAS_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const getIdeaDetail = (URL, params) => async (dispatch) => {
  dispatch({
    type: GET_IDEA_DETAIL_LOAD,
  })
  try {
    await axios
      .get(URL, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_IDEA_DETAIL,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_IDEA_DETAIL_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const createIdeasVote = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_IDEAS_VOTE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }

    await axios
      .post(`${endpoint}/api/ideas/create_ideas_vote`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_IDEAS_VOTE,
          payload: res.data,
        })
        toast('Successfuly voted for idea.', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_IDEAS_VOTE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const createIdea = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_IDEA_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/ideas/create_idea`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_IDEA,
          payload: res.data,
        })
        toast(
          'Created Idea successfully! Sent for Admin Approval.',
          toastTopRightSuccess
        )
      })
  } catch (e) {
    dispatch({
      type: ADD_IDEA_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const editIdea = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_IDEA_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/ideas/edit_idea`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_IDEA,
          payload: res.data,
        })
        toast('Idea Updated successfully.', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: EDIT_IDEA_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const approveOrRejectIdea = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_REJECT_IDEA_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/ideas/approve_idea`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: APPROVE_REJECT_IDEA,
          payload: res.data,
        })
        toast('Idea Status updated successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: APPROVE_REJECT_IDEA_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const assignIdea = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ASSIGN_IDEA_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/ideas/assign_idea`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ASSIGN_IDEA,
          payload: res.data,
        })
        toast('Idea Assigned successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ASSIGN_IDEA_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const markIdeaCompletion = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: MARK_IDEA_COMPLETION_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/ideas/mark_idea_completion`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: MARK_IDEA_COMPLETION,
          payload: res.data,
        })
        toast('Idea Marked as Completed successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: MARK_IDEA_COMPLETION_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
