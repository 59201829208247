import {
  GET_DBT_REPORT,
  GET_DBT_REPORT_ERROR,
  GET_DBT_REPORT_LOADING,
} from '../constants/types'

const initialState = {
  dbtReportData: [],
  loading: false,
  error: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DBT_REPORT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_DBT_REPORT:
      return {
        ...state,
        dbtReportData: action.payload,
        loading: false,
        error: null,
      }
    case GET_DBT_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
