import {
  GET_AZURE_OCR_PDF_LOADING,
  GET_AZURE_OCR_PDF_DATA,
  GET_AZURE_OCR_PDF_ERROR,
  GET_AZURE_OCR_MODELS_LOADING,
  GET_AZURE_OCR_MODELS,
  GET_AZURE_OCR_MODELS_ERROR,
} from '../constants/types'

import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'

export const getAzureOcrPdf =
  (endpoint, params, containerName, directoryName, fileName) =>
  async (dispatch) => {
    dispatch({
      type: GET_AZURE_OCR_PDF_LOADING,
    })
    let post_headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }

    try {
      await axios
        .post(
          `${endpoint}/api/azure_ocr/export_ocr_pdf?container_name=${containerName}&directory_name=${directoryName}&file_name=${fileName}`,
          params,
          {
            headers: post_headers,
          }
        )
        .then((res) => {
          dispatch({
            type: GET_AZURE_OCR_PDF_DATA,
            payload: res.data,
          })
          const pdfProxy = `${res.data}`
          window.open(pdfProxy, '_blank')
        })
    } catch (e) {
      dispatch({
        type: GET_AZURE_OCR_PDF_ERROR,
        payload: e.response.data.message,
      })
    }
  }

export const getModels = (endpoint, token) => async (dispatch) => {
  dispatch({
    type: GET_AZURE_OCR_MODELS_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/azure_ocr/get_ocr_tables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_AZURE_OCR_MODELS,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_AZURE_OCR_MODELS_ERROR,
      payload: e.response.data.message,
    })
  }
}
