//@ts-ignore
import dir_icon from '../assets/dir_icon.svg'
//@ts-ignore
import dir_icon_open from '../assets/dir_icon_open.svg'
//@ts-ignore
import doc_icon from '../assets/doc_icon.svg'
//@ts-ignore
// import doc_selected_icon from "../../../assets/doc_selected_icon.svg";

import { useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import { TREE_MODE } from '../BQDataDictionary'

type LookupTable = { [key: string]: boolean }[][]
type Action = { type: 'toggle'; group: number; index: number; id: string }

function* id_generator(id_list: string[]) {
  while (id_list.length) {
    yield id_list.pop() as string
  }
}

function buildLookupTable(tree: FlatTree): LookupTable {
  return tree.map((row) =>
    (row[1] as FlatTree).map((x) =>
      Object.fromEntries(
        x
          .flat(21)
          .filter((s) => s[0] === '!')
          .map(() => [Math.random().toString(36).slice(2, 7), false])
      )
    )
  )
}

function reducer(lookupTable: LookupTable, action: Action): LookupTable {
  const { type, group, index, id } = action
  switch (type) {
    case 'toggle': {
      const newTable = lookupTable.slice()
      newTable[group][index][id] = !lookupTable[group][index][id]
      return newTable
    }
    default:
      throw new Error()
  }
}

interface TreeProps {
  group: number
  index: number
  children: FlatData
  tree_mode: TREE_MODE
  default_open: boolean
  dispatch: (action: Action) => void
  lookupTable: { [key: string]: boolean }
  keygenerator: Generator<string, void, unknown>
}
const Tree: React.FC<TreeProps> = (props) => {
  const id = props.keygenerator.next().value || ''
  const isOpen = props.lookupTable[id]
  const [label, data] = props.children
  const trimedLabel = label.slice(2)
  const overviewIndicator = label.slice(0, 2)
  const isOverview = overviewIndicator == '!!' || overviewIndicator == '#!'

  return (
    <>
      <li
        onClick={() => {
          props.dispatch({
            type: 'toggle',
            group: props.group,
            index: props.index,
            id: id,
          })
        }}
      >
        {isOverview ? (
          <Link
            to={
              overviewIndicator == '!!'
                ? `/data-dictionary-bq/doc.${trimedLabel}.${trimedLabel}_overview`
                : `/data-dictionary-bq/doc.${trimedLabel}.__${trimedLabel}__`
            }
          >
            <span>
              <img
                width="16px"
                height="16px"
                src={isOpen ? dir_icon_open : dir_icon}
              />
              {label.slice(2)}
            </span>
          </Link>
        ) : (
          <span>
            <img
              width="16px"
              height="16px"
              src={isOpen ? dir_icon_open : dir_icon}
            />
            {label.slice(1)}
          </span>
        )}
      </li>

      {isOpen ? (
        <ul>
          {data.map((_data: any) =>
            typeof _data === 'string' ? (
              <li key={_data}>
                <span>
                  <img width="16px" height="16px" src={doc_icon} />
                  <Link to={`/data-dictionary-bq/${_data}`}>
                    {_data.split('.').pop()}
                  </Link>
                </span>
              </li>
            ) : (
              <Tree
                key={_data[0]}
                group={props.group}
                index={props.index}
                default_open={false}
                dispatch={props.dispatch}
                tree_mode={props.tree_mode}
                lookupTable={props.lookupTable}
                keygenerator={props.keygenerator}
              >
                {_data as FlatData}
              </Tree>
            )
          )}
        </ul>
      ) : null}
    </>
  )
}

interface Props {
  data: FlatTree
  tree_mode: TREE_MODE
}
const MasterTree: React.FC<Props> = (props) => {
  const [lookupTable, dispatch] = useReducer(
    reducer,
    buildLookupTable(props.data)
  )
  const [open, setOpen] = useState([false, true, false])
  return (
    <div id="tree-scroll">
      <div id="exposure-tee">
        <strong onClick={() => setOpen([!open[0], open[1], open[2]])}>
          Exposures
        </strong>
        {open[0] && (
          <ul id="tree-root">
            {(props.data[0][1] as FlatTree).map((tree, i) => {
              return (
                <Tree
                  key={tree[0]}
                  group={0}
                  index={i}
                  dispatch={dispatch}
                  default_open={true}
                  tree_mode={props.tree_mode}
                  lookupTable={lookupTable[0][i]}
                  keygenerator={id_generator(Object.keys(lookupTable[0][i]))}
                >
                  {tree}
                </Tree>
              )
            })}
          </ul>
        )}
      </div>

      <div id="project-tree">
        <strong onClick={() => setOpen([open[0], !open[1], open[2]])}>
          Projects
        </strong>
        {open[1] && (
          <ul id="tree-root">
            {(props.data[1][1] as FlatTree).map((tree, i) => {
              return (
                <Tree
                  key={tree[0]}
                  group={1}
                  index={i}
                  dispatch={dispatch}
                  default_open={true}
                  tree_mode={props.tree_mode}
                  lookupTable={lookupTable[1][i]}
                  keygenerator={id_generator(Object.keys(lookupTable[1][i]))}
                >
                  {tree}
                </Tree>
              )
            })}
          </ul>
        )}
      </div>

      <div id="source-tree">
        <strong onClick={() => setOpen([open[0], open[1], !open[2]])}>
          Sources
        </strong>
        {open[2] && (
          <ul id="tree-root">
            {(props.data[2][1] as FlatTree).map((tree, i) => {
              return (
                <Tree
                  key={tree[0]}
                  group={2}
                  index={i}
                  dispatch={dispatch}
                  default_open={true}
                  tree_mode={props.tree_mode}
                  lookupTable={lookupTable[2][i]}
                  keygenerator={id_generator(Object.keys(lookupTable[2][i]))}
                >
                  {tree}
                </Tree>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default MasterTree
