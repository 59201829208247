import {
  FILE_EXPORT_BADGE_ERROR,
  FILE_EXPORT_BADGE_SUCCESS,
  FILE_LOADING_BADGE,
} from '../constants/types'

const initialState = {
  exportDataBadge: [],
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FILE_LOADING_BADGE:
      return {
        ...state,
        loading: true,
      }

    case FILE_EXPORT_BADGE_SUCCESS:
      return {
        ...state,
        exportDataBadge: action.payload,
        loading: false,
      }
    case FILE_EXPORT_BADGE_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
