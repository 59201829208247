import React from 'react'
import Select from 'react-select'
import theme from '../../../data/theme.json'

const white = (styles) => ({ ...styles, color: theme.font })

const selectStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    backgroundColor: 'rgb(235 235 235)',
    height: 'auto',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '.5rem 1rem',
    fontWeight: 800,
  }),
  input: (styles) => ({
    ...styles,
    color: '#000',
    fontWeight: 300,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: '#000',
    fontWeight: 300,
  }),
  indicatorSeparator: white,
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#000',
    fontWeight: 300,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000',
    fontWeight: 500,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#ccc',
    fontWeight: 300,
  }),
  menu: (styles, { isSelected, isFocused }) => ({
    ...styles,
    zIndex: 998,
    backgroundColor: isSelected || isFocused ? theme.primary : theme.secondary,
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    zIndex: 999,
    width: '100%',
    color: '#fff',
    backgroundColor: isSelected || isFocused ? theme.primary : theme.secondary,
  }),
}

const Selector = (props) => (
  <Select
    placeholder="Search a Node Name..."
    hideSelectedOptions={false}
    isClearable
    styles={selectStyles}
    {...props}
  />
)

export default Selector
