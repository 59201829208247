import jwt_decode from 'jwt-decode'

class CheckLoginKholabChapterMember {
  static CheckChapterMember = (token, channel) => {
    try {
      const value = jwt_decode(token)
      const email = value?.preferred_username?.toLowerCase()
      const badgeChapterDetails = localStorage.getItem('badge_metadata')
      const badgeChapterDetailsObj = JSON.parse(badgeChapterDetails)
      for (let [key, value] of Object.entries(
        badgeChapterDetailsObj?.channel_admins
      )) {
        if (channel === key) {
          let adminEmail = value
          const isValid = adminEmail.includes(email)
          return isValid
        }
      }
    } catch (error) {
      return false
    }
  }
}

export { CheckLoginKholabChapterMember }
