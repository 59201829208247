import { APPROVED_STATUS, PENDING_STATUS } from '../constants/types'

const initialState = { is_approved: null }

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case APPROVED_STATUS:
      return { is_approved: true }
    case PENDING_STATUS:
      return { is_approved: null }
    default:
      return state
  }
}
