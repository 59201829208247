import React, { useState, useEffect } from 'react'
import LoadingStatus from '../../Helper/LoadingStatus'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import '../../../App.css'
import { AuthContext } from '../../context/authProvider'

import { useDispatch, useSelector } from 'react-redux'
import {
  uploadFileToBlobAction,
  getDeFileUploadPaths,
  getDeFileUploadRegex,
  getDeFilePresentNames,
} from '../../../redux/actions/deFileUploadAction/deFileUploadAction'

export default function BlobUpload() {
  const [authCreds] = React.useContext(AuthContext)

  const [blobFile, setBlobUploadFile] = React.useState('')
  const [blobUploadErrors, setBlobUploadErrors] = useState({})
  const [blobUploadForm, setBlobUploadForm] = useState({})
  const [blobUploadFilePath, setBlobUploadFilePath] = React.useState('')
  const [blobUploadFileName, setBlobUploadFileName] = React.useState('')
  const [blobUploadFileSize, setBlobUploadFileSize] = React.useState('')
  const [blobUploadOverwrite, setBlobUploadOverwrite] = React.useState(false)
  const [blobUploadFileRegex, setBlobUploadFileRegex] = React.useState('')
  const [deFileUploadSelectedPathFiles, setDeFileUploadSelectedPathFiles] =
    React.useState('')
  const [
    deFileUploadSelectedPathFilesAvailable,
    setDeFileUploadSelectedPathFilesAvailable,
  ] = React.useState(false)
  const [
    deFileUploadSelectedPathFilesStart,
    setDeFileUploadSelectedPathFilesStart,
  ] = React.useState(0)

  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Uploading file...',
  })

  const [presentFilesLoadingStatus, setPresentFilesLoadingStatus] =
    React.useState({
      status: false,
      message: 'Getting present files...',
    })

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const fileBufferStepIncrease = 8

  const deFileUploadStateRegex = useSelector((state) => state.deFileUploadRegex)
  const deFileUploadPaths = useSelector((state) => state.deFileUploadPaths)
  const deFileUploadSelectedPathFilesState = useSelector(
    (state) => state.deFileUploadPresentFiles
  )

  //handle blob Upload modal
  const handleCloseBlobUpload = () => {
    setBlobUploadErrors({})
    setBlobUploadForm(new Object())
  }
  const uploadFileToBlob = () => {
    let formData = new FormData()
    let blobPath = blobUploadFilePath
    let blobName = blobUploadFileName
    formData.append('filebytes', blobFile)

    setLoadingStatus({ status: true, message: 'Uploading file...' })
    dispatch(
      uploadFileToBlobAction(
        endpoint,
        formData,
        blobFile,
        blobPath,
        blobName,
        blobUploadOverwrite
      )
    )
      .then(() => {
        setLoadingStatus({ status: false, message: 'Uploading file...' })
        handleCloseBlobUpload()
      })
      .catch((error) => {
        return error
      })
  }

  async function refreshFilePaths() {
    setLoadingStatus({
      status: true,
      message: 'Getting file paths and regex...',
    })
    setDeFileUploadSelectedPathFiles('')
    setDeFileUploadSelectedPathFilesStart(0)

    dispatch(getDeFileUploadRegex(endpoint)).catch((error) => {
      return error
    })

    dispatch(getDeFileUploadPaths(endpoint))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Getting file paths and regex...',
        })
      })
      .catch((error) => {
        return error
      })
  }

  const refreshSelectedPathFiles = (path) => {
    setDeFileUploadSelectedPathFiles('')
    setDeFileUploadSelectedPathFilesStart(0)
    setPresentFilesLoadingStatus({
      status: true,
      message: 'Getting Present Files...',
    })
    dispatch(getDeFilePresentNames(endpoint, path))
      .then(() => {
        setPresentFilesLoadingStatus({
          status: false,
          message: 'Getting Present Files...',
        })
        setDeFileUploadSelectedPathFilesAvailable(true)
      })
      .catch((error) => {
        return error
      })
  }

  const IncreaseSelectedPathStart = () => {
    setDeFileUploadSelectedPathFilesStart(
      deFileUploadSelectedPathFilesStart + fileBufferStepIncrease
    )
  }

  const DecreaseSelectedPathStart = () => {
    setDeFileUploadSelectedPathFilesStart(
      deFileUploadSelectedPathFilesStart - fileBufferStepIncrease
    )
  }

  //handle upload to blob form submit
  const handleUploadSubmit = (e) => {
    e.preventDefault()
    // get our new errors
    const newBlobUploadErrors = findBlobUploadFormErrors()
    // Conditional logic:
    if (Object.keys(newBlobUploadErrors).length > 0) {
      // We got errors!
      setBlobUploadErrors(newBlobUploadErrors)
    } else {
      // No errors! Put any logic here for the form submission!
      uploadFileToBlob()
    }
  }

  //Find form errors
  const findBlobUploadFormErrors = () => {
    const { blob_upload_file } = blobUploadForm
    const newBlobUploadErrors = {}
    // Upload to blob File path and name errors
    if (!blob_upload_file || blob_upload_file === '')
      newBlobUploadErrors.blob_upload_file = 'No file selected'
    else if (blobUploadFileSize > 210000000)
      newBlobUploadErrors.blob_upload_file =
        'File Size should not be more than 200 mb'
    else if (blobUploadFilePath === 'Select Path')
      newBlobUploadErrors.blobUploadFilePath = 'Please select a path'
    else if (blobUploadFileName === '')
      newBlobUploadErrors.blobUploadFileName = 'Filename should not be empty'
    else if (blobUploadFileName.includes('/'))
      newBlobUploadErrors.blobUploadFileName = 'Filename should not contain /'
    else if (blobUploadFilePath === '')
      newBlobUploadErrors.blobUploadFilePath = 'Filepath should not be empty'
    else if (blobUploadFilePath.charAt(0) === '/')
      newBlobUploadErrors.blobUploadFilePath =
        'Filepath should not start with /'
    else if (blobUploadFilePath.charAt(blobUploadFilePath.length - 1) !== '/')
      newBlobUploadErrors.blobUploadFilePath = 'Filepath should end with /'
    else if (blobUploadFileName !== blobUploadFileName.toLocaleLowerCase())
      newBlobUploadErrors.blobUploadFileName =
        'Filename should not contain Uppercase letters'
    else if (blobUploadFilePath !== blobUploadFilePath.toLocaleLowerCase())
      newBlobUploadErrors.blobUploadFilePath =
        'File path should not contain Uppercase letters'

    return newBlobUploadErrors
  }

  //update error and form states and handle on change
  const setBlobUploadField = (field, value, e) => {
    setBlobUploadForm({
      ...blobUploadForm,
      [field]: value,
    })
    // Check and see if errors exist, and remove them from the error object:
    if (blobUploadErrors[field])
      setBlobUploadErrors({
        ...blobUploadErrors,
        [field]: null,
      })

    if (field === 'blob_upload_file') {
      let blobUploadfile = e.target.files[0]
      setBlobUploadFile(blobUploadfile)
      setBlobUploadFileSize(blobUploadfile.size)
      setBlobUploadFileName(blobUploadfile.name)
    } else if (field === 'blob_upload_file_path') {
      setDeFileUploadSelectedPathFiles('')
      setDeFileUploadSelectedPathFilesStart(0)
      setDeFileUploadSelectedPathFilesAvailable(false)
      let blobUploadFilePath = e.target.value
      setBlobUploadFilePath(blobUploadFilePath)
      if (blobUploadFilePath !== 'Select Path') {
        setBlobUploadFileRegex(
          deFileUploadStateRegex.deFileUploadRegex['data'][blobUploadFilePath]
        )
        refreshSelectedPathFiles(e.target.value)
      } else {
        setBlobUploadFileRegex('')
      }
    } else if (field === 'blob_upload_overwrite') {
      setBlobUploadOverwrite(e.target.checked)
    }
  }

  useEffect(() => {
    setDeFileUploadSelectedPathFiles(
      deFileUploadSelectedPathFilesState?.deFileUploadPresentFiles
    )
  }, [deFileUploadSelectedPathFilesState])

  useEffect(() => {
    refreshFilePaths()
  }, [])

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>DE File Upload</Modal.Title>
      </Modal.Header>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group
                  controlId="formFile"
                  className="mb-3"
                  data-testid="steps"
                >
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <p>
                          Step 1: Please ensure you have access to upload
                          location.
                        </p>
                        <p>Step 2: Select a folder from drop down.</p>
                        <p>
                          Step 3: Filename must match expected filename format.
                        </p>
                        <p>
                          Step 4: Select the file and click Upload File. Size
                          Limit: 200mb
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Form.Group>
                <Card>
                  <Card.Body>
                    <Form.Group controlId="PathSelect" className="mb-3">
                      <Row>
                        <Col sm={{ span: 2 }}>
                          <Form.Label
                            className="frm-label"
                            style={{ fontSize: '14px' }}
                          >
                            Folder:
                          </Form.Label>
                        </Col>
                        <Col sm={{ span: 10 }}>
                          <Form.Select
                            size="sm"
                            defaultValue={blobUploadFilePath}
                            onChange={(e) =>
                              setBlobUploadField(
                                'blob_upload_file_path',
                                e.target.value,
                                e
                              )
                            }
                            isInvalid={!!blobUploadErrors.blobUploadFilePath}
                          >
                            <option>Select Path</option>
                            {deFileUploadPaths?.deFileUploadPaths?.data?.map(
                              (path) => {
                                return (
                                  <option key={path} value={path}>
                                    {path}
                                  </option>
                                )
                              }
                            )}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {blobUploadErrors.blobUploadFilePath}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={{ span: 2 }}>
                          <Form.Label
                            className="frm-label"
                            style={{ fontSize: '14px' }}
                          >
                            Filename Validation:
                          </Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            plaintext
                            placeholder="Allowed File Regex"
                            aria-label="Disabled input example"
                            readOnly
                            value={blobUploadFileRegex}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={{ span: 2 }}>
                          <Form.Label
                            className="frm-label"
                            style={{ fontSize: '14px' }}
                          >
                            Overwrite Exisiting:
                          </Form.Label>
                        </Col>
                        <Col sm={{ span: 8 }}>
                          <Form.Check
                            type="checkbox"
                            checked={blobUploadOverwrite}
                            id="default-checkbox"
                            label=""
                            onChange={(e) =>
                              setBlobUploadField(
                                'blob_upload_overwrite',
                                e.target.value,
                                e
                              )
                            }
                            size="large"
                            text-align="left"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={{ span: 2 }}>
                          <Form.Label
                            className="frm-label"
                            style={{ fontSize: '14px' }}
                          >
                            Select File:
                          </Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            type="file"
                            size="sm"
                            onChange={(e) =>
                              setBlobUploadField(
                                'blob_upload_file',
                                e.target.value,
                                e
                              )
                            }
                            isInvalid={
                              !!blobUploadErrors.blob_upload_file ||
                              !!blobUploadErrors.blobUploadFileName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {blobUploadErrors.blob_upload_file ||
                              blobUploadErrors.blobUploadFileName}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleUploadSubmit}
                    >
                      Upload File
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={5}>
                <Form.Group controlId="PreviewFile" className="mb-3">
                  {deFileUploadSelectedPathFilesAvailable ? (
                    <Card style={{ width: '30rem' }}>
                      <Card.Header>
                        <Row>
                          <Col xs={9}>
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={DecreaseSelectedPathStart}
                              disabled={
                                !blobUploadFilePath ||
                                blobUploadFilePath === 'Select Path' ||
                                !deFileUploadSelectedPathFiles ||
                                deFileUploadSelectedPathFilesStart <= 0
                              }
                            >
                              Prev
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={IncreaseSelectedPathStart}
                              disabled={
                                !blobUploadFilePath ||
                                blobUploadFilePath === 'Select Path' ||
                                !deFileUploadSelectedPathFiles ||
                                deFileUploadSelectedPathFiles?.data.length <=
                                  deFileUploadSelectedPathFilesStart +
                                    fileBufferStepIncrease
                              }
                            >
                              Next
                            </Button>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Header>
                        {deFileUploadSelectedPathFiles?.data
                          ?.slice(
                            deFileUploadSelectedPathFilesStart,
                            deFileUploadSelectedPathFilesStart +
                              fileBufferStepIncrease
                          )
                          .map((path) => {
                            return (
                              <Card.Text key={path} value={path}>
                                {path}
                              </Card.Text>
                            )
                          })}
                      </Card.Header>
                    </Card>
                  ) : presentFilesLoadingStatus.status ? (
                    <LoadingStatus
                      status_message={presentFilesLoadingStatus.message}
                    />
                  ) : (
                    <Row>
                      <Col sm={{}}>
                        <Card style={{ width: '30rem' }}>
                          <Card.Header>
                            <Card.Text>
                              {Array.from({ length: 8 }).map((_, idx) => (
                                <br key={idx}></br>
                              ))}
                            </Card.Text>
                            <Card.Text>
                              <div
                                style={{
                                  textAlign: 'center',
                                  fontSize: '15px',
                                }}
                              >
                                No Preview Available
                              </div>
                            </Card.Text>
                            <Card.Text>
                              {Array.from({ length: 8 }).map((_, idx) => (
                                <br key={idx}></br>
                              ))}
                            </Card.Text>
                          </Card.Header>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      )}
    </>
  )
}
