import {
  GET_REF_TABLES_DATA,
  GET_REF_TABLES_DATA_LOADING,
  DELETE_REF_TABLES_DATA,
  TABLE_ERROR,
  GET_REF_TABLES_DATA_ERROR,
} from '../constants/types'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
export const getRefTableData = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REF_TABLES_DATA_LOADING,
    })
    await axios
      .get(`${endpoint}/api/ref_data/get_collection`, {
        params: params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          khuser: `${localStorage.getItem('user')}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_REF_TABLES_DATA,
          payload: res.data,
        })
        if (res.data.data.length === 0) {
          toast('No records found!', toastTopRightError)
        }
      })
      .catch((error) => {
        toast(error.response.data.message, toastTopRightError)
        dispatch({
          type: GET_REF_TABLES_DATA_ERROR,
          payload: error.response.data,
        })
      })
  } catch (e) {
    dispatch({
      type: TABLE_ERROR,
      payload: console.log(e),
    })
  }
}

export const DeleteRefTableData =
  (endpoint, uri, payload) => async (dispatch) => {
    try {
      dispatch({
        type: GET_REF_TABLES_DATA_LOADING,
      })
      await axios
        .post(
          `${endpoint}/api/ref_data/soft_delete?table_name=${uri}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              khuser: `${localStorage.getItem('user')}`,
            },
          }
        )
        .then((res) => {
          dispatch({
            type: DELETE_REF_TABLES_DATA,
            payload: res.data,
          })
          toast('Rows deleted!', toastTopRightSuccess)
        })
        .catch(() => {
          toast('Error in deleting records!', toastTopRightError)
        })
    } catch (e) {
      dispatch({
        type: TABLE_ERROR,
        payload: console.log(e),
      })
    }
  }
