import columnsMap from '../../../data/ColumnsMapping'

class Helpers {
  formatDate(recdate) {
    let date = '' + recdate
    if (recdate == null) {
      return ''
    }
    const splibySpace = date.split(' ')
    const splicedDateBySpace = splibySpace[0]
    const splicedByDash = splicedDateBySpace.split('-')

    if (splicedByDash.length === 3) {
      return splicedByDash[1] + '-' + splicedByDash[2] + '-' + splicedByDash[0]
    } else {
      return ''
    }
  }

  formatDateForFilters(recdata) {
    const result =
      recdata.substring(0, 4) +
      '-' +
      recdata.substring(4, 6) +
      '-' +
      recdata.substring(6, 8)
    return result
  }

  filterDate(inDate) {
    const splitDate = inDate.replace('-', '').replace('-', '')
    return splitDate
  }

  paintCell(coloumnId, value, index) {
    if (
      (coloumnId === columnsMap.CFR_MISSED ||
        coloumnId === columnsMap.ORAD_MISSED) &&
      value === 'YES'
    ) {
      return 'danger'
    } else if (coloumnId === columnsMap.IF_DISCREPENCIES && value === 'YES') {
      return 'danger'
    } else if (coloumnId === 'act_arrival_date') {
      if (index < 1) {
        return 'danger'
      } else {
        return ''
      }
    } else if (
      coloumnId === 'delivered_date_by_retailer' ||
      coloumnId === 'violation_type'
    ) {
      if (value !== '') {
        return 'danger'
      } else {
        return 'safe'
      }
    } else if (coloumnId === 'fill_rate_original_retailer') {
      if (index < 1) {
        return 'danger'
      } else {
        return 'safe'
      }
    } else if (coloumnId === 'fill_rate_revised_retailer') {
      if (index < 1) {
        return 'danger'
      } else {
        return 'safe'
      }
    } else if (coloumnId === 'fill_rate_original') {
      if (index < 1) {
        return 'danger'
      } else {
        return 'safe'
      }
    } else if (coloumnId === 'fill_rate_revised') {
      if (index < 1) {
        return 'danger'
      } else {
        return 'safe'
      }
    } else if (coloumnId === 'for_dispute') {
      if (value === 'Yes') {
        return 'danger'
      } else {
        return 'safe'
      }
    } else if (coloumnId === 'invoice_deduction_amount') {
      if (value !== '') {
        return 'danger'
      } else {
        return 'safe'
      }
    }
    return ''
  }

  formatNumbers(value, precision = 2) {
    let val = value || 0
    return Number(val)
      ?.toFixed(precision)
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  getOffsetFromCurrentDate(offset = 0) {
    const currentDate = new Date()
    const pastDate = new Date(currentDate)
    pastDate.setDate(currentDate.getDate() - offset)

    const month = (pastDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    const day = pastDate.getDate().toString().padStart(2, '0')
    const year = pastDate.getFullYear()

    return `${year}-${month}-${day}`
  }

  checkOradOtsValidation(orderData, fine) {
    let allowed_status = ['SHIPPED', 'EARLY', 'LATE', 'DELIVERED', 'ON TIME']
    let shipped_status = orderData?.[columnsMap.SHIPPING_STATUS]?.toUpperCase()
    let fine_missed = orderData?.[columnsMap[`${fine}_MISSED`]]?.toUpperCase()

    if (allowed_status.includes(shipped_status)) {
      if (fine_missed === 'YES') {
        return 'cross'
      } else {
        return 'tick'
      }
    }
  }

  prepareFilters(filters) {
    let modifiedFilters = []
    if (filters?.retailer && filters?.retailer !== '') {
      modifiedFilters.push(
        `{"op":"ilike","field":"${columnsMap.RETAILER_NAME}","value":"%${filters?.retailer}%"}`
      )
    }
    if (filters?.country && filters?.country !== '') {
      modifiedFilters.push(
        `{"op":"ilike","field":"${columnsMap.RETAILER_COUNTRY}","value":"%${filters?.country}%"}`
      )
    }
    if (filters?.state && filters?.state !== '') {
      modifiedFilters.push(
        `{"op":"ilike","field":"${columnsMap.RETAILER_STATE}","value":"%${filters?.state}%"}`
      )
    }
    if (filters?.search_term && filters?.search_term !== '') {
      modifiedFilters.push(
        `{"op":"eq","field":"${columnsMap.CUSTOMER_PO_NO}","value":"${filters?.search_term}"}`
      )
    }
    let dateFilterApply = false
    let formatted_from_date = ''
    let formatted_to_date = ''
    if (
      filters?.date_type === 'range' &&
      filters?.from_date !== '' &&
      filters?.to_date !== ''
    ) {
      formatted_from_date = this.formatDateForFilters(filters?.from_date)
      formatted_to_date = this.formatDateForFilters(filters?.to_date)
      dateFilterApply = true
    } else if (filters?.date_type === 'last_week') {
      formatted_from_date = this.getOffsetFromCurrentDate(7)
      formatted_to_date = this.getOffsetFromCurrentDate(0)
      dateFilterApply = true
    } else if (filters?.date_type === 'last_month') {
      formatted_from_date = this.getOffsetFromCurrentDate(30)
      formatted_to_date = this.getOffsetFromCurrentDate(0)
      dateFilterApply = true
    } else if (filters?.date_type === '3_months') {
      formatted_from_date = this.getOffsetFromCurrentDate(90)
      formatted_to_date = this.getOffsetFromCurrentDate(0)
      dateFilterApply = true
    } else if (filters?.date_type === '6_months') {
      formatted_from_date = this.getOffsetFromCurrentDate(180)
      formatted_to_date = this.getOffsetFromCurrentDate(0)
      dateFilterApply = true
    }
    if (dateFilterApply === true) {
      modifiedFilters.push(
        `{"op":"gt","field":"${columnsMap.PO_DATE}","value":"${formatted_from_date}"}`
      )
      modifiedFilters.push(
        `{"op":"lt","field":"${columnsMap.PO_DATE}","value":"${formatted_to_date}"}`
      )
    }
    let filterString = '[' + modifiedFilters.join(',') + ']'
    return filterString
  }

  prepareLikeFilter(filters) {
    let modifiedFilters = []
    filters.forEach((filter) => {
      modifiedFilters.push(
        `{"op":"ilike","field":"${filter.field}","value":"${filter.value}"}`
      )
    })
    return '[' + modifiedFilters.join(',') + ']'
  }

  validateEmail(email) {
    let re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i
    if (re.test(email)) {
      return true
    } else {
      return false
    }
  }
}

const help = new Helpers()
export default help
