import React, { useState, useEffect, useContext } from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingStatus from '../../Helper/LoadingStatus'
import { MdHelpOutline } from 'react-icons/md'
import { Button } from 'react-bootstrap'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from '../../context/authProvider'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { getAvgAlgorithmsScore } from '../../../redux/actions/dbtGradingActions/dbtGradingAction'

export default function GetAvgAlgorithmsScore({
  uid,
  showModal,
  projectName,
  handleCloseModal,
}) {
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [authCreds] = useContext(AuthContext)
  const [tableData, setTableData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [numTermsShowed, setNumTermsShowed] = useState(0)
  const handlePageChange = (number) => {
    setCurrentPage(number)
  }
  // const [columnFilters, setColumnFilters] = useState([])
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: '12px',
      whiteSpace: 'break-spaces',
    },
  }))
  // const [columnSort, setColumnSortValue] = useState([])
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const avgAlgorithmsScore = useSelector(
    (state) => state.getAvgAlgorithmsScoreReducer
  )

  const fetchData = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (uid) {
      const params = {
        page_size: 10,
        page_number: currentPage, //handle 0 indexing
        project_id: uid,
        sort: JSON.stringify([{ field: `${sortBy}`, direction: `${orderBy}` }]),
      }
      dispatch(getAvgAlgorithmsScore(endpoint, params))
        .then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching Average Algorithm Scores...',
          })
        })
        .catch(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching Average Algorithm Scores...',
          })
        })
    }
  }

  useEffect(() => {
    let table_data = avgAlgorithmsScore?.avgAlgorithmsScore?.data
    if (table_data?.length > 0) {
      setTotalRows(avgAlgorithmsScore?.avgAlgorithmsScore?.total_results)
      setTotalPages(avgAlgorithmsScore?.avgAlgorithmsScore?.num_pages)
      setTableData(table_data)
      let numPages = avgAlgorithmsScore?.avgAlgorithmsScore?.num_pages
      if (numPages === currentPage) {
        setNumTermsShowed(totalRows)
      } else {
        setNumTermsShowed(18 * currentPage)
      }
    } else {
      setTotalRows(0)
      setTotalPages(0)
      setTableData([])
    }
  }, [setTableData, avgAlgorithmsScore])
  const orderByMapping = ['desc', 'asc']
  const sortByMapping = {
    ALGORITHM: 'Algorithm',
    avg_score: 'Average Score',
    GRADE: 'Grade',
  }
  const [sortBy, setSortBy] = useState('ALGORITHM')
  const [orderBy, setOrderBy] = useState('desc')

  useEffect(() => {
    fetchData()
  }, [uid, sortBy, orderBy])

  return (
    <>
      <Modal size="xl" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            View Average Algorithm Score ({projectName})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          {loadingStatus.status ? (
            <LoadingStatus status_message={loadingStatus.message} />
          ) : (
            <>
              <div style={{ background: '#fff' }}>
                <div className="nav-menu" style={{ display: 'flex' }}>
                  <div>
                    <DropdownButton
                      id="dropdown-order-by"
                      title={`Sort By: ${sortByMapping[sortBy]}`}
                      className="glossary-sort-buttons glossary-sort-buttons-idea"
                      onSelect={(eventKey) => setSortBy(eventKey)}
                    >
                      {Object.entries(sortByMapping).map(([key, val]) => (
                        <Dropdown.Item eventKey={key} key={key}>
                          {val}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div>
                    <DropdownButton
                      id="dropdown-order-by"
                      title={orderBy}
                      className="glossary-sort-buttons glossary-sort-buttons-idea"
                      onSelect={(eventKey) => setOrderBy(eventKey)}
                    >
                      {orderByMapping.map((val) => (
                        <Dropdown.Item eventKey={val} key={val}>
                          {val}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </div>
                <div className="glossary-card-list">
                  {tableData.map(function (data) {
                    return (
                      <div className="glossary-card" key={data.ALGORITHM}>
                        <BootstrapTooltip title="Algorithm">
                          <h2 style={{ color: '#1D3C6D' }}>
                            {data.ALGORITHM}{' '}
                            <MdHelpOutline
                              style={{ fontSize: '28px', color: '#0a58ca' }}
                            />
                          </h2>
                        </BootstrapTooltip>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            flexDirection: 'row',
                          }}
                        >
                          <span>
                            <span style={{ fontWeight: '500' }}>
                              AVERAGE SCORE:
                            </span>
                            <span
                              style={{
                                borderRadius: '9999px',
                                backgroundColor: '#227D9B',
                                color: '#fff',
                                padding: '0.45em',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'inline-flex',
                                width: '1.75rem',
                                height: '1.75rem',
                              }}
                            >
                              {data.avg_score}
                            </span>
                          </span>
                          <span>
                            <span style={{ fontWeight: '500' }}>GRADE:</span>
                            <span
                              style={{
                                borderRadius: '9999px',
                                backgroundColor: '#227D9B',
                                color: '#fff',
                                padding: '0.45em',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'inline-flex',
                                width: '1.75rem',
                                height: '1.75rem',
                              }}
                            >
                              {data.Grade}
                            </span>
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* Bottom ToolBar of table */}
                <div className="glossary-pagination-container">
                  <br />
                  <button
                    className="badge-btn"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || totalRows === 0}
                  >
                    Prev
                  </button>
                  <button
                    className="badge-btn"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages || totalRows === 0}
                  >
                    Next
                  </button>
                  <p className="glossary-pagination-text">
                    Showing: {numTermsShowed} of {totalRows} Scores
                  </p>
                </div>
              </div>
            </>
          )}
          <Modal.Footer>
            <Button
              variant="danger"
              type="submit"
              className="addModalBtn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  )
}
