import React from 'react'
import IngestionTable from './IngestionTable'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from '../../Navbar'
import IngestionHelpContent from './IngestionHelpContent'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
/// let tokenValue = jwt_decode(localStorage.getItem('token'))

const IngestionIntakeTable = () => {
  let navigate = useNavigate()
  const columns = React.useMemo(
    () => [
      {
        Header: 'PROJECT NAME',
        accessor: 'PROJECT_NAME', // accessor is the "key" in the data
        is_editable: true,
        is_filterable: true,
        size: 10,
      },
      {
        Header: 'STATUS',
        accessor: 'STATUS',
        is_editable: true,
        is_filterable: true,
        size: 10,
      },
      {
        Header: 'SOURCE DETAIL',
        accessor: 'SOURCE_DETAIL',
      },
      {
        Header: 'DATA CLASSIFICATION',
        accessor: 'DATA_CLASSIFICATION',
      },
      {
        Header: 'REQUESTED BY',
        accessor: 'ENTERED_BY',
        is_editable: false,
        is_filterable: true,
        size: 10,
      },
      {
        Header: 'REQUESTED FOR',
        accessor: 'REQUESTED_BY',
      },
      {
        Header: 'REQUEST DATE',
        accessor: 'REQUEST_DATE',
      },
      {
        Header: 'COMMENTS',
        accessor: 'COMMENTS',
        Cell: ({ value }) => (
          <div
            title={value}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value}
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: 'HISTORY',
        accessor: 'REVIEW_COMMENTS',
        Cell: ({ value }) => (
          <div
            title={value}
            style={{
              maxWidth: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {value}
          </div>
        ),
        size: 10,
      },
      // add more columns here
    ],
    []
  )
  const handleClick = () => {
    navigate('/ingestion-intake/form')
  }
  const handleClickHome = () => {
    navigate(0)
  }

  return (
    <>
      <React.Fragment>
        <div className="nps">
          <Navbar
            title={'Ingestion Intake Form Submissions'}
            HelpContent={IngestionHelpContent}
          />
          <Button
            className={'m_r-5 m_t-10 nav-btn  btn-lg badge-nav-btn'}
            onClick={handleClickHome}
          >
            Home
          </Button>
          <Button
            className={'m_r-5 m_t-10 nav-btn  btn-lg badge-nav-btn'}
            onClick={handleClick}
          >
            Submit Form
          </Button>
          <div
            class="container-fluid bg-white text-dark"
            style={{ height: 'fit-content', paddingTop: '20px', width: '80%' }}
          >
            <IngestionTable columns={columns} />
          </div>
        </div>
      </React.Fragment>
    </>
  )
}

export default IngestionIntakeTable
