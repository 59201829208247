import React, { useEffect, useMemo, useContext } from 'react'
import '../RefData/RefTable.css'
import LoadingStatus from '../../Helper/LoadingStatus'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import {
  Table,
  Button,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import Sidebar from '../AppBar/Appbar'
// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import { toastTopRightError } from '../../Helper/ToastObjects'
import { AuthContext } from '../../context/authProvider'
import { TableHandler } from '../../Utils'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import '../../../App.css'
import './users.css'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'

import '../../../App.css'
import Container from 'react-bootstrap/Container'
import {
  getAllusers,
  editUser,
  exportAdminUsers,
} from '../../../redux/actions/badgeAction'
import { MdHighlightOff, MdOutlineDone } from 'react-icons/md'
import { BiExport } from 'react-icons/bi'
import './users.css'
export default function Users() {
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })

  const [authCreds] = useContext(AuthContext)
  const [editRows, setEditRows] = React.useState([])
  //   const [enabledColsMap, setEnabledColsMap] = React.useState({})
  const [tableData, setTableData] = React.useState([])
  const [totalPages, setTotalPages] = React.useState(1)
  /* eslint-disable no-unused-vars */
  const [totalRows, setTotalRows] = React.useState(0)
  const [tableColumns, setTableColumns] = React.useState([])
  const [localPageIndex, setLocalPageIndex] = React.useState(0)
  const [columnFilters, setColumnFilters] = React.useState([])
  const [channel, setChannel] = React.useState(localStorage.getItem('channel'))

  const [columnSort, setColumnSortValue] = React.useState([])
  const [isSort, setSort] = React.useState(false)
  const [showModal, setshowModal] = React.useState(false)
  const [editModeState, setEditModeState] = React.useState(false)
  const [rowIndex, setRowIndex] = React.useState(null)
  const [inlineValue, setInlineValue] = React.useState([])
  const [userEmail, setUserEmail] = React.useState('')
  const [userName, setUserName] = React.useState('')
  const [userTitle, setUserTitle] = React.useState('')
  const [userCompany, setUserCompany] = React.useState('')
  const [userRoletype, setUserRoletype] = React.useState('')
  const [userChapterRole,setUserChapterRole] = React.useState('')
  const [userGroup, setUserGroup] = React.useState('')
  const [isactive, setIsactive] = React.useState('')
  const [userCity, setUserCity] = React.useState('')
  const [userCountry, setUserCountry] = React.useState('')
  const [checkBox, setCheckBox] = React.useState(false)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const getAllUser = useSelector((state) => state.getAllUser)

  const table_name = 'USER'
  const columnMetadata = {
    USER: {
      fields: [
        {
          name: 'user_email',
          headerTitle: 'USER EMAIL',
          is_editable: true,
          is_filterable: true,
          size: 20,
        },
        {
          name: 'user_name',
          headerTitle: 'USER NAME',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'title',
          headerTitle: 'TITLE',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'role_type',
          headerTitle: 'ROLE TYPE',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'chapter_role',
          headerTitle: 'CHAPTER ROLE',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'company',
          headerTitle: 'COMPANY',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'khc_group',
          headerTitle: 'KHC GROUP',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'city',
          headerTitle: 'CITY',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'country',
          headerTitle: 'COUNTRY',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
        {
          name: 'is_active',
          headerTitle: 'IS ACTIVE',
          is_editable: true,
          is_filterable: true,
          size: 9,
        },
      ],
      tag: 'user',
    },
  }
  const hiddenColumns = {
    USER: [
      'KH_UID',
      'KH_ETL_IS_DELETED',
      'KH_MODIFIED_ON',
      'KH_MODIFIED_BY',
      'KH_CREATED_ON',
      'KH_CREATED_BY',
    ],
  }

  const visibleUserColumns = [
    'user_email',
    'user_name',
    'title',
    'role_type',
    'chapter_role',
    'company',
    'khc_group',
    'city',
    'country',
    'is_active',
  ]

  useEffect(() => {
    localStorage.setItem('menu', 'badge-users')
  }),
    []

  const fetchData = async (
    { pageSize = 50, pageIndex = 1 },
    filters = null,
    sort_value = columnSort
  ) => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    setLocalPageIndex(pageIndex)

    const params = {
      page_size: pageSize,
      page_number: pageIndex + 1, //handle 0 indexing
      filters: filters || JSON.stringify(columnFilters),
      sort: sort_value,
    }
    dispatch(getAllusers(endpoint, params))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }

  useEffect(() => {
    let table_data = getAllUser?.getAllUser?.data
    if (table_data?.length > 0) {
      setTableColumns(
        TableHandler.createColumnMappingforKholab(
          [
            'user_email',
            'user_name',
            'title',
            'role_type',
            'chapter_role',
            'company',
            'khc_group',
            'city',
            'country',
            'is_active',
          ],
          columnMetadata,
          table_name
        )
      )
      setTotalPages(getAllUser?.getAllUser?.num_pages)
      setTotalRows(getAllUser?.getAllUser?.total_results)
      setTableData(table_data)
    } else {
      setTotalPages(0)
      setTotalRows(0)
      setTableData([])
    }
  }, [setTableData, getAllUser])

  const handleEdit = () => {
    if (editRows.length > 0) {
      setshowModal(true)
      setUserEmail(editRows[0].user_email)
      setUserName(editRows[0].user_name)
      setUserTitle(editRows[0].title)
      setUserCompany(editRows[0].company)
      setUserRoletype(editRows[0].role_type)
      setUserChapterRole(editRows[0].chapter_role)
      setUserGroup(editRows[0].khc_group)
      setUserCity(editRows[0].city)
      setUserCountry(editRows[0].country)
      setIsactive(editRows[0].is_active)
    }
  }
  const handleCloseModal = () => {
    setshowModal(false)
  }
  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    const payload = {
      user_email: userEmail,
      first_name: editRows[0].first_name,
      last_name: editRows[0].last_name,
      user_name: userName,
      title: userTitle,
      role_type: userRoletype == '' ? null : userRoletype,
      chapter_role: userChapterRole == '' ? null : userChapterRole,
      company: userCompany,
      khc_group: userGroup,
      city: userCity,
      country: userCountry,
      badge_score: editRows[0].badge_score,
      is_active: isactive == true || isactive == 'true' ? true : false,
      start_date: editRows[0].start_date,
      created_on: editRows[0].created_on,
      created_by: editRows[0].created_by,
    }
    dispatch(editUser(endpoint, payload))
      .then(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })

        handleCloseModal()

        fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
      })
      .catch(() => {
        setLoadingStatus({
          status: false,
          message: 'Fetching table records...',
        })
      })
  }
  //Update using Inline Editing
  const handleUpdateEditMode = () => {
    editRows.push(inlineValue)

    // updateRecords_inline_editing()
    setEditModeState(false)
    setEditRows([])
  }

  const handleValueEditableMode = (idx) => (e) => {
    setLoadingStatus({ status: false, message: 'Fetching table records...' })

    inlineValue[idx] = e.target.value
  }

  //handle sorting on columns by updating fetch data sort value
  const handleSorting = (header) => {
    setSort(true)

    if (columnSort.length !== 0 && columnSort[0].field !== header) {
      setColumnSortValue([
        { field: `${header}`, direction: columnSort[0].direction },
      ])
    } else {
      if (columnSort.length === 0) {
        setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
      } else if (columnSort[0].direction === 'asc') {
        setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
      } else {
        setColumnSortValue([])
      }
    }
  }

  // contains list of setTimout ids
  let timeoutIds = []

  // clears all the setTimeout from timeoutIds array
  const clearAllTimeout = () => {
    for (let i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i])
    }
    timeoutIds = []
  }
  // Custome Checkbox component
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef()
      const resolvedRef = ref || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      )
    }
  )

  // Deafult Column

  function DefaultColumnFilter({
    column: { filterValue, setFilter },
    headers,
  }) {
    const [initLoad, setInitLoad] = React.useState(true)

    useEffect(() => {
      if (initLoad === true) {
        return
      }
      clearAllTimeout()
      setEditModeState(false)

      let timeoutId = setTimeout(() => {
        let filter_payload =
          checkBox === false
            ? []
            : [{ op: 'eq', field: 'is_active', value: checkBox.toString() }]
        headers.forEach((header_obj, index) => {
          if (header_obj.filterValue !== undefined && index >= 1) {
            let multipleFilter = header_obj.filterValue.split(',')
            multipleFilter.forEach((value) => {
              if (value.length !== 0) {
                if (value[0] === '"' && value[value.length - 1] === '"') {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'like' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] =
                      '%' + value.slice(1, value.length - 1) + '%'
                    filter_payload.push(local_obj)
                  }
                } else if (
                  value[0] === '*' &&
                  value[value.length - 1] === '*'
                ) {
                  if (value.length === 2) {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = null
                    filter_payload.push(local_obj)
                    filter_payload.push({
                      op: 'ilike',
                      field: header_obj.id,
                      value: '',
                    })
                  } else {
                    let local_obj = { op: 'eq' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = value.slice(1, value.length - 1)
                    filter_payload.push(local_obj)
                  }
                } else {
                  let local_obj = { op: 'ilike' }
                  local_obj['field'] = header_obj.id
                  local_obj['value'] = '%' + value + '%'
                  filter_payload.push(local_obj)
                }
              } else {
                let local_obj = { op: 'ilike' }
                local_obj['field'] = header_obj.id
                local_obj['value'] = '%' + value + '%'
                filter_payload.push(local_obj)
              }
            })
          }
        })
        setInitLoad(true)
        setColumnFilters(filter_payload)
        fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
      }, 2200)

      timeoutIds.push(timeoutId)
    }, [filterValue, pageSize])

    return (
      <input
        //disabled={disableColumn}
        className="form-control"
        value={filterValue || ''}
        onFocus={() => {
          setInitLoad(false)
        }}
        placeholder="type to search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      />
    )
  }
  const defaultColumn = {
    Filter: DefaultColumnFilter,
  }

  const available_page_size = ['50', '100', '200', '500']
  const data = useMemo(() => tableData, [hiddenColumns, tableData])
  const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    state: { selectedRowIds, pageIndex, pageSize },
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: localPageIndex,
        hiddenColumns: hiddenColumns[table_name],
        pageSize: 50,
        selectedRowIds: { 1: false },
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: totalPages,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: <div></div>,
          Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
            const currentState = row.getToggleRowSelectedProps()
            return (
              <IndeterminateCheckbox
                {...currentState}
                onClick={() => {
                  toggleAllRowsSelected(false)
                  toggleRowSelected(row.id, !currentState.checked)
                }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  // state to export badge
  const exportUsers = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    let visibleCols = visibleUserColumns

    let columnRawFilters = columnFilters

    if (columnRawFilters === null || columnRawFilters.length === 0) {
      columnRawFilters = [
        { op: 'eq', field: 'is_active', value: checkBox.toString() },
      ]
    }

    const params = {
      filters: JSON.stringify(columnRawFilters),
      sort: JSON.stringify(columnSort),
    }
    dispatch(exportAdminUsers(endpoint, visibleCols, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }

  // used for manual pagination
  const onNext = () => {
    gotoPage(pageIndex + 1)
  }
  const onPrevious = () => {
    gotoPage(pageIndex - 1)
  }
  const handleClickCheckbox = (e) => {
    if (e.target.checked) {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }
  // updates editRows state on select checkbox event
  useEffect(() => {
    setEditRows(selectedFlatRows.map((d) => d.original))
  }, [selectedFlatRows])

  useEffect(() => {
    //   setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (checkBox === false) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(columnFilters),
        JSON.stringify(columnSort)
      )
    } else if (checkBox === true) {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify([{ op: 'eq', field: 'is_active', value: 'true' }]),
        JSON.stringify(columnSort)
      )
    } else {
      fetchData(
        { pageSize, pageIndex },
        JSON.stringify(columnFilters),
        JSON.stringify(columnSort)
      )
    }
  }, [pageSize, pageIndex, columnSort, checkBox])
  // updates localStorage with new list of hiddenColumns
  useEffect(() => {
    localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
  }, [hiddenColumns])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="Users"
            Index={
              <>
                <div className="top-toolbar">
                  <div className="right-toolbar">
                    <OverlayTrigger
                      overlay={<Tooltip id="tooltip"> Export Data</Tooltip>}
                    >
                      <button
                        variant="outline-primary"
                        // title="Export Data"

                        onClick={exportUsers}
                      >
                        <span className="toolbar-icon">
                          <BiExport />
                        </span>{' '}
                        Export
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
                <div
                  className="table-container"
                  style={{ textAlign: 'center' }}
                >
                  <Table {...getTableProps()}>
                    <thead className="sticky-table-header">
                      {headerGroups.map((headerGroup, i) => (
                        <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column, i) =>
                            column?.id === 'selection' ? (
                              <th key={column.id}>
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps(),
                                    { style: { width: column.size } }
                                  )}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['Header'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            ) : column.id == 'is_active' ? (
                              <th key={i}>
                                <div className="table-header-badge">
                                  {column.render('Header')}
                                </div>
                                <div>
                                  <input
                                    type="checkbox"
                                    onClick={handleClickCheckbox}
                                    checked={checkBox}
                                  />
                                </div>
                              </th>
                            ) : (
                              <th key={i}>
                                <div
                                  onClick={() => handleSorting(column.id)}
                                  className="table-header-badge"
                                >
                                  {column.render('Header')}

                                  {columnSort[0]?.field === column['id'] ? (
                                    <span>
                                      {columnSort[0]?.direction === 'asc'
                                        ? ' 🔽'
                                        : '' ||
                                          columnSort[0]?.direction === 'desc'
                                        ? ' 🔼'
                                        : ''}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render('Filter')
                                    : null}
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      className="tbody mybadgesBody"
                      {...getTableBodyProps()}
                    >
                      {page.map((row, index) => {
                        prepareRow(row)
                        return (
                          <tr key={index}>
                            {row.cells.map((cell) => {
                              return cell.column.id === 'is_active' ? (
                                cell.value == true ? (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    <MdOutlineDone
                                      style={{
                                        fontSize: '25px',
                                        color: 'green',
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td
                                    key={cell}
                                    {...cell.getCellProps({
                                      style: {
                                        width: cell.column.size + '%',
                                      },
                                    })}
                                  >
                                    <MdHighlightOff
                                      style={{
                                        fontSize: '25px',
                                        color: 'red',
                                      }}
                                    />
                                  </td>
                                )
                              ) : (
                                <td
                                  key={cell}
                                  {...cell.getCellProps({
                                    style: {
                                      width: cell.column.size + '%',
                                    },
                                  })}
                                >
                                  {cell.render('Cell')}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                {/* Bottom ToolBar of table */}
                <Row>
                  <Col sm={6}>
                    <div className="page-control">
                      <div className="page-of">
                        Page{' '}
                        <em>
                          {pageIndex + 1} of {totalPages}
                        </em>
                      </div>
                      <div className="prev-next-btn">
                        <button
                          className="badge-btn"
                          onClick={() => onPrevious()}
                          disabled={!canPreviousPage}
                        >
                          {' '}
                          Prev{' '}
                        </button>
                        <button
                          onClick={() => onNext()}
                          className="badge-btn"
                          disabled={!canNextPage}
                        >
                          {' '}
                          Next{' '}
                        </button>
                      </div>
                      <div className="second-control">
                        <span>Go to page:</span>
                        <input
                          className="page-number-input"
                          type="number"
                          defaultValue={pageIndex || 1}
                          onBlur={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value)
                              : 0
                            // handling zero indexing
                            gotoPage(page - 1)
                          }}
                        />
                        <select
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(Number(e.target.value))
                          }}
                        >
                          {available_page_size.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: 'inline-block',
                          marginLeft: '24px',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        {' '}
                        Total results: {getAllUser?.getAllUser?.total_results}
                      </div>
                    </div>
                  </Col>
                  {/* Bottom-right Buttons */}
                  <Col sm={6}>
                    <Button
                      className="m_r-5 block-btn1 main-button btn-sm"
                      onClick={handleEdit}
                    >
                      EDIT
                    </Button>
                  </Col>
                </Row>
                <Modal size="xl" show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Request</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="modalBody">
                    <>
                      <Form>
                        <Container className="m-0">
                          <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group
                              className=""
                              as={Col}
                              md="4"
                              controlId="validationFormik01"
                            >
                              <Form.Label>User Email</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  disabled
                                  aria-describedby="inputGroupPrepend"
                                  name="mail"
                                  defaultValue={userEmail}
                                  onChange={(e) => setUserEmail(e.target.value)}
                                  // onChange={(e) => setField('de_mail', e.target.value, e)}
                                  // isInvalid={!!errors.de_mail}
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group
                              className=""
                              as={Col}
                              md="4"
                              controlId="validationFormik01"
                            >
                              <Form.Label>User Name</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  aria-describedby="inputGroupPrepend"
                                  name="user_name"
                                  disabled
                                  defaultValue={userName}
                                  onChange={(e) => setUserName(e.target.value)}
                                  // onChange={(e) => setField('de_mail', e.target.value, e)}
                                  // isInvalid={!!errors.de_mail}
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="flexItem" as={Col} md="4">
                              <Form.Label>Title</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  aria-describedby="inputGroupPrepend"
                                  name="mail"
                                  disabled
                                  defaultValue={userTitle}
                                  onChange={(e) => setUserTitle(e.target.value)}
                                  // onChange={(e) => setField('de_mail', e.target.value, e)}
                                  // isInvalid={!!errors.de_mail}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group className="flexItem" as={Col} md="4">
                              <Form.Label>Role Type</Form.Label>
                              <Form.Select
                                value={userRoletype}
                                onChange={(e) =>
                                  setUserRoletype(e.target.value)
                                }
                              >
                                <option value="">None</option>

                                {JSON.parse(
                                  localStorage.getItem('badge_metadata')
                                )?.channels?.map((chapter, index) => {
                                  return (
                                    <option value={chapter} key={index}>
                                      {chapter}
                                    </option>
                                  )
                                })}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="flexItem" as={Col} md="4">
                              <Form.Label>Chapter Role</Form.Label>
                              <Form.Select
                                value={userChapterRole}
                                onChange={(e) =>
                                  setUserChapterRole(e.target.value)
                                }
                              >
                                <option value="">None</option>
                                <option value="Chapter Head">Chapter Head</option>
                                <option value="C4E">C4E</option>
                                <option value="Delegate">Delegate</option>
                                <option value="Vendor Lead">Vendor Lead</option>
                              </Form.Select>
                            </Form.Group>
                            <Form.Group
                              className="flexItem"
                              as={Col}
                              md="3"
                              controlId="validationFormikUsername"
                            >
                              <Form.Label>Company</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  aria-describedby="inputGroupPrepend"
                                  name="mail"
                                  defaultValue={userCompany}
                                  onChange={(e) =>
                                    setUserCompany(e.target.value)
                                  }
                                  // onChange={(e) => setField('de_mail', e.target.value, e)}
                                  // isInvalid={!!errors.de_mail}
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="flexItem" as={Col} md="4">
                              <Form.Label>Group</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  aria-describedby="inputGroupPrepend"
                                  name="mail"
                                  defaultValue={userGroup}
                                  onChange={(e) => setUserGroup(e.target.value)}
                                  // onChange={(e) => setField('de_mail', e.target.value, e)}
                                  // isInvalid={!!errors.de_mail}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Row>
                          <Row className="mb-3 mr-3 rowFlex">
                            <Form.Group as={Col} md="4" className="flexItem">
                              <Form.Label>City</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  aria-describedby="inputGroupPrepend"
                                  name="mail"
                                  defaultValue={userCity}
                                  onChange={(e) => setUserCity(e.target.value)}
                                  // onChange={(e) => setField('de_mail', e.target.value, e)}
                                  // isInvalid={!!errors.de_mail}
                                />
                              </InputGroup>
                            </Form.Group>{' '}
                            <Form.Group as={Col} md="4" className="flexItem">
                              <Form.Label>Country</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  aria-describedby="inputGroupPrepend"
                                  name="mail"
                                  defaultValue={userCountry}
                                  onChange={(e) =>
                                    setUserCountry(e.target.value)
                                  }
                                  // onChange={(e) => setField('de_mail', e.target.value, e)}
                                  // isInvalid={!!errors.de_mail}
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className="flexItem">
                              <Form.Label>Is Active</Form.Label>
                              <Form.Check // prettier-ignore
                                disabled
                                type="switch"
                                id="custom-switch"
                                //   value={isactive}
                                checked={isactive}
                                onChange={(e) => setIsactive(e.target.checked)}
                                // label="Check this switch"
                              />
                            </Form.Group>
                          </Row>
                        </Container>
                      </Form>
                      <Modal.Footer>
                        <Button
                          variant="primary"
                          type="submit"
                          className="addModalBtn"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                        <Button
                          variant="danger"
                          type="submit"
                          className="addModalBtn"
                          onClick={handleCloseModal}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </>
                  </Modal.Body>
                </Modal>
              </>
            }
          />{' '}
        </>
      )}
    </>
  )
}
