import React, { useState, createContext } from 'react'

export const RefDataContext = createContext()

export const RefDataProvider = (props) => {
  const [refData, setRefData] = useState({
    tableList: [],
    columnMetadata: {},
    auditTable: '',
    hiddenColumns: {},
  })

  return (
    <RefDataContext.Provider value={[refData, setRefData]}>
      {props.children}
    </RefDataContext.Provider>
  )
}
