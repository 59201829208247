import React, { useContext, useEffect, useState } from 'react'
import EditPost from './EditPost'
import Sidebar from '../../../AppBar/Appbar'
import { AuthContext } from '../../../../context/authProvider'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import { getpostbyid } from '../../../../../redux/actions/collabAction'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: theme.palette.text.secondary,
  height: '100%',
}))
export function Index() {
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [questionData, setQuestionData] = useState()
  const [tagValue, setTagValue] = useState([])
  //const ifChannel = false

  const getPostbyidcollabData = useSelector(
    (state) => state.getPostbyidcollabData
  )

  const dispatch = useDispatch()
  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('q')
  useEffect(() => {
    async function handlepostbyid() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      dispatch(getpostbyid(endpoint, id)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    handlepostbyid()
  }, [id])

  useEffect(() => {
    if (getPostbyidcollabData?.loading == false) {
      let quesData = getPostbyidcollabData?.getPostbyidcollabData?.data
      setQuestionData(quesData?.question)
      let tagName = quesData?.question?.tags
      var finalArray = tagName.map(function (obj) {
        return obj.tag_name
      })
      setTagValue(finalArray)
    }
  }, [getPostbyidcollabData?.getPostbyidcollabData?.data])

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="Edit Question"
            Index={
              <>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Item>
                        <EditPost
                          questionData={questionData}
                          tagValue={tagValue}
                        />
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </>
            }
          />
        </>
      )}
    </>
  )
}

export default Index
