import React from 'react'
import { MdHelpOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Typography } from '@mui/material'
import { Button } from 'react-bootstrap'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
import Slide from '@mui/material/Slide'

export default function helpContent() {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{ marginRight: '10px' }}>
      <OverlayTrigger
        overlay={<Tooltip id="tooltip">What is Capacity Tracker ?</Tooltip>}
      >
        <Button
          aria-label="how to use capacity tracker ?"
          variant="outlined"
          label="fd"
          onClick={handleClickOpen}
        >
          <MdHelpOutline style={{ fontSize: '28px', color: '#F9D949' }} />
        </Button>
      </OverlayTrigger>

      <Dialog
        maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="How to Use Capacity Tracker ?"
      >
        <DialogTitle>{'What is Capacity Tracker ?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography gutterBottom>
              1. Capacity Tracker allows you to Add your Sprint details by click
              on add button and fill information. By default current sprint date
              will be shown in the modal. Only Admins can select other sprint
              dates from the list and also they can modify it.
            </Typography>

            <Typography gutterBottom>
              2. In Capacity Tracker you can perform filter and multi filter
              across the columns. You can also sort the data by clicking on the
              column name.
            </Typography>

            <Typography gutterBottom>
              3. For searching multiple values in a single column, enter values
              separated by a comma with no spaces in between. For example inside
              board column type KIDEO,CA.
            </Typography>
            <Typography gutterBottom>
              4. If you missed your capacity entry due to any unforeseen
              reasons. Please email at{' '}
              <a
                href="mailto:khcdataengineeringcoe@kraftheinz.com"
                className="email-link"
              >
                khcdataengineeringcoe@kraftheinz.com
              </a>
              . On the basis of discussion admin will record your entry.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
