import {
  BADGE_RESET,
  EDIT_ADMIN_BADGE_ERROR,
  EDIT_ADMIN_BADGE_POST,
  EDIT_ADMIN_BADGE_LOAD,
} from '../constants/types'

const initialState = {
  editBadgeAdmin: [],
  loading: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_ADMIN_BADGE_LOAD:
      return {
        ...state,
        loading: true,
      }

    case EDIT_ADMIN_BADGE_POST:
      return {
        ...state,
        editBadgeAdmin: action.payload,
        loading: false,
      }
    case EDIT_ADMIN_BADGE_ERROR:
      return {
        ...state,
        loading: false,
      }
    case BADGE_RESET:
      return {
        badgeApprove: [],
        loading: true,
      }
    default:
      return state
  }
}
