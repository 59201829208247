import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from 'react-bootstrap'

const HelperComponent = ({ openModel, closeModel, transiton }) => {
  return (
    <Dialog
      maxWidth="md"
      open={openModel}
      TransitionComponent={transiton}
      keepMounted
      onClose={closeModel}
      aria-describedby="How to use the Glossary?"
    >
      <DialogTitle>{'How to use the Glossary?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography gutterBottom component="div">
            <Box fontWeight="700" display="inline">
              The Goal of the Glossary
            </Box>{' '}
            {`
                              - is to serve as a resource for tech teams to share definitions of important technical terms
                              as well as important KHC terms within each team's respective field.
                              This resource can be a great way to share knowledge across the company and help new team members
                              quickly grasp important concepts and terminology.
                              `}
            <br />
          </Typography>

          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Submit Terms
            </Box>{' '}
            - You are encouraged to contribute to the glossary and submit new
            terms! Each term will go through an approval process to be added to
            the glossary. There are designated approvers for each Chapter (Data
            Engineering, Data Science, etc...). Each Term can also have tags to
            help classify your terms.
            <br />
          </Typography>

          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Finding Terms
            </Box>{' '}
            - Please use the search bar or the ABC selector to filter to your
            desired term. Double-click on a letter to reset and show all
            letters.
          </Typography>
          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Help
            </Box>{' '}
            - If you need any help you can reach out to us
            <br />{' '}
            <a
              href="mailto:pankaj.maddan@kraftheinz.com"
              className="email-link"
            >
              pankaj.maddan@kraftheinz.com
            </a>
            ,
            <a
              href="mailto:manvendrasingh.bais@kraftheinz.com "
              className="email-link"
            >
              {' '}
              manvendrasingh.bais@kraftheinz.com
            </a>
            ,
            <a href="mailto:rohit.tiwari@kraftheinz.com" className="email-link">
              {' '}
              rohit.tiwari@kraftheinz.com
            </a>
            ,
            <a href="mailto:grant.culp@kraftheinz.com" className="email-link">
              {' '}
              grant.culp@kraftheinz.com
            </a>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default HelperComponent
