import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from 'react-bootstrap'

const HelperComponent = ({ openModel, closeModel, transiton }) => {
  return (
    <Dialog
      maxWidth="md"
      open={openModel}
      TransitionComponent={transiton}
      keepMounted
      onClose={closeModel}
      aria-describedby="How to Use Capacity Tracker ?"
    >
      <DialogTitle>{'How to use Q&A Forum ?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography gutterBottom component="div">
            <Box fontWeight="700" display="inline">
              Ask Question
            </Box>{' '}
            - Q&A Forum tool allows you to add questions with the options of
            title, body and tags.
            <br />
            <ul>
              <ol>
                title- Add the relevant title for the post.
                <br />
                body- You can add description of your post and elabrote your
                query in detail. you can use code-blocks, links, headings and
                other specify tools in text area.
                <br />
                <b>Note:</b> You can use @ to mention someone in your post.
                <br />
                tags- You can add tags relevant with the post you are adding.
                Click on enter after writing tag in input field.
              </ol>
            </ul>
          </Typography>

          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Answer/Comment
            </Box>{' '}
            - You are allowed to contribute in the platform by answering to the
            queries and give them detailed answer. Also you can commnet on the
            post if you want to convey something to the user who posted the
            question. user will be notified via email who posted the questions.
            <br />
            <b>Note:</b> You can use @ to mention someone in your answer.
          </Typography>

          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Upvote/Downvote
            </Box>{' '}
            - If you think any post you saw is relevant and helps you as well
            you can give upvote by clicking on up arrow showing along with
            question/answer. similarly if something is not useful and wrong post
            is posted you can downvote it.
          </Typography>
          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Search
            </Box>{' '}
            - You can search any post by entering the value in the search field.
            Minimum 3 characters are allowed to perform auto search.
          </Typography>
          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Filters
            </Box>{' '}
            - You can sort the questions with the given options of newest/oldest
            and mostly viewed by click on filters showing in homepage.
          </Typography>
          <Typography gutterBottom>
            <Box fontWeight="700" display="inline">
              Help
            </Box>{' '}
            - If you need any help you can reach out to us
            <br />{' '}
            <a
              href="mailto:pankaj.maddan@kraftheinz.com"
              className="email-link"
            >
              pankaj.maddan@kraftheinz.com
            </a>
            ,
            <a
              href="mailto:manvendrasingh.bais@kraftheinz.com "
              className="email-link"
            >
              manvendrasingh.bais@kraftheinz.com{' '}
            </a>
            ,
            <a href="mailto:rohit.tiwari@kraftheinz.com" className="email-link">
              rohit.tiwari@kraftheinz.com
            </a>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default HelperComponent
