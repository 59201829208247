const COLORS = {
  TEXT_DEFAULT: '#ffffff',
  CURSOR_DEFUALT: '#000000',
  FILTERED_DEFUALT: '#919599',
  BACKGROUND_DEFUALT: '#005e7a',

  TITLE: '#ffffff',
  CURSOR: '#000000',
  FILTERED: '#919599',
  BACKGROUND: '#005e7a',

  SELECTED: {
    BASE: '#88447d',
    HIGHLIGHT: '#44517c',
  },

  EDGE: {
    BASE: '#006f8a',
    SELECTED: '#bc6bb6',
    DEFAULT: {
      BASE: '#006f8a',
    },
  },

  EXPOSURE: {
    BASE: '#ff6316',
    HIGHLIGHT: '#ff8c75',
    TEXT: '#ffffff',

    DEFAULT: {
      BASE: '#ff6316',
      HIGHLIGHT: '#ff8c75',
      TEXT: '#ffffff',
    },
  },

  ANALYSIS: {
    BASE: '#23C11B',
    HIGHLIGHT: '#1a8414',
    TEXT: '#ffffff',

    DEFAULT: {
      BASE: '#23C11B',
      HIGHLIGHT: '#1a8414',
      TEXT: '#ffffff',
    },
  },

  SOURCE: {
    BASE: '#23C11B',
    HIGHLIGHT: '#1a8414',
    TEXT: '#ffffff',

    DEFAULT: {
      BASE: '#23C11B',
      HIGHLIGHT: '#1a8414',
      TEXT: '#ffffff',

      SELECTED: '#88447d',
    },
  },

  NODE: {
    BASE: '#0094b3',
    HIGHLIGHT: '#007997',
    TEXT: '#ffffff',

    DEFAULT: {
      BASE: '#0094b3',
      HIGHLIGHT: '#007997',
      TEXT: '#ffffff',
    },
  },

  SNAPSHOT: {
    BASE: '#002db3',
    HIGHLIGHT: '#1a8cff',
    TEXT: '#ffffff',

    DEFAULT: {
      BASE: '#002db3',
      HIGHLIGHT: '#1a8cff',
      TEXT: '#ffffff',
    },
  },

  SEED: {
    BASE: '#3a6e78',
    HIGHLIGHT: '#595959',
    TEXT: '#ffffff',

    DEFAULT: {
      BASE: '#3a6e78',
      HIGHLIGHT: '#595959',
      TEXT: '#ffffff',
    },
  },

  TEST: {
    BASE: '#b3b300',
    HIGHLIGHT: '#808000',
    TEXT: '#ffffff',

    DEFAULT: {
      BASE: '#b3b300',
      HIGHLIGHT: '#808000',
      TEXT: '#ffffff',
    },
  },
}

export default COLORS
