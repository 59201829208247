import React from 'react'
import { protectedResources } from '../../context/authConfig.js'
import axios from 'axios'

export default function GetUserProfilePhoto({ selected_user_email }) {
  const [imageUrl, setImageUrl] = React.useState(null)
  const graphEndpoint = `${protectedResources.graphUser.endpoint}/${selected_user_email}/photo/$value`
  React.useEffect(() => {
    if (selected_user_email) {
      axios
        .get(graphEndpoint, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('graphToken')}`,
          },
          responseType: 'blob',
        })
        .then((res) => {
          const url = window.URL || window.webkitURL
          const blobUrl = url.createObjectURL(res.data)
          setImageUrl(blobUrl)
        })
        .catch((e) => {
          if (e.response.status == 404) {
            console.log(
              `${e.response.status} : profile picture does not exist for this user`
            )
          } else {
            console.error(e.message)
          }
        })
    }
  }, [])

  return (
    <>
      {imageUrl ? (
        <img src={imageUrl} alt="user-profile-photo" />
      ) : (
        <img
          src="https://gravatar.com/avatar/52b6cd44df3318182df4c76821e7dd4c?s=400&d=robohash&r=x"
          alt=""
        />
      )}
    </>
  )
}
