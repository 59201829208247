import { React, useContext } from 'react'
import './css/Leftbar.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../../../context/authProvider'

function Rightbar({
  tags,
  upvotedPosts,
  setsearchCollabname,
  setQuestions,
  setTotalPages,
  setLoadingStatus,
  channel,
  setTotalQuestions,
}) {
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint

  function handleTagClick(e) {
    fetchPostsByTag(e.target.innerText)
  }

  const fetchPostsByTag = async (data) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })

    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }
    await axios
      .get(
        `${endpoint}/api/collab/get_post_by_tag?tag_name=${data}&page=1&per_page=10&sort_order=desc&channel=${channel}`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        setsearchCollabname(`[${data}]`)
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        setQuestions(res?.data?.data)
        setTotalPages(Math.ceil(res?.data?.record_count / 10))
        setTotalQuestions(res?.data?.total_count)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <div class="left-content">
        <div className="PopularTags">
          <div className="heading-tags">
            <p> Popular Tags</p>
          </div>
          <div className={'popular'}>
            {tags?.map((tag) => {
              return (
                <div key={tag}>
                  <a className="tag" onClick={handleTagClick}>
                    {tag.tag_name}
                  </a>
                  <span className={'multiplier'}>×</span>
                  &nbsp;
                  <span className={'count'}>{tag.post_count}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div className="leftbar">
          <div className="leftbar-container">
            <div className="leftbar-options">
              <div className="leftbar-option">
                <div className="link">
                  <ul>
                    <div className="heading-tags">Most Upvoted Questions</div>
                    <li className="vote-links">
                      {upvotedPosts?.map((post) => {
                        return (
                          <div className="link-ques" key={post}>
                            <Link
                              to={`/kholab/post?q=${post?.id}`}
                              className="post-title"
                            >
                              {post.title}
                            </Link>
                          </div>
                        )
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Rightbar
