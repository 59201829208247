import {
  GET_TABLES,
  GET_TABLES_DATA_LOADING,
  GET_TABLES_DATA_ERROR,
} from '../constants/types'

const initialState = {
  tables: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TABLES_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_TABLES:
      return {
        ...state,
        tables: action.payload,
        loading: false,
      }
    case GET_TABLES_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
