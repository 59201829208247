import {
  addGridRuleAction,
  deleteGridRuleAction,
  getGridRulesAction,
  getItemsAction,
  getStep05LikelihoodReportAction,
  getStep05SummaryAction,
  getStep0LikelihoodReportAction,
  getStep0SummaryAction,
  getStep1LikelihoodReportAction,
  getStep1SummaryAction,
  getUniqueValuesAction,
  sendEmailAction,
  updateGridRuleAction,
} from '../redux/actions/fnfAction.js'
import { exportFile } from '../redux/actions/importExportFileAction.js'
class feesFinesServices {
  getUniqueValues(dispatch, endpoint, step) {
    return dispatch(getUniqueValuesAction(endpoint, step)).then(() => {})
  }
  getStep0Summary(dispatch, endpoint, payload) {
    return dispatch(getStep0SummaryAction(endpoint, payload)).then(() => {})
  }

  getStep05Summary(dispatch, endpoint, payload) {
    return dispatch(getStep05SummaryAction(endpoint, payload)).then(() => {})
  }
  getStep1Summary(dispatch, endpoint, payload) {
    return dispatch(getStep1SummaryAction(endpoint, payload)).then(() => {})
  }
  getLikelihoodReportStep0(dispatch, endpoint, payload) {
    return dispatch(getStep0LikelihoodReportAction(endpoint, payload)).then(
      () => {}
    )
  }
  getLikelihoodReportStep05(dispatch, endpoint, payload) {
    return dispatch(getStep05LikelihoodReportAction(endpoint, payload)).then(
      () => {}
    )
  }

  getLikelihoodReportStep1(dispatch, endpoint, payload) {
    return dispatch(getStep1LikelihoodReportAction(endpoint, payload)).then(
      () => {}
    )
  }

  getItems(dispatch, endpoint, payload) {
    return dispatch(getItemsAction(endpoint, payload)).then(() => {})
  }

  getGridRules(dispatch, endpoint, payload) {
    return dispatch(getGridRulesAction(endpoint, payload)).then(() => {})
  }

  getGridUniqueValues(dispatch, endpoint) {
    return dispatch(getUniqueValuesAction(endpoint, 'grid')).then(() => {})
  }

  addGridRule(dispatch, endpoint, wrapper) {
    return dispatch(addGridRuleAction(endpoint, wrapper))
  }

  editGridRule(dispatch, endpoint, wrapper) {
    return dispatch(updateGridRuleAction(endpoint, wrapper))
  }

  deleteGridRule(dispatch, endpoint, wrapper) {
    return dispatch(deleteGridRuleAction(endpoint, wrapper))
  }

  sendEmail(dispatch, endpoint, payload) {
    return dispatch(sendEmailAction(endpoint, payload))
  }

  exportFile(dispatch, endpoint, payload, params, fileName, isFnf) {
    return dispatch(exportFile(endpoint, payload, params, fileName, isFnf))
  }
}

const fAndF = new feesFinesServices()
export default fAndF
