import {
  CREATE_JIRA_TRACKER_ENTRY,
  CREATE_JIRA_TRACKER_ENTRY_LOADING,
  CREATE_JIRA_TRACKER_ENTRY_ERROR,
  GET_JIRA_TRACKER_ENTRIES,
  GET_JIRA_TRACKER_ENTRIES_ERROR,
  GET_JIRA_TRACKER_ENTRIES_LOADING,
  EDIT_JIRA_TRACKER_ENTRY_LOADING,
  EDIT_JIRA_TRACKER_ENTRY,
  EDIT_JIRA_TRACKER_ENTRY_ERROR,
  GET_JIRA_MODAL_METADATA,
  GET_JIRA_MODAL_METADATA_ERROR,
  GET_JIRA_MODAL_METADATA_LOADING,
  GET_CURRENT_SPRINT_LOADING,
  GET_CURRENT_SPRINT_ERROR,
  GET_CURRENT_SPRINT,
} from '../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const createNewJiraEntry = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_JIRA_TRACKER_ENTRY_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/capacity_tracker/create_entry`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: CREATE_JIRA_TRACKER_ENTRY,
          payload: res.data,
        })
        toast('Data Added successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: CREATE_JIRA_TRACKER_ENTRY_ERROR,
      payload: console.log('error in create jira entry'),
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getJiraEntries = (endpoint, params, token) => async (dispatch) => {
  dispatch({
    type: GET_JIRA_TRACKER_ENTRIES_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/capacity_tracker/get_entry`, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_JIRA_TRACKER_ENTRIES,
          payload: res.data,
        })
        if (res.data.data.length === 0) {
          toast('No records found!', toastTopRightError)
        }
      })
  } catch (e) {
    dispatch({
      type: GET_JIRA_TRACKER_ENTRIES_ERROR,
      payload: e.response.data.message,
    })
  }
}

export const editJiraEntry = (endpoint, payload, param) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_JIRA_TRACKER_ENTRY_LOADING,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(
        `${endpoint}/api/capacity_tracker/update_object?key=${param}&table_name=DE_CAPACITY_TRACKER`,
        payload,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        dispatch({
          type: EDIT_JIRA_TRACKER_ENTRY,
          payload: res.data,
        })
        toast('Data Edit successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: EDIT_JIRA_TRACKER_ENTRY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getModalMetadata = (endpoint, token) => async (dispatch) => {
  dispatch({
    type: GET_JIRA_MODAL_METADATA_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/capacity_tracker/metadata`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_JIRA_MODAL_METADATA,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_JIRA_MODAL_METADATA_ERROR,
      payload: console.log(e),
    })
  }
}
export const getCurrentSprint = (endpoint, token) => async (dispatch) => {
  dispatch({
    type: GET_CURRENT_SPRINT_LOADING,
  })
  try {
    await axios
      .get(`${endpoint}/api/capacity_tracker/get_current_sprint`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_CURRENT_SPRINT,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_CURRENT_SPRINT_ERROR,
      payload: console.log(e),
    })
  }
}
