import axios from 'axios'
class TableHandler {
  // predifines hidden columns (as per audit columns from reftables)
  static DEFAULT_HIDDEN_COLS = [
    'KH_UID',
    'KH_ETL_IS_DELETED',
    'KH_MODIFIED_ON',
    'KH_MODIFIED_BY',
    'KH_CREATED_ON',
    'KH_CREATED_BY',
  ]

  // creates column mapping for useTable hook
  static createColumnMapping = (list_of_cols, column_metadata, table_name) => {
    table_name = table_name.toUpperCase()
    let no_of_cols = list_of_cols.length
    let column_mapping = []
    if (table_name in column_metadata) {
      let metadata_array = column_metadata[table_name]['fields']
      for (let i = 0; i < no_of_cols; i++) {
        let can_filter = metadata_array[i]?.is_filterable
        column_mapping.push({
          Header: list_of_cols[i],
          accessor: list_of_cols[i],
          disableColumn: !can_filter,
          show: false,
          // disableFilters: !can_filter
        })
      }
    }
    return column_mapping
  }

  // returns an object containing empty values for enables columns (used by AddRow component)
  static createAddRowObject = (enabled_columns) => {
    const obj = {}
    enabled_columns.forEach((element) => {
      obj[element] = ''
    })
    return obj
  }

  // returns an array containing editable column
  static getEditableColumnList = (column_metadata, table_name) => {
    let result = []
    table_name = table_name.toUpperCase()
    if (table_name in column_metadata) {
      column_metadata[table_name]['fields'].forEach((value) => {
        if (value.is_editable) result.push(value.name)
      })
    }
    return result
  }

  // returns an array containing all the columns in a table excluding hidden columns for eg. all the "KH_" columns
  static getAddColumnList = (column_metadata, table_name, hidden_columns) => {
    let column_list = []
    table_name = table_name.toUpperCase()
    if (table_name in column_metadata) {
      column_metadata[table_name]['fields'].forEach((value) => {
        if (!hidden_columns?.includes(value.name)) {
          column_list.push(value.name)
        }
      })
    }
    return column_list
  }

  // given a table returns all columns in an array
  static getAllColumns = (column_metadata, table_name) => {
    let result = ['Select All']
    table_name = table_name.toUpperCase()
    if (table_name in column_metadata) {
      column_metadata[table_name]['fields'].forEach((value) => {
        result.push(value.name)
      })
    }
    return result
  }

  // returns an object containing editable columns with value 'true'
  static getEditableColumnMap = (column_metadata, table_name) => {
    let result = {}
    table_name = table_name.toUpperCase()
    if (table_name in column_metadata) {
      column_metadata[table_name]['fields'].forEach((value) => {
        let key = value.name
        result[key] = value.is_editable
      })
    }
    return result
  }

  static getDatatype = (column_metadata, table_name) => {
    let result = {}
    table_name = table_name.toUpperCase()
    if (table_name in column_metadata) {
      column_metadata[table_name]['fields'].forEach((value) => {
        let key = value.name
        result[key] = value.datatype
      })
    }
    return result
  }

  // returns name of the audit table used (reads tag [audit_table] from the server)
  static getAuditTableName = (column_metadata) => {
    for (const table in column_metadata) {
      if (column_metadata[table]['tag'] === 'audit_table') return table
    }
    return null
  }

  // returns object containg all table names with their hidden column (from server or from local storage)
  static createHiddenList = (column_metadata) => {
    let result = {}

    // gets hidden_columns from local storage
    // const hidden_columns = JSON.parse(localStorage.getItem("hidden_columns"));

    // if(hidden_columns) {
    // For remembering the last selected list of hidden columns
    // if(hidden_columns) {
    //     console.log("picked from storage")
    //     return hidden_columns
    // }

    Object.keys(column_metadata).forEach((table_name) => {
      if (table_name === 'SNOWFLAKE_CLONE') {
        result[table_name] = [
          'KH_UID',
          'LAST_CLONED_BY_DEV',
          'LAST_CLONED_BY_UAT',
          'LAST_CLONED_BY_SBX',
          'LAST_CLONED_ON_DEV',
          'LAST_CLONED_ON_UAT',
          'LAST_CLONED_ON_SBX',
          'KH_MODIFIED_ON',
          'KH_MODIFIED_BY',
          'KH_CREATED_ON',
          'KH_CREATED_BY',
        ]
      } else if (table_name === 'GREENSTOCK_MODELS') {
        result[table_name] = [
          'KH_UID',
          'KH_ETL_IS_DELETED',
          'KH_MODIFIED_ON',
          'KH_MODIFIED_BY',
          'KH_CREATED_ON',
          'KH_CREATED_BY',
          'MODEL_ID',
          'ARCHIVE_LOCATION',
        ]
      } else {
        result[table_name] = this.DEFAULT_HIDDEN_COLS
      }
    })
    // sets hidden_columns to local storage
    localStorage.setItem('hidden_columns', JSON.stringify(result))
    return result
  }

  // returns the column which should be visible in the table (used in export api to mention available columns)
  static getVisibleColumns = (
    hidden_column_list,
    column_metadata,
    table_name
  ) => {
    let result = ['KH_UID']
    table_name = table_name.toUpperCase()
    if (table_name in column_metadata) {
      column_metadata[table_name]['fields'].forEach((value) => {
        if (
          hidden_column_list.find((element) => element === value.name) ===
            undefined &&
          value.name !== 'KH_UID'
        )
          result.push(value.name)
      })
    }

    return result
  }

  // changes react-table sortBy object to kh_api compatible object
  static getSortArray = (sortby_array) => {
    if (sortby_array.length < 1) return null

    let result = sortby_array.map((obj) => {
      return {
        field: obj.id,
        direction: obj.desc ? 'desc' : 'asc',
      }
    })
    return result
  }

  static createColumnMappingforKholab = (
    list_of_cols,
    column_metadata,
    table_name
  ) => {
    table_name = table_name.toUpperCase()
    let metadata_array = column_metadata[table_name]['fields']
    const column_mapping = metadata_array.map((result) => {
      const data = {
        Header: result.headerTitle,
        headerTitle: result.headerTitle,
        accessor: result.name,
        disableColumn: !result.is_filterable,
        show: false,
        disableFilters: !result?.is_filterable,
        size: result?.size,
      }
      return data
    })

    return column_mapping
  }
}

// ToDo: create services for all the requests made to the server [Improvement]
class RequestHandler {
  static getCollection = async (requestUri, accessToken, urlParams) => {
    axios
      .get(requestUri, {
        params: urlParams,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        let table_data = response.data.data
        if (table_data.length > 0) {
          return table_data
        } else {
          return { message: 'Zero records fetched' }
        }
      })
      .catch((error) => {
        return { message: `Error: ${error}` }
      })
  }
}

class ForumHandler {
  static toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link'],
    [{ header: 1 }, { header: 2 }], // custom button value
    [{ list: 'ordered' }, { list: 'bullet' }],
  ]
  static allowedCharactersQuill = /^[A-Za-z\sÅÄÖåäö.]*$/
  static userHTMLCharacters =
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi

  static fetchUserData = async (
    endpoint,
    searchTerm,
    renderList,
    mentionChar
  ) => {
    try {
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .get(`${endpoint}/api/collab/get_users?user_email=${searchTerm}`, {
          headers: post_headers,
        })
        .then((res) => {
          let userData

          if (mentionChar === '@') {
            userData = res.data.data.slice(0, 10)
            const values = userData?.map((d) => {
              let value
              if (d?.khc_group && d?.title) {
                value =
                  d?.user_name + ' (' + d?.khc_group + '-' + d?.title + ')'
              } else if (d?.title) {
                value = d?.user_name + ' (' + d?.title + ')'
              } else if (d?.khc_group) {
                value = d?.user_name + ' (' + d?.khc_group + ')'
              } else {
                value = d?.user_name
              }
              return { id: d?.user_email, value: value }
            })
            renderList(values, searchTerm)
          }
        })
    } catch (error) {
      return { message: `Error: ${error}` }
    }
  }
}

export { TableHandler, RequestHandler, ForumHandler }
