import {
  GET_BADGE_TOP_EARNERS,
  GET_BADGE_TOP_EARNERS_LOAD,
  GET_BADGE_TOP_EARNERS_ERROR,
} from '../constants/types'

const initialState = {
  badgeTopEarnersData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BADGE_TOP_EARNERS_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_BADGE_TOP_EARNERS:
      return {
        ...state,
        loading: false,
        badgeTopEarnersData: action.payload,
      }
    case GET_BADGE_TOP_EARNERS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
