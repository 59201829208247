import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import pako from 'pako'

export const pgpUploadFile = (endpoint, payload, file1) => async (dispatch) => {
  try {
    dispatch(pgpFileUploadBegin())
    let post_headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    await axios
      .post(`${endpoint}/api/crypto/encrypt_file`, payload, {
        headers: post_headers,
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          // Show progress in case of large files
          const statusMessage = document.getElementById('status-message')
          statusMessage.textContent = `Downloading file.. ${Math.round(
            progressEvent.progress * 100
          )}%`
        },
      })
      .then(async (res) => {
        dispatch(pgpFileUploadSuccess('ok'))
        const decompressedBlob = await decompressBlob(res.data)
        const url = window.URL.createObjectURL(decompressedBlob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${file1.name.split('.')[0]}.csv.pgp`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
  } catch (e) {
    dispatch(pgpFileUploadFailure(e))
  }
}

export const PGP_FILE_UPLOAD_BEGIN = 'PGP_FILE_UPLOAD_BEGIN'
export const PGP_FILE_UPLOAD_SUCCESS = 'PGP_FILE_UPLOAD_SUCCESS'
export const PGP_FILE_UPLOAD_FAIL = 'PGP_FILE_UPLOAD_FAIL'

const pgpFileUploadBegin = () => ({
  type: PGP_FILE_UPLOAD_BEGIN,
})

const pgpFileUploadSuccess = (getData) => ({
  type: PGP_FILE_UPLOAD_SUCCESS,
  payload: getData,
})

const pgpFileUploadFailure = (error) => ({
  type: PGP_FILE_UPLOAD_FAIL,
  payload: { error },
})

const decompressBlob = async (blob) => {
  const decompressedArrayBuffer = await blob.arrayBuffer()
  const decompressedData = pako.inflate(new Uint8Array(decompressedArrayBuffer))
  return new Blob([decompressedData])
}
