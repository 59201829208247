import {
  GET_SUMMARY_BADGES,
  GET_SUMMARY_BADGES_LOAD,
  GET_SUMMARY_BADGE_ERROR,
} from '../constants/types'

const initialState = {
  summaryData: [],
  loading: true,
  error: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUMMARY_BADGES_LOAD:
      return {
        ...state,
        loading: true,
      }
    case GET_SUMMARY_BADGES:
      return {
        ...state,
        loading: false,
        summaryData: action.payload,
      }
    case GET_SUMMARY_BADGE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
